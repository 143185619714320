import React, { useState, useEffect, useRef } from "react";
import getPeaks from "../../../../utils/recorder/peaks";

const dpr = window.devicePixelRatio || 1;

type Props = {
  audioBuffer: AudioBuffer | null;
  width: number;
  height: number;
  color1: string;
  color2: string;
};
const Waver = (props: Props) => {
  const canvasRef = useRef<any>(null);
  const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);
  const { audioBuffer, width, height, color1, color2 } = props;
  const [peaks, setPeaks] = useState<[number, number][] | null>(null);

  useEffect(() => {
    const setPeak = (channelData: Float32Array) => {
      const localPeaks = getPeaks(width * dpr, channelData);
      setPeaks(localPeaks);
    };
    if (audioBuffer !== null) {
      setPeak(audioBuffer.getChannelData(0));
    }
  }, [audioBuffer, width]);

  useEffect(() => {
    const repaint = () => {
      if (peaks !== null && ctx !== null) {
        const count = peaks?.length;
        const centerY = (height / 2) * dpr;

        ctx.lineWidth = 1;
        ctx.clearRect(0, 0, width * dpr, height * dpr);

        for (let i = 0; i < count; i += 1) {
          const [min, max] = peaks[i];
          const x = i - 0.5;

          ctx.beginPath();
          ctx.strokeStyle = color1;
          ctx.moveTo(x, (min + 1) * height + 0.5);
          ctx.lineTo(x, centerY);
          ctx.stroke();

          ctx.beginPath();
          ctx.strokeStyle = color2;
          ctx.moveTo(x, centerY);
          ctx.lineTo(x, (max + 1) * height + 0.5);
          ctx.stroke();
        }
      }
    };
    const canvas = canvasRef.current;
    if (canvas !== null) {
      const localCtx = canvas.getContext("2d");
      setCtx(localCtx);
      repaint();
    }
  }, [color1, color2, ctx, height, peaks, width]);

  return (
    <canvas
      ref={canvasRef}
      className="wave-canvas"
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
      width={width * dpr}
      height={height * dpr}
    />
  );
};

export default Waver;
