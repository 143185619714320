import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    menu: number;
    content: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    menu: 0,
    content: "",
  },
};

const contactFormModule = createSlice({
  name: "contactForm",
  initialState,
  reducers: {
    setContactFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.menu = payload.formValue.menu;
      state.formValue.content = payload.formValue.content;
    },
  },
});

export const { setContactFormValue } = contactFormModule.actions;

export default contactFormModule.reducer;
