/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  Popover,
  Whisper,
  Modal,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  SelectPicker,
  Schema,
  Alert,
  List,
} from "rsuite";
import { FormInstance } from "rsuite/lib/Form";
import { Typography } from "@material-ui/core";
import Select from "react-select";
import UsersTable from "../../../presentational/molecules/Table/UsersTable";
import { RootState } from "../../../../reducers/rootReducer";
import { fetchUsers } from "../../../../modules/Management/Users/usersApiModule";
import Title from "../../../presentational/atoms/Text/Title";
import Loading from "../../../presentational/molecules/Loading";
import { Margin } from "../../../../utils/styled";
import { gray } from "../../../../utils/color";
import { setEditFormValue } from "../../../../modules/Management/Form/User/userEditFormModule";
import { putUser } from "../../../../modules/Management/Users/updateUserApiModule";
import { setCheckedKeys } from "../../../../modules/Management/checkModule";
import { setAddFormValue } from "../../../../modules/Management/Form/User/userAddFormModule";
import { fetchRoomGroups } from "../../../../modules/Management/RoomGroups/roomGroupsApiModule";
import { postUser } from "../../../../modules/Management/Users/addUserApiModule";
import { delUser } from "../../../../modules/Management/Users/deleteUserApiModule";
import { delUsers } from "../../../../modules/Management/Users/deleteUsersApiModule";
import MuiPrimaryButton from "../../../presentational/atoms/Button/MuiPrimaryButton";
import MuiDangerButton from "../../../presentational/atoms/Button/MuiDangerButton";
import MuiCancelButton from "../../../presentational/atoms/Button/MuiCancelButton";
import { theme as colorTheme } from "../../../../theme";
import useAlert from "../../../../hooks/useAlert";
import getCompanies from "../../../../api/Management/companiesApi";

export const StyledPrimaryButton = styled(MuiPrimaryButton)`
  margin-right: 5px;
`;

export const StyledCancelButton = styled(MuiCancelButton)``;

export const StyledEditButton = styled(({ isChecked, ...props }) => (
  <MuiPrimaryButton {...props} />
))`
  ${({ isChecked, theme }) => {
    return css`
      ${isChecked
        ? css`
            background: ${theme.palette.primary.main};
          `
        : css`
            background: ${gray};
            &:hover {
              background: ${gray};
            }
          `}
      margin-right: 5px;
    `;
  }}
`;

export const StyledDeleteButton = styled(({ isChecked, ...props }) => (
  <MuiDangerButton {...props} />
))`
  ${({ isChecked, theme }) => {
    return css`
      ${isChecked
        ? css`
            background: ${theme.palette.error.main};
          `
        : css`
            background: ${gray};
            &:hover {
              background: ${gray};
            }
          `}
      margin-right: 5px;
    `;
  }}
`;

export const StyledButtons = styled.div`
  width: 100%;
  padding-top: 5px;
  text-align: right;
`;

const SelectWrapper = styled.div`
  width: 20%;
  font-size: 12px;
  margin-right: 10px;
`;

export const StyledModal = styled(Modal)`
  margin-top: 100px;
`;

const editModel = Schema.Model({
  name: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  auth: Schema.Types.NumberType().isRequired("必須項目です"),
  userType: Schema.Types.NumberType().isRequired("必須項目です"),
  roomGroupId: Schema.Types.StringType().isRequired("必須項目です"),
});

const addModel = Schema.Model({
  mail: Schema.Types.StringType()
    .isEmail("正しいメールアドレスを入力してください")
    .isRequired("必須項目です"),
  auth: Schema.Types.NumberType().isRequired("必須項目です"),
  userType: Schema.Types.NumberType().isRequired("必須項目です"),
  roomGroupId: Schema.Types.StringType().isRequired("必須項目です"),
});

type listProps = { label: string; value: number }[];
export const authList: listProps = [
  {
    label: "管理者",
    value: 0,
  },
  {
    label: "開発者",
    value: 1,
  },
  {
    label: "オーナー遠隔スタッフ",
    value: 2,
  },
  {
    label: "一般遠隔スタッフ",
    value: 3,
  },
];

export const userTypeList: listProps = [
  {
    label: "管理者",
    value: 0,
  },
  {
    label: "遠隔スタッフ",
    value: 1,
  },
  {
    label: "システム",
    value: 2,
  },
  {
    label: "ロボット",
    value: 3,
  },
];

const Users = () => {
  const { info, success, error, closeSnackbar } = useAlert();
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [editForm, setEditForm] = useState<FormInstance>();
  const [addForm, setAddForm] = useState<FormInstance>();
  const [authForm, setAuthForm] = useState<listProps>(authList);
  const [userTypeForm] = useState<listProps>(userTypeList);

  const [condition, setCondition] = useState<any>();
  const [companyOptions, setCompanyOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const dispatch = useDispatch();
  const { token, auth } = useSelector((state: RootState) => state.auth);

  const { data, isLoading, error: usersError } = useSelector(
    (state: RootState) => state.users
  );
  const { data: roomGroupData, error: roomGroupError } = useSelector(
    (state: RootState) => state.roomGroups
  );

  const roomGroups = useMemo(
    () =>
      roomGroupData?.roomGroups.map(value => {
        return {
          label: `${value.name}: ${value.roomList.reduce(
            (p, c) => `${p}, ${c}`,
            ""
          )}`,
          value: value.roomGroupId,
        };
      }),
    [roomGroupData]
  );

  const { checks } = useSelector((state: RootState) => state.checkCells);
  const checkedKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "users")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );

  const { formValue: editFormValue } = useSelector(
    (state: RootState) => state.userEditForm
  );

  const { formValue: addFormValue } = useSelector(
    (state: RootState) => state.userAddForm
  );

  const filteredUserType = useMemo(() => {
    if (addFormValue.auth === 2) {
      return userTypeForm.filter(item => item.value === 0);
    }
    if (addFormValue.auth === 3) {
      return userTypeForm.filter(item => item.value === 1);
    }

    if (editFormValue.auth === 2) {
      return userTypeForm.filter(item => item.value === 0);
    }
    if (editFormValue.auth === 3) {
      return userTypeForm.filter(item => item.value === 1);
    }
    return userTypeForm;
  }, [addFormValue, editFormValue.auth, userTypeForm]);

  useEffect(() => {
    const fetchData = () => {
      if (token) {
        const companyIds = condition?.map((item: any) => item.value);
        dispatch(fetchUsers(token, companyIds));
        dispatch(fetchRoomGroups(token));
      }
    };
    fetchData();
  }, [dispatch, token, condition]);

  useEffect(() => {
    if (auth !== null) {
      setAuthForm(authList.filter(authNo => authNo.value >= auth));
    }
  }, [auth]);

  useEffect(() => {
    const fetchCompanies = async () => {
      if (token && auth !== null && auth < 2) {
        const res = await getCompanies(token);
        const companies = Array.from(
          new Map(
            res.companies
              .map(company => {
                return { value: company.companyId, label: company.name };
              })
              .map(item => [item.value, item])
          ).values()
        );

        setCompanyOptions(companies);
      }
    };
    fetchCompanies();
  }, [auth, token]);

  const handleEditClick = () => {
    const userData = data?.users.filter(
      user => user.userId === checkedKeys[0]
    )[0];
    dispatch(
      setEditFormValue({
        formValue: {
          id: checkedKeys[0],
          name: userData?.name,
          auth: userData?.authNo,
          userType: userData?.userType,
          roomGroupId: userData?.roomGroupId,
          memo: userData?.remark,
        },
      })
    );
    setShowEdit(true);
  };

  const handleAddClick = () => {
    setShowAdd(true);
  };

  const handleDeleteClick = () => {
    setShowDelete(true);
  };

  const onCancelClick = () => {
    setShowEdit(false);
    setShowAdd(false);
    dispatch(
      setAddFormValue({
        formValue: {
          mail: "",
          auth: 0,
          userType: 0,
          roomGroupId: "",
          memo: "",
        },
      })
    );
    setShowDelete(false);
  };

  const handleCheckAndSubmitEditForm = async () => {
    const checkResult = editForm!.check();
    if (checkResult && token) {
      setShowEdit(false);
      try {
        await dispatch(
          putUser(
            token,
            editFormValue.id ? editFormValue.id : "",
            editFormValue.name ? editFormValue.name : "",
            editFormValue.auth !== undefined ? editFormValue.auth : "",
            editFormValue.userType !== undefined ? editFormValue.userType : "",
            editFormValue.roomGroupId ? editFormValue.roomGroupId : "",
            editFormValue.memo ? editFormValue.memo : ""
          )
        );
        Alert.success("遠隔スタッフ情報の更新をしました", 5000);
        dispatch(
          setCheckedKeys({
            id: "users",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        await dispatch(fetchUsers(token));
        await dispatch(fetchRoomGroups(token));
        setShowEdit(false);
      } catch (err) {
        Alert.error("不明なエラーが発生しました", 5000);
      }
    }
  };

  const handleCheckAndSubmitAddForm = async () => {
    const checkResult = addForm!.check();
    if (checkResult && token) {
      try {
        setShowAdd(false);
        const userAddKey = info({
          text: "指定したメールアドレスに認証メールを送信中...",
        });
        await dispatch(
          postUser(
            token,
            addFormValue.mail,
            addFormValue.auth,
            addFormValue.userType,
            addFormValue.roomGroupId,
            addFormValue.memo ? addFormValue.memo : ""
          )
        );
        dispatch(
          setCheckedKeys({
            id: "users",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        dispatch(
          setAddFormValue({
            formValue: {
              mail: "",
              auth: 0,
              userType: 0,
              roomGroupId: "",
              memo: "",
            },
          })
        );
        closeSnackbar(userAddKey);
        success({ text: "指定したメールアドレスに認証メールを送信しました" });
        setShowAdd(false);
        await dispatch(fetchUsers(token));
        await dispatch(fetchRoomGroups(token));
      } catch (err) {
        closeSnackbar();
        error({ text: "不明なエラーが発生しました" });
        console.error(err);
      }
    }
  };

  const handleSubmitDelete = async () => {
    if (token) {
      try {
        if (checkedKeys.length === 1) {
          await dispatch(delUser(token, checkedKeys[0]));
        } else {
          await dispatch(delUsers(token, checkedKeys));
        }
        dispatch(
          setCheckedKeys({
            id: "users",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        setShowDelete(false);
        Alert.success("削除しました", 5000);
        await dispatch(fetchUsers(token));
        await dispatch(fetchRoomGroups(token));
      } catch (err) {
        if (err.resultStatus) {
          switch (err.resultStatus) {
            case 115:
              Alert.error(
                "この遠隔スタッフには、指定のユーザを削除できる権限がありません。",
                2000
              );
              break;
            default:
              Alert.error("不明なエラーが発生しました", 2000);
          }
        }
        Alert.error("不明なエラーが発生しました", 2000);
      }
    }
  };

  const handleEditFormChange = (value: any) => {
    dispatch(setEditFormValue({ formValue: value }));
  };

  const handleAddFormChange = (value: any) => {
    dispatch(setAddFormValue({ formValue: value }));
  };

  const editSpeaker = () => {
    if (checkedKeys.length > 1) {
      return (
        <Popover title="編集について">
          <p>一度に編集できる遠隔スタッフは1人です</p>
          <p>チェックを外してください</p>
        </Popover>
      );
    }

    return (
      <Popover title="編集について">
        <p>編集したい遠隔スタッフにチェックをつけてください</p>
      </Popover>
    );
  };

  const deleteSpeaker = () => {
    return (
      <Popover title="削除について">
        <p>削除したい遠隔スタッフにチェックをつけてください</p>
        <p>※複数可</p>
      </Popover>
    );
  };

  if (roomGroupError === 115 || usersError === 115) {
    return (
      <>
        <Title>遠隔スタッフ管理</Title>
        <Margin top={20}>
          <div>このページを閲覧する権限がありません</div>
        </Margin>
      </>
    );
  }

  return (
    <>
      <Typography variant="h6">遠隔スタッフ管理</Typography>
      <Margin top={20}>
        {auth !== null && auth < 2 && (
          <SelectWrapper>
            <Select
              styles={{ menu: provided => ({ ...provided, zIndex: 11111 }) }}
              isMulti
              isSearchable
              name="conditions"
              options={companyOptions}
              value={condition}
              onChange={value => {
                setCondition(value);
              }}
              placeholder="カンパニーが選べます"
              closeMenuOnSelect={false}
            />
          </SelectWrapper>
        )}
        {!isLoading ? (
          data && data !== null && roomGroupData && roomGroupData !== null ? (
            <>
              <StyledButtons>
                <StyledPrimaryButton
                  variant="contained"
                  color="primary"
                  onClick={handleAddClick}
                >
                  追加
                </StyledPrimaryButton>
                {checkedKeys.length > 0 && checkedKeys.length < 2 ? (
                  <StyledEditButton
                    variant="contained"
                    color="primary"
                    isChecked
                    onClick={handleEditClick}
                  >
                    編集
                  </StyledEditButton>
                ) : (
                  <Whisper
                    placement="autoVerticalStart"
                    trigger="hover"
                    speaker={editSpeaker()}
                  >
                    <StyledEditButton
                      variant="contained"
                      color="primary"
                      isChecked={false}
                    >
                      編集
                    </StyledEditButton>
                  </Whisper>
                )}
                {checkedKeys.length > 0 ? (
                  <StyledDeleteButton
                    variant="contained"
                    color="primary"
                    isChecked
                    onClick={handleDeleteClick}
                  >
                    削除
                  </StyledDeleteButton>
                ) : (
                  <Whisper
                    placement="autoVerticalStart"
                    trigger="hover"
                    speaker={deleteSpeaker()}
                  >
                    <StyledDeleteButton
                      variant="contained"
                      color="primary"
                      isChecked={false}
                    >
                      削除
                    </StyledDeleteButton>
                  </Whisper>
                )}
              </StyledButtons>
              <Margin top={10} />
              <UsersTable
                users={data.users}
                roomGroups={roomGroupData.roomGroups}
              />
            </>
          ) : (
            <>
              <div>遠隔スタッフはいません</div>
              <StyledPrimaryButton onClick={handleAddClick}>
                追加
              </StyledPrimaryButton>
            </>
          )
        ) : (
          <Loading />
        )}
      </Margin>
      <StyledModal show={showEdit} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            遠隔スタッフ編集
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={editModel}
            formValue={editFormValue}
            ref={(ref: FormInstance) => setEditForm(ref)}
            onChange={handleEditFormChange}
          >
            <FormGroup>
              <ControlLabel>遠隔スタッフID</ControlLabel>
              <FormControl readOnly name="id" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>遠隔スタッフ名</ControlLabel>
              <FormControl name="name" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>権限</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                searchable={false}
                data={authForm}
                name="auth"
                placeholder="権限を選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>遠隔スタッフタイプ</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                searchable={false}
                data={filteredUserType}
                name="userType"
                placeholder="遠隔スタッフタイプを選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>ルームグループ</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                searchable={false}
                data={roomGroups || [{ label: "", value: "" }]}
                name="roomGroupId"
                placeholder="ルームグループを選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="memo" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <StyledPrimaryButton
            variant="contained"
            color="primary"
            onClick={handleCheckAndSubmitEditForm}
          >
            登録
          </StyledPrimaryButton>
          <StyledCancelButton
            variant="contained"
            color="primary"
            onClick={onCancelClick}
          >
            取り消し
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showAdd} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            遠隔スタッフ追加
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={addModel}
            formValue={addFormValue}
            ref={(ref: FormInstance) => setAddForm(ref)}
            onChange={handleAddFormChange}
          >
            <FormGroup>
              <ControlLabel>メールアドレス</ControlLabel>
              <FormControl name="mail" placeholder="my_mail@domain.com" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>権限</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                searchable={false}
                data={authForm}
                name="auth"
                placeholder="権限を選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>遠隔スタッフタイプ</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                searchable={false}
                data={filteredUserType}
                name="userType"
                placeholder="遠隔スタッフタイプを選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>ルームグループ</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                searchable={false}
                data={roomGroups || [{ label: "", value: "" }]}
                name="roomGroupId"
                placeholder="ルームグループを選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="memo" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <StyledPrimaryButton
            variant="contained"
            color="primary"
            onClick={handleCheckAndSubmitAddForm}
          >
            登録
          </StyledPrimaryButton>
          <StyledCancelButton
            variant="contained"
            color="primary"
            onClick={onCancelClick}
          >
            取り消し
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showDelete} overflow onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            遠隔スタッフ削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List bordered={false}>
            {checkedKeys
              .map(key => {
                return data?.users
                  .filter(user => user.userId === key)
                  .map(value => value.name)[0];
              })
              .map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{
                    paddingLeft: "10px",
                    color: colorTheme.palette.error.main,
                  }}
                >
                  {item}
                </List.Item>
              ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: colorTheme.palette.error.main,
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記の遠隔スタッフを削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          <StyledDeleteButton
            variant="contained"
            color="primary"
            isChecked
            onClick={handleSubmitDelete}
          >
            削除
          </StyledDeleteButton>
          <StyledCancelButton
            variant="contained"
            color="primary"
            onClick={onCancelClick}
          >
            取り消し
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>
    </>
  );
};

export default Users;
