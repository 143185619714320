import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface pieGraph {
  id: string;
  name: string;
  graph: {
    name: string;
    value: number;
  }[];
}

interface graphState {
  graphs: pieGraph[];
}

const initialState: graphState = {
  graphs: [
    {
      id: "sex",
      name: "男女比",
      graph: [
        {
          name: "男性",
          value: 0,
        },
        {
          name: "女性",
          value: 0,
        },
      ],
    },
    {
      id: "generation",
      name: "年齢比",
      graph: [
        {
          name: "10代",
          value: 0,
        },
        {
          name: "20代",
          value: 0,
        },
        {
          name: "30代",
          value: 0,
        },
        {
          name: "40代",
          value: 0,
        },
        {
          name: "50代",
          value: 0,
        },
      ],
    },
    {
      id: "purpose",
      name: "利用目的",
      graph: [
        {
          name: "仕事",
          value: 0,
        },
        {
          name: "見学",
          value: 0,
        },
        {
          name: "休憩",
          value: 0,
        },
        {
          name: "自習",
          value: 0,
        },
      ],
    },
  ],
};

const pieGraphModule = createSlice({
  name: "piegraph",
  initialState,
  reducers: {
    setRate(state, { payload }: PayloadAction<pieGraph>) {
      state.graphs.forEach(element => {
        if (element.id === payload.id) {
          element.name = payload.name;
          element.graph = payload.graph;
        }
      });
    },
  },
});

export const { setRate } = pieGraphModule.actions;

export default pieGraphModule.reducer;
