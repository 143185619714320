import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

interface Pick {
  id: string;
  startDate: string;
  endDate: string;
}
interface DatePickState {
  picks: Pick[];
}

export const toUtcIso8601str = (momentInstance: any) => {
  return momentInstance
    .clone()
    .utc("ja")
    .format("YYYY-MM-DD HH:mm:ss");
};

const datePickInitialState: DatePickState = {
  picks: [
    {
      id: "csCount",
      startDate: toUtcIso8601str(moment().startOf("day")),
      endDate: toUtcIso8601str(moment().endOf("day")),
    },
    {
      id: "workers",
      startDate: toUtcIso8601str(moment().startOf("day")),
      endDate: toUtcIso8601str(moment().endOf("day")),
    },
    {
      id: "shop",
      startDate: toUtcIso8601str(moment().startOf("day")),
      endDate: toUtcIso8601str(moment().endOf("day")),
    },
    {
      id: "audio",
      startDate: toUtcIso8601str(moment().startOf("day")),
      endDate: toUtcIso8601str(moment().endOf("day")),
    },
  ],
};

const datePickModule = createSlice({
  name: "datePick",
  initialState: datePickInitialState,
  reducers: {
    setDate(state: DatePickState, { payload }: PayloadAction<Pick>) {
      state.picks.forEach(element => {
        if (element.id === payload.id) {
          element.startDate = payload.startDate;
          element.endDate = payload.endDate;
        }
      });
    },
  },
});

export const { setDate } = datePickModule.actions;

export default datePickModule.reducer;
