import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from "@material-ui/core";
import {
  ThemeProvider as StyledThemeProvider,
  createGlobalStyle,
} from "styled-components";
import { SnackbarProvider } from "notistack";
import store, { persistor } from "./stores";
import Routes from "./Routes";
import Loading from "./components/presentational/molecules/Loading";
import * as serviceWorker from "./serviceWorker";
import { theme } from "./theme";

export const drawerWidth = 240;
const GlobalStyle = createGlobalStyle`
body {
  font-family: "Hiragino Sans W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
`;

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <StylesProvider injectFirst>
        <GlobalStyle />
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <StyledThemeProvider theme={theme}>
              <PersistGate loading={<Loading />} persistor={persistor}>
                <Routes />
              </PersistGate>
            </StyledThemeProvider>
          </SnackbarProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </Provider>,
    document.getElementById("root")
  );
};

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (process.env.NODE_ENV === "development" && (module as any).hot) {
  (module as any).hot.accept("./Routes", render);
}
