import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface lineGraph {
  id: string;
  label?: string | null;
  graph: {
    [name: string]: string | number;
  }[];
}

interface graphState {
  graphs: lineGraph[];
}

const initialState: graphState = {
  graphs: [
    {
      id: "servedCount",
      label: "接客数",
      graph: [
        {
          noData: 0,
        },
      ],
    },
  ],
};

const lineGraphModule = createSlice({
  name: "linegraph",
  initialState,
  reducers: {
    setLineGraph(state, { payload }: PayloadAction<lineGraph>) {
      state.graphs.forEach(element => {
        if (element.id === payload.id) {
          element.graph = payload.graph;
        }
      });
    },
  },
});

export const { setLineGraph } = lineGraphModule.actions;

export default lineGraphModule.reducer;
