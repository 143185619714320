/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useContext, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Avatar,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import MenuIcon from "@material-ui/icons/Menu";
import HelpIcon from "@material-ui/icons/Help";
import Link from "@material-ui/core/Link";
import { RoutePath } from "../../../../Routes";
import { RootState } from "../../../../reducers/rootReducer";
import { logout as logoutApi } from "../../../../modules/auth";
import { fetchProfile } from "../../../../modules/Management/Profile/getProfileApiModule";
import { BaseLink } from "../../../presentational/atoms/Link/BaseLink";
import { SidebarContext } from "../../templates/Layout";
import MuiPrimaryButton from "../../../presentational/atoms/Button/MuiPrimaryButton";
import { authList, userTypeList } from "../../templates/Management/Users";

export const drawerWidth = 300;

const StyledAppBar = styled(({ isOpen, ...props }) => <AppBar {...props} />)`
  ${({ isOpen }) =>
    isOpen &&
    `
    width: calc(100% - ${drawerWidth}px);
    `}
  background-color: ${props => props.theme.palette.background.default};
  z-index: ${props => props.theme.zIndex.drawer + 1};
  transition: ${props =>
    props.theme.transitions.create(["width", "margin"], {
      easing: props.theme.transitions.easing.sharp,
      duration: props.theme.transitions.duration.leavingScreen,
    })};
`;

const StyledToolbar = styled(Toolbar)`
  padding-right: 24px;
`;

const StyledIconButton = styled(IconButton)`
  margin-right: 10px;
`;

const LogoLink = styled(BaseLink)`
  flex-grow: 1;
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.palette.primary};
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    color: ${props => props.theme.palette.primary};
  }
`;

const Company = styled(Typography)``;

const ExternalLink = styled(Link)`
  margin-right: 10px;
  text-decoration: none;
  color: black;
  &:focus,
  &:hover {
    color: gray;
    transition: 0.1s;
  }
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const UserName = styled(Typography)`
  font-weight: bold;
  margin-left: 5px;
  margin-bottom: 5px;
`;

const TextGridItem = styled(Grid)``;
const NavHeader = () => {
  const { isOpen, setIsOpen } = useContext(SidebarContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const { token, userId, userName, company, auth, userType } = useSelector(
    (state: RootState) => state.auth
  );
  const { data } = useSelector((state: RootState) => state.getProfile);

  useEffect(() => {
    if (token && userId) {
      dispatch(fetchProfile(token, userId));
    }
  }, [dispatch, token, userId]);

  const authLabel = useMemo(() => {
    return authList.find(item => item.value === (auth as number))?.label;
  }, [auth]);

  const userTypeLabel = useMemo(() => {
    return userTypeList.find(item => item.value === userType)?.label;
  }, [userType]);

  const logout = async () => {
    await dispatch(logoutApi());
    window.location.href = RoutePath.login;
  };

  const clickMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <StyledAppBar position="fixed" isOpen={isOpen}>
      <StyledToolbar>
        {!isOpen && (
          <StyledIconButton edge="start" aria-label="menu" onClick={clickMenu}>
            <MenuIcon />
          </StyledIconButton>
        )}
        <LogoLink to={RoutePath.top}>RURA MANAGEMENT</LogoLink>
        <Company variant="h6" color="inherit" noWrap>
          {company}
        </Company>
        <IconButton disableRipple disableFocusRipple onClick={handleMenu}>
          <Avatar alt={userName ?? "avatar"} src={data?.icon}>
            {userName?.slice(0, 2)}
          </Avatar>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          <UserName>{`遠隔スタッフ名: ${userName}`}</UserName>
          <UserName>{`権限: ${authLabel ?? "不明"}`}</UserName>
          <UserName>{`ユーザータイプ: ${userTypeLabel ?? "不明"}`}</UserName>
          <MenuItem
            component={BaseLink}
            to={RoutePath.management.profile}
            onClick={handleClose}
          >
            <SettingsIcon />
            プロフィール設定
          </MenuItem>
        </Menu>
        <ExternalLink
          href="https://help.timeleap-rura.com/"
          target="_blank"
          rel="noreferrer"
        >
          <TextGridItem container direction="row" alignItems="center">
            <TextGridItem item>
              <HelpIcon
                style={{ display: "inline-flex", verticalAlign: "middle" }}
              />
            </TextGridItem>
            <TextGridItem item>ヘルプ</TextGridItem>
          </TextGridItem>
        </ExternalLink>
        <MuiPrimaryButton variant="contained" color="primary" onClick={logout}>
          ログアウト
        </MuiPrimaryButton>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default NavHeader;
