import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    id: string;
    name: string;
    password: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    id: "",
    name: "",
    password: "",
  },
};

const signUpFormModule = createSlice({
  name: "signUpForm",
  initialState,
  reducers: {
    setSignUpFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.id = payload.formValue.id;
      state.formValue.name = payload.formValue.name;
      state.formValue.password = payload.formValue.password;
    },
  },
});

export const { setSignUpFormValue } = signUpFormModule.actions;

export default signUpFormModule.reducer;
