/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useEffect } from "react";
import { FormInstance } from "rsuite/lib/Form";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Whisper,
  Popover,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Schema,
  SelectPicker,
  Alert,
  List,
} from "rsuite";
import { Typography } from "@material-ui/core";
import { RootState } from "../../../../../reducers/rootReducer";
import { fetchPaymentItems } from "../../../../../modules/Management/PaymentItems/paymentItemsApiModule";
import { Margin } from "../../../../../utils/styled";
import PaymentItemsTable from "../../../../presentational/molecules/Table/PaymentItemsTable";
import {
  StyledPrimaryButton,
  StyledEditButton,
  StyledDeleteButton,
  StyledCancelButton,
  StyledModal,
} from "../Users";
import Loading from "../../../../presentational/molecules/Loading";
import {
  setAddFormValue,
  setAddFormInitialValue,
} from "../../../../../modules/Management/Form/Payment/paymentItemAddFormModule";
import { setEditFormValue } from "../../../../../modules/Management/Form/Payment/paymentItemEditFormModule";
import { postPaymentItem } from "../../../../../modules/Management/PaymentItems/addPaymentItemApiModule";
import { putPaymentItem } from "../../../../../modules/Management/PaymentItems/updatePaymentItemApiModule";
import { setCheckedKeys } from "../../../../../modules/Management/checkModule";
import { delPaymentItem } from "../../../../../modules/Management/PaymentItems/deletePaymentItemApiModule";

const addModel = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  name: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  amount: Schema.Types.StringType()
    .pattern(/\d{1,3}(,\d{3})*/, "数字を入力してください")
    .isRequired("必須項目です"),
  tax: Schema.Types.NumberType().isRequired("必須項目です"),
});

const parseMoney = (value: string) => {
  const result = Number(value.split(",").join(""));
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(result)) {
    return 0;
  }
  return result;
};

const PaymentItems = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [addForm, setAddForm] = useState<FormInstance>();

  const [showEdit, setShowEdit] = useState(false);
  const [editForm, setEditForm] = useState<FormInstance>();

  const [showDelete, setShowDelete] = useState(false);

  const dispatch = useDispatch();

  const { token } = useSelector((state: RootState) => state.auth);

  const { data, isLoading } = useSelector(
    (state: RootState) => state.paymentItems
  );

  useEffect(() => {
    if (token) {
      dispatch(fetchPaymentItems(token));
    }
  }, [dispatch, token]);

  const { checks } = useSelector((state: RootState) => state.checkCells);
  const checkedKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "paymentItems")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );

  const { formValue: addFormValue } = useSelector(
    (state: RootState) => state.paymentItemAddForm
  );

  const { formValue: editFormValue } = useSelector(
    (state: RootState) => state.paymentItemEditForm
  );

  const handleAddClick = () => {
    setShowAdd(true);
  };

  const handleAddFormChange = (value: any) => {
    dispatch(
      setAddFormValue({
        formValue: {
          id: value.id,
          name: value.name,
          amount:
            typeof value.amount === "string"
              ? parseMoney(value.amount).toLocaleString()
              : value.amount,
          tax: value.tax,
          remark: value.remark,
        },
      })
    );
  };

  const handleCheckAndSubmitAddForm = async () => {
    const checkResult = addForm!.check();
    if (checkResult && token) {
      try {
        await dispatch(
          postPaymentItem(
            token,
            addFormValue.id,
            addFormValue.name,
            typeof addFormValue.amount === "string"
              ? parseMoney(addFormValue.amount)
              : addFormValue.amount,
            addFormValue.tax,
            addFormValue.remark
          )
        );
        dispatch(setAddFormInitialValue());
        Alert.success("商品を追加しました", 5000);
        setShowAdd(false);
        await dispatch(fetchPaymentItems(token));
      } catch (err) {
        Alert.error("入力したIDはすでに登録されています", 2000);
      }
    }
  };

  const handleEditClick = () => {
    if (data) {
      const paymentItem = data.paymentItems.filter(
        pi => pi.id === checkedKeys[0]
      )[0];
      dispatch(
        setEditFormValue({
          formValue: {
            id: paymentItem.id,
            name: paymentItem.name,
            amount: paymentItem.amount.toLocaleString(),
            tax: paymentItem.tax,
            remark: paymentItem.remark,
          },
        })
      );
      setShowEdit(true);
    }
  };

  const handleEditFormChange = (value: any) => {
    dispatch(
      setEditFormValue({
        formValue: {
          id: value.id,
          name: value.name,
          amount:
            typeof value.amount === "string"
              ? parseMoney(value.amount).toLocaleString()
              : value.amount,
          tax: value.tax,
          remark: value.remark,
        },
      })
    );
  };

  const handleCheckAndSubmitEditForm = async () => {
    const checkResult = editForm!.check();
    if (checkResult && token) {
      try {
        setShowEdit(false);
        await dispatch(
          putPaymentItem(
            token,
            editFormValue.id,
            editFormValue.name,
            typeof editFormValue.amount === "string"
              ? parseMoney(editFormValue.amount)
              : editFormValue.amount,
            editFormValue.tax,
            editFormValue.remark
          )
        );
        Alert.success("商品情報を更新しました", 5000);
        dispatch(
          setCheckedKeys({
            id: "paymentItems",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        await dispatch(fetchPaymentItems(token));
      } catch (err) {
        Alert.error("不明なエラーが発生しました", 5000);
      }
    }
  };

  const handleDeleteClick = () => {
    setShowDelete(true);
  };

  const handleSubmitDelete = async () => {
    if (token) {
      try {
        setShowDelete(false);
        await dispatch(delPaymentItem(token, checkedKeys[0]));
        dispatch(
          setCheckedKeys({
            id: "paymentItems",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        Alert.success("商品を削除しました", 5000);
        await dispatch(fetchPaymentItems(token));
      } catch (err) {
        Alert.error("不明なエラーが発生しました", 5000);
      }
    }
  };

  const onCancelClick = () => {
    setShowAdd(false);
    setShowEdit(false);
    setShowDelete(false);
  };

  const editSpeaker = () => {
    if (checkedKeys.length > 1) {
      return (
        <Popover title="編集について">
          <p>一度に編集できる商品は1つです</p>
          <p>チェックを外してください</p>
        </Popover>
      );
    }

    return (
      <Popover title="編集について">
        <p>編集したい商品にチェックをつけてください</p>
      </Popover>
    );
  };

  const deleteSpeaker = () => {
    return (
      <Popover title="削除について">
        <p>削除したい商品にチェックをつけてください</p>
      </Popover>
    );
  };

  return (
    <>
      <Typography variant="h6">商品一覧</Typography>
      <Margin top={20}>
        {!isLoading ? (
          data &&
          data !== null &&
          data.paymentItems &&
          data.paymentItems !== null ? (
            <>
              <PaymentItemsTable paymentItems={data.paymentItems} />
              <Margin top={5} />
              <StyledPrimaryButton onClick={handleAddClick}>
                追加
              </StyledPrimaryButton>
              {checkedKeys.length === 1 ? (
                <>
                  <StyledEditButton isChecked onClick={handleEditClick}>
                    編集
                  </StyledEditButton>
                  <StyledDeleteButton isChecked onClick={handleDeleteClick}>
                    削除
                  </StyledDeleteButton>
                </>
              ) : (
                <>
                  <Whisper
                    placement="autoVerticalStart"
                    trigger="hover"
                    speaker={editSpeaker()}
                  >
                    <StyledEditButton isChecked={false}>編集</StyledEditButton>
                  </Whisper>
                  <Whisper
                    placement="autoVerticalStart"
                    trigger="hover"
                    speaker={deleteSpeaker()}
                  >
                    <StyledDeleteButton isChecked={false}>
                      削除
                    </StyledDeleteButton>
                  </Whisper>
                </>
              )}
            </>
          ) : (
            <>
              <div>商品は登録されていません</div>
              <StyledPrimaryButton onClick={handleAddClick}>
                追加
              </StyledPrimaryButton>
            </>
          )
        ) : (
          <Loading />
        )}
      </Margin>

      <StyledModal show={showAdd} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            商品追加
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={addModel}
            formValue={addFormValue}
            ref={(ref: FormInstance) => setAddForm(ref)}
            onChange={handleAddFormChange}
          >
            <FormGroup>
              <ControlLabel>商品ID</ControlLabel>
              <FormControl name="id" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>商品名</ControlLabel>
              <FormControl name="name" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>金額</ControlLabel>
              <FormControl name="amount" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>税金</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={[
                  { label: "10%", value: 10 },
                  { label: "8%", value: 8 },
                  { label: "税込み", value: 0 },
                ]}
                name="tax"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="remark" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <StyledPrimaryButton onClick={handleCheckAndSubmitAddForm}>
            OK
          </StyledPrimaryButton>
          <StyledCancelButton onClick={onCancelClick}>
            Cancel
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showEdit} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title>商品編集</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={addModel}
            formValue={editFormValue}
            ref={(ref: FormInstance) => setEditForm(ref)}
            onChange={handleEditFormChange}
          >
            <FormGroup>
              <ControlLabel>商品ID</ControlLabel>
              <FormControl readOnly name="id" plaintext />
            </FormGroup>
            <FormGroup>
              <ControlLabel>商品名</ControlLabel>
              <FormControl name="name" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>金額</ControlLabel>
              <FormControl name="amount" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>税金</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={[
                  { label: "10%", value: 10 },
                  { label: "8%", value: 8 },
                  { label: "税込み", value: 0 },
                ]}
                name="tax"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="remark" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <StyledPrimaryButton onClick={handleCheckAndSubmitEditForm}>
            OK
          </StyledPrimaryButton>
          <StyledCancelButton onClick={onCancelClick}>
            Cancel
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showDelete} overflow onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            商品削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List bordered={false}>
            {checkedKeys
              .map(key => {
                return data?.paymentItems
                  .filter(pi => pi.id === key)
                  .map(value => value.name)[0];
              })
              .map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{
                    paddingLeft: "10px",
                    color: "#d84421",
                  }}
                >
                  {item}
                </List.Item>
              ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: "#d84421",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記の商品を削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          <StyledDeleteButton isChecked onClick={handleSubmitDelete}>
            OK
          </StyledDeleteButton>
          <StyledCancelButton onClick={onCancelClick}>
            Cancel
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>
    </>
  );
};

export default PaymentItems;
