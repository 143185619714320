import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type Shop = {
  id: string;
  shop: [
    {
      value: string;
      label: string;
    }
  ];
};

type ShopState = {
  shops: Shop[];
};
const initialState: ShopState = {
  shops: [
    {
      id: "dashboard",
      shop: [
        {
          value: "",
          label: "",
        },
      ],
    },
    {
      id: "workers",
      shop: [
        {
          value: "",
          label: "",
        },
      ],
    },
    {
      id: "worker",
      shop: [
        {
          value: "",
          label: "",
        },
      ],
    },
    {
      id: "shop",
      shop: [
        {
          value: "",
          label: "",
        },
      ],
    },
    {
      id: "audio",
      shop: [
        {
          value: "",
          label: "",
        },
      ],
    },
  ],
};

const shopModule = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setShops(state: ShopState, { payload }: PayloadAction<Shop[]>) {
      state.shops.forEach(shop => {
        payload.forEach(selectedShops => {
          if (shop.id === selectedShops.id) {
            shop.shop = selectedShops.shop;
          }
        });
      });
    },
  },
});

export const { setShops } = shopModule.actions;

export default shopModule.reducer;
