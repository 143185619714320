import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type Condition = {
  id: string;
  condition: boolean;
};

type ConditionState = {
  conditions: Condition[];
};

const initialState: ConditionState = {
  conditions: [
    {
      id: "audioOnly",
      condition: false,
    },
    {
      id: "textOnly",
      condition: false,
    },
    {
      id: "workerAudioOnly",
      condition: false,
    },
    {
      id: "workerTextOnly",
      condition: false,
    },
  ],
};

const preCheckConditionModule = createSlice({
  name: "preCheckCondition",
  initialState,
  reducers: {
    setPreCheckConditions(
      state: ConditionState,
      { payload }: PayloadAction<Condition[]>
    ) {
      state.conditions.forEach(condition => {
        payload.forEach(selectedConditions => {
          if (condition.id === selectedConditions.id) {
            condition.condition = selectedConditions.condition;
          }
        });
      });
    },
  },
});

export const { setPreCheckConditions } = preCheckConditionModule.actions;

export default preCheckConditionModule.reducer;
