import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    id: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    id: "",
  },
};

const forgotPasswordFormModule = createSlice({
  name: "forgotPasswordForm",
  initialState,
  reducers: {
    setForgotPasswordFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.id = payload.formValue.id;
    },
  },
});

export const { setForgotPasswordFormValue } = forgotPasswordFormModule.actions;

export default forgotPasswordFormModule.reducer;
