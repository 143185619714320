import React, { useState } from "react";
import {
  Modal,
  Schema,
  FormGroup,
  ControlLabel,
  FormControl,
  Uploader,
  Icon,
  Form,
} from "rsuite";
import { FormInstance } from "rsuite/lib/Form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../reducers/rootReducer";
import { setAddVideoFormValue } from "../../../../modules/Management/Form/videoAddFormModule";
import {
  StyledPrimaryButton,
  StyledCancelButton,
  StyledModal,
  StyledEditButton,
} from "../../../container/templates/Management/Users";

const addVideoModel = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です")
    .pattern(
      /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
      "IDとして入力できるのは半角英数記号のみです。"
    ),
  file: Schema.Types.StringType().isRequired("必須項目です"),
  text: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
});

type Props = {
  show: boolean;
  onHide: () => void;
  setForm: any;
  submitVideo: () => void;
  videoFileInfo: any;
  setVideoFileInfo: any;
};

const AddVideoModal = ({
  show,
  onHide,
  setForm,
  submitVideo,
  videoFileInfo,
  setVideoFileInfo,
}: Props) => {
  const dispatch = useDispatch();

  const [videoLoading, setVideoLoading] = useState(false);
  const { formValue: addVideoFormValue } = useSelector(
    (state: RootState) => state.videoAddForm
  );

  const { isLoading } = useSelector((state: RootState) => state.addVideo);

  const handleAddVideoFormChange = (value: any) => {
    dispatch(
      setAddVideoFormValue({
        formValue: {
          id: value.id,
          file: addVideoFormValue.file,
          text: value.text,
          val: value.id,
          playTime: addVideoFormValue.playTime,
          fileSize: addVideoFormValue.fileSize,
        },
      })
    );
  };

  const handleAddVideoChange = (file: string, playTime: number) => {
    dispatch(
      setAddVideoFormValue({
        formValue: {
          id: addVideoFormValue.id,
          file,
          text: addVideoFormValue.text,
          val: addVideoFormValue.id,
          playTime,
          fileSize: addVideoFormValue.fileSize,
        },
      })
    );
  };

  const previewFile = (file: any, callback: any) => {
    setVideoLoading(true);
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
    setVideoLoading(false);
  };

  return (
    <StyledModal show={show} overflow={false} onHide={onHide}>
      <Modal.Header>
        <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
          動画追加
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          fluid
          model={addVideoModel}
          formValue={addVideoFormValue}
          ref={(ref: FormInstance) => setForm(ref)}
          onChange={handleAddVideoFormChange}
        >
          <FormGroup>
            <ControlLabel>動画ID</ControlLabel>
            <FormControl name="id" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>表示テキスト</ControlLabel>
            <FormControl name="text" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>動画アップロード（100MBまで）</ControlLabel>
            <Uploader
              accept="video/mp4"
              fileListVisible={false}
              autoUpload={false}
              listType="picture"
              onChange={files => {
                const afile = files.pop();
                if (afile !== undefined) {
                  previewFile(afile.blobFile, async (value: any) => {
                    const media = new Audio(value);

                    await setVideoFileInfo({
                      name: afile?.name ? afile.name : "",
                      value,
                      file: afile.blobFile,
                      fileSize: afile.blobFile?.size,
                    });
                    media.onloadedmetadata = () => {
                      handleAddVideoChange(
                        afile.name ? afile.name : "",
                        media.duration
                      );
                    };
                  });
                }
              }}
            >
              <button style={{ width: "200px", height: "112px" }} type="button">
                {videoFileInfo.value !== "" ? (
                  <video src={videoFileInfo.value} width="100%" height="100%" />
                ) : (
                  <Icon icon="camera" size="5x" />
                )}
              </button>
            </Uploader>
            {videoFileInfo.fileSize !== undefined &&
            videoFileInfo.fileSize >= 104857600 ? (
              <div style={{ color: "red" }}>
                <Icon
                  icon="remind"
                  style={{
                    color: "#ffb300",
                    fontSize: 24,
                  }}
                />
                アップロードされた動画はサイズが大きすぎるためアップロードできません
              </div>
            ) : (
              ""
            )}
            <ControlLabel>ファイル名</ControlLabel>
            <FormControl
              name="file"
              value={videoFileInfo.name}
              readOnly
              plaintext
            />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {isLoading || videoLoading ? (
          <>
            <StyledEditButton
              variant="contained"
              color="primary"
              isChecked={false}
            >
              登録
            </StyledEditButton>
            <StyledEditButton
              variant="contained"
              color="primary"
              isChecked={false}
            >
              取り消し
            </StyledEditButton>
          </>
        ) : (
          <>
            <StyledPrimaryButton
              variant="contained"
              color="primary"
              onClick={submitVideo}
            >
              登録
            </StyledPrimaryButton>
            <StyledCancelButton
              variant="contained"
              color="primary"
              onClick={onHide}
            >
              取り消し
            </StyledCancelButton>
          </>
        )}
      </Modal.Footer>
    </StyledModal>
  );
};

export default AddVideoModal;
