import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Uploader,
  Loader,
  Icon,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Schema,
  Avatar,
  Modal,
  Whisper,
  Tooltip,
  Alert,
  SelectPicker,
} from "rsuite";
import { useSelector, useDispatch } from "react-redux";
import { FormInstance } from "rsuite/lib/Form";
import { useHistory } from "react-router-dom";
import Title from "../../../../presentational/atoms/Text/Title";
import { Margin } from "../../../../../utils/styled";
import {
  StyledPrimaryButton,
  StyledCancelButton,
  StyledEditButton,
  StyledModal,
} from "../Users";
import { RootState } from "../../../../../reducers/rootReducer";
import { postMail } from "../../../../../modules/Management/Profile/confirmMailApiModule";
import { RoutePath } from "../../../../../Routes";
import { setProfileFormValue } from "../../../../../modules/Management/Form/Profile/profileFormModule";
import { fetchProfile } from "../../../../../modules/Management/Profile/getProfileApiModule";
import { putProfile } from "../../../../../modules/Management/Profile/updateProfileApiModule";
import { putProfileImage } from "../../../../../modules/Management/Profile/uploadImageApiModule";
import Loading from "../../../../presentational/molecules/Loading";
import SubTitle from "../../../../presentational/atoms/Text/SubTitle";
import { postResetMail } from "../../../../../modules/Management/Profile/resetPasswordMailApiModule";
import { textGray } from "../../../../../utils/color";
import useAlert from "../../../../../hooks/useAlert";
import { preSignProfileImage } from "../../../../../api/Management/profileApi/preSign";
import addS3 from "../../../../../api/Management/imageApi/addS3";

const ProfileWrapper = styled.div`
  background: white;
  padding: 5px;
`;

const FormWrapper = styled.div`
  width: 50%;
  margin: 0 auto;
  text-align: center;
`;

const Item = styled.div`
  margin-left: 5px;
  margin-bottom: 10px;
`;

const ItemTitle = styled.div`
  padding-top: 5px;
  font-size: 15px;
  font-weight: bold;
`;

const StyledTextLink = styled.a`
  font-size: 12px;
  cursor: pointer;
  color: ${textGray};
  text-decoration: none !important;
`;

const model = Schema.Model({
  email: Schema.Types.StringType().isEmail(
    "有効なメールアドレスを入力してください"
  ),
});

const Profile = () => {
  const { info, success, error, closeSnackbar } = useAlert();
  const [uploading] = useState(false);
  const [fileInfo, setFileInfo] = useState<{
    file: File;
    value: string;
  } | null>(null);
  const [showUpload, setShowUpload] = useState(false);

  const [form, setForm] = useState<FormInstance>();

  const [showReset, setShowReset] = useState(false);

  const { token, userId, userName } = useSelector(
    (state: RootState) => state.auth
  );

  const { data, isLoading: profileLoading } = useSelector(
    (state: RootState) => state.getProfile
  );

  const { formValue } = useSelector((state: RootState) => state.profileForm);

  const { isLoading } = useSelector((state: RootState) => state.confirmMail);

  const { isLoading: resetPassLoading } = useSelector(
    (state: RootState) => state.resetPasswordMail
  );

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (token && userId) {
      dispatch(fetchProfile(token, userId));
    }
  }, [dispatch, token, userId]);

  useEffect(() => {
    if (data) {
      dispatch(
        setProfileFormValue({
          formValue: {
            email: data.mail,
            avatar: data.robot_2d,
          },
        })
      );
    }
  }, [data, dispatch]);

  const onCancelClick = () => {
    setShowUpload(false);
    setFileInfo(null);
    setShowReset(false);
  };

  const previewFile = (file: File, callback: Function) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const tooltip = <Tooltip>クリックで変更</Tooltip>;

  const handleAddSubmit = async () => {
    const checkResult = form!.check();
    if (token && userId && checkResult) {
      try {
        await dispatch(postMail(token, userId, formValue.email));
        await history.push(RoutePath.management.confirmMail);
      } catch (err) {
        Alert.error("不明なエラーが発生しました", 5000);
        console.error(err);
      }
    }
  };

  const handleUpload = async () => {
    if (token && userId && fileInfo !== null) {
      try {
        setShowUpload(false);
        info({ text: "プロフィール画像をアップロード中..." });
        const presigned = await preSignProfileImage(token, userId, "add");
        await addS3(presigned.url, fileInfo.file);
        await dispatch(putProfileImage(token, userId, presigned.fileName));

        closeSnackbar();
        success({ text: "アップロード完了" });

        await dispatch(fetchProfile(token, userId));
      } catch (err) {
        error({ text: "不明なエラーが発生しました" });
      }
    }
  };

  const handleFormChange = (value: any) => {
    if (token && userId && value.avatar !== formValue.avatar) {
      dispatch(putProfile(token, userId, value.avatar));
    }

    dispatch(setProfileFormValue({ formValue: value }));
  };

  const confirm = async () => {
    const checkResult = form!.check();
    if (
      checkResult &&
      token &&
      userId &&
      data &&
      data.mail !== formValue.email
    ) {
      try {
        await dispatch(postMail(token, userId, formValue.email));
        await history.push(RoutePath.management.confirmMail);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleResetClick = () => {
    setShowReset(true);
  };

  const handleSubmit = async () => {
    if (userId && data && data.mail) {
      try {
        setShowReset(false);
        Alert.info("メールを送信しています...", 100000000);
        await dispatch(postResetMail(userId));
        Alert.close();
        Alert.success("パスワードリセットのメールを送信しました", 5000);
      } catch (err) {
        Alert.closeAll();
        Alert.error("不明なエラーが発生しました", 5000);
        console.error(err);
      }
    }
  };

  if (data !== null && data.mail === undefined) {
    return (
      <>
        <Title>プロフィール設定</Title>
        <Margin top={20} />
        <ProfileWrapper>
          <SubTitle>まだプロフィールが設定されていません</SubTitle>
          <p>まずはメールアドレスを登録しましょう！</p>
          <Margin top={20}>
            <FormWrapper>
              <Form
                model={model}
                formValue={formValue}
                ref={(ref: FormInstance) => setForm(ref)}
                onChange={handleFormChange}
              >
                <FormGroup>
                  <ControlLabel>メールアドレス</ControlLabel>
                  <FormControl name="email" type="email" />
                </FormGroup>
              </Form>
              <Margin top={10} />
              {isLoading ? (
                <StyledPrimaryButton>確認メール送信中...</StyledPrimaryButton>
              ) : (
                <StyledPrimaryButton onClick={handleAddSubmit}>
                  登録する
                </StyledPrimaryButton>
              )}
            </FormWrapper>
          </Margin>
        </ProfileWrapper>
      </>
    );
  }
  return (
    <>
      <Title>プロフィール設定</Title>
      {!profileLoading ? (
        <ProfileWrapper>
          <Item>
            <ItemTitle>名前</ItemTitle>
            <p>{userName}</p>
          </Item>
          <Item>
            <ItemTitle>アイコン写真</ItemTitle>
            <Margin top={5} left={5}>
              <Whisper placement="right" trigger="hover" speaker={tooltip}>
                <button
                  type="button"
                  style={{
                    width: "100",
                    height: "100",
                    borderRadius: "50px",
                    background: "transparent",
                    outline: "none",
                  }}
                  onClick={() => {
                    setShowUpload(true);
                  }}
                >
                  {uploading && <Loader backdrop center />}
                  {fileInfo ? (
                    <Avatar
                      circle
                      src={fileInfo.value}
                      width="100%"
                      height="100%"
                      alt={userName || "avatar"}
                      style={{ background: "white" }}
                    />
                  ) : (
                    <Avatar
                      circle
                      width="100%"
                      height="100%"
                      size="lg"
                      src={
                        data && data.icon !== null && data.icon !== ""
                          ? data.icon
                          : ""
                      }
                      alt={userName || "avatar"}
                      style={
                        data && data.icon !== null && data.icon !== ""
                          ? { background: "white" }
                          : {}
                      }
                    >
                      {userName?.slice(0, 2)}
                    </Avatar>
                  )}
                </button>
              </Whisper>
            </Margin>
          </Item>
          <Form
            model={model}
            ref={(ref: FormInstance) => setForm(ref)}
            formValue={formValue}
            onChange={handleFormChange}
          >
            <Item>
              <ItemTitle>遠隔スタッフ情報</ItemTitle>
              <Margin top={5} left={5}>
                <FormGroup>
                  <ControlLabel>メールアドレス</ControlLabel>
                  <FormControl name="email" type="email" />
                </FormGroup>
              </Margin>

              <Margin top={5} left={5} bottom={5}>
                {isLoading ? (
                  <StyledPrimaryButton>確認メール送信中...</StyledPrimaryButton>
                ) : (
                  <StyledEditButton
                    isChecked={data ? data.mail !== formValue.email : false}
                    onClick={confirm}
                  >
                    メールアドレス認証
                  </StyledEditButton>
                )}
              </Margin>
              <Margin top={20} left={5}>
                <FormGroup>
                  <ControlLabel>アバター選択</ControlLabel>
                  <FormControl
                    accepter={SelectPicker}
                    name="avatar"
                    searchable={false}
                    data={[
                      { label: "トリープン", value: "toripun" },
                      { label: "Vivi", value: "vivi" },
                      { label: "MenAvatar", value: "men_avatar" },
                      { label: "WomenAvatar", value: "women_avatar" },
                      { label: "ClinicAvatar", value: "clinic_avatar" },
                      { label: "HotelWomanAvatar", value: "hotelwomen" },
                      { label: "HotelManAvatar", value: "hotelmen" },
                    ]}
                  />
                </FormGroup>
              </Margin>
            </Item>
          </Form>
          <Margin top={20} left={5} bottom={5} style={{ textAlign: "center" }}>
            <StyledTextLink
              style={{ fontSize: "12px" }}
              onClick={handleResetClick}
            >
              パスワードを忘れた方はこちら
            </StyledTextLink>
          </Margin>
        </ProfileWrapper>
      ) : (
        <Loading />
      )}
      <StyledModal show={showUpload} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            アイコン写真アップロード
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <Uploader
            accept="image/png, image/jpeg"
            fileListVisible={false}
            listType="picture"
            onChange={files => {
              const afile = files.pop();
              if (afile !== undefined && afile.blobFile !== undefined) {
                previewFile(afile.blobFile, (value: string) => {
                  if (afile.blobFile !== undefined) {
                    setFileInfo({ file: afile.blobFile, value });
                  }
                });
              }
            }}
          >
            <button
              type="button"
              style={{ width: "100px", height: "100px" }}
              onClick={() => {
                setShowUpload(true);
              }}
            >
              {uploading && <Loader backdrop center />}
              {fileInfo ? (
                <img
                  src={fileInfo.value}
                  width="100%"
                  height="100%"
                  alt="avatar"
                />
              ) : (
                <Icon icon="camera" />
              )}
            </button>
          </Uploader>
        </Modal.Body>
        <Modal.Footer>
          {fileInfo ? (
            <StyledPrimaryButton onClick={handleUpload}>
              アップロード
            </StyledPrimaryButton>
          ) : (
            <StyledCancelButton>アップロード</StyledCancelButton>
          )}
          <StyledCancelButton
            onClick={onCancelClick}
            style={{ marginLeft: "5px" }}
          >
            キャンセル
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>

      <Modal show={showReset} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            パスワードリセット
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          パスワードをリセットするために
          <br />
          以下のメールアドレスに認証メールを送信します。
          <br />
          <p
            style={{
              margin: "24px",
              color: "#d84421",
              fontSize: "20px",
            }}
          >
            {data ? data.mail : ""}
          </p>
          よろしいですか？
        </Modal.Body>
        <Modal.Footer>
          {!resetPassLoading ? (
            <StyledPrimaryButton onClick={handleSubmit}>
              送信する
            </StyledPrimaryButton>
          ) : (
            <StyledCancelButton>送信する</StyledCancelButton>
          )}
          <StyledCancelButton
            onClick={onCancelClick}
            style={{ marginLeft: "5px" }}
          >
            キャンセル
          </StyledCancelButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Profile;
