import React, { useState, useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { Row, Col, Alert, Modal, List } from "rsuite";
import Title from "../../../../presentational/atoms/Text/Title";
import { RootState } from "../../../../../reducers/rootReducer";
import { fetchMonitors } from "../../../../../modules/Management/Monitor/monitorApiModule";
import { Margin } from "../../../../../utils/styled";
import { setCheckedKeys } from "../../../../../modules/Management/checkModule";
import {
  StyledPrimaryButton,
  StyledCancelButton,
  StyledDeleteButton,
} from "../Users";
import { primaryBlue } from "../../../../../utils/color";
import { StyledCheckbox } from "../../../../presentational/molecules/Media/VideoMedia";
import { delMonitor } from "../../../../../modules/Management/Monitor/deleteMonitorApiModule";
import { delMonitors } from "../../../../../modules/Management/Monitor/deleteMonitorsApiModule";
import Loading from "../../../../presentational/molecules/Loading";

const StyledTabs = styled(Tabs)`
  background: #fff;
  border-radius: 5px 5px 0 0;
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 165px;
  padding: 5px;
  object-fit: contain;
`;

const StyledFooter = styled.div`
  background: #fff;
  width: 100%;
  height: 100px;
  padding-top: 30px;
  border-radius: 0 0 5px 5px;
  text-align: center;
`;

export const ImgWrapper = styled.div<{ isChecked: boolean }>`
  ${({ isChecked }) => {
    return css`
      ${isChecked
        ? css`
            border: 3px solid ${primaryBlue};
            transition: all 0.2s;
          `
        : `
        border: 3px solid transparent;
        transition: all 0.2s;
        `}
      width: 100%;
      height: 250px;
      position: "relative";
      box-sizing: border-box;
      transition: all 0.2s;
      &:hover {
        border: 3px solid ${primaryBlue};
        transition: all 0.2s;
        cursor: pointer;
      }
    `;
  }}
`;

const Monitor = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);

  const [showDelete, setShowDelete] = useState(false);

  const { token } = useSelector((state: RootState) => state.auth);
  const { data, isLoading } = useSelector((state: RootState) => state.monitor);
  const { checks } = useSelector((state: RootState) => state.checkCells);
  const checkedMonitorKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "monitors")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );

  const dataKeys = useMemo(() => {
    if (data) {
      return Object.keys(data).filter(value => value !== "tokenExpiration");
    }
    return [];
  }, [data]);
  const monitorsData = useMemo(() => {
    if (data) {
      const monitors: any = [];
      dataKeys.forEach(key => {
        const moni = data[key].map(monitor => {
          return {
            pcId: key,
            monitor: monitor.monitor,
            videoUrl: monitor.videoUrl,
            id: monitor.id,
            time: monitor.updTimestamp,
          };
        });
        monitors.push(moni);
      });
      let cm: any = [];
      for (let i = 0; i < dataKeys.length; i += 1) {
        const rmoni = cm.concat(monitors[i]);
        cm = rmoni;
      }

      return cm;
    }
    return [];
  }, [data, dataKeys]);

  useEffect(() => {
    if (token) {
      dispatch(fetchMonitors(token));
    }
  }, [dispatch, token]);

  const handleSelect = (index: number) => {
    setSelected(index);
  };

  const handleDeleteClick = () => {
    setShowDelete(true);
  };

  const onCancelClick = () => {
    setShowDelete(false);
  };

  const handleMonitorCheck = (value: any, isChecked: boolean) => {
    const nextCheckedKeys = isChecked
      ? [...checkedMonitorKeys, value]
      : checkedMonitorKeys.filter(item => item !== value);
    dispatch(
      setCheckedKeys({
        id: "monitors",
        checkInfo: {
          checked: false,
          checkedKeys: nextCheckedKeys,
          indeterminate: false,
        },
      })
    );
  };

  const handleSubmitDeleteVideo = async () => {
    if (token) {
      try {
        setShowDelete(false);
        Alert.info("モニターカメラを削除中...", 100000000);
        if (checkedMonitorKeys.length === 1) {
          await dispatch(delMonitor(token, checkedMonitorKeys[0]));
        } else {
          await dispatch(delMonitors(token, checkedMonitorKeys));
        }
        dispatch(
          setCheckedKeys({
            id: "videos",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        await Alert.close();
        Alert.success("削除しました", 5000);
        await dispatch(fetchMonitors(token));
      } catch (err) {
        Alert.error("不明なエラーが発生しました", 2000);
      }
    }
  };

  return (
    <>
      <Title>モニターカメラ管理</Title>
      <Margin top={20} />
      {!isLoading ? (
        <>
          <StyledTabs onSelect={handleSelect} selectedIndex={selected}>
            <TabList>
              {dataKeys.map((key, i) => (
                <Tab style={{ padding: "10px 20px" }} key={i.toString()}>
                  {key}
                </Tab>
              ))}
            </TabList>
            {dataKeys.map((key, idx) => {
              return (
                <TabPanel
                  style={{
                    overflow: "scroll",
                    minHeight: "300px",
                    maxHeight: "500px",
                  }}
                  key={idx.toString()}
                >
                  <Row>
                    {monitorsData ? (
                      monitorsData
                        .filter((value: any) => value.pcId === key)
                        .map((monitor: any, index: number) => {
                          return (
                            <Col
                              md={6}
                              sm={12}
                              key={index.toString()}
                              style={{
                                boxSizing: "border-box",
                                padding: "10px",
                                border: "1px solid rgba(0, 0, 0, 0.4",
                              }}
                            >
                              <ImgWrapper
                                isChecked={checkedMonitorKeys.some(
                                  (item: any) => item === monitor.id
                                )}
                                onClick={() => {
                                  const isChecked = checkedMonitorKeys.some(
                                    (item: any) => item === monitor.id
                                  );
                                  handleMonitorCheck(monitor.id, !isChecked);
                                }}
                              >
                                <p>{`モニターID: ${monitor.monitor}`}</p>
                                <p>{`動画ID: ${monitor.id}`}</p>
                                <p>{monitor.time}</p>
                                <StyledVideo src={monitor.videoUrl} controls />
                                <StyledCheckbox
                                  value={monitor.id}
                                  checked={checkedMonitorKeys.some(
                                    (item: any) => item === monitor.id
                                  )}
                                />
                              </ImgWrapper>
                            </Col>
                          );
                        })
                    ) : (
                      <div>なし</div>
                    )}
                  </Row>
                </TabPanel>
              );
            })}
          </StyledTabs>
          <StyledFooter>
            {checkedMonitorKeys.length > 0 ? (
              <StyledDeleteButton isChecked onClick={handleDeleteClick}>
                削除
              </StyledDeleteButton>
            ) : (
              <StyledDeleteButton isChecked={false} onClick={handleDeleteClick}>
                削除
              </StyledDeleteButton>
            )}
            <p style={{ color: "red", fontSize: "12px", margin: "5px" }}>
              データの保存期間は三ヶ月です。三ヶ月経過したデータは完全に削除されます。
            </p>
          </StyledFooter>
        </>
      ) : (
        <Loading />
      )}

      <Modal show={showDelete} overflow onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            動画削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List bordered={false}>
            {checkedMonitorKeys
              .map(key => {
                return monitorsData.filter(
                  (monitor: any) => monitor.id === key
                )[0];
              })
              .map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{
                    paddingLeft: "10px",
                    color: "#d84421",
                  }}
                >
                  <p>
                    動画ID:
                    {item?.id}
                  </p>
                  <StyledVideo src={item?.videoUrl} autoPlay muted>
                    <source src={item?.videoUrl} />
                  </StyledVideo>
                </List.Item>
              ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: "#d84421",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記の動画を削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          <StyledPrimaryButton onClick={handleSubmitDeleteVideo}>
            OK
          </StyledPrimaryButton>
          <StyledCancelButton onClick={onCancelClick}>
            Cancel
          </StyledCancelButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Monitor;
