import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import updateGraph, {
  UpdateGraph,
} from "../../../api/Analysis/graphApi/update";
import { expiredToken } from "../../auth";
import { AppThunk } from "../../../stores";
import { startLoading, loadingFailed, ApiState } from "../../../api/apiService";

const apiInitialState: ApiState<UpdateGraph> = {
  isLoading: false,
  error: null,
  data: null,
};

const updateGraphApiModule = createSlice({
  name: "updateGraph",
  initialState: apiInitialState,
  reducers: {
    getApiStart: startLoading,
    getApiSuccess(state, { payload }: PayloadAction<UpdateGraph>) {
      state.isLoading = false;
      state.error = null;
      state.data = payload;
    },
    getApiFailure: loadingFailed,
  },
});

export const {
  getApiStart,
  getApiSuccess,
  getApiFailure,
} = updateGraphApiModule.actions;

export default updateGraphApiModule.reducer;

export const putGraph = (
  token: string,
  id: string,
  name: string,
  type: string,
  infoButtonIds: string[]
): AppThunk => async dispatch => {
  try {
    dispatch(getApiStart());
    const data = await updateGraph(token, id, name, type, infoButtonIds);
    dispatch(getApiSuccess(data));
  } catch (err) {
    switch (err.resultStatus) {
      case 126:
        dispatch(expiredToken(err.resultStatus));
        break;
      case 115:
        dispatch(getApiFailure(err.resultStatus));
        throw err;
      default:
        console.error(err);
        break;
    }
    dispatch(getApiFailure(err));
  }
};
