import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import addVideo, { AddVideo } from "../../../api/Management/videoApi/add";
import { expiredToken } from "../../auth";
import { AppThunk } from "../../../stores";
import { ApiState, startLoading, loadingFailed } from "../../../api/apiService";

const apiInitialState: ApiState<AddVideo> = {
  isLoading: false,
  error: null,
  data: null,
};

const addVideoApiModule = createSlice({
  name: "addVideo",
  initialState: apiInitialState,
  reducers: {
    getApiStart: startLoading,
    getApiSuccess(state, { payload }: PayloadAction<AddVideo>) {
      state.isLoading = false;
      state.error = null;
      state.data = payload;
    },
    getApiFailure: loadingFailed,
  },
});

export const {
  getApiStart,
  getApiSuccess,
  getApiFailure,
} = addVideoApiModule.actions;

export default addVideoApiModule.reducer;

export const postVideo = (
  token: string,
  id: string,
  fileName: string,
  text: string,
  val: string,
  playTime: number
): AppThunk => async dispatch => {
  try {
    dispatch(getApiStart());
    const data = await addVideo(token, id, fileName, text, val, playTime);
    dispatch(getApiSuccess(data));
  } catch (err) {
    switch (err.resultStatus) {
      case 144:
        dispatch(getApiFailure(err.resultStatus));
        throw err;
      case 126:
        dispatch(expiredToken(err.resultStatus));
        break;
      case 115:
        dispatch(getApiFailure(err.resultStatus));
        throw err;
      case 111:
        dispatch(getApiFailure(err.resultStatus));
        throw err;
      case 110:
        dispatch(getApiFailure(err.resultStatus));
        throw err;
      default:
        console.error(err);
        break;
    }
    dispatch(getApiFailure(err));
  }
};
