/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormInstance } from "rsuite/lib/Form";
import {
  Form,
  Whisper,
  Popover,
  FormGroup,
  ControlLabel,
  FormControl,
  Schema,
  SelectPicker,
  List,
} from "rsuite";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { delNotificationButton } from "../../../../modules/Management/NotificationButtons/deleteNotificationButtonApiModule";
import useAlert from "../../../../hooks/useAlert";
import { setCheckedKeys } from "../../../../modules/Management/checkModule";
import { setAddFormValue } from "../../../../modules/Management/Form/NotificationButton/notificationButtonAddFormModule";
import { setEditFormValue } from "../../../../modules/Management/Form/NotificationButton/notificationButtonEditFormModule";
import { postNotificationButton } from "../../../../modules/Management/NotificationButtons/addNotificationButtonApiModule";
import { fetchNotificationButtons } from "../../../../modules/Management/NotificationButtons/notificationButtonApiModule";
import { putNotificationButton } from "../../../../modules/Management/NotificationButtons/updateNotificationButtonApiModule";
import { RootState } from "../../../../reducers/rootReducer";
import { Margin } from "../../../../utils/styled";
import Title from "../../../presentational/atoms/Text/Title";
import {
  StyledButtons,
  StyledCancelButton,
  StyledDeleteButton,
  StyledEditButton,
  StyledPrimaryButton,
} from "./Users";
import Loading from "../../../presentational/molecules/Loading";
import NotificationButtonsTable from "../../../presentational/molecules/Table/NotificationButtonsTable";
import { theme } from "../../../../theme";

const addModel = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  val: Schema.Types.StringType().isRequired("必須項目です"),
  viewData: Schema.Types.StringType().isRequired("必須項目です"),
  buttonType: Schema.Types.NumberType().isRequired("必須項目です"),
});

const Notifications = () => {
  const { info, success, error } = useAlert();
  const [showAdd, setShowAdd] = useState(false);
  const [addForm, setAddForm] = useState<FormInstance>();

  const [showEdit, setShowEdit] = useState(false);
  const [editForm, setEditForm] = useState<FormInstance>();

  const [showDelete, setShowDelete] = useState(false);

  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.auth);
  const { data, isLoading } = useSelector(
    (state: RootState) => state.notificationButtons
  );

  const { checks } = useSelector((state: RootState) => state.checkCells);
  const checkedKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "notificationButtons")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );

  useEffect(() => {
    if (token) {
      dispatch(fetchNotificationButtons(token));
    }
  }, [dispatch, token]);

  const { formValue: addFormValue } = useSelector(
    (state: RootState) => state.notificationButtonAddForm
  );
  const { formValue: editFormValue } = useSelector(
    (state: RootState) => state.notificationButtonEditForm
  );

  const handleAddClick = () => {
    setShowAdd(true);
  };

  const handleAddFormChange = (value: any) => {
    dispatch(setAddFormValue({ formValue: value }));
  };

  const handleCheckAndSubmitAddForm = async () => {
    const checkResult = addForm!.check();
    if (checkResult && token) {
      try {
        setShowAdd(false);
        info({ text: "通知ボタンを追加中..." });
        await dispatch(
          postNotificationButton(
            token,
            addFormValue.id,
            addFormValue.val,
            addFormValue.viewData,
            addFormValue.buttonType,
            addFormValue.remark
          )
        );
        success({ text: "通知ボタンを追加しました" });
        await dispatch(fetchNotificationButtons(token));
      } catch (err) {
        error({ text: "入力したIDはすでに存在しています" });
      }
    }
  };

  const handleEditClick = () => {
    if (data) {
      const filteredNotificationButtons = data.notificationButtons.filter(
        notificationButton => notificationButton.id === checkedKeys[0]
      )[0];
      dispatch(
        setEditFormValue({
          formValue: {
            id: filteredNotificationButtons.id,
            val: filteredNotificationButtons.val,
            viewData: filteredNotificationButtons.viewData,
            buttonType: filteredNotificationButtons.buttonType,
            remark: filteredNotificationButtons.remark,
          },
        })
      );
    }
    setShowEdit(true);
  };

  const handleEditFormChange = (value: any) => {
    dispatch(setEditFormValue({ formValue: value }));
  };

  const handleCheckAndSubmitEditForm = async () => {
    const checkResult = editForm!.check();
    if (checkResult && token) {
      try {
        setShowEdit(false);
        info({ text: "通知ボタン更新中..." });
        await dispatch(
          putNotificationButton(
            token,
            editFormValue.id,
            editFormValue.val,
            editFormValue.viewData,
            editFormValue.buttonType,
            editFormValue.remark
          )
        );
        dispatch(fetchNotificationButtons(token));
        success({ text: "通知ボタンを更新しました" });
      } catch (err) {
        console.error(err);
        error({ text: "不明なエラーが発生しました" });
      }
    }
  };

  const handleDeleteClick = () => {
    setShowDelete(true);
  };

  const handleSubmitDelete = async () => {
    if (token) {
      try {
        setShowDelete(false);
        info({ text: "通知ボタン削除中..." });
        await dispatch(delNotificationButton(token, checkedKeys[0]));
        dispatch(fetchNotificationButtons(token));
        success({ text: "通知ボタンを削除しました" });
        dispatch(
          setCheckedKeys({
            id: "notificationButtons",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
      } catch (err) {
        console.error(err);
        error({ text: "不明なエラーが発生しました" });
      }
    }
  };

  const onCancelClick = () => {
    setShowAdd(false);
    setShowEdit(false);
    setShowDelete(false);
    dispatch(
      setAddFormValue({
        formValue: {
          id: "",
          val: "",
          viewData: "",
          buttonType: "",
          remark: "",
        },
      })
    );
  };

  const editSpeaker = () => {
    if (checkedKeys.length > 1) {
      return (
        <Popover title="編集について">
          <p>一度に編集できる通知ボタンは1つです</p>
          <p>チェックを外してください</p>
        </Popover>
      );
    }

    return (
      <Popover title="編集について">
        <p>編集したい通知ボタンにチェックをつけてください</p>
      </Popover>
    );
  };

  const deleteSpeaker = () => {
    return (
      <Popover title="削除について">
        <p>削除したい通知ボタンにチェックをつけてください</p>
      </Popover>
    );
  };
  return (
    <>
      <Title>通知ボタン管理</Title>
      <Margin top={20} />
      {!isLoading ? (
        data &&
        data !== null &&
        data.notificationButtons &&
        data.notificationButtons.length !== 0 ? (
          <>
            <StyledButtons>
              <StyledPrimaryButton onClick={handleAddClick}>
                追加
              </StyledPrimaryButton>
              {checkedKeys.length === 1 ? (
                <>
                  <StyledEditButton isChecked onClick={handleEditClick}>
                    編集
                  </StyledEditButton>
                  <StyledDeleteButton isChecked onClick={handleDeleteClick}>
                    削除
                  </StyledDeleteButton>
                </>
              ) : (
                <>
                  <Whisper
                    placement="autoVerticalStart"
                    trigger="hover"
                    speaker={editSpeaker()}
                  >
                    <StyledEditButton isChecked={false}>編集</StyledEditButton>
                  </Whisper>
                  <Whisper
                    placement="autoVerticalStart"
                    trigger="hover"
                    speaker={deleteSpeaker()}
                  >
                    <StyledDeleteButton isChecked={false}>
                      削除
                    </StyledDeleteButton>
                  </Whisper>
                </>
              )}
            </StyledButtons>
            <Margin top={5} />
            <NotificationButtonsTable
              notificationButtons={data.notificationButtons}
              id="notificationButtons"
            />
          </>
        ) : (
          <>
            <StyledButtons>
              <StyledPrimaryButton onClick={handleAddClick}>
                追加
              </StyledPrimaryButton>
            </StyledButtons>
            <Margin top={5} />
            <div>通知ボタンはありません</div>
          </>
        )
      ) : (
        <Loading />
      )}

      <Dialog
        open={showAdd}
        onClose={onCancelClick}
        fullWidth
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        scroll="body"
      >
        <DialogTitle id="form-dialog-title">通知ボタン追加</DialogTitle>
        <DialogContent>
          <Form
            fluid
            model={addModel}
            formValue={addFormValue}
            ref={(ref: FormInstance) => setAddForm(ref)}
            onChange={handleAddFormChange}
          >
            <FormGroup>
              <ControlLabel>通知ボタンID</ControlLabel>
              <FormControl name="id" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>通知ボタンの値</ControlLabel>
              <FormControl name="val" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>表示データ</ControlLabel>
              <FormControl name="viewData" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>ボタンタイプ</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                searchable={false}
                data={[
                  { label: "テキストボタン", value: 0 },
                  { label: "画像ボタン", value: 1 },
                  { label: "デザインボタン", value: 2 },
                ]}
                name="buttonType"
                placeholder="表示ボタンを選択してください"
                menuStyle={{ zIndex: 99999 }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="remark" />
            </FormGroup>
          </Form>
          <DialogActions>
            <StyledPrimaryButton onClick={handleCheckAndSubmitAddForm}>
              OK
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelClick}>
              Cancel
            </StyledCancelButton>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showEdit}
        onClose={onCancelClick}
        fullWidth
        maxWidth="lg"
        scroll="body"
      >
        <DialogTitle>通知ボタン編集</DialogTitle>
        <DialogContent>
          <Form
            fluid
            model={addModel}
            formValue={editFormValue}
            ref={(ref: FormInstance) => setEditForm(ref)}
            onChange={handleEditFormChange}
          >
            <FormGroup>
              <ControlLabel>通知ボタンID</ControlLabel>
              <FormControl name="id" readOnly plaintext />
            </FormGroup>
            <FormGroup>
              <ControlLabel>通知ボタンの値</ControlLabel>
              <FormControl name="val" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>表示データ</ControlLabel>
              <FormControl name="viewData" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>ボタンタイプ</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                searchable={false}
                data={[
                  { label: "テキストボタン", value: 0 },
                  { label: "画像ボタン", value: 1 },
                  { label: "デザインボタン", value: 2 },
                ]}
                name="buttonType"
                placeholder="ボタンタイプを選択してください"
                menuStyle={{ zIndex: 99999 }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="remark" />
            </FormGroup>
          </Form>
          <DialogActions>
            <StyledPrimaryButton onClick={handleCheckAndSubmitEditForm}>
              OK
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelClick}>
              Cancel
            </StyledCancelButton>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showDelete}
        onClose={onCancelClick}
        fullWidth
        maxWidth="lg"
        scroll="body"
      >
        <DialogTitle>通知ボタン削除</DialogTitle>
        <DialogContent>
          <List bordered={false}>
            {checkedKeys
              .map(key => {
                return data?.notificationButtons
                  .filter(notificationButton => notificationButton.id === key)
                  .map(value => value.id)[0];
              })
              .map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{
                    paddingLeft: "10px",
                    color: theme.palette.error.main,
                  }}
                >
                  {item}
                </List.Item>
              ))}
          </List>
          <p
            style={{
              marginTop: "24px",
              color: theme.palette.error.main,
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記の通知ボタンを削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          <DialogActions>
            <StyledDeleteButton isChecked onClick={handleSubmitDelete}>
              OK
            </StyledDeleteButton>
            <StyledCancelButton onClick={onCancelClick}>
              Cancel
            </StyledCancelButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Notifications;
