// tokenの期限切れ
// パスワードリセット
// メールアドレス変更
// アカウント作成

import React from "react";
import styled from "styled-components";
import { Typography, withStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MuiPrimaryButton from "../../presentational/atoms/Button/MuiPrimaryButton";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
`;

const SummaryTypography = withStyles({
  root: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: "24px",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.87)",
  },
})(Typography);

const DetailTypography = withStyles({
  root: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: "16px",
    textAlign: "center",
    letterSpacing: "0.15px",
    color: "#37352F",
  },
})(Typography);

const MoveToButton = withStyles({
  root: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 15,
    lineHeight: "26px",
    letterSpacing: 0.46,
    textTransform: "uppercase",
    marginTop: "2rem",
    padding: "8px 22px",
  },
})(MuiPrimaryButton);

type Props = {
  /** 見出し */
  title?: string;

  /** 詳細 */
  detail?: string;

  /**
   * 遷移先
   *
   * 指定した場合、クリックするとページ遷移するボタンを配置します。
   */
  moveTo?: {
    text: string;
    to: string;
  };
};

const ExpiredToken = ({
  title = "アカウント作成の有効期限が切れました",
  detail = `アカウント作成の有効期限はリクエストしてから24時間です。
  貴社ご担当者様にお問い合わせください。`,
  moveTo,
}: Props) => {
  const { push } = useHistory();

  /** 遷移ボタンクリック時のハンドラ */
  const handleMoveTo = () => {
    if (moveTo !== undefined) push(moveTo.to);
  };

  return (
    <Wrapper>
      <img
        src="/rura-management-rectangle.svg"
        alt="rura-management-rectangle"
        style={{ marginBottom: "3rem" }}
      />
      <img
        src="/hourgrass.svg"
        alt="hourgrass"
        style={{ marginBottom: "2.625rem" }}
      />
      <SummaryTypography
        variant="h5"
        style={{ whiteSpace: "pre-line", marginBottom: "1.5rem" }}
      >
        {title}
      </SummaryTypography>
      <DetailTypography variant="h6" style={{ whiteSpace: "pre-line" }}>
        {detail}
      </DetailTypography>
      {moveTo && (
        <MoveToButton onClick={handleMoveTo}>{moveTo.text}</MoveToButton>
      )}
    </Wrapper>
  );
};

export default ExpiredToken;
