/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from "react";
import { FormInstance } from "rsuite/lib/Form";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Schema,
  SelectPicker,
  Alert,
  Whisper,
  Popover,
  List,
} from "rsuite";
import { Typography } from "@material-ui/core";
import { RootState } from "../../../../../reducers/rootReducer";
import { fetchRobots } from "../../../../../modules/Management/Robots/robotsApiModule";
import RobotsTable from "../../../../presentational/molecules/Table/RobotsTable";
import {
  StyledPrimaryButton,
  StyledCancelButton,
  StyledEditButton,
  StyledDeleteButton,
  StyledModal,
  StyledButtons,
} from "../Users";
import Loading from "../../../../presentational/molecules/Loading";
import { Margin } from "../../../../../utils/styled";
import {
  setAddFormValue,
  setAddFormInitialValue,
} from "../../../../../modules/Management/Form/Robot/robotAddFormModule";
import { postRobot } from "../../../../../modules/Management/Robots/addRobotApiModule";
import {
  setEditFormValue,
  setEditFormInitialValue,
} from "../../../../../modules/Management/Form/Robot/robotEditFormModule";
import { delRobot } from "../../../../../modules/Management/Robots/deleteRobotApiModule";
import { setCheckedKeys } from "../../../../../modules/Management/checkModule";
import { putRobot } from "../../../../../modules/Management/Robots/updateRobotApiModule";

export const robotType = [
  {
    label: "pepper",
    value: "1",
  },
  {
    label: "2D display Robot",
    value: "2",
  },
  {
    label: "3D display Robot",
    value: "3",
  },
  {
    label: "顔出し",
    value: "4",
  },
];

const addModel = Schema.Model({
  robotId: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  name: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  robotNo: Schema.Types.StringType().isRequired("必須項目です"),
});

const Robots = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [addForm, setAddForm] = useState<FormInstance>();

  const [showEdit, setShowEdit] = useState(false);
  const [editForm, setEditForm] = useState<FormInstance>();

  const [showDelete, setShowDelete] = useState(false);

  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.auth);

  const { data, isLoading } = useSelector((state: RootState) => state.robots);

  const { checks } = useSelector((state: RootState) => state.checkCells);
  const checkedKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "robots")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );

  useEffect(() => {
    if (token) {
      dispatch(fetchRobots(token));
    }
  }, [dispatch, token]);

  const { formValue: addFormValue } = useSelector(
    (state: RootState) => state.robotAddForm
  );
  const { formValue: editFormValue } = useSelector(
    (state: RootState) => state.robotEditForm
  );

  const handleAddClick = () => {
    setShowAdd(true);
  };

  const handleAddFormChange = (value: any) => {
    dispatch(setAddFormValue({ formValue: value }));
  };

  const handleCheckAndSubmitAddForm = async () => {
    const checkResult = addForm!.check();
    if (checkResult && token) {
      try {
        setShowAdd(false);
        Alert.info("ロボットを追加中...", 100000000);
        await dispatch(
          postRobot(
            token,
            addFormValue.robotId,
            addFormValue.name,
            addFormValue.ip,
            addFormValue.robotNo,
            addFormValue.mac,
            addFormValue.switchbotMac,
            addFormValue.remark
          )
        );
        dispatch(setAddFormInitialValue());
        Alert.close();
        Alert.success("ロボットを追加しました", 5000);
        await dispatch(fetchRobots(token));
      } catch (err) {
        Alert.error("入力したIDはすでに登録されています", 2000);
      }
    }
  };

  const handleEditClick = () => {
    if (data) {
      const filteredRobot = data.robots.filter(
        robot => robot.robotId === checkedKeys[0]
      )[0];
      dispatch(
        setEditFormValue({
          formValue: {
            robotId: filteredRobot.robotId,
            name: filteredRobot.name,
            robotNo: filteredRobot.robotNo.toString(),
            ip: filteredRobot.ip,
            mac: filteredRobot.mac,
            switchbotMac: filteredRobot.switchbotMac,
            remark: filteredRobot.remark,
          },
        })
      );
      setShowEdit(true);
    }
  };

  const handleEditFormChange = (value: any) => {
    dispatch(setEditFormValue({ formValue: value }));
  };

  const handleCheckAndSubmitEditForm = async () => {
    const checkResult = editForm!.check();
    if (checkResult && token) {
      try {
        setShowEdit(false);
        Alert.info("ロボット更新中...", 100000000);
        await dispatch(
          putRobot(
            token,
            editFormValue.robotId,
            editFormValue.name,
            editFormValue.ip,
            editFormValue.robotNo,
            editFormValue.mac,
            editFormValue.switchbotMac,
            editFormValue.remark
          )
        );
        dispatch(
          setCheckedKeys({
            id: "robots",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        dispatch(setEditFormInitialValue());
        Alert.close();
        Alert.success("ロボットを更新しました", 5000);
        await dispatch(fetchRobots(token));
      } catch (err) {
        Alert.closeAll();
        Alert.error("不明なエラーが発生しました", 2000);
      }
    }
  };

  const handleDeleteClick = () => {
    setShowDelete(true);
  };

  const handleSubmitDelete = async () => {
    if (token) {
      try {
        setShowDelete(false);
        Alert.info("ロボットを削除中...", 100000000);
        await dispatch(delRobot(token, checkedKeys[0]));
        Alert.close();
        Alert.success("ロボットを削除しました", 5000);
        dispatch(
          setCheckedKeys({
            id: "robots",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        await dispatch(fetchRobots(token));
      } catch (err) {
        Alert.error("不明なエラーが発生しました", 2000);
      }
    }
  };

  const onCancelClick = () => {
    setShowAdd(false);
    setShowEdit(false);
    setShowDelete(false);
  };

  const editSpeaker = () => {
    if (checkedKeys.length > 1) {
      return (
        <Popover title="編集について">
          <p>一度に編集できるロボットは1つです</p>
          <p>チェックを外してください</p>
        </Popover>
      );
    }

    return (
      <Popover title="編集について">
        <p>編集したいロボットにチェックをつけてください</p>
      </Popover>
    );
  };

  const deleteSpeaker = () => {
    return (
      <Popover title="削除について">
        <p>削除したいロボットにチェックをつけてください</p>
      </Popover>
    );
  };

  return (
    <>
      <Typography variant="h6">ロボット管理</Typography>
      <Margin top={20}>
        {!isLoading ? (
          data && data !== null && data.robots && data.robots.length !== 0 ? (
            <>
              <StyledButtons>
                <StyledPrimaryButton onClick={handleAddClick}>
                  追加
                </StyledPrimaryButton>
                {checkedKeys.length === 1 ? (
                  <>
                    <StyledEditButton isChecked onClick={handleEditClick}>
                      編集
                    </StyledEditButton>
                    <StyledDeleteButton isChecked onClick={handleDeleteClick}>
                      削除
                    </StyledDeleteButton>
                  </>
                ) : (
                  <>
                    <Whisper
                      placement="autoVerticalStart"
                      trigger="hover"
                      speaker={editSpeaker()}
                    >
                      <StyledEditButton isChecked={false}>
                        編集
                      </StyledEditButton>
                    </Whisper>
                    <Whisper
                      placement="autoVerticalStart"
                      trigger="hover"
                      speaker={deleteSpeaker()}
                    >
                      <StyledDeleteButton isChecked={false}>
                        削除
                      </StyledDeleteButton>
                    </Whisper>
                  </>
                )}
              </StyledButtons>
              <Margin top={5} />
              <RobotsTable robots={data.robots} />
            </>
          ) : (
            <>
              <div>ロボットデータはありません</div>
              <StyledPrimaryButton onClick={handleAddClick}>
                追加
              </StyledPrimaryButton>
            </>
          )
        ) : (
          <Loading />
        )}
      </Margin>

      <StyledModal show={showAdd} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            ロボット追加
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={addModel}
            formValue={addFormValue}
            ref={(ref: FormInstance) => setAddForm(ref)}
            onChange={handleAddFormChange}
          >
            <FormGroup>
              <ControlLabel>ロボットID</ControlLabel>
              <FormControl name="robotId" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>ロボット名</ControlLabel>
              <FormControl name="name" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>IPアドレス</ControlLabel>
              <FormControl name="ip" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>ロボットタイプ</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={robotType}
                name="robotNo"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>MACアドレス</ControlLabel>
              <FormControl name="mac" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Switchbot MACアドレス</ControlLabel>
              <FormControl name="switchbotMac" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="remark" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledPrimaryButton onClick={handleCheckAndSubmitAddForm}>
              OK
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelClick}>
              Cancel
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledModal>
      <StyledModal show={showEdit} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            ロボット編集
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={addModel}
            formValue={editFormValue}
            ref={(ref: FormInstance) => setEditForm(ref)}
            onChange={handleEditFormChange}
          >
            <FormGroup>
              <ControlLabel>ロボットID</ControlLabel>
              <FormControl readOnly name="robotId" plaintext />
            </FormGroup>
            <FormGroup>
              <ControlLabel>ロボット名</ControlLabel>
              <FormControl name="name" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>IPアドレス</ControlLabel>
              <FormControl name="ip" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>ロボットタイプ</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={robotType}
                name="robotNo"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>MACアドレス</ControlLabel>
              <FormControl name="mac" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Switchbot MACアドレス</ControlLabel>
              <FormControl name="switchbotMac" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="remark" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledPrimaryButton onClick={handleCheckAndSubmitEditForm}>
              OK
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelClick}>
              Cancel
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showDelete} overflow onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            ロボット削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List bordered={false}>
            {checkedKeys
              .map(key => {
                return data?.robots
                  .filter(robot => robot.robotId === key)
                  .map(value => value.robotId)[0];
              })
              .map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{
                    paddingLeft: "10px",
                    color: "#d84421",
                  }}
                >
                  {item}
                </List.Item>
              ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: "#d84421",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記のロボットを削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          <StyledDeleteButton isChecked onClick={handleSubmitDelete}>
            OK
          </StyledDeleteButton>
          <StyledCancelButton onClick={onCancelClick}>
            Cancel
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>
    </>
  );
};

export default Robots;
