import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type PreMemo = {
  id: string;
  preMemoInfo: {
    preMemoTitle?: string;
    preMemoText?: string;
    remark?: string;
  };
};

type PreMemoState = {
  preMemos: PreMemo[];
};

const initialState: PreMemoState = {
  preMemos: [],
};

const preMemosModule = createSlice({
  name: "preMemos",
  initialState,
  reducers: {
    setPreMemo(state: PreMemoState, { payload }: PayloadAction<PreMemoState>) {
      state.preMemos = payload.preMemos;
    },
    setPreMemoTitle(state: PreMemoState, { payload }: PayloadAction<PreMemo>) {
      state.preMemos.forEach(memo => {
        if (memo.id === payload.id) {
          memo.preMemoInfo.preMemoTitle = payload.preMemoInfo.preMemoTitle;
        }
      });
    },
    setPreMemoText(state: PreMemoState, { payload }: PayloadAction<PreMemo>) {
      state.preMemos.forEach(memo => {
        if (memo.id === payload.id) {
          memo.preMemoInfo.preMemoText = payload.preMemoInfo.preMemoText;
        }
      });
    },
  },
});

export const {
  setPreMemo,
  setPreMemoTitle,
  setPreMemoText,
} = preMemosModule.actions;

export default preMemosModule.reducer;
