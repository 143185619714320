import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import updateRobot, {
  UpdateRobot,
} from "../../../api/Management/robotsApi/update";
import { expiredToken } from "../../auth";
import { AppThunk } from "../../../stores";
import { startLoading, loadingFailed, ApiState } from "../../../api/apiService";

const apiInitialState: ApiState<UpdateRobot> = {
  isLoading: false,
  error: null,
  data: null,
};

const updateRobotApiModule = createSlice({
  name: "updateRobot",
  initialState: apiInitialState,
  reducers: {
    getApiStart: startLoading,
    getApiSuccess(state, { payload }: PayloadAction<UpdateRobot>) {
      state.isLoading = false;
      state.error = null;
      state.data = payload;
    },
    getApiFailure: loadingFailed,
  },
});

export const {
  getApiStart,
  getApiSuccess,
  getApiFailure,
} = updateRobotApiModule.actions;

export default updateRobotApiModule.reducer;

export const putRobot = (
  token: string,
  id: string,
  name: string,
  ip: string,
  robotNo: string,
  mac: string,
  switchbotMac: string,
  remark: string
): AppThunk => async dispatch => {
  try {
    dispatch(getApiStart());
    const data = await updateRobot(
      token,
      id,
      name,
      ip,
      robotNo,
      mac,
      switchbotMac,
      remark
    );
    dispatch(getApiSuccess(data));
  } catch (err) {
    switch (err.resultStatus) {
      case 126:
        dispatch(expiredToken(err.resultStatus));
        break;
      case 115:
        dispatch(getApiFailure(err.resultStatus));
        throw err;
      default:
        console.error(err);
        break;
    }
    dispatch(getApiFailure(err));
  }
};
