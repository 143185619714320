/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, {
  useState,
  useEffect,
  useMemo,
  Fragment,
  useRef,
  VFC,
} from "react";
import AudioPlayer from "react-h5-audio-player";
import moment from "moment";
import "react-h5-audio-player/lib/styles.css";
import styled from "styled-components";
import { Timeline, Grid, Row, Col, Tag } from "rsuite";
import Checkbox from "@material-ui/core/Checkbox";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@material-ui/core";
import Title from "../../../presentational/atoms/Text/Title";
import { Margin } from "../../../../utils/styled";
import SelectBoxForConditions from "../../../presentational/molecules/SelectBox/SelectBoxForConditions";
import MultipleDatePicker from "../../../presentational/molecules/DatePicker";
import CancelButton from "../../../presentational/atoms/Button/CancelButton";
import PrimaryButton from "../../../presentational/atoms/Button/PrimaryButton";
import BaseButton from "../../../presentational/atoms/Button/BaseButton";
import { primaryBlue, textGray, white } from "../../../../utils/color";
import { RootState } from "../../../../reducers/rootReducer";
import { fetchAudio } from "../../../../modules/Analysis/audioApiModule";
import { fetchConditions } from "../../../../modules/Analysis/conditionsApiModule";
import { setConditions } from "../../../../modules/Analysis/conditionModule";
import {
  setPreConditions,
  setInitialPreConditions,
} from "../../../../modules/Analysis/preConditionModule";
import { setCheckConditions } from "../../../../modules/Analysis/checkConditionModule";
import SubTitle from "../../../presentational/atoms/Text/SubTitle";
import { fetchDetails } from "../../../../modules/Analysis/detailsApiModule";
import Loading from "../../../presentational/molecules/Loading";
import { setPreCheckConditions } from "../../../../modules/Analysis/preCheckConditionModule";
import apiService from "../../../../api/apiService";

const Wrapper = styled.div`
  font-size: 12px;
  display: flex;
  flex: row;
  justify-content: space-between;
`;

const StartWrapper = styled.div`
  display: flex;
  flex: row;
  width: 100%;
  justify-content: flex-start;
`;

const EndWrapper = styled.div`
  display: flex;
  flex: row;
  width: 100%;
  justify-content: flex-end;
`;

const SelectWrapper = styled.div`
  width: 100%;
  font-size: 12px;
  margin-right: 10px;
`;

const StyledButton = styled(BaseButton)`
  background-color: #fff;
  color: ${primaryBlue};
  line-height: 1;
  padding: 10px;
  &:hover {
    color: ${primaryBlue};
    border-radius: 20px;
    transition: 0.25s;
  }
  transition: 0.25s;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-right: 5px;
  &:hover {
    color: #fff;
    border-radius: 20px;
    transition: 0.25s;
  }
  transition: 0.25s;
`;

const StyledAudioButton = styled(PrimaryButton)`
  width: 20%;
  margin-left: 5px;
  font-size: 13px;
  &:hover {
    color: #fff;
    border-radius: 20px;
    transition: 0.25s;
  }
  transition: 0.25s;
`;

const StyledAudioGrayButton = styled(StyledAudioButton)`
  background-color: #73818f;
`;

const StyledCancelButton = styled(CancelButton)`
  &:hover {
    color: #fff;
    border-radius: 20px;
    transition: 0.25s;
  }
  transition: 0.25s;
`;

const CaptionButton = styled.p`
  color: ${textGray};
  cursor: pointer;
  font-size: 12px;
  margin-top: 5px;
  &:hover {
    color: red;
    border-radius: 20px;
    transition: 0.25s;
  }
  transition: 0.25s;
`;

const DotSpan = styled.span`
  &::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-color: #ffc042;
    border-radius: 50%;
  }
`;

const TimelineWrapper = styled.div`
  max-height: 750px;
  background: ${white};
  border-radius: 6px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  padding: 10px;
  overflow: scroll;
`;

const TimelineItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const StyledRow = styled(Row)`
  ::-webkit-scrollbar {
    display: none;
  }
  max-height: 400px;
  overflow: scroll;
  margin: 20px;
  padding: 10px;
`;

const StyledTimelineItem = styled(Timeline.Item)`
  &:hover {
    color: orange;
    cursor: pointer;
  }
`;

const StyledCsv = styled(CSVLink)`
  display: inline-block;
  background-color: ${primaryBlue};
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  line-height: 1;
  text-decoration: none !important;
  letter-spacing: 0.05em;
  padding: 0.4em 1em;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  -webkit-tap-highlight-color: transparent;
  transition: 0.3s ease-out;
  &:hover {
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px 0px rgba(0, 0, 0, 0.2);
    color: #fff;
  }
`;

const csvHeaders = [
  { label: "接客ID", key: "csId" },
  { label: "PC名", key: "pcName" },
  { label: "遠隔スタッフID", key: "userId" },
  { label: "遠隔スタッフ名", key: "userName" },
  { label: "分析情報タイプ", key: "infoButtonTypeText" },
  { label: "分析情報名", key: "infoButtonText" },
  { label: "接客開始日", key: "startDate" },
  { label: "接客開始時間", key: "startTime" },
  { label: "接客終了日", key: "finishDate" },
  { label: "接客終了時間", key: "finishTime" },
  { label: "接客時間", key: "csTime" },
  { label: "接客しなかった", key: "notService" },
];

const Analysis = () => {
  const [show, setShow] = useState(false);
  const [audioShow, setAudioShow] = useState(false);
  const [audioData, setAudioData] = useState<{
    csId?: string;
    audioUrl?: string;
    userName?: string;
    date?: string;
    startTime?: string;
    finishTime?: string;
    workerAudioUrl?: string;
  }>();

  const [csvData, setCsvData] = useState<any>();

  const audioRef = useRef<AudioPlayer>(null);
  const dates: string[] = [];

  const dispatch = useDispatch();

  const { token, userId, userType } = useSelector(
    (state: RootState) => state.auth
  );

  const { picks } = useSelector((state: RootState) => state.datePick);
  const { startDate: adStartDate, endDate: adEndDate } = picks.filter(value => {
    return value.id === "audio";
  })[0];

  const { data: conditionData } = useSelector(
    (state: RootState) => state.conditionsApi
  );

  const { data: detailData, isLoading: detailLoading } = useSelector(
    (state: RootState) => state.detailsApi
  );

  const { pcList } = useSelector((state: RootState) => state.auth);

  const { conditions: preConditions } = useSelector(
    (state: RootState) => state.preCondition
  );
  const { conditions: checkConditions } = useSelector(
    (state: RootState) => state.checkCondition
  );
  const { conditions: preCheckconditions } = useSelector(
    (state: RootState) => state.preCheckCondition
  );

  const { conditions } = useSelector((state: RootState) => state.condition);
  const selectedPcs = useMemo(
    () =>
      conditions
        .filter(value => value.id === "pcs")
        .map(pc => pc.condition)[0]
        .map(item => {
          if (typeof item.value === "string") {
            return item.value;
          }
          return null;
        }),
    [conditions]
  );
  const selectedUsers = useMemo(
    () =>
      conditions
        .filter(value => value.id === "users")
        .map(user => user.condition)[0]
        .map(item => {
          if (typeof item.value === "string") {
            return item.value;
          }
          return null;
        }),
    [conditions]
  );
  const selectedContents = useMemo(
    () =>
      conditions
        .filter(value => value.id === "contents")
        .map(content => content.condition)[0]
        .map(item => {
          if (typeof item.value === "string") {
            return item.value;
          }
          return null;
        }),
    [conditions]
  );
  const selectedEmotions = useMemo(
    () =>
      conditions
        .filter(value => value.id === "emotions")
        .map(emotion => emotion.condition)[0]
        .map(item => {
          if (typeof item.value === "string") {
            return item.value;
          }
          return null;
        }),
    [conditions]
  );
  const selectedActions = useMemo(
    () =>
      conditions
        .filter(value => value.id === "actions")
        .map(action => action.condition)[0]
        .map(item => {
          if (typeof item.value === "string") {
            return item.value;
          }
          return null;
        }),
    [conditions]
  );

  const preCheckedAudioOnly = useMemo(
    () =>
      preCheckconditions.filter(value => value.id === "audioOnly")[0].condition,
    [preCheckconditions]
  );

  const preCheckedTextOnly = useMemo(
    () =>
      preCheckconditions.filter(value => value.id === "textOnly")[0].condition,
    [preCheckconditions]
  );
  const preCheckedWorkerAudioOnly = useMemo(
    () =>
      preCheckconditions.filter(value => value.id === "workerAudioOnly")[0]
        .condition,
    [preCheckconditions]
  );
  const preCheckedWorkerTextOnly = useMemo(
    () =>
      preCheckconditions.filter(value => value.id === "workerTextOnly")[0]
        .condition,
    [preCheckconditions]
  );

  const checkedAudioOnly = useMemo(
    () =>
      checkConditions.filter(value => value.id === "audioOnly")[0].condition,
    [checkConditions]
  );

  const checkedTextOnly = useMemo(
    () => checkConditions.filter(value => value.id === "textOnly")[0].condition,
    [checkConditions]
  );
  const checkedWorkerAudioOnly = useMemo(
    () =>
      checkConditions.filter(value => value.id === "workerAudioOnly")[0]
        .condition,
    [checkConditions]
  );
  const checkedWorkerTextOnly = useMemo(
    () =>
      checkConditions.filter(value => value.id === "workerTextOnly")[0]
        .condition,
    [checkConditions]
  );

  useEffect(() => {
    const fetchData = () => {
      if (token) {
        dispatch(fetchConditions(token));
      }
    };
    fetchData();
  }, [dispatch, token]);

  // CSV の件数が上限を超過しているか
  // true の場合は検索条件を見直す必要がある
  const [isCsvOverLimit, setIsCsvOverLimit] = useState(false);

  useEffect(() => {
    setCsvData(undefined);
    setIsCsvOverLimit(false);
    const fetchData = async () => {
      if (token) {
        const searchParam = new URLSearchParams();
        searchParam.append("start_time", adStartDate);
        searchParam.append("finish_time", adEndDate);
        const api = {
          path: `/rura/analysis/customer_service/csv?${searchParam.toString()}`,
          req: {
            method: "GET",
            headers: apiService.createHeaders(token),
          },
        };
        const response = await apiService.request<any>(api);

        if (response?.resultStatus === 150) {
          setIsCsvOverLimit(true);
          setCsvData([]);
        } else {
          setCsvData(response.data.csv);
        }
      }
    };
    fetchData();
  }, [adEndDate, adStartDate, token]);

  useEffect(() => {
    const fetchData = () => {
      if (token && userId && userType === 1) {
        dispatch(
          fetchAudio(
            token,
            adStartDate,
            adEndDate,
            selectedPcs,
            selectedUsers,
            selectedContents,
            selectedEmotions,
            selectedActions,
            checkedAudioOnly,
            checkedTextOnly,
            checkedWorkerAudioOnly,
            checkedWorkerTextOnly,
            userId
          )
        );
      }
      if (token && userType !== 1) {
        dispatch(
          fetchAudio(
            token,
            adStartDate,
            adEndDate,
            selectedPcs,
            selectedUsers,
            selectedContents,
            selectedEmotions,
            selectedActions,
            checkedAudioOnly,
            checkedTextOnly,
            checkedWorkerAudioOnly,
            checkedWorkerTextOnly
          )
        );
      }
    };
    fetchData();
  }, [
    adEndDate,
    adStartDate,
    checkedAudioOnly,
    checkedTextOnly,
    checkedWorkerAudioOnly,
    checkedWorkerTextOnly,
    dispatch,
    selectedActions,
    selectedContents,
    selectedEmotions,
    selectedPcs,
    selectedUsers,
    token,
    userId,
    userType,
  ]);

  const { data } = useSelector((state: RootState) => state.audioApi);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempCondition = [
      {
        id: event.target.name,
        condition: event.target.checked,
      },
    ];
    dispatch(setPreCheckConditions(tempCondition));
  };
  const onOkClick = () => {
    dispatch(setConditions(preConditions));
    dispatch(setCheckConditions(preCheckconditions));
    setShow(false);
  };
  const onCancelClick = () => {
    dispatch(setPreCheckConditions(checkConditions));
    dispatch(setPreConditions(conditions));
    setShow(false);
  };
  const onResetClick = () => {
    dispatch(setInitialPreConditions());
  };

  const onAudioClick = async (e: any) => {
    const id = e.currentTarget.getAttribute("data-csid");
    const url = e.currentTarget.getAttribute("data-audiourl");
    const workerUrl = e.currentTarget.getAttribute("data-workeraudiourl");
    const user = e.currentTarget.getAttribute("data-user");
    const date = e.currentTarget.getAttribute("data-date");
    const start = e.currentTarget.getAttribute("data-start");
    const finish = e.currentTarget.getAttribute("data-finish");
    if (token) {
      dispatch(fetchDetails(token, id));
    }
    setAudioData({
      csId: id,
      audioUrl: url,
      userName: user,
      date,
      startTime: start,
      finishTime: finish,
      workerAudioUrl: workerUrl,
    });
  };

  useEffect(() => {
    if (detailData === null) return;
    setAudioShow(true);
    setAudioData(prev => {
      return {
        ...prev,
        audioUrl: detailData.audioUrl,
        workerAudioUrl: detailData.workerAudioUrl,
      };
    });
  }, [detailData]);

  const onCloseClick = () => {
    setAudioShow(false);
  };

  const handleTimeline = (time: string) => {
    if (audioRef.current !== null && audioRef.current.audio.current !== null) {
      const startMoment = moment(audioData?.startTime, "hh:mi:ss");
      const finishMoment = moment(audioData?.finishTime, "hh:mi:ss");
      const diffMoment = finishMoment.diff(startMoment, "seconds", true);
      const selectedTime = moment(time, "hh:mi:ss");
      const seconds = finishMoment.diff(selectedTime, "seconds", true);
      const result = diffMoment - seconds;
      audioRef.current.audio.current.currentTime = result;
    }
  };

  const audioHeader = () => {
    if (detailLoading) {
      return <Loading />;
    }
    return (
      <>
        {detailData?.action.length !== 0 ||
        detailData?.content.length !== 0 ||
        detailData?.emotion.length !== 0 ? (
          <Grid fluid>
            <StyledRow>
              {detailData?.action.length !== 0 ? (
                <Col xs={24} sm={24} md={8} lg={6}>
                  <Timeline align="right">
                    {detailData?.action.map((ac, index) => {
                      return (
                        <StyledTimelineItem
                          time={ac.time}
                          key={index.toString()}
                          onClick={() => handleTimeline(ac.time)}
                        >
                          {ac.val}
                        </StyledTimelineItem>
                      );
                    })}
                  </Timeline>
                </Col>
              ) : (
                ""
              )}
              {detailData?.content.length !== 0 ? (
                <Col xs={24} sm={24} md={8} lg={6}>
                  <Timeline align="right">
                    {detailData?.content.map((co, index) => {
                      return (
                        <Timeline.Item
                          time={co.time}
                          key={index.toString()}
                          onClick={() => handleTimeline(co.time)}
                        >
                          {co.val}
                        </Timeline.Item>
                      );
                    })}
                  </Timeline>
                </Col>
              ) : (
                ""
              )}
              {detailData?.emotion.length !== 0 ? (
                <Col xs={24} sm={24} md={8} lg={6}>
                  <Timeline align="right">
                    {detailData?.emotion.map((em, index) => {
                      return (
                        <Timeline.Item
                          time={em.time}
                          key={index.toString()}
                          onClick={() => handleTimeline(em.time)}
                        >
                          {em.val}
                        </Timeline.Item>
                      );
                    })}
                  </Timeline>
                </Col>
              ) : (
                ""
              )}
            </StyledRow>
          </Grid>
        ) : (
          <div>タイミングは登録されていません</div>
        )}
      </>
    );
  };

  const CSVComponent: VFC = () => {
    if (csvData === undefined) {
      return <span>CSVロード中</span>;
    }
    if (isCsvOverLimit) {
      return (
        <span>CSVの取得上限を超過しています。取得期間を見直してください。</span>
      );
    }
    if (csvData.length === 0) {
      return <></>;
    }
    return (
      <StyledCsv
        headers={csvHeaders}
        data={csvData}
        filename={`analysis-data-${moment(adStartDate).format(
          "YYYY-MM-DD"
        )}-${moment(adEndDate).format("YYYY-MM-DD")}.csv`}
      >
        CSV出力
      </StyledCsv>
    );
  };
  return (
    <>
      <Title>接客分析</Title>
      <Margin top={20} bottom={15}>
        <Wrapper>
          <StartWrapper>
            <Margin>
              <StyledButton onClick={() => setShow(true)}>
                絞り込み条件設定
              </StyledButton>
              <CSVComponent />
              <Margin top={5}>
                <div>
                  {conditions.map(condition => {
                    return condition.condition.map(item => {
                      if (item.value === "" || item.value === false)
                        return null;
                      return <Tag color="blue">{item.label}</Tag>;
                    });
                  })}
                  {checkConditions.map(checkCondition => {
                    switch (checkCondition.id) {
                      case "audioOnly":
                        return checkCondition.condition ? (
                          <Tag color="red">顧客音声あり</Tag>
                        ) : null;
                      case "textOnly":
                        return checkCondition.condition ? (
                          <Tag color="red">顧客テキストあり</Tag>
                        ) : null;
                      case "workerAudioOnly":
                        return checkCondition.condition ? (
                          <Tag color="red">遠隔スタッフ音声あり</Tag>
                        ) : null;
                      case "workerTextOnly":
                        return checkCondition.condition ? (
                          <Tag color="red">遠隔スタッフテキストあり</Tag>
                        ) : null;
                      default:
                        return null;
                    }
                  })}
                </div>
              </Margin>
            </Margin>
            <Dialog
              open={show}
              onClose={onCancelClick}
              fullWidth
              maxWidth="lg"
              aria-labelledby="form-dialog-title"
              scroll="body"
            >
              <DialogTitle id="form-dialog-title">絞り込み条件設定</DialogTitle>
              <DialogContent>
                <SelectWrapper>
                  <SelectBoxForConditions
                    id="pcs"
                    placeholder="店舗を選択できます"
                    data={pcList}
                  />
                </SelectWrapper>
                {userType !== 1 && (
                  <>
                    <Margin bottom={10} />
                    <SelectWrapper>
                      <SelectBoxForConditions
                        id="users"
                        placeholder="遠隔スタッフを選択できます"
                        data={conditionData?.userList}
                      />
                    </SelectWrapper>
                  </>
                )}
                <Margin bottom={10} />
                <SelectWrapper>
                  <SelectBoxForConditions
                    id="contents"
                    placeholder="分析項目を選択できます"
                    data={conditionData?.contentList}
                  />
                </SelectWrapper>
                <Margin bottom={10} />
                <SelectWrapper>
                  <SelectBoxForConditions
                    id="actions"
                    placeholder="アクションを選択できます"
                    data={conditionData?.actionList}
                  />
                </SelectWrapper>
                <FormControlLabel
                  control={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Checkbox
                      checked={preCheckedAudioOnly}
                      onChange={handleChange}
                      name="audioOnly"
                    />
                  }
                  label="顧客音声あり"
                />
                <FormControlLabel
                  control={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Checkbox
                      checked={preCheckedTextOnly}
                      onChange={handleChange}
                      name="textOnly"
                    />
                  }
                  label="顧客テキストあり"
                />
                <FormControlLabel
                  control={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Checkbox
                      checked={preCheckedWorkerAudioOnly}
                      onChange={handleChange}
                      name="workerAudioOnly"
                    />
                  }
                  label="遠隔スタッフ音声あり"
                />
                <FormControlLabel
                  control={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Checkbox
                      checked={preCheckedWorkerTextOnly}
                      onChange={handleChange}
                      name="workerTextOnly"
                    />
                  }
                  label="遠隔スタッフテキストあり"
                />
                <DialogActions>
                  <StyledPrimaryButton onClick={onOkClick}>
                    OK
                  </StyledPrimaryButton>
                  <StyledCancelButton onClick={onCancelClick}>
                    Cancel
                  </StyledCancelButton>
                  <CaptionButton onClick={onResetClick}>
                    条件をリセット
                  </CaptionButton>
                </DialogActions>
              </DialogContent>
            </Dialog>
          </StartWrapper>
          <EndWrapper>
            <MultipleDatePicker id="audio" placement="bottomEnd" />
          </EndWrapper>
        </Wrapper>
      </Margin>
      <SubTitle>接客一覧</SubTitle>
      <Margin bottom={5} />
      {data && data !== null && data.length !== 0 ? (
        <>
          <TimelineWrapper>
            <Timeline>
              {data && data !== null && data.length !== 0 ? (
                data.map((audio, index) => {
                  if (dates.includes(`${audio.startDate},${audio.pcName}`)) {
                    return (
                      <Timeline.Item key={index.toString()}>
                        <TimelineItemWrapper>
                          <div style={{ width: "50%" }}>
                            {`${audio.startTime} ~ ${audio.finishTime} ${audio.userName}`}
                          </div>
                          {(audio.audioUrl !== null && audio.audioUrl !== "") ||
                          (audio.workerAudioUrl !== null &&
                            audio.workerAudioUrl !== "") ? (
                            // eslint-disable-next-line react/jsx-indent
                            <StyledAudioButton
                              data-csid={audio.csId}
                              data-audiourl={audio.audioUrl}
                              data-workeraudiourl={audio.workerAudioUrl}
                              data-user={audio.userName}
                              data-date={audio.startDate}
                              data-start={audio.startTime}
                              data-finish={audio.finishTime}
                              onClick={onAudioClick}
                            >
                              詳細
                            </StyledAudioButton>
                          ) : (
                            <StyledAudioGrayButton
                              data-csid={audio.csId}
                              data-audiourl={audio.audioUrl}
                              data-workeraudiourl={audio.workerAudioUrl}
                              data-user={audio.userName}
                              data-date={audio.startDate}
                              data-start={audio.startTime}
                              data-finish={audio.finishTime}
                              onClick={onAudioClick}
                            >
                              詳細
                            </StyledAudioGrayButton>
                          )}
                        </TimelineItemWrapper>
                      </Timeline.Item>
                    );
                  }
                  dates.push(`${audio.startDate},${audio.pcName}`);
                  return (
                    <Fragment key={index.toString()}>
                      <Timeline.Item key={index.toString()} dot={<DotSpan />}>
                        <div>{`${audio.startDate} ${audio.pcName}`}</div>
                      </Timeline.Item>
                      <Timeline.Item key={audio.csId}>
                        <TimelineItemWrapper>
                          <div style={{ width: "50%" }}>
                            {`${audio.startTime} ~ ${audio.finishTime} ${audio.userName}`}
                          </div>
                          {audio.audioUrl !== null && audio.audioUrl !== "" ? (
                            <StyledAudioButton
                              data-csid={audio.csId}
                              data-audiourl={audio.audioUrl}
                              data-workeraudiourl={audio.workerAudioUrl}
                              data-user={audio.userName}
                              data-date={audio.startDate}
                              data-start={audio.startTime}
                              data-finish={audio.finishTime}
                              onClick={onAudioClick}
                            >
                              詳細
                            </StyledAudioButton>
                          ) : (
                            <StyledAudioGrayButton
                              data-csid={audio.csId}
                              data-audiourl={audio.audioUrl}
                              data-workeraudiourl={audio.workerAudioUrl}
                              data-user={audio.userName}
                              data-date={audio.startDate}
                              data-start={audio.startTime}
                              data-finish={audio.finishTime}
                              onClick={onAudioClick}
                            >
                              詳細
                            </StyledAudioGrayButton>
                          )}
                        </TimelineItemWrapper>
                      </Timeline.Item>
                    </Fragment>
                  );
                })
              ) : (
                <Timeline.Item>データがありません</Timeline.Item>
              )}
            </Timeline>
          </TimelineWrapper>
          <Dialog
            open={audioShow}
            onClose={onCloseClick}
            fullWidth
            maxWidth="lg"
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle>接客音声</DialogTitle>
            <p style={{ margin: "10px" }}>
              {`${audioData?.date} ${audioData?.startTime} ~ ${audioData?.finishTime} ${audioData?.userName}`}
            </p>
            <DialogContent>
              {audioData && audioData.workerAudioUrl !== null ? (
                <>
                  <div>遠隔スタッフの音声</div>
                  <AudioPlayer
                    header={audioHeader()}
                    ref={audioRef}
                    src={audioData.workerAudioUrl}
                    preload="metadata"
                  />
                </>
              ) : (
                <>
                  {audioHeader()}
                  <p>音声はありません</p>
                </>
              )}
              {audioData && audioData.workerAudioUrl !== null ? (
                <p>
                  <a
                    download="sample.mp3"
                    href={audioData?.workerAudioUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    遠隔スタッフの音声をダウンロード
                  </a>
                </p>
              ) : (
                ""
              )}
              {audioData && audioData.audioUrl !== null ? (
                <>
                  <Margin top={10} />
                  <div>顧客の音声</div>
                  <AudioPlayer
                    header={audioHeader()}
                    ref={audioRef}
                    src={audioData.audioUrl}
                    preload="metadata"
                  />
                </>
              ) : (
                <>
                  <Margin top={10} />
                  <p>顧客の音声はありません</p>
                </>
              )}
              {audioData && audioData.audioUrl !== null ? (
                <p>
                  <a
                    download="sample.mp3"
                    href={audioData?.audioUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    顧客の音声をダウンロード
                  </a>
                </p>
              ) : (
                ""
              )}
            </DialogContent>
          </Dialog>
        </>
      ) : (
        "データがありません"
      )}
    </>
  );
};

export default Analysis;
