import React, { useMemo, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../reducers/rootReducer";
import SelectBox from "../../../presentational/molecules/SelectBox";
import Title from "../../../presentational/atoms/Text/Title";
import { Margin } from "../../../../utils/styled";
import MultipleDatePicker from "../../../presentational/molecules/DatePicker";
import Card from "../../../presentational/molecules/Card";
import { fetchCsCount } from "../../../../modules/Analysis/csCountApiModule";
import { fetchGraph } from "../../../../modules/Analysis/Graph/graphApiModule";
import { fetchWorkers } from "../../../../modules/Analysis/workersApiModule";
import LineCharts from "../../../presentational/molecules/Charts/LineCharts";
import PieCharts from "../../../presentational/molecules/Charts/PieCharts";
import Table from "../../../presentational/molecules/Table/WorkersTable";

const Wrapper = styled.div`
  font-size: 12px;
  display: flex;
  flex: row;
  justify-content: space-between;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const SelectWrapper = styled.div`
  width: 50%;
  font-size: 12px;
  margin-right: 10px;
`;

const LineChartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const PieChartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const Shop = () => {
  const dispatch = useDispatch();

  const { token } = useSelector((state: RootState) => state.auth);

  const { shops } = useSelector((state: RootState) => state.shop);
  const selectedShops = useMemo(
    () => shops.filter(value => value.id === "shop").map(shop => shop.shop),
    [shops]
  );

  const { picks } = useSelector((state: RootState) => state.datePick);
  const { startDate, endDate } = picks.filter(value => {
    return value.id === "shop";
  })[0];

  const { data: csCountData, isLoading: csCountLoading } = useSelector(
    (state: RootState) => state.csCountApi
  );

  const { data } = useSelector((state: RootState) => state.workersApi);

  useEffect(() => {
    const fetchData = () => {
      const pcId = selectedShops[0].map(item => item.value);
      if (token && pcId[0] !== "") {
        dispatch(fetchCsCount(token, startDate, endDate, pcId));
        dispatch(fetchGraph(token, startDate, endDate, pcId));
        dispatch(fetchWorkers(token, startDate, endDate, pcId));
      }
    };
    fetchData();
  }, [dispatch, endDate, selectedShops, startDate, token]);

  return (
    <>
      <Title>店舗分析</Title>
      <Margin top={20} />
      <Wrapper>
        <SelectWrapper>
          <SelectBox
            id="shop"
            isMulti={false}
            placeholder="店舗を選択してください"
          />
        </SelectWrapper>
        <MultipleDatePicker id="shop" placement="bottomEnd" />
      </Wrapper>
      <Margin top={20} />
      {selectedShops[0][0].value !== "" ? (
        <>
          <CardWrapper>
            <Card
              header="接客総数"
              count={csCountData ? csCountData.totalCounts : 0}
              isLoading={csCountLoading}
            />
            <Margin left={20} />
            <Card
              header="接客総時間"
              count={csCountData ? csCountData.totalHours : "00:00:00"}
              isLoading={csCountLoading}
            />
          </CardWrapper>
          <Margin top={20} />
          {data ? <Table data={data} /> : <div />}
          <Margin top={20} />
          <LineChartWrapper>
            <LineCharts />
          </LineChartWrapper>

          <Margin top={10} />
          <PieChartWrapper>
            <PieCharts />
          </PieChartWrapper>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Shop;
