import { createSlice } from "@reduxjs/toolkit";

interface SpeechMicState {
  speechMic: SpeechMicDeviceId;
}

export interface SpeechMicDeviceId {
  deviceId: string;
}

const initialState: SpeechMicState = {
  speechMic: {
    deviceId: "default",
  },
};

const speechMicModule = createSlice({
  name: "speechMic",
  initialState,
  reducers: {
    changeSpeechMic(state, action) {
      state.speechMic.deviceId = action.payload.selectedOptions.value;
      return state;
    },
  },
});

export default speechMicModule.reducer;

export const { changeSpeechMic } = speechMicModule.actions;
