/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Title from "../../../presentational/atoms/Text/Title";
import { RootState } from "../../../../reducers/rootReducer";
import Card from "../../../presentational/molecules/Card";
import { Margin } from "../../../../utils/styled";
import { fetchWorker } from "../../../../modules/Analysis/workerApiModule";
import { fetchWorkerTotal } from "../../../../modules/Analysis/workerTotalApiModule";
import SelectBox from "../../../presentational/molecules/SelectBox";
import MultipleDatePicker from "../../../presentational/molecules/DatePicker";
import Table from "../../../presentational/molecules/Table/WorkerTable";
import PrimaryButton from "../../../presentational/atoms/Button/PrimaryButton";

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const Wrapper = styled.div`
  font-size: 12px;
  display: flex;
  flex: row;
  justify-content: space-between;
`;

const StartWrapper = styled.div`
  display: flex;
  flex: row;
  width: 100%;
  justify-content: flex-start;
`;

const EndWrapper = styled.div`
  display: flex;
  flex: row;
  width: 100%;
  justify-content: flex-end;
`;

const SelectWrapper = styled.div`
  width: 50%;
  font-size: 12px;
  margin-right: 10px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  text-decoration: none !important;
  font-size: 16px;
  font-weight: bold;
  &:hover {
    color: #eee;
  }
`;

const Worker = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { workerId } = useParams<{ workerId: string }>();
  const { workers } = useSelector((state: RootState) => state.workers);
  const worker = workers
    ? workers.filter(item => item.userId === workerId)[0]
    : "";

  const { token } = useSelector((state: RootState) => state.auth);

  const { shops } = useSelector((state: RootState) => state.shop);
  const selectedShops = useMemo(
    () => shops.filter(value => value.id === "worker").map(shop => shop.shop),
    [shops]
  );

  const { picks } = useSelector((state: RootState) => state.datePick);
  const { startDate: wkStartDate, endDate: wkEndDate } = picks.filter(value => {
    return value.id === "workers";
  })[0];

  const { data: workerApiData, isLoading } = useSelector(
    (state: RootState) => state.workerApi
  );

  const { data: workerTotalApiData, isLoading: totalIsLoading } = useSelector(
    (state: RootState) => state.workerTotalApi
  );

  useEffect(() => {
    const fetchData = () => {
      if (token && workerId) {
        const pcIds: string[] = selectedShops[0].map(item => item.value);
        dispatch(
          fetchWorkerTotal(token, workerId, wkStartDate, wkEndDate, pcIds)
        );
      }
    };
    fetchData();
  }, [dispatch, selectedShops, token, wkEndDate, wkStartDate, workerId]);

  useEffect(() => {
    const fetchData = () => {
      if (token && workerId) {
        const pcIds: string[] = selectedShops[0].map(item => item.value);
        dispatch(fetchWorker(token, workerId, wkStartDate, wkEndDate, pcIds));
      }
    };
    fetchData();
  }, [dispatch, selectedShops, token, wkEndDate, wkStartDate, workerId]);

  return (
    <>
      {worker ? <Title>{`${worker.userName}  さん`}</Title> : ""}
      <Margin top={20} />
      <CardWrapper>
        <Card
          header="総接客回数"
          count={
            workerTotalApiData && Object.keys(workerTotalApiData).length !== 0
              ? workerTotalApiData.totalServedCount
              : "0"
          }
          isLoading={totalIsLoading}
        />
        <Margin left={20} />
        <Card
          header="総接客時間"
          count={
            workerTotalApiData && Object.keys(workerTotalApiData).length !== 0
              ? workerTotalApiData.totalServedTime
              : "00:00:00"
          }
          isLoading={totalIsLoading}
        />
        <Margin left={20} />
        <Card
          header="シフトイン時間"
          count={
            workerApiData
              ? workerApiData[0]
                ? workerApiData[0].logTime
                : "00:00:00"
              : "00:00:00"
          }
          isLoading={isLoading}
        />
        <Margin left={20} />
        <Card
          header="休憩時間"
          count={
            workerApiData
              ? workerApiData[0]
                ? workerApiData[0].restTime
                : "00:00:00"
              : "00:00:00"
          }
          isLoading={isLoading}
        />
      </CardWrapper>
      <Margin top={40} />
      <Wrapper>
        <StartWrapper>
          <SelectWrapper>
            <SelectBox id="worker" />
          </SelectWrapper>
        </StartWrapper>
        <EndWrapper>
          <MultipleDatePicker id="workers" placement="bottomEnd" />
        </EndWrapper>
      </Wrapper>
      <Margin top={20} bottom={20}>
        {workerApiData && Object.keys(workerApiData).length !== 0 ? (
          <Table data={workerApiData} />
        ) : (
          <div>データがありません</div>
        )}
      </Margin>
      <Wrapper>
        <EndWrapper>
          <StyledPrimaryButton
            type="button"
            onClick={() => {
              history.goBack();
            }}
          >
            戻る
          </StyledPrimaryButton>
        </EndWrapper>
      </Wrapper>
    </>
  );
};

export default Worker;
