import { Button } from "@material-ui/core";
import styled from "styled-components";
import { darkGray, textGray } from "../../../../utils/color";

const MuiCancelButton = styled(Button)`
  background-color: ${textGray};
  &:hover {
    background-color: ${darkGray};
  }
  color: ${props => props.theme.palette.text.secondary};
`;

export default MuiCancelButton;
