import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import updateMemo, {
  UpdateMemo,
} from "../../../api/Management/shopApi/updateMemo";
import { expiredToken } from "../../auth";
import { AppThunk } from "../../../stores";
import { ApiState, startLoading, loadingFailed } from "../../../api/apiService";

const apiInitialState: ApiState<UpdateMemo> = {
  isLoading: false,
  error: null,
  data: null,
};

const updateMemoApiModule = createSlice({
  name: "updateMemo",
  initialState: apiInitialState,
  reducers: {
    getApiStart: startLoading,
    getApiSuccess(state, { payload }: PayloadAction<UpdateMemo>) {
      state.isLoading = false;
      state.error = null;
      state.data = payload;
    },
    getApiFailure: loadingFailed,
  },
});

export const {
  getApiStart,
  getApiSuccess,
  getApiFailure,
} = updateMemoApiModule.actions;

export default updateMemoApiModule.reducer;

export const putMemo = (
  token: string,
  shopId: string,
  id: string,
  title: string,
  text: string,
  remark: string
): AppThunk => async dispatch => {
  try {
    dispatch(getApiStart());
    const data = await updateMemo(token, shopId, id, title, text, remark);
    dispatch(getApiSuccess(data));
  } catch (err) {
    switch (err.resultStatus) {
      case 126:
        dispatch(expiredToken(err.resultStatus));
        break;
      case 115:
        dispatch(getApiFailure(err.resultStatus));
        throw err;
      case 110:
        dispatch(getApiFailure(err.resultStatus));
        throw err;
      default:
        console.error(err.resultText);
        break;
    }
    dispatch(getApiFailure(err));
  }
};
