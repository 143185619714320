import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import addRoom, { AddRoom } from "../../../api/Management/roomsApi/add";
import { expiredToken } from "../../auth";
import { AppThunk } from "../../../stores";
import { ApiState, startLoading, loadingFailed } from "../../../api/apiService";

const apiInitialState: ApiState<AddRoom> = {
  isLoading: false,
  error: null,
  data: null,
};

const addRoomApiModule = createSlice({
  name: "addRoom",
  initialState: apiInitialState,
  reducers: {
    getApiStart: startLoading,
    getApiSuccess(state, { payload }: PayloadAction<AddRoom>) {
      state.isLoading = false;
      state.error = null;
      state.data = payload;
    },
    getApiFailure: loadingFailed,
  },
});

export const {
  getApiStart,
  getApiSuccess,
  getApiFailure,
} = addRoomApiModule.actions;

export default addRoomApiModule.reducer;

export const postRoom = (
  token: string,
  id: string,
  name: string,
  pcIds: string[],
  deusGroupNo: number,
  liteFlg: boolean,
  remark: string
): AppThunk => async dispatch => {
  try {
    dispatch(getApiStart());
    const data = await addRoom(
      token,
      id,
      name,
      pcIds,
      deusGroupNo,
      liteFlg,
      remark
    );
    dispatch(getApiSuccess(data));
  } catch (err) {
    switch (err.resultStatus) {
      case 126:
        dispatch(expiredToken(err.resultStatus));
        break;
      case 115:
        dispatch(getApiFailure(err.resultStatus));
        throw err;
      default:
        console.error(err);
        break;
    }
    dispatch(getApiFailure(err));
  }
};
