/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormInstance } from "rsuite/lib/Form";
import {
  Form,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Schema,
  Alert,
  TagPicker,
  Whisper,
  Popover,
  List,
} from "rsuite";
import { Typography } from "@material-ui/core";
import { RootState } from "../../../../../reducers/rootReducer";
import { fetchRobotTypes } from "../../../../../modules/Management/RobotTypes/robotTypesApiModule";
import { Margin } from "../../../../../utils/styled";
import RobotTypesTable from "../../../../presentational/molecules/Table/RobotTypesTable";
import {
  StyledPrimaryButton,
  StyledCancelButton,
  StyledEditButton,
  StyledDeleteButton,
  StyledModal,
} from "../Users";
import Loading from "../../../../presentational/molecules/Loading";
import { setAddFormValue } from "../../../../../modules/Management/Form/RobotType/robotTypeAddFormModule";
import { fetchMotions } from "../../../../../modules/Management/Motions/motionsApiModule";
import {
  setEditFormValue,
  setEditFormInitialValue,
} from "../../../../../modules/Management/Form/RobotType/robotTypeEditFormModule";
import { postRobotType } from "../../../../../modules/Management/RobotTypes/addRobotTypeApiModule";
import { putRobotType } from "../../../../../modules/Management/RobotTypes/updateRobotTypeApiModule";
import { setCheckedKeys } from "../../../../../modules/Management/checkModule";
import { delRobotType } from "../../../../../modules/Management/RobotTypes/deleteRobotTypeApiModule";

const addModel = Schema.Model({
  robotTypeId: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
});

const RobotTypes = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [addForm, setAddForm] = useState<FormInstance>();

  const [showEdit, setShowEdit] = useState(false);
  const [editForm, setEditForm] = useState<FormInstance>();

  const [showDelete, setShowDelete] = useState(false);

  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.auth);

  const { data, isLoading } = useSelector(
    (state: RootState) => state.robotTypes
  );
  const { data: motionData } = useSelector((state: RootState) => state.motions);

  const filteredMotionData = useMemo(() => {
    if (motionData !== null) {
      return motionData.motions.map(motion => {
        return {
          label: motion.text,
          value: motion.motionId,
        };
      });
    }
    return [
      {
        label: "",
        value: "",
      },
    ];
  }, [motionData]);

  const { checks } = useSelector((state: RootState) => state.checkCells);
  const checkedKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "robotTypes")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );

  useEffect(() => {
    if (token) {
      dispatch(fetchRobotTypes(token));
      dispatch(fetchMotions(token));
    }
  }, [dispatch, token]);

  const { formValue: addFormValue } = useSelector(
    (state: RootState) => state.robotTypeAddForm
  );
  const { formValue: editFormValue } = useSelector(
    (state: RootState) => state.robotTypeEditForm
  );

  const handleAddClick = () => {
    setShowAdd(true);
  };

  const handleAddFormChange = (value: any) => {
    dispatch(setAddFormValue({ formValue: value }));
  };

  const handleCheckAndSubmitAddForm = async () => {
    const checkResult = addForm!.check();
    if (checkResult && token) {
      try {
        setShowAdd(false);
        Alert.info("ロボットタイプ追加中...", 100000000);
        await dispatch(
          postRobotType(
            token,
            addFormValue.robotTypeId,
            addFormValue.name,
            addFormValue.motions,
            addFormValue.remark
          )
        );
        Alert.close();
        Alert.success("ロボットタイプを追加しました", 5000);
        await dispatch(fetchRobotTypes(token));
      } catch (err) {
        Alert.closeAll();
        Alert.error("入力したIDはすでに存在しています", 2000);
      }
    }
  };

  const handleEditClick = () => {
    if (data && motionData) {
      const filteredRobotType = data.robotTypes.filter(
        robotType => robotType.robotTypeId === checkedKeys[0]
      )[0];
      dispatch(
        setEditFormValue({
          formValue: {
            robotTypeId: filteredRobotType.robotTypeId,
            name: filteredRobotType.name,
            motions: filteredRobotType.motions,
            remark: filteredRobotType.remark,
          },
        })
      );
      setShowEdit(true);
    }
  };

  const handleEditFormChange = (value: any) => {
    dispatch(setEditFormValue({ formValue: value }));
  };

  const handleCheckAndSubmitEditForm = async () => {
    const checkResult = editForm!.check();
    if (checkResult && token) {
      try {
        setShowEdit(false);
        Alert.info("ロボットタイプ更新中...", 100000000);
        await dispatch(
          putRobotType(
            token,
            editFormValue.robotTypeId,
            editFormValue.name,
            editFormValue.motions,
            editFormValue.remark
          )
        );
        dispatch(
          setCheckedKeys({
            id: "robotTypes",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        dispatch(setEditFormInitialValue());
        Alert.close();
        Alert.success("ロボットタイプを更新しました", 5000);
        await dispatch(fetchRobotTypes(token));
      } catch (err) {
        Alert.closeAll();
        Alert.error("不明なエラーが発生しました", 2000);
      }
    }
  };

  const handleDeleteClick = () => {
    setShowDelete(true);
  };

  const handleSubmitDelete = async () => {
    if (token) {
      try {
        setShowDelete(false);
        Alert.info("ロボットタイプ削除中...", 100000000);
        await dispatch(delRobotType(token, checkedKeys[0]));
        Alert.close();
        Alert.success("ロボットタイプを削除しました", 5000);
        dispatch(
          setCheckedKeys({
            id: "robotTypes",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        await dispatch(fetchRobotTypes(token));
      } catch (err) {
        Alert.closeAll();
        Alert.error("不明なエラーが発生しました", 5000);
      }
    }
  };

  const onCancelClick = () => {
    setShowAdd(false);
    setShowEdit(false);
    setShowDelete(false);
  };

  const editSpeaker = () => {
    if (checkedKeys.length > 1) {
      return (
        <Popover title="編集について">
          <p>一度に編集できるロボットタイプは1つです</p>
          <p>チェックを外してください</p>
        </Popover>
      );
    }

    return (
      <Popover title="編集について">
        <p>編集したいロボットタイプにチェックをつけてください</p>
      </Popover>
    );
  };

  const deleteSpeaker = () => {
    return (
      <Popover title="削除について">
        <p>削除したいロボットタイプにチェックをつけてください</p>
      </Popover>
    );
  };

  return (
    <>
      <Typography variant="h6">ロボットタイプ管理</Typography>
      <Margin top={20}>
        {!isLoading ? (
          data &&
          data !== null &&
          data.robotTypes &&
          data.robotTypes.length !== 0 ? (
            <>
              <RobotTypesTable robotTypes={data.robotTypes} />
              <Margin top={5} />
              <StyledPrimaryButton onClick={handleAddClick}>
                追加
              </StyledPrimaryButton>
              {checkedKeys.length === 1 ? (
                <>
                  <StyledEditButton isChecked onClick={handleEditClick}>
                    編集
                  </StyledEditButton>
                  <StyledDeleteButton isChecked onClick={handleDeleteClick}>
                    削除
                  </StyledDeleteButton>
                </>
              ) : (
                <>
                  <Whisper
                    placement="autoVerticalStart"
                    trigger="hover"
                    speaker={editSpeaker()}
                  >
                    <StyledEditButton isChecked={false}>編集</StyledEditButton>
                  </Whisper>
                  <Whisper
                    placement="autoVerticalStart"
                    trigger="hover"
                    speaker={deleteSpeaker()}
                  >
                    <StyledDeleteButton isChecked={false}>
                      削除
                    </StyledDeleteButton>
                  </Whisper>
                </>
              )}
            </>
          ) : (
            <>
              <div>ロボットタイプはありません</div>
              <StyledPrimaryButton onClick={handleAddClick}>
                追加
              </StyledPrimaryButton>
            </>
          )
        ) : (
          <Loading />
        )}
      </Margin>

      <StyledModal show={showAdd} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            ロボットタイプ追加
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={addModel}
            formValue={addFormValue}
            ref={(ref: FormInstance) => setAddForm(ref)}
            onChange={handleAddFormChange}
          >
            <FormGroup>
              <ControlLabel>ロボットタイプID</ControlLabel>
              <FormControl name="robotTypeId" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>ロボットタイプ名</ControlLabel>
              <FormControl name="name" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>モーションID</ControlLabel>
              <FormControl
                accepter={TagPicker}
                style={{ width: "100%" }}
                data={filteredMotionData}
                name="motions"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="remark" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledPrimaryButton onClick={handleCheckAndSubmitAddForm}>
              OK
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelClick}>
              Cancel
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showEdit} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            ロボットタイプ編集
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={addModel}
            formValue={editFormValue}
            ref={(ref: FormInstance) => setEditForm(ref)}
            onChange={handleEditFormChange}
          >
            <FormGroup>
              <ControlLabel>ロボットタイプID</ControlLabel>
              <FormControl name="robotTypeId" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>ロボットタイプ名</ControlLabel>
              <FormControl name="name" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>モーションID</ControlLabel>
              <FormControl
                accepter={TagPicker}
                style={{ width: "100%" }}
                data={filteredMotionData}
                name="motions"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="remark" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledPrimaryButton onClick={handleCheckAndSubmitEditForm}>
              OK
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelClick}>
              Cancel
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showDelete} overflow onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            ロボットタイプ削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List bordered={false}>
            {checkedKeys
              .map(key => {
                return data?.robotTypes
                  .filter(robotType => robotType.robotTypeId === key)
                  .map(value => value.robotTypeId)[0];
              })
              .map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{
                    paddingLeft: "10px",
                    color: "#d84421",
                  }}
                >
                  {item}
                </List.Item>
              ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: "#d84421",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記のロボットタイプを削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          <StyledDeleteButton isChecked onClick={handleSubmitDelete}>
            OK
          </StyledDeleteButton>
          <StyledCancelButton onClick={onCancelClick}>
            Cancel
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>
    </>
  );
};
export default RobotTypes;
