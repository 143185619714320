import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type Condition = {
  id: string;
  condition: [
    {
      value: string | boolean;
      label: string;
    }
  ];
};

type ConditionState = {
  conditions: Condition[];
};

const initialState: ConditionState = {
  conditions: [
    {
      id: "pcs",
      condition: [
        {
          value: "",
          label: "",
        },
      ],
    },
    {
      id: "users",
      condition: [
        {
          value: "",
          label: "",
        },
      ],
    },
    {
      id: "contents",
      condition: [
        {
          value: "",
          label: "",
        },
      ],
    },
    {
      id: "emotions",
      condition: [
        {
          value: "",
          label: "",
        },
      ],
    },
    {
      id: "actions",
      condition: [
        {
          value: "",
          label: "",
        },
      ],
    },
    {
      id: "audioOnly",
      condition: [
        {
          value: false,
          label: "",
        },
      ],
    },
    {
      id: "textOnly",
      condition: [
        {
          value: false,
          label: "",
        },
      ],
    },
    {
      id: "workerAudioOnly",
      condition: [
        {
          value: false,
          label: "",
        },
      ],
    },
    {
      id: "workerTextOnly",
      condition: [
        {
          value: false,
          label: "",
        },
      ],
    },
  ],
};

const conditionModule = createSlice({
  name: "condition",
  initialState,
  reducers: {
    setConditions(
      state: ConditionState,
      { payload }: PayloadAction<Condition[]>
    ) {
      state.conditions.forEach(condition => {
        payload.forEach(selectedConditions => {
          if (condition.id === selectedConditions.id) {
            condition.condition = selectedConditions.condition;
          }
        });
      });
    },
  },
});

export const { setConditions } = conditionModule.actions;

export default conditionModule.reducer;
