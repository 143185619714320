import styled from "styled-components";
import { headerFont } from "../../../../utils/fonts";

const CardHeader = styled.h2`
  font-size: 16px;
  font-weight: bold;
  font-family: ${headerFont};
`;

export default CardHeader;
