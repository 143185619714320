import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MediaEvent } from "../../../api/Management/actionsApi";

export type FormValueState = {
  formValue: {
    id?: string;
    name?: string;
    images?: string[];
    videos?: string[];
    speeches?: string[];
    notificationButtons?: string[];
    mediaEvents?: MediaEvent[];
    infoButtons?: string[];
    remark?: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    id: "",
    name: "",
    images: [],
    videos: [],
    speeches: [],
    notificationButtons: [],
    mediaEvents: [],
    infoButtons: [],
    remark: "",
  },
};

const shopEditFormModule = createSlice({
  name: "shopEditForm",
  initialState,
  reducers: {
    setShopEditFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.id = payload.formValue.id;
      state.formValue.name = payload.formValue.name
        ? payload.formValue.name
        : "";
      state.formValue.images = payload.formValue.images
        ? payload.formValue.images
        : [];
      state.formValue.videos = payload.formValue.videos
        ? payload.formValue.videos
        : [];
      state.formValue.speeches = payload.formValue.speeches
        ? payload.formValue.speeches
        : [];
      state.formValue.notificationButtons = payload.formValue
        .notificationButtons
        ? payload.formValue.notificationButtons
        : [];
      state.formValue.infoButtons = payload.formValue.infoButtons
        ? payload.formValue.infoButtons
        : [];
      state.formValue.mediaEvents = payload.formValue.mediaEvents ?? [];
      state.formValue.remark = payload.formValue.remark
        ? payload.formValue.remark
        : "";
    },
    setShopVideoFormValue(
      state: FormValueState,
      { payload }: PayloadAction<string[]>
    ) {
      state.formValue.videos = payload;
    },
    setShopImageFormValue(
      state: FormValueState,
      { payload }: PayloadAction<string[]>
    ) {
      state.formValue.images = payload;
    },
  },
});

export const {
  setShopEditFormValue,
  setShopVideoFormValue,
  setShopImageFormValue,
} = shopEditFormModule.actions;

export default shopEditFormModule.reducer;
