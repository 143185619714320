import React, { useMemo, VFC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Schema,
  SelectPicker,
} from "rsuite";
import { FormInstance } from "rsuite/lib/Form";
import { setAddFormValue } from "../../../../modules/Management/Form/InfoButton/infoButtonAddFormModule";
import { RootState } from "../../../../reducers/rootReducer";
import {
  StyledCancelButton,
  StyledPrimaryButton,
} from "../../../container/templates/Management/AnalysisInfo";
import { StyledModal } from "../../../container/templates/Management/Users";

type Props = {
  show: boolean;
  onHide: () => void;
  setForm: any;
  submitAnalysisInfo: () => void;
};

const addModel = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  text: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  val: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  infoButtonTypeId: Schema.Types.StringType().isRequired("必須項目です"),
  displayTypeId: Schema.Types.StringType().isRequired("必須項目です"),
});

const addNewModel = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  text: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  val: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  infoButtonTypeId: Schema.Types.StringType().isRequired("必須項目です"),
  newInfoButtonTypeId: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  newInfoButtonTypeText: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  newInfoType: Schema.Types.StringType().isRequired("必須項目です"),
  displayTypeId: Schema.Types.StringType().isRequired("必須項目です"),
});

export const AddAnalysisInfoModal: VFC<Props> = props => {
  const { show, onHide, setForm, submitAnalysisInfo } = props;

  const dispatch = useDispatch();

  const { formValue: addFormValue } = useSelector(
    (state: RootState) => state.infoButtonAddForm
  );

  const { data: infoButtonTypesData } = useSelector(
    (state: RootState) => state.getInfoButtonTypes
  );

  const infoButtonTypeOptions = useMemo(() => {
    if (infoButtonTypesData && infoButtonTypesData.infoButtonTypes) {
      return infoButtonTypesData.infoButtonTypes.map(infoButtonType => {
        return {
          label: infoButtonType.text,
          value: infoButtonType.id,
        };
      });
    }
    return [];
  }, [infoButtonTypesData]);

  const handleAddFormChange = (value: any) => {
    dispatch(setAddFormValue({ formValue: value }));
  };
  return (
    <StyledModal show={show} overflow={false} onHide={() => onHide()}>
      <Modal.Header>
        <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
          分析情報追加
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          fluid
          model={
            addFormValue.infoButtonTypeId !==
            "create_new_rura_analysis_info_option"
              ? addModel
              : addNewModel
          }
          formValue={addFormValue}
          ref={(ref: FormInstance) => setForm(ref)}
          onChange={handleAddFormChange}
        >
          <FormGroup>
            <ControlLabel>分析情報ID</ControlLabel>
            <FormControl name="id" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>分析情報名</ControlLabel>
            <FormControl name="text" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>分析情報値</ControlLabel>
            <FormControl name="val" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>分析タイプ</ControlLabel>
            <FormControl
              accepter={SelectPicker}
              searchable
              data={[
                {
                  label: "新規作成",
                  value: "create_new_rura_analysis_info_option",
                },
              ].concat(infoButtonTypeOptions)}
              name="infoButtonTypeId"
              placeholder="分析タイプを選択してください"
            />
          </FormGroup>
          {addFormValue.infoButtonTypeId ===
          "create_new_rura_analysis_info_option" ? (
            <FormGroup>
              <ControlLabel>分析タイプID</ControlLabel>
              <FormControl name="newInfoButtonTypeId" />
              <ControlLabel>分析タイプ名</ControlLabel>
              <FormControl name="newInfoButtonTypeText" />
              <ControlLabel>分析情報タイプ</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                searchable
                data={[
                  {
                    label: "分析項目",
                    value: "contents",
                  },
                ]}
                name="newInfoType"
                placeholder="分析情報タイプを選択してください"
              />
            </FormGroup>
          ) : (
            ""
          )}
          <FormGroup>
            <ControlLabel>分析タイミング</ControlLabel>
            <FormControl
              accepter={SelectPicker}
              searchable={false}
              data={[
                {
                  label: "接客中",
                  value: "ec32e79c-ddd5-4265-bff3-c7bbc4c9cb84",
                },
                {
                  label: "接客後",
                  value: "e4c0dddc-89a3-40ad-b6b6-f4d65631203c",
                },
              ]}
              name="displayTypeId"
              placeholder="分析タイミングを選択してください"
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>備考</ControlLabel>
            <FormControl name="remark" />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <StyledPrimaryButton
          onClick={() => {
            submitAnalysisInfo();
          }}
        >
          OK
        </StyledPrimaryButton>
        <StyledCancelButton onClick={() => onHide()}>Cancel</StyledCancelButton>
      </Modal.Footer>
    </StyledModal>
  );
};
