/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormInstance } from "rsuite/lib/Form";
import {
  Whisper,
  Popover,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Schema,
  Form,
  Alert,
  List,
  SelectPicker,
} from "rsuite";
import { Typography } from "@material-ui/core";
import { Margin } from "../../../../../utils/styled";
import { RootState } from "../../../../../reducers/rootReducer";
import { fetchCompanies } from "../../../../../modules/Management/Companies/companiesApiModule";
import Loading from "../../../../presentational/molecules/Loading";
import CompaniesTable from "../../../../presentational/molecules/Table/CompaniesTable";
import {
  StyledPrimaryButton,
  StyledEditButton,
  StyledDeleteButton,
  StyledCancelButton,
  StyledModal,
} from "../Users";
import { setAddFormValue } from "../../../../../modules/Management/Form/Company/companyAddFormModule";
import { postCompany } from "../../../../../modules/Management/Companies/addCompanyApiModule";
import { setCheckedKeys } from "../../../../../modules/Management/checkModule";
import { setEditFormValue } from "../../../../../modules/Management/Form/Company/companyEditFormModule";
import { putCompany } from "../../../../../modules/Management/Companies/updateCompanyApiModule";
import { delCompany } from "../../../../../modules/Management/Companies/deleteCompanyApiModule";

const addModel = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  name: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
});

const editModel = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  name: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
});

const Companies = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [addForm, setAddForm] = useState<FormInstance>();

  const [showEdit, setShowEdit] = useState(false);
  const [editForm, setEditForm] = useState<FormInstance>();

  const [showDelete, setShowDelete] = useState(false);
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.auth);

  const { data, isLoading } = useSelector(
    (state: RootState) => state.companies
  );

  const { checks } = useSelector((state: RootState) => state.checkCells);
  const checkedKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "companies")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );

  const { formValue: addFormValue } = useSelector(
    (state: RootState) => state.companyAddForm
  );

  const { formValue: editFormValue } = useSelector(
    (state: RootState) => state.companyEditForm
  );

  useEffect(() => {
    if (token) {
      dispatch(fetchCompanies(token));
    }
  }, [dispatch, token]);

  const handleAddClick = () => {
    setShowAdd(true);
  };

  const handleEditClick = () => {
    const companyData = data?.companies.filter(
      company => company.companyId === checkedKeys[0]
    )[0];
    dispatch(
      setEditFormValue({
        formValue: {
          id: checkedKeys[0],
          name: companyData?.name,
          recordFlg: companyData?.recordFlg ? 0 : 1,
          remark: companyData?.remark,
        },
      })
    );
    setShowEdit(true);
  };

  const handleDeleteClick = () => {
    setShowDelete(true);
  };

  const onCancelClick = () => {
    setShowAdd(false);
    setShowEdit(false);
  };

  const handleAddFormChange = (value: any) => {
    dispatch(setAddFormValue({ formValue: value }));
  };

  const handleCheckAndSubmitAddForm = async () => {
    const checkResult = addForm!.check();
    if (checkResult && token) {
      try {
        await dispatch(
          postCompany(
            token,
            addFormValue.id,
            addFormValue.name,
            addFormValue.recordFlg,
            addFormValue.remark
          )
        );
        dispatch(
          setCheckedKeys({
            id: "companies",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        dispatch(
          setAddFormValue({
            formValue: {
              id: "",
              name: "",
              recordFlg: 0,
              remark: "",
            },
          })
        );
        Alert.success("カンパニーを追加しました", 5000);
        setShowAdd(false);
        await dispatch(fetchCompanies(token));
      } catch (err) {
        Alert.error("入力したIDはすでに登録されています", 2000);
      }
    }
  };

  const handleEditFormChange = (value: any) => {
    dispatch(setEditFormValue({ formValue: value }));
  };

  const handleCheckAndSubmitEditForm = async () => {
    const checkResult = editForm!.check();
    if (checkResult && token) {
      setShowEdit(false);
      try {
        await dispatch(
          putCompany(
            token,
            editFormValue.id ? editFormValue.id : "",
            editFormValue.name ? editFormValue.name : "",
            editFormValue.recordFlg !== undefined ? editFormValue.recordFlg : 0,
            editFormValue.remark ? editFormValue.remark : ""
          )
        );
        Alert.success("カンパニーを更新しました", 5000);
        dispatch(
          setCheckedKeys({
            id: "companies",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        await dispatch(fetchCompanies(token));
      } catch (err) {
        Alert.error("不明なエラーが発生しました", 2000);
      }
    }
  };

  const handleSubmitDelete = async () => {
    if (token) {
      try {
        setShowDelete(false);
        Alert.info("カンパニーを削除中...", 100000000);
        await dispatch(delCompany(token, checkedKeys[0]));
        dispatch(
          setCheckedKeys({
            id: "companies",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        Alert.close();
        Alert.success("カンパニーを削除しました", 5000);
        await dispatch(fetchCompanies(token));
      } catch (err) {
        Alert.closeAll();
        Alert.error("不明なエラーが発生しました", 2000);
      }
    }
  };

  const editSpeaker = () => {
    if (checkedKeys.length > 1) {
      return (
        <Popover title="編集について">
          <p>一度に編集できるカンパニーは1つです</p>
          <p>チェックを外してください</p>
        </Popover>
      );
    }

    return (
      <Popover title="編集について">
        <p>編集したいカンパニーにチェックをつけてください</p>
      </Popover>
    );
  };

  const deleteSpeaker = () => {
    return (
      <Popover title="削除について">
        <p>削除したいカンパニーにチェックをつけてください</p>
      </Popover>
    );
  };

  return (
    <>
      <Typography variant="h6">カンパニー管理</Typography>
      <Margin top={20}>
        {!isLoading ? (
          data && data !== null ? (
            <>
              <CompaniesTable companies={data.companies} />
              <StyledPrimaryButton onClick={handleAddClick}>
                追加
              </StyledPrimaryButton>
              {checkedKeys.length === 1 ? (
                <StyledEditButton isChecked onClick={handleEditClick}>
                  編集
                </StyledEditButton>
              ) : (
                <Whisper
                  placement="autoVerticalStart"
                  trigger="hover"
                  speaker={editSpeaker()}
                >
                  <StyledEditButton isChecked={false}>編集</StyledEditButton>
                </Whisper>
              )}
              {checkedKeys.length === 1 ? (
                <StyledDeleteButton isChecked onClick={handleDeleteClick}>
                  削除
                </StyledDeleteButton>
              ) : (
                <Whisper
                  placement="autoVerticalStart"
                  trigger="hover"
                  speaker={deleteSpeaker()}
                >
                  <StyledDeleteButton isChecked={false}>
                    削除
                  </StyledDeleteButton>
                </Whisper>
              )}
            </>
          ) : (
            <>
              <div>カンパニーは存在しません</div>
              <StyledPrimaryButton onClick={handleAddClick}>
                追加
              </StyledPrimaryButton>
            </>
          )
        ) : (
          <Loading />
        )}
      </Margin>
      <StyledModal show={showAdd} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            カンパニー追加
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={addModel}
            formValue={addFormValue}
            ref={(ref: FormInstance) => setAddForm(ref)}
            onChange={handleAddFormChange}
          >
            <FormGroup>
              <ControlLabel>カンパニーID</ControlLabel>
              <FormControl name="id" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>カンパニー名</ControlLabel>
              <FormControl name="name" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>録音フラグ</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                searchable={false}
                placeholder="録音可否を選択してください"
                data={[
                  { label: "録音可", value: 0 },
                  { label: "録音不可", value: 1 },
                ]}
                name="recordFlg"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="remark" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <StyledPrimaryButton onClick={handleCheckAndSubmitAddForm}>
            OK
          </StyledPrimaryButton>
          <StyledCancelButton onClick={onCancelClick}>
            Cancel
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>
      <StyledModal show={showEdit} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            カンパニー編集
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={editModel}
            formValue={editFormValue}
            ref={(ref: FormInstance) => setEditForm(ref)}
            onChange={handleEditFormChange}
          >
            <FormGroup>
              <ControlLabel>カンパニーID</ControlLabel>
              <FormControl readOnly name="id" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>カンパニー名</ControlLabel>
              <FormControl name="name" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>録音フラグ</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                searchable={false}
                placeholder="録音可否を選択してください"
                data={[
                  { label: "録音可", value: 0 },
                  { label: "録音不可", value: 1 },
                ]}
                name="recordFlg"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="remark" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <StyledPrimaryButton onClick={handleCheckAndSubmitEditForm}>
            OK
          </StyledPrimaryButton>
          <StyledCancelButton onClick={onCancelClick}>
            Cancel
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>
      <StyledModal show={showDelete} overflow onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            カンパニー削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List bordered={false}>
            {checkedKeys
              .map(key => {
                return data?.companies
                  .filter(company => company.companyId === key)
                  .map(value => value.name)[0];
              })
              .map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{ paddingLeft: "10px", color: "#d84421" }}
                >
                  {item}
                </List.Item>
              ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: "#d84421",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記のカンパニーを削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          <StyledDeleteButton isChecked onClick={handleSubmitDelete}>
            OK
          </StyledDeleteButton>
          <StyledCancelButton onClick={onCancelClick}>
            Cancel
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>
    </>
  );
};

export default Companies;
