import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import deleteMotion, {
  DeleteMotion,
} from "../../../api/Management/motionsApi/delete";
import { expiredToken } from "../../auth";
import { AppThunk } from "../../../stores";
import { startLoading, loadingFailed, ApiState } from "../../../api/apiService";

const apiInitialState: ApiState<DeleteMotion> = {
  isLoading: false,
  error: null,
  data: null,
};

const deleteMotionApiModule = createSlice({
  name: "deleteMotion",
  initialState: apiInitialState,
  reducers: {
    getApiStart: startLoading,
    getApiSuccess(state, { payload }: PayloadAction<DeleteMotion>) {
      state.isLoading = false;
      state.error = null;
      state.data = payload;
    },
    getApiFailure: loadingFailed,
  },
});

export const {
  getApiStart,
  getApiSuccess,
  getApiFailure,
} = deleteMotionApiModule.actions;

export default deleteMotionApiModule.reducer;

export const delMotion = (
  token: string,
  id: string
): AppThunk => async dispatch => {
  try {
    dispatch(getApiStart());
    const data = await deleteMotion(token, id);
    dispatch(getApiSuccess(data));
  } catch (err) {
    switch (err.resultStatus) {
      case 126:
        dispatch(expiredToken(err.resultStatus));
        break;
      case 115:
        dispatch(getApiFailure(err.resultStatus));
        throw err;
      default:
        console.error(err);
        break;
    }
    dispatch(getApiFailure(err));
  }
};
