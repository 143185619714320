import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    id?: string;
    name?: string;
    pcIds?: string[];
    deusGroupNo?: number;
    liteFlg?: boolean;
    memo?: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    id: "",
    name: "",
    pcIds: [""],
    deusGroupNo: 0,
    liteFlg: false,
    memo: "",
  },
};

const roomEditFormModule = createSlice({
  name: "roomEditForm",
  initialState,
  reducers: {
    setEditFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.id = payload.formValue.id;
      state.formValue.name = payload.formValue.name
        ? payload.formValue.name
        : "";
      state.formValue.pcIds = payload.formValue.pcIds
        ? payload.formValue.pcIds
        : [""];
      state.formValue.deusGroupNo = payload.formValue.deusGroupNo ?? 0;
      state.formValue.liteFlg = payload.formValue.liteFlg ?? false;
      state.formValue.memo = payload.formValue.memo
        ? payload.formValue.memo
        : "";
    },
  },
});

export const { setEditFormValue } = roomEditFormModule.actions;

export default roomEditFormModule.reducer;
