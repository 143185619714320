import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { CssBaseline } from "@material-ui/core";
import Login from "./components/container/templates/Login";
import AuthRoute from "./components/container/templates/AuthRoute";
import MainLayout from "./components/container/templates/Layout";
import Main from "./components/container/templates/Analysis/Main";
import Workers from "./components/container/templates/Analysis/Workers";
import Worker from "./components/container/templates/Analysis/Worker";
import Shop from "./components/container/templates/Analysis/Shop";
import ManagementShop from "./components/container/templates/Management/Shops/Shop";
import Analysis from "./components/container/templates/Analysis/Analysis";
import Top from "./components/container/templates/Top";
import Users from "./components/container/templates/Management/Users";
import Rooms from "./components/container/templates/Management/Rooms";
import Shops from "./components/container/templates/Management/Shops/Shops";
import Media from "./components/container/templates/Management/Media/Media";
import Camera from "./components/container/templates/Management/Monitor/Camera";
import Monitor from "./components/container/templates/Management/Monitor/Monitor";
import AnalysisInfo from "./components/container/templates/Management/AnalysisInfo";
import Profile from "./components/container/templates/Management/Profile/Profile";
import Done from "./components/container/templates/Management/Profile/Done";
import AuthDone from "./components/container/templates/Management/Profile/AuthDone";
import Companies from "./components/container/templates/Management/Admin/Companies";
import { RootState } from "./reducers/rootReducer";
import PaymentView from "./components/container/templates/Management/Payment/PaymentView";
import PaymentItems from "./components/container/templates/Management/Payment/PaymentItems";
import PaymentSettings from "./components/container/templates/Management/Payment/PaymentSettings";
import Robots from "./components/container/templates/Management/Admin/Robots";
import RoomGroups from "./components/container/templates/Management/Admin/RoomGroups";
import Motions from "./components/container/templates/Management/Admin/Motions";
import RobotTypes from "./components/container/templates/Management/Admin/RobotTypes";
import Actions from "./components/container/templates/Management/Admin/Actions";
import Pcs from "./components/container/templates/Management/Admin/Pcs";
import ResetPassword from "./components/container/templates/Management/Profile/ResetPassword";
import SignUp from "./components/container/templates/SignUp";
import ForgotPassword from "./components/container/templates/ForgotPassword";
import Notifications from "./components/container/templates/Management/NotificationButtons";
import NotFound from "./components/container/templates/NotFound";
import ExpiredToken from "./components/container/templates/ExpiredToken";
import ExpiredChangeMail from "./components/container/templates/Expired/ExpiredChangeMail";
import ExpiredChangePassword from "./components/container/templates/Expired/ExpiredChangePassword";

export const RoutePath = {
  login: "/login",
  signup: "/signup/:uuid",
  forgot: "/forgot_password",
  reset: "/reset_password/:uuid",
  expiredMail: "/expired/mail",
  expiredPassword: "/expired/password",
  expired: "/expired",
  top: "/",
  management: {
    users: "/management/users",
    profile: "/management/users/profile",
    confirmMail: "/management/mail/done",
    doneMail: "/management/mail/auth/:uuid",
    rooms: "/management/rooms",
    shops: "/management/shops",
    shop: "/management/shops/:shopId",
    media: "/management/media",
    camera: "/management/camera",
    monitor: "/management/monitor",
    analysisInfo: "/management/analysis_info",
    paymentView: "/management/payment/history",
    paymentItems: "/management/payment/items",
    paymentSettings: "/management/payment/settings",
    notifications: "/management/notifications",
    timeleap: {
      companies: "/management/companies",
      robots: "/management/robots",
      roomGroups: "/management/room_groups",
      motions: "/management/motions",
      robotTypes: "/management/robot_types",
      actions: "/management/actions",
      pcs: "/management/pcs",
    },
  },
  analysis: {
    dashboard: "/analysis/dashboard",
    shop: "/analysis/shop",
    workers: "/analysis/workers",
    worker: "/analysis/workers/:workerId",
    customerService: "/analysis/customer_service",
  },
};

const Routes = () => {
  const { userType, auth } = useSelector((state: RootState) => state.auth);

  if (process.env.REACT_APP_NEW_MANAGEMENT_URL) {
    window.location.replace(process.env.REACT_APP_NEW_MANAGEMENT_URL);
    return null;
  }

  return (
    <>
      <CssBaseline />
      {process.env.REACT_APP_MINIMUM === "on" ? (
        <Router>
          <Switch>
            <Route exact path={RoutePath.login}>
              <Login />
            </Route>
            <Route exact path={RoutePath.signup}>
              <SignUp />
            </Route>
            <Route exact path={RoutePath.forgot}>
              <ForgotPassword />
            </Route>
            <Route exact path={RoutePath.reset}>
              <ResetPassword />
            </Route>
            <Route exact path={RoutePath.expiredMail}>
              <ExpiredChangeMail />
            </Route>
            <Route exact path={RoutePath.expiredPassword}>
              <ExpiredChangePassword />
            </Route>
            <Route exact path={RoutePath.expired}>
              <ExpiredToken />
            </Route>
            <AuthRoute>
              <Switch>
                <Route path={RoutePath.top}>
                  <MainLayout>
                    <Switch>
                      <Route exact path={RoutePath.top}>
                        <Top />
                      </Route>
                      <Route exact path={RoutePath.management.profile}>
                        <Profile />
                      </Route>
                      <Route exact path={RoutePath.management.confirmMail}>
                        <Done />
                      </Route>
                      <Route exact path={RoutePath.management.doneMail}>
                        <AuthDone />
                      </Route>
                      {userType === 0 &&
                        (auth !== null && auth > 2 ? (
                          <>
                            <Route exact path={RoutePath.management.users}>
                              <Users />
                            </Route>
                            <Route exact path={RoutePath.management.shops}>
                              <Shops />
                            </Route>
                          </>
                        ) : (
                          <>
                            <Route exact path={RoutePath.management.users}>
                              <Users />
                            </Route>
                            <Route exact path={RoutePath.management.shops}>
                              <Shops />
                            </Route>
                            <Route exact path={RoutePath.management.shop}>
                              <ManagementShop />
                            </Route>
                            <Route
                              exact
                              path={RoutePath.management.analysisInfo}
                            >
                              <AnalysisInfo />
                            </Route>
                            <Route
                              exact
                              path={RoutePath.management.timeleap.companies}
                            >
                              <Companies />
                            </Route>
                            <Route
                              exact
                              path={RoutePath.management.timeleap.robots}
                            >
                              <Robots />
                            </Route>
                            <Route exact path={RoutePath.management.rooms}>
                              <Rooms />
                            </Route>
                            <Route
                              exact
                              path={RoutePath.management.timeleap.roomGroups}
                            >
                              <RoomGroups />
                            </Route>
                            <Route
                              exact
                              path={RoutePath.management.timeleap.motions}
                            >
                              <Motions />
                            </Route>
                            <Route
                              exact
                              path={RoutePath.management.timeleap.robotTypes}
                            >
                              <RobotTypes />
                            </Route>
                            <Route
                              exact
                              path={RoutePath.management.timeleap.actions}
                            >
                              <Actions />
                            </Route>
                            <Route
                              exact
                              path={RoutePath.management.timeleap.pcs}
                            >
                              <Pcs />
                            </Route>
                            <Route exact path={RoutePath.analysis.dashboard}>
                              <Main />
                            </Route>
                            <Route exact path={RoutePath.analysis.workers}>
                              <Workers />
                            </Route>
                            <Route exact path={RoutePath.analysis.worker}>
                              <Worker />
                            </Route>
                            <Route
                              exact
                              path={RoutePath.analysis.customerService}
                            >
                              <Analysis />
                            </Route>
                            {auth !== null && auth <= 1 && (
                              <>
                                <Route exact path={RoutePath.analysis.shop}>
                                  <Shop />
                                </Route>
                              </>
                            )}
                          </>
                        ))}
                      <Route path="*">
                        <NotFound />
                      </Route>
                    </Switch>
                  </MainLayout>
                </Route>
              </Switch>
            </AuthRoute>
          </Switch>
        </Router>
      ) : (
        <Router>
          <Switch>
            <Route exact path={RoutePath.login}>
              <Login />
            </Route>
            <Route exact path={RoutePath.signup}>
              <SignUp />
            </Route>
            <Route exact path={RoutePath.forgot}>
              <ForgotPassword />
            </Route>
            <Route exact path={RoutePath.reset}>
              <ResetPassword />
            </Route>
            <Route exact path={RoutePath.expiredMail}>
              <ExpiredChangeMail />
            </Route>
            <Route exact path={RoutePath.expiredPassword}>
              <ExpiredChangePassword />
            </Route>
            <Route exact path={RoutePath.expired}>
              <ExpiredToken />
            </Route>

            <AuthRoute>
              <Switch>
                <Route path={RoutePath.top}>
                  <MainLayout>
                    <Switch>
                      <Route exact path={RoutePath.top}>
                        <Top />
                      </Route>
                      <Route exact path={RoutePath.management.profile}>
                        <Profile />
                      </Route>
                      <Route exact path={RoutePath.management.confirmMail}>
                        <Done />
                      </Route>
                      <Route exact path={RoutePath.management.doneMail}>
                        <AuthDone />
                      </Route>
                      {auth !== 2 && (
                        <Route exact path={RoutePath.analysis.customerService}>
                          <Analysis />
                        </Route>
                      )}
                      {userType === 0 && (
                        <>
                          {auth === 2 ? (
                            <>
                              <Route exact path={RoutePath.management.users}>
                                <Users />
                              </Route>
                              <Route exact path={RoutePath.management.shops}>
                                <Shops />
                              </Route>
                            </>
                          ) : (
                            <>
                              <Route exact path={RoutePath.management.users}>
                                <Users />
                              </Route>
                              <Route exact path={RoutePath.management.rooms}>
                                <Rooms />
                              </Route>
                              <Route exact path={RoutePath.management.shops}>
                                <Shops />
                              </Route>
                              <Route exact path={RoutePath.management.shop}>
                                <ManagementShop />
                              </Route>
                              <Route exact path={RoutePath.management.media}>
                                <Media />
                              </Route>
                              <Route exact path={RoutePath.management.camera}>
                                <Camera />
                              </Route>
                              <Route exact path={RoutePath.management.monitor}>
                                <Monitor />
                              </Route>
                              <Route
                                exact
                                path={RoutePath.management.analysisInfo}
                              >
                                <AnalysisInfo />
                              </Route>
                              <Route
                                exact
                                path={RoutePath.management.paymentView}
                              >
                                <PaymentView />
                              </Route>
                              <Route
                                exact
                                path={RoutePath.management.paymentItems}
                              >
                                <PaymentItems />
                              </Route>
                              <Route
                                exact
                                path={RoutePath.management.paymentSettings}
                              >
                                <PaymentSettings />
                              </Route>
                              <Route
                                exact
                                path={RoutePath.management.notifications}
                              >
                                <Notifications />
                              </Route>
                              <Route exact path={RoutePath.analysis.dashboard}>
                                <Main />
                              </Route>
                              <Route exact path={RoutePath.analysis.workers}>
                                <Workers />
                              </Route>
                              <Route exact path={RoutePath.analysis.worker}>
                                <Worker />
                              </Route>
                              <Route exact path={RoutePath.analysis.shop}>
                                <Shop />
                              </Route>

                              <Route
                                exact
                                path={RoutePath.management.timeleap.companies}
                              >
                                <Companies />
                              </Route>
                              <Route
                                exact
                                path={RoutePath.management.timeleap.robots}
                              >
                                <Robots />
                              </Route>
                              <Route
                                exact
                                path={RoutePath.management.timeleap.roomGroups}
                              >
                                <RoomGroups />
                              </Route>
                              <Route
                                exact
                                path={RoutePath.management.timeleap.motions}
                              >
                                <Motions />
                              </Route>
                              <Route
                                exact
                                path={RoutePath.management.timeleap.robotTypes}
                              >
                                <RobotTypes />
                              </Route>
                              <Route
                                exact
                                path={RoutePath.management.timeleap.actions}
                              >
                                <Actions />
                              </Route>
                              <Route
                                exact
                                path={RoutePath.management.timeleap.pcs}
                              >
                                <Pcs />
                              </Route>
                            </>
                          )}
                        </>
                      )}

                      <Route path="*">
                        <NotFound />
                      </Route>
                    </Switch>
                  </MainLayout>
                </Route>
              </Switch>
            </AuthRoute>
          </Switch>
        </Router>
      )}
    </>
  );
};

export default Routes;
