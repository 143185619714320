import { combineReducers } from "@reduxjs/toolkit";
import authModule from "../modules/auth";
import shopModule from "../modules/Analysis/shopModule";
import sidebarModule from "../modules/sidebarModule";
import servedCountApiModule from "../modules/Analysis/servedCountApiModule";
import csCountApiModule from "../modules/Analysis/csCountApiModule";
import datePickModule from "../modules/Analysis/datePickModule";
import graphApiModule from "../modules/Analysis/Graph/graphApiModule";
import pieGraphModule from "../modules/Analysis/pieGraph";
import lineGraphModule from "../modules/Analysis/lineGraph";
import emotionCountApiModule from "../modules/Analysis/emotionCountApiModule";
import workersApiModule from "../modules/Analysis/workersApiModule";
import workerApiModule from "../modules/Analysis/workerApiModule";
import workersModule from "../modules/Analysis/workers";
import workerTotalApiModule from "../modules/Analysis/workerTotalApiModule";
import audioApiModule from "../modules/Analysis/audioApiModule";
import conditionsApiModule from "../modules/Analysis/conditionsApiModule";
import conditionModule from "../modules/Analysis/conditionModule";
import preConditionModule from "../modules/Analysis/preConditionModule";
import detailsApiModule from "../modules/Analysis/detailsApiModule";
import usersApiModule from "../modules/Management/Users/usersApiModule";
import checkModule from "../modules/Management/checkModule";
import userEditFormModule from "../modules/Management/Form/User/userEditFormModule";
import updateUserApiModule from "../modules/Management/Users/updateUserApiModule";
import userAddFormModule from "../modules/Management/Form/User/userAddFormModule";
import roomGroupsApiModule from "../modules/Management/RoomGroups/roomGroupsApiModule";
import addRoomGroupApiModule from "../modules/Management/RoomGroups/addRoomGroupApiModule";
import updateRoomGroupApiModule from "../modules/Management/RoomGroups/updateRoomGroupApiModule";
import deleteRoomGroupApiModule from "../modules/Management/RoomGroups/deleteRoomGroupApiModule";
import roomGroupAddFormModule from "../modules/Management/Form/RoomGroup/roomGroupAddFormModule";
import roomGroupEditFormModule from "../modules/Management/Form/RoomGroup/roomGroupEditFormModule";
import deleteUserApiModule from "../modules/Management/Users/deleteUserApiModule";
import deleteUsersApiModule from "../modules/Management/Users/deleteUsersApiModule";
import roomsApiModule from "../modules/Management/Rooms/roomsApiModule";
import roomAddFormModule from "../modules/Management/Form/Room/roomAddFormModule";
import addRoomApiModule from "../modules/Management/Rooms/addRoomApiModule";
import roomEditFormModule from "../modules/Management/Form/Room/roomEditFormModule";
import updateRoomApiModule from "../modules/Management/Rooms/updateRoomApiModule";
import pcApiModule from "../modules/Management/Pcs/pcApiModule";
import getPcApiModule from "../modules/Management/Pcs/getPcApiModule";
import shopApiModule from "../modules/Management/Pcs/shopApiModule";
import shopEditFormModule from "../modules/Management/Form/shopEditFormModule";
import updatePcActionApiModule from "../modules/Management/Pcs/updateActionApiModule";
import updateRemarkApiModule from "../modules/Management/Pcs/updateRemarkApiModule";
import imageApiModule from "../modules/Management/Media/imageApiModule";
import updateImageApiModule from "../modules/Management/Media/updateImageApiModule";
import deleteImageApiModule from "../modules/Management/Media/deleteImageApiModule";
import deleteImagesApiModule from "../modules/Management/Media/deleteImagesApiModule";
import addImageApiModule from "../modules/Management/Media/addImageApiModule";
import imageAddFormModule from "../modules/Management/Form/imageAddFormModule";
import videoApiModule from "../modules/Management/Media/videoApiModule";
import updateVideoApiModule from "../modules/Management/Media/updateVideoApiModule";
import deleteVideoApiModule from "../modules/Management/Media/deleteVideoApiModule";
import deleteVideosApiModule from "../modules/Management/Media/deleteVideosApiModule";
import videoAddFormModule from "../modules/Management/Form/videoAddFormModule";
import addVideoApiModule from "../modules/Management/Media/addVideoApiModule";
import speechApiModule from "../modules/Management/Media/speechApiModule";
import speechAddFormModule from "../modules/Management/Form/speechAddFormModule";
import addSpeechApiModule from "../modules/Management/Media/addSpeechApiModule";
import updateSpeechApiModule from "../modules/Management/Media/updateSpeechApiModule";
import deleteSpeechApiModule from "../modules/Management/Media/deleteSpeechApiModule";
import deleteSpeechesApiModule from "../modules/Management/Media/deleteSpeechesApiModule";
import speechMicModule from "../modules/Management/Media/speechMicModule";
import memosModule from "../modules/Management/Pcs/memosModule";
import preMemosModule from "../modules/Management/Pcs/preMemosModule";
import memoAddFormModule from "../modules/Management/Form/memoAddFormModule";
import deleteMemosApiModule from "../modules/Management/Pcs/deleteMemosApiModule";
import addMemoApiModule from "../modules/Management/Pcs/addMemoApiModule";
import cameraApiModule from "../modules/Management/Monitor/cameraApiModule";
import monitorApiModule from "../modules/Management/Monitor/monitorApiModule";
import infoButtonApiModule from "../modules/Management/InfoButtons/infoButtonApiModule";
import infoButtonAddFormModule from "../modules/Management/Form/InfoButton/infoButtonAddFormModule";
import addInfoButtonTypeApiModule from "../modules/Management/InfoButtons/addInfoButtonTypeApiModule";
import getInfoButtonTypeApiModule from "../modules/Management/InfoButtons/getInfoButtonTypeApiModule";
import addInfoButtonApiModule from "../modules/Management/InfoButtons/addInfoButtonApiModule";
import infoButtonEditFormModule from "../modules/Management/Form/InfoButton/infoButtonEditFormModule";
import deleteInfoButtonApiModule from "../modules/Management/InfoButtons/deleteInfoButtonApiModule";
import updateInfoButtonApiModule from "../modules/Management/InfoButtons/updateInfoButtonApiModule";
import updatePcApiModule from "../modules/Management/Pcs/updatePcApiModule";
import deleteCameraApiModule from "../modules/Management/Monitor/deleteCameraApiModule";
import deleteCamerasApiModule from "../modules/Management/Monitor/deleteCamerasApiModule";
import deleteMonitorApiModule from "../modules/Management/Monitor/deleteMonitorApiModule";
import deleteMonitorsApiModule from "../modules/Management/Monitor/deleteMonitorsApiModule";
import fileInfoModule from "../modules/Management/Media/fileInfoModule";
import confirmMailApiModule from "../modules/Management/Profile/confirmMailApiModule";
import profileFormModule from "../modules/Management/Form/Profile/profileFormModule";
import confirmAuthApiModule from "../modules/Management/Profile/confirmAuthApiModule";
import getProfileApiModule from "../modules/Management/Profile/getProfileApiModule";
import uploadImageApiModule from "../modules/Management/Profile/uploadImageApiModule";
import companiesApiModule from "../modules/Management/Companies/companiesApiModule";
import companyAddFormModule from "../modules/Management/Form/Company/companyAddFormModule";
import addCompanyApiModule from "../modules/Management/Companies/addCompanyApiModule";
import companyEditFormModule from "../modules/Management/Form/Company/companyEditFormModule";
import updateCompanyApiModule from "../modules/Management/Companies/updateCompanyApiModule";
import deleteCompanyApiModule from "../modules/Management/Companies/deleteCompanyApiModule";
import graphAddFormModule from "../modules/Analysis/Form/graphAddFormModule";
import addGraphApiModule from "../modules/Analysis/Graph/addGraphApiModule";
import graphEditFormModule from "../modules/Analysis/Form/graphEditFormModule";
import deleteGraphApiModule from "../modules/Analysis/Graph/deleteGraphApiModule";
import updateGraphApiModule from "../modules/Analysis/Graph/updateGraphApiModule";
import paymentsApiModule from "../modules/Management/Payment/paymentsApiModule";
import deletePaymentApiModule from "../modules/Management/Payment/deletePaymentApiModule";
import paymentItemsApiModule from "../modules/Management/PaymentItems/paymentItemsApiModule";
import addPaymentItemApiModule from "../modules/Management/PaymentItems/addPaymentItemApiModule";
import updatePaymentItemApiModule from "../modules/Management/PaymentItems/updatePaymentItemApiModule";
import deletePaymentItemApiModule from "../modules/Management/PaymentItems/deletePaymentItemApiModule";
import paymentItemAddFormModule from "../modules/Management/Form/Payment/paymentItemAddFormModule";
import paymentItemEditFormModule from "../modules/Management/Form/Payment/paymentItemEditFormModule";
import paymentSettingsApiModule from "../modules/Management/PaymentSettings/paymentSettingsApiModule";
import paymentSettingsAddFormModule from "../modules/Management/Form/Payment/paymentSettingsAddFormModule";
import paymentSettingsEditFormModule from "../modules/Management/Form/Payment/paymentSettingsEditFormModule";
import addPaymentSettingApiModule from "../modules/Management/PaymentSettings/addPaymentSettingApiModule";
import updatePaymentSettingApiModule from "../modules/Management/PaymentSettings/updatePaymentSettingApiModule";
import deletePaymentSettingApiModule from "../modules/Management/PaymentSettings/deletePaymentSettingApiModule";
import robotsApiModule from "../modules/Management/Robots/robotsApiModule";
import addRobotApiModule from "../modules/Management/Robots/addRobotApiModule";
import updateRobotApiModule from "../modules/Management/Robots/updateRobotApiModule";
import deleteRobotApiModule from "../modules/Management/Robots/deleteRobotApiModule";
import robotAddFormModule from "../modules/Management/Form/Robot/robotAddFormModule";
import robotEditFormModule from "../modules/Management/Form/Robot/robotEditFormModule";
import motionsApiModule from "../modules/Management/Motions/motionsApiModule";
import addMotionApiModule from "../modules/Management/Motions/addMotionApiModule";
import updateMotionApiModule from "../modules/Management/Motions/updateMotionApiModule";
import deleteMotionApiModule from "../modules/Management/Motions/deleteMotionApiModule";
import motionAddFormModule from "../modules/Management/Form/Motion/motionAddFormModule";
import motionEditFormModule from "../modules/Management/Form/Motion/motionEditFormModule";
import robotTypesApiModule from "../modules/Management/RobotTypes/robotTypesApiModule";
import addRobotTypeApiModule from "../modules/Management/RobotTypes/addRobotTypeApiModule";
import updateRobotTypeApiModule from "../modules/Management/RobotTypes/updateRobotTypeApiModule";
import deleteRobotTypeApiModule from "../modules/Management/RobotTypes/deleteRobotTypeApiModule";
import robotTypeAddFormModule from "../modules/Management/Form/RobotType/robotTypeAddFormModule";
import robotTypeEditFormModule from "../modules/Management/Form/RobotType/robotTypeEditFormModule";
import actionsApiModule from "../modules/Management/Actions/actionsApiModule";
import getActionApiModule from "../modules/Management/Actions/getActionApiModule";
import addActionApiModule from "../modules/Management/Actions/addActionApiModule";
import updateActionApiModule from "../modules/Management/Actions/updateActionApiModule";
import deleteActionApiModule from "../modules/Management/Actions/deleteActionApiModule";
import actionAddFormModule from "../modules/Management/Form/Action/actionAddFormModule";
import actionEditFormModule from "../modules/Management/Form/Action/actionEditFormModule";
import pcAddFormModule from "../modules/Management/Form/Pc/pcAddFormModule";
import pcEditFormModule from "../modules/Management/Form/Pc/pcEditFormModule";
import addPcApiModule from "../modules/Management/Pcs/addPcApiModule";
import updatePcsApiModule from "../modules/Management/Pcs/updatePcsApiModule";
import deletePcApiModule from "../modules/Management/Pcs/deletePcApiModule";
import resetPasswordFormModule from "../modules/Management/Form/Profile/resetPasswordFormModule";
import confirmPasswordApiModule from "../modules/Management/Users/confirmPasswordApiModule";
import changePasswordApiModule from "../modules/Management/Users/changePasswordApiModule";
import resetPasswordMailApiModule from "../modules/Management/Profile/resetPasswordMailApiModule";
import checkSignUpStatusApiModule from "../modules/SignUp/checkSignUpApiModule";
import signUpFormModule from "../modules/SignUp/signUpFormModule";
import signUpApiModule from "../modules/SignUp/signUpApiModule";
import forgotPasswordFormModule from "../modules/forgotPasswordFormModule";
import contactFormModule from "../modules/contactFormModule";
import postInquiryApiModule from "../modules/Management/Inquiries/InquiriesApiModule";
import checkConditionModule from "../modules/Analysis/checkConditionModule";
import preCheckConditionModule from "../modules/Analysis/preCheckConditionModule";
import notificationButtonApiModule from "../modules/Management/NotificationButtons/notificationButtonApiModule";
import updateNotificationButtonApiModule from "../modules/Management/NotificationButtons/updateNotificationButtonApiModule";
import addNotificationButtonApiModule from "../modules/Management/NotificationButtons/addNotificationButtonApiModule";
import deleteNotificationButtonApiModule from "../modules/Management/NotificationButtons/deleteNotificationButtonApiModule";
import notificationButtonAddFormModule from "../modules/Management/Form/NotificationButton/notificationButtonAddFormModule";
import notificationButtonEditFormModule from "../modules/Management/Form/NotificationButton/notificationButtonEditFormModule";

const rootReducer = combineReducers({
  sidebar: sidebarModule,
  servedCountApi: servedCountApiModule,
  csCountApi: csCountApiModule,
  datePick: datePickModule,
  auth: authModule,
  shop: shopModule,
  graphApi: graphApiModule,
  pieGraph: pieGraphModule,
  emotionCountApi: emotionCountApiModule,
  lineGraph: lineGraphModule,
  workersApi: workersApiModule,
  workers: workersModule,
  workerApi: workerApiModule,
  workerTotalApi: workerTotalApiModule,
  audioApi: audioApiModule,
  conditionsApi: conditionsApiModule,
  condition: conditionModule,
  preCondition: preConditionModule,
  detailsApi: detailsApiModule,
  users: usersApiModule,
  checkCells: checkModule,
  userEditForm: userEditFormModule,
  updateUser: updateUserApiModule,
  userAddForm: userAddFormModule,
  roomGroups: roomGroupsApiModule,
  addRoomGroup: addRoomGroupApiModule,
  updateRoomGroup: updateRoomGroupApiModule,
  deleteRoomGroup: deleteRoomGroupApiModule,
  roomGroupAddForm: roomGroupAddFormModule,
  roomGroupEditForm: roomGroupEditFormModule,
  deleteUser: deleteUserApiModule,
  deleteUsers: deleteUsersApiModule,
  rooms: roomsApiModule,
  roomAddFormn: roomAddFormModule,
  addRoom: addRoomApiModule,
  roomEditForm: roomEditFormModule,
  updateRoom: updateRoomApiModule,
  pcs: pcApiModule,
  pc: getPcApiModule,
  shopApi: shopApiModule,
  shopEditForm: shopEditFormModule,
  updatePc: updatePcApiModule,
  updatePcAction: updatePcActionApiModule,
  updateRemark: updateRemarkApiModule,
  images: imageApiModule,
  updateImage: updateImageApiModule,
  deleteImage: deleteImageApiModule,
  deleteImages: deleteImagesApiModule,
  addImage: addImageApiModule,
  imageAddForm: imageAddFormModule,
  videos: videoApiModule,
  updateVideo: updateVideoApiModule,
  deleteVideo: deleteVideoApiModule,
  deleteVideos: deleteVideosApiModule,
  videoAddForm: videoAddFormModule,
  addVideo: addVideoApiModule,
  speech: speechApiModule,
  speechAddForm: speechAddFormModule,
  addSpeech: addSpeechApiModule,
  updateSpeech: updateSpeechApiModule,
  deleteSpeech: deleteSpeechApiModule,
  deleteSpeeches: deleteSpeechesApiModule,
  memos: memosModule,
  preMemos: preMemosModule,
  memoAddForm: memoAddFormModule,
  deleteMemos: deleteMemosApiModule,
  addMemo: addMemoApiModule,
  camera: cameraApiModule,
  monitor: monitorApiModule,
  infoButton: infoButtonApiModule,
  infoButtonAddForm: infoButtonAddFormModule,
  addInfoButtonType: addInfoButtonTypeApiModule,
  getInfoButtonTypes: getInfoButtonTypeApiModule,
  addInfoButton: addInfoButtonApiModule,
  infoButtonEditForm: infoButtonEditFormModule,
  deleteInfoButton: deleteInfoButtonApiModule,
  updateInfoButton: updateInfoButtonApiModule,
  deleteCamera: deleteCameraApiModule,
  deleteCameras: deleteCamerasApiModule,
  deleteMonitor: deleteMonitorApiModule,
  deleteMonitors: deleteMonitorsApiModule,
  fileInfo: fileInfoModule,
  confirmMail: confirmMailApiModule,
  profileForm: profileFormModule,
  confirmAuth: confirmAuthApiModule,
  getProfile: getProfileApiModule,
  uploadProfileImage: uploadImageApiModule,
  companies: companiesApiModule,
  companyAddForm: companyAddFormModule,
  addCompany: addCompanyApiModule,
  companyEditForm: companyEditFormModule,
  updateCompany: updateCompanyApiModule,
  deleteCompany: deleteCompanyApiModule,
  graphAddForm: graphAddFormModule,
  addGraphApi: addGraphApiModule,
  graphEditForm: graphEditFormModule,
  deleteGraphApi: deleteGraphApiModule,
  updateGraphApi: updateGraphApiModule,
  payments: paymentsApiModule,
  deletePayment: deletePaymentApiModule,
  paymentItems: paymentItemsApiModule,
  addPaymentItem: addPaymentItemApiModule,
  updatePaymentItem: updatePaymentItemApiModule,
  deletePaymentItem: deletePaymentItemApiModule,
  paymentItemAddForm: paymentItemAddFormModule,
  paymentItemEditForm: paymentItemEditFormModule,
  paymentSettings: paymentSettingsApiModule,
  paymentSettingsAddForm: paymentSettingsAddFormModule,
  paymentSettingsEditForm: paymentSettingsEditFormModule,
  addPaymentSetting: addPaymentSettingApiModule,
  updatePaymentSetting: updatePaymentSettingApiModule,
  deletePaymentSetting: deletePaymentSettingApiModule,
  robots: robotsApiModule,
  addRobot: addRobotApiModule,
  updateRobot: updateRobotApiModule,
  deleteRobot: deleteRobotApiModule,
  robotAddForm: robotAddFormModule,
  robotEditForm: robotEditFormModule,
  motions: motionsApiModule,
  addMotion: addMotionApiModule,
  updateMotion: updateMotionApiModule,
  deleteMotion: deleteMotionApiModule,
  motionAddForm: motionAddFormModule,
  motionEditForm: motionEditFormModule,
  robotTypes: robotTypesApiModule,
  addRobotType: addRobotTypeApiModule,
  updateRobotType: updateRobotTypeApiModule,
  deleteRobotType: deleteRobotTypeApiModule,
  robotTypeAddForm: robotTypeAddFormModule,
  robotTypeEditForm: robotTypeEditFormModule,
  actions: actionsApiModule,
  action: getActionApiModule,
  addAction: addActionApiModule,
  updateAction: updateActionApiModule,
  deleteAction: deleteActionApiModule,
  actionAddForm: actionAddFormModule,
  actionEditForm: actionEditFormModule,
  pcAddForm: pcAddFormModule,
  pcEditForm: pcEditFormModule,
  addPc: addPcApiModule,
  updatePcs: updatePcsApiModule,
  deletePc: deletePcApiModule,
  resetPasswordForm: resetPasswordFormModule,
  confirmPassword: confirmPasswordApiModule,
  changePassword: changePasswordApiModule,
  resetPasswordMail: resetPasswordMailApiModule,
  checkSignUpStatus: checkSignUpStatusApiModule,
  signUpForm: signUpFormModule,
  signUp: signUpApiModule,
  forgotPasswordForm: forgotPasswordFormModule,
  contactForm: contactFormModule,
  postInquiry: postInquiryApiModule,
  checkCondition: checkConditionModule,
  preCheckCondition: preCheckConditionModule,
  notificationButtons: notificationButtonApiModule,
  addNotificationButton: addNotificationButtonApiModule,
  updateNotificationButton: updateNotificationButtonApiModule,
  deleteNotificationButton: deleteNotificationButtonApiModule,
  notificationButtonAddForm: notificationButtonAddFormModule,
  notificationButtonEditForm: notificationButtonEditFormModule,
  speechMic: speechMicModule,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
