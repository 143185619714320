/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-wrap-multilines */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import { RootState } from "../../../../reducers/rootReducer";
import {
  setDate,
  toUtcIso8601str,
} from "../../../../modules/Analysis/datePickModule";

const parseAsMoment = (dateTimeStr: string) => {
  return moment(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja");
};

const Ranges: any = [
  {
    label: "今日",
    value: [
      parseAsMoment(toUtcIso8601str(moment().startOf("day"))).toDate(),
      parseAsMoment(toUtcIso8601str(moment().endOf("day"))).toDate(),
    ],
  },
  {
    label: "昨日",
    value: [
      parseAsMoment(
        toUtcIso8601str(
          moment()
            .startOf("day")
            .add(-1, "day")
        )
      ).toDate(),
      parseAsMoment(
        toUtcIso8601str(
          moment()
            .endOf("day")
            .add(-1, "day")
        )
      ).toDate(),
    ],
  },
  {
    label: "過去7日間",
    value: [
      parseAsMoment(
        toUtcIso8601str(
          moment()
            .startOf("day")
            .add(-6, "day")
        )
      ).toDate(),
      parseAsMoment(toUtcIso8601str(moment().endOf("day"))).toDate(),
    ],
  },
  {
    label: "過去30日間",
    value: [
      parseAsMoment(
        toUtcIso8601str(
          moment()
            .startOf("day")
            .add(-29, "day")
        )
      ).toDate(),
      parseAsMoment(toUtcIso8601str(moment().endOf("day"))).toDate(),
    ],
  },
  {
    label: "今月",
    value: [
      parseAsMoment(toUtcIso8601str(moment().startOf("month"))).toDate(),
      parseAsMoment(toUtcIso8601str(moment().endOf("day"))).toDate(),
    ],
  },
  {
    label: "先月",
    value: [
      parseAsMoment(
        toUtcIso8601str(
          moment()
            .add(-1, "month")
            .startOf("month")
        )
      ).toDate(),
      parseAsMoment(
        toUtcIso8601str(
          moment()
            .add(-1, "month")
            .endOf("month")
        )
      ).toDate(),
    ],
  },
];

const MultipleDatePicker = ({
  id,
  placement,
}: {
  id: "workers" | "csCount" | "shop" | "audio";
  placement?:
    | "top"
    | "bottom"
    | "right"
    | "left"
    | "bottomStart"
    | "bottomEnd"
    | "topStart"
    | "topEnd"
    | "leftStart"
    | "rightStart"
    | "leftEnd"
    | "rightEnd"
    | "auto"
    | "autoVerticalStart"
    | "autoVerticalEnd"
    | "autoHorizontalStart"
    | "autoHorizontalEnd"
    | undefined;
}) => {
  const { picks } = useSelector((state: RootState) => state.datePick);
  const { startDate, endDate } = picks.filter(value => {
    return value.id === id;
  })[0];
  const dispatch = useDispatch();

  const handleChange = (dates: object) => {
    const pick = {
      id,
      startDate: toUtcIso8601str(
        moment(Object.values(dates)[0]).startOf("day")
      ),
      endDate: toUtcIso8601str(moment(Object.values(dates)[1]).endOf("day")),
    };
    dispatch(setDate(pick));
  };

  return (
    <>
      <DateRangePicker
        placement={placement}
        onChange={value => handleChange(value)}
        cleanable={false}
        value={[
          parseAsMoment(startDate).toDate(),
          parseAsMoment(endDate).toDate(),
        ]}
        ranges={Ranges}
      />
    </>
  );
};

export default MultipleDatePicker;
