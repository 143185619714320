/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormInstance } from "rsuite/lib/Form";
import {
  Form,
  Whisper,
  Popover,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Alert,
  Schema,
  List,
  SelectPicker,
} from "rsuite";
import { Typography } from "@material-ui/core";
import { RootState } from "../../../../../reducers/rootReducer";
import { fetchMotions } from "../../../../../modules/Management/Motions/motionsApiModule";
import { Margin } from "../../../../../utils/styled";
import MotionsTable from "../../../../presentational/molecules/Table/MotionsTable";
import {
  StyledPrimaryButton,
  StyledEditButton,
  StyledDeleteButton,
  StyledCancelButton,
  StyledModal,
} from "../Users";
import Loading from "../../../../presentational/molecules/Loading";
import { setAddFormValue } from "../../../../../modules/Management/Form/Motion/motionAddFormModule";
import { postMotion } from "../../../../../modules/Management/Motions/addMotionApiModule";
import {
  setEditFormValue,
  setEditFormInitialValue,
} from "../../../../../modules/Management/Form/Motion/motionEditFormModule";
import { putMotion } from "../../../../../modules/Management/Motions/updateMotionApiModule";
import { setCheckedKeys } from "../../../../../modules/Management/checkModule";
import { delMotion } from "../../../../../modules/Management/Motions/deleteMotionApiModule";

const addModel = Schema.Model({
  motionId: Schema.Types.StringType().isRequired("必須項目です"),
  text: Schema.Types.StringType().isRequired("必須項目です"),
  val: Schema.Types.StringType().isRequired("必須項目です"),
});

export const motionStatusList = [
  {
    label: "keep: 連続して、同じモーションを行う",
    value: "keep",
  },
  {
    label: "one: 1度だけモーションを実行して、その後Waitモーションを実行する",
    value: "one",
  },
  {
    label: "turn: 指定したモーションの順番でループして実行する",
    value: "turn",
  },
  {
    label: "random: 指定したモーションの中でランダムに実行し続ける",
    value: "random",
  },
];

const Motions = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [addForm, setAddForm] = useState<FormInstance>();

  const [showEdit, setShowEdit] = useState(false);
  const [editForm, setEditForm] = useState<FormInstance>();

  const [showDelete, setShowDelete] = useState(false);

  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.auth);

  const { data, isLoading } = useSelector((state: RootState) => state.motions);

  const { checks } = useSelector((state: RootState) => state.checkCells);
  const checkedKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "motions")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );

  useEffect(() => {
    if (token) {
      dispatch(fetchMotions(token));
    }
  }, [dispatch, token]);

  const { formValue: addFormValue } = useSelector(
    (state: RootState) => state.motionAddForm
  );
  const { formValue: editFormValue } = useSelector(
    (state: RootState) => state.motionEditForm
  );

  const handleAddClick = () => {
    setShowAdd(true);
  };

  const handleAddFormChange = (value: any) => {
    dispatch(setAddFormValue({ formValue: value }));
  };

  const handleCheckAndSubmitAddForm = async () => {
    const checkResult = addForm!.check();
    if (checkResult && token) {
      try {
        setShowAdd(false);
        Alert.info("モーション追加中...", 100000000);
        await dispatch(
          postMotion(
            token,
            addFormValue.motionId,
            addFormValue.text,
            addFormValue.val,
            addFormValue.status,
            addFormValue.position,
            addFormValue.playTime,
            addFormValue.remark
          )
        );
        Alert.close();
        Alert.success("モーションを追加しました", 5000);
        await dispatch(fetchMotions(token));
      } catch (err) {
        Alert.closeAll();
        Alert.error("入力されたIDはすでに存在しています", 2000);
      }
    }
  };

  const handleEditClick = () => {
    if (data) {
      const filteredMotion = data.motions.filter(
        motion => motion.motionId === checkedKeys[0]
      )[0];
      dispatch(
        setEditFormValue({
          formValue: {
            motionId: filteredMotion.motionId,
            text: filteredMotion.text,
            val: filteredMotion.val,
            status: filteredMotion.status,
            position: filteredMotion.position,
            playTime: filteredMotion.playTime,
            remark: filteredMotion.remark,
          },
        })
      );
      setShowEdit(true);
    }
  };

  const handleEditFormChange = (value: any) => {
    dispatch(setEditFormValue({ formValue: value }));
  };

  const handleCheckAndSubmitEditForm = async () => {
    const checkResult = editForm!.check();
    if (checkResult && token) {
      try {
        setShowEdit(false);
        Alert.info("モーション更新中...", 100000000);
        await dispatch(
          putMotion(
            token,
            editFormValue.motionId,
            editFormValue.text,
            editFormValue.val,
            editFormValue.status,
            editFormValue.position,
            editFormValue.playTime,
            editFormValue.remark
          )
        );
        dispatch(
          setCheckedKeys({
            id: "motions",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        dispatch(setEditFormInitialValue());
        Alert.close();
        Alert.success("モーションを更新しました", 5000);
        await dispatch(fetchMotions(token));
      } catch (err) {
        Alert.closeAll();
        Alert.error("不明なエラーが発生しました", 2000);
      }
    }
  };

  const handleDeleteClick = () => {
    setShowDelete(true);
  };

  const handleSubmitDelete = async () => {
    if (token) {
      try {
        setShowDelete(false);
        Alert.info("モーション削除中...", 100000000);
        await dispatch(delMotion(token, checkedKeys[0]));
        dispatch(
          setCheckedKeys({
            id: "motions",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        Alert.close();
        Alert.success("モーションを削除しました", 5000);
        await dispatch(fetchMotions(token));
      } catch (err) {
        Alert.closeAll();
        Alert.error("不明なエラーが発生しました", 2000);
      }
    }
  };

  const onCancelClick = () => {
    setShowAdd(false);
    setShowEdit(false);
    setShowDelete(false);
  };

  const editSpeaker = () => {
    if (checkedKeys.length > 1) {
      return (
        <Popover title="編集について">
          <p>一度に編集できるモーションは1つです</p>
          <p>チェックを外してください</p>
        </Popover>
      );
    }

    return (
      <Popover title="編集について">
        <p>編集したいモーションにチェックをつけてください</p>
      </Popover>
    );
  };

  const deleteSpeaker = () => {
    return (
      <Popover title="削除について">
        <p>削除したいモーションにチェックをつけてください</p>
      </Popover>
    );
  };

  return (
    <>
      <Typography variant="h6">モーション管理</Typography>
      <Margin top={20}>
        {!isLoading ? (
          data && data !== null && data.motions && data.motions.length !== 0 ? (
            <>
              <MotionsTable motions={data.motions} />
              <Margin top={5} />
              <StyledPrimaryButton onClick={handleAddClick}>
                追加
              </StyledPrimaryButton>
              {checkedKeys.length === 1 ? (
                <>
                  <StyledEditButton isChecked onClick={handleEditClick}>
                    編集
                  </StyledEditButton>
                  <StyledDeleteButton isChecked onClick={handleDeleteClick}>
                    削除
                  </StyledDeleteButton>
                </>
              ) : (
                <>
                  <Whisper
                    placement="autoVerticalStart"
                    trigger="hover"
                    speaker={editSpeaker()}
                  >
                    <StyledEditButton isChecked={false}>編集</StyledEditButton>
                  </Whisper>
                  <Whisper
                    placement="autoVerticalStart"
                    trigger="hover"
                    speaker={deleteSpeaker()}
                  >
                    <StyledDeleteButton isChecked={false}>
                      削除
                    </StyledDeleteButton>
                  </Whisper>
                </>
              )}
            </>
          ) : (
            <>
              <div>モーションデータはありません</div>
              <StyledPrimaryButton onClick={handleAddClick}>
                追加
              </StyledPrimaryButton>
            </>
          )
        ) : (
          <Loading />
        )}
      </Margin>

      <StyledModal show={showAdd} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            モーション追加
          </Modal.Title>
          <div>
            <p>
              ※モーションの値は、ステータスがturnやrandomの場合は次のように設定してください
              <br />
              Talk,Wait,UpHand
            </p>
            <p>
              モーションを動作させる場所は次のように設定してください。
              <br />
              0,0,0: 0,0,0: x,y,z座標で指定する
              <br />
              モーションを実行させながら移動したいときには、複数設定することもできます。
              <br />
              0,0,0/10,10,10/20,20,20:
              0.5秒の間隔で移動していきます。アニメーションの長さのぶんだけ設定してください。
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={addModel}
            formValue={addFormValue}
            ref={(ref: FormInstance) => setAddForm(ref)}
            onChange={handleAddFormChange}
          >
            <FormGroup>
              <ControlLabel>モーションID</ControlLabel>
              <FormControl name="motionId" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>モーション名</ControlLabel>
              <FormControl name="text" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>モーション設定値</ControlLabel>
              <FormControl name="val" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>モーションの状態</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={motionStatusList}
                name="status"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>モーション位置</ControlLabel>
              <FormControl name="position" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>モーション時間</ControlLabel>
              <FormControl name="playTime" />
            </FormGroup>
            <FormGroup>
              <p>
                3Dロボットのモーションで、連携するモーションを設定する方法
                <br />
                備考に次のものを記載することで、連動する。
                <br />
                TALK:
                websocket経由での接客中に一定音声以上でモーションが動作する
                <br />
                SERVING:
                websocket経由での接客中に一定音声以下でモーションが動作する
                <br />
                WAIT: WAIT.png画像が表示された際(待機状態)
                <br />
                REST: REST.png画像が表示された際(休憩中状態)
                <br />
                IMAGE: 画像/動画切り替えを行った際
              </p>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="remark" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledPrimaryButton onClick={handleCheckAndSubmitAddForm}>
              OK
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelClick}>
              Cancel
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showEdit} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            モーション編集
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={addModel}
            formValue={editFormValue}
            ref={(ref: FormInstance) => setEditForm(ref)}
            onChange={handleEditFormChange}
          >
            <FormGroup>
              <ControlLabel>モーションID</ControlLabel>
              <FormControl readOnly name="motionId" plaintext />
            </FormGroup>
            <FormGroup>
              <ControlLabel>モーション名</ControlLabel>
              <FormControl name="text" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>モーション設定値</ControlLabel>
              <FormControl name="val" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>モーションの状態</ControlLabel>
              <FormControl name="status" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>モーション位置</ControlLabel>
              <FormControl name="position" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>モーション時間</ControlLabel>
              <FormControl name="playTime" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="remark" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledPrimaryButton onClick={handleCheckAndSubmitEditForm}>
              OK
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelClick}>
              Cancel
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showDelete} overflow onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            モーション削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List bordered={false}>
            {checkedKeys
              .map(key => {
                return data?.motions
                  .filter(motion => motion.motionId === key)
                  .map(value => value.motionId)[0];
              })
              .map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{
                    paddingLeft: "10px",
                    color: "#d84421",
                  }}
                >
                  {item}
                </List.Item>
              ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: "#d84421",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記のモーションを削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          <StyledDeleteButton isChecked onClick={handleSubmitDelete}>
            OK
          </StyledDeleteButton>
          <StyledCancelButton onClick={onCancelClick}>
            Cancel
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>
    </>
  );
};

export default Motions;
