import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    id: string;
    roundingType: string;
    paymentFlg: string;
    receiptType: string;
    receiptType2: string;
    paymentItemId: string[];
    receiptLogoImage: string;
    remark: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    id: "",
    roundingType: "0",
    paymentFlg: "false",
    receiptType: "1",
    receiptType2: "1",
    paymentItemId: [],
    receiptLogoImage: "",
    remark: "",
  },
};

const paymentSettingsAddFormModule = createSlice({
  name: "paymentSettingsForm",
  initialState,
  reducers: {
    setAddFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.id = payload.formValue.id;
      state.formValue.roundingType = payload.formValue.roundingType;
      state.formValue.paymentFlg = payload.formValue.paymentFlg;
      state.formValue.receiptType = payload.formValue.receiptType;
      state.formValue.receiptType2 = payload.formValue.receiptType2;
      state.formValue.paymentItemId = payload.formValue.paymentItemId;
      state.formValue.receiptLogoImage = payload.formValue.receiptLogoImage;
      state.formValue.remark = payload.formValue.remark;
    },
    setAddFormInitialValue(state: FormValueState) {
      state.formValue = initialState.formValue;
    },
  },
});

export const {
  setAddFormValue,
  setAddFormInitialValue,
} = paymentSettingsAddFormModule.actions;

export default paymentSettingsAddFormModule.reducer;
