/* eslint-disable no-nested-ternary */
import React, { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormInstance } from "rsuite/lib/Form";
import {
  Form,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Schema,
  SelectPicker,
  TagPicker,
  Uploader,
  Icon,
  Alert,
  Whisper,
  Popover,
  List,
} from "rsuite";
import { Typography } from "@material-ui/core";
import { RootState } from "../../../../../reducers/rootReducer";
import { fetchPaymentSettings } from "../../../../../modules/Management/PaymentSettings/paymentSettingsApiModule";
import { Margin } from "../../../../../utils/styled";
import PaymentSettingsTable from "../../../../presentational/molecules/Table/PaymentSettingsTable";
import Loading from "../../../../presentational/molecules/Loading";
import {
  StyledPrimaryButton,
  StyledCancelButton,
  StyledEditButton,
  StyledDeleteButton,
  StyledModal,
} from "../Users";
import {
  setAddFormValue,
  setAddFormInitialValue,
} from "../../../../../modules/Management/Form/Payment/paymentSettingsAddFormModule";
import { fetchPaymentItems } from "../../../../../modules/Management/PaymentItems/paymentItemsApiModule";
import { postPaymentSetting } from "../../../../../modules/Management/PaymentSettings/addPaymentSettingApiModule";
import {
  setEditFormValue,
  setEditFormInitialValue,
} from "../../../../../modules/Management/Form/Payment/paymentSettingsEditFormModule";
import { putPaymentSetting } from "../../../../../modules/Management/PaymentSettings/updatePaymentSettingApiModule";
import { delPaymentSetting } from "../../../../../modules/Management/PaymentSettings/deletePaymentSettingApiModule";
import { setCheckedKeys } from "../../../../../modules/Management/checkModule";

export const roundingTypeList = [
  {
    label: "切捨て",
    value: "0",
  },
  {
    label: "切上げ",
    value: "1",
  },
  {
    label: "四捨五入",
    value: "2",
  },
];

export const receiptType1List = [
  {
    label: "レシートタイプ１",
    value: "1",
  },
  {
    label: "レシートタイプ２",
    value: "2",
  },
];

export const receiptType2List = [
  {
    label: "領収書タイプ１",
    value: "1",
  },
  {
    label: "領収書タイプ２",
    value: "2",
  },
];

const addModel = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  roundingType: Schema.Types.StringType().isRequired("必須項目です"),
  receiptType: Schema.Types.StringType().isRequired("必須項目です"),
  receiptType2: Schema.Types.StringType().isRequired("必須項目です"),
  paymentFlg: Schema.Types.StringType().isRequired("必須項目です"),
  paymentItemId: Schema.Types.ArrayType().isRequired("必須項目です"),
});

const PaymentSettings = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [addForm, setAddForm] = useState<FormInstance>();

  const [showEdit, setShowEdit] = useState(false);
  const [editForm, setEditForm] = useState<FormInstance>();
  const [imageFileInfo, setImageFileInfo] = useState({
    name: "",
    value: "",
    size: true,
  });

  const [showDelete, setShowDelete] = useState(false);

  const dispatch = useDispatch();

  const { token } = useSelector((state: RootState) => state.auth);
  const { data, isLoading } = useSelector(
    (state: RootState) => state.paymentSettings
  );
  const { data: paymentItems } = useSelector(
    (state: RootState) => state.paymentItems
  );
  const filteredPaymetItems = useMemo(() => {
    if (paymentItems !== null) {
      return paymentItems.paymentItems.map(pi => {
        return {
          label: pi.name,
          value: pi.id,
        };
      });
    }
    return [
      {
        label: "",
        value: "",
      },
    ];
  }, [paymentItems]);

  const { checks } = useSelector((state: RootState) => state.checkCells);
  const checkedKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "paymentSettings")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );

  useEffect(() => {
    if (token) {
      dispatch(fetchPaymentSettings(token));
      dispatch(fetchPaymentItems(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    const image = new Image();
    image.src = imageFileInfo.value;
    if (imageFileInfo.value !== "") {
      image.onload = () => {
        setImageFileInfo({
          name: imageFileInfo.name,
          value: imageFileInfo.value,
          size: image.naturalWidth === 225 && image.naturalHeight === 50,
        });
      };
    }
  }, [imageFileInfo.name, imageFileInfo.value]);

  const { formValue: addFormValue } = useSelector(
    (state: RootState) => state.paymentSettingsAddForm
  );

  const { formValue: editFormValue } = useSelector(
    (state: RootState) => state.paymentSettingsEditForm
  );

  const handleAddClick = () => {
    setShowAdd(true);
  };

  const handleAddFormChange = (value: any) => {
    dispatch(setAddFormValue({ formValue: value }));
  };

  const handleAddImageChange = (file: any) => {
    dispatch(
      setAddFormValue({
        formValue: {
          id: addFormValue.id,
          roundingType: addFormValue.roundingType,
          paymentFlg: addFormValue.paymentFlg,
          receiptType: addFormValue.receiptType,
          receiptType2: addFormValue.receiptType2,
          paymentItemId: addFormValue.paymentItemId,
          receiptLogoImage: file,
          remark: addFormValue.remark,
        },
      })
    );
  };

  const handleCheckAndSubmitAddForm = async () => {
    const checkResult = addForm!.check();
    if (checkResult && token) {
      try {
        setShowAdd(false);
        Alert.info("決済設定を追加中...", 100000000);
        await dispatch(
          postPaymentSetting(
            token,
            addFormValue.id,
            addFormValue.roundingType,
            addFormValue.paymentFlg,
            addFormValue.receiptType,
            addFormValue.receiptType2,
            addFormValue.paymentItemId,
            addFormValue.receiptLogoImage,
            addFormValue.remark
          )
        );
        dispatch(setAddFormInitialValue());
        Alert.close();
        Alert.success("決済設定を追加しました", 5000);
        await dispatch(fetchPaymentSettings(token));
        await dispatch(fetchPaymentItems(token));
      } catch (err) {
        Alert.closeAll();
        Alert.error("入力したIDはすでに登録されています", 2000);
      }
    }
  };

  const handleEditClick = () => {
    if (data && paymentItems) {
      const paymentSetting = data.paymentSettings.filter(
        ps => ps.id === checkedKeys[0]
      )[0];
      setImageFileInfo({
        name: paymentSetting.id,
        value: paymentSetting.receiptLogoImage,
        size: true,
      });
      dispatch(
        setEditFormValue({
          formValue: {
            id: paymentSetting.id,
            roundingType: paymentSetting.roundingType.toString(),
            paymentFlg: paymentSetting.paymentFlg.toString(),
            receiptType: paymentSetting.receiptType.toString(),
            receiptType2: paymentSetting.receiptType2.toString(),
            paymentItemId: paymentSetting.paymentItemId,
            receiptLogoImage: paymentSetting.receiptLogoImage,
            remark: paymentSetting.remark,
          },
        })
      );
      setShowEdit(true);
    }
  };

  const handleEditFormChange = (value: any) => {
    dispatch(setEditFormValue({ formValue: value }));
  };

  const handleCheckAndSubmitEditForm = async () => {
    const checkResult = editForm?.check();
    if (checkResult && token) {
      try {
        setShowEdit(false);
        Alert.info("決済設定を更新中...", 100000000);
        await dispatch(
          putPaymentSetting(
            token,
            editFormValue.id,
            editFormValue.roundingType,
            editFormValue.paymentFlg,
            editFormValue.receiptType,
            editFormValue.receiptType2,
            editFormValue.paymentItemId,
            editFormValue.receiptLogoImage.includes("https://")
              ? ""
              : editFormValue.receiptLogoImage,
            editFormValue.remark
          )
        );
        dispatch(
          setCheckedKeys({
            id: "paymentSettings",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        dispatch(setEditFormInitialValue());
        Alert.close();
        Alert.success("決済設定を更新しました", 5000);
        await dispatch(fetchPaymentSettings(token));
        await dispatch(fetchPaymentItems(token));
      } catch (err) {
        Alert.closeAll();
        Alert.error("不明なエラーが発生しました", 5000);
      }
    }
  };

  const handleEditImageChange = (file: any) => {
    dispatch(
      setEditFormValue({
        formValue: {
          id: editFormValue.id,
          roundingType: editFormValue.roundingType,
          paymentFlg: editFormValue.paymentFlg,
          receiptType: editFormValue.receiptType,
          receiptType2: editFormValue.receiptType2,
          paymentItemId: editFormValue.paymentItemId,
          receiptLogoImage: file,
          remark: editFormValue.remark,
        },
      })
    );
  };

  const handleDeleteClick = () => {
    setShowDelete(true);
  };

  const handleSubmitDelete = async () => {
    if (token) {
      try {
        setShowDelete(false);
        Alert.info("決済設定を削除中...", 100000000);
        await dispatch(delPaymentSetting(token, checkedKeys[0]));
        Alert.close();
        Alert.success("決済設定を削除しました", 5000);
        dispatch(
          setCheckedKeys({
            id: "paymentSettings",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        await dispatch(fetchPaymentSettings(token));
        await dispatch(fetchPaymentItems(token));
      } catch (err) {
        Alert.closeAll();
        Alert.error("不明なエラーが発生しました", 5000);
      }
    }
  };

  const onCancelClick = () => {
    setShowAdd(false);
    setShowEdit(false);
    setShowDelete(false);
  };

  const previewFile = (file: any, callback: any) => {
    const image = new Image();
    const reader = new FileReader();
    reader.onloadend = () => {
      image.src = reader.result?.toString()
        ? reader.result?.toString()
        : String(reader.result);
      callback(reader.result, image.naturalWidth, image.naturalHeight);
    };
    reader.readAsDataURL(file);
  };

  const editSpeaker = () => {
    if (checkedKeys.length > 1) {
      return (
        <Popover title="編集について">
          <p>一度に編集できる決済設定は1つです</p>
          <p>チェックを外してください</p>
        </Popover>
      );
    }

    return (
      <Popover title="編集について">
        <p>編集したい決済設定にチェックをつけてください</p>
      </Popover>
    );
  };

  const deleteSpeaker = () => {
    return (
      <Popover title="削除について">
        <p>削除したい決済設定にチェックをつけてください</p>
      </Popover>
    );
  };

  return (
    <>
      <Typography variant="h6">決済設定</Typography>
      <Margin top={20}>
        {!isLoading ? (
          data &&
          data !== null &&
          data.paymentSettings &&
          data.paymentSettings !== null ? (
            <>
              <PaymentSettingsTable paymentSettings={data.paymentSettings} />
              <Margin top={5} />
              <StyledPrimaryButton onClick={handleAddClick}>
                追加
              </StyledPrimaryButton>
              {checkedKeys.length === 1 ? (
                <>
                  <StyledEditButton isChecked onClick={handleEditClick}>
                    編集
                  </StyledEditButton>
                  <StyledDeleteButton isChecked onClick={handleDeleteClick}>
                    削除
                  </StyledDeleteButton>
                </>
              ) : (
                <>
                  <Whisper
                    placement="autoVerticalStart"
                    trigger="hover"
                    speaker={editSpeaker()}
                  >
                    <StyledEditButton isChecked={false}>編集</StyledEditButton>
                  </Whisper>
                  <Whisper
                    placement="autoVerticalStart"
                    trigger="hover"
                    speaker={deleteSpeaker()}
                  >
                    <StyledDeleteButton isChecked={false}>
                      削除
                    </StyledDeleteButton>
                  </Whisper>
                </>
              )}
            </>
          ) : (
            <>
              <div>決済設定はありません</div>
            </>
          )
        ) : (
          <Loading />
        )}
      </Margin>

      <StyledModal show={showAdd} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            決済設定追加
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={addModel}
            formValue={addFormValue}
            ref={(ref: FormInstance) => setAddForm(ref)}
            onChange={handleAddFormChange}
          >
            <FormGroup>
              <ControlLabel>決済設定ID</ControlLabel>
              <FormControl name="id" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>端数処理</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={roundingTypeList}
                name="roundingType"
                placeholder="端数処理を選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>決済システム使用有無</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={[
                  {
                    label: "使用する",
                    value: "true",
                  },
                  {
                    label: "停止する",
                    value: "false",
                  },
                ]}
                name="paymentFlg"
                placeholder="決済システムの使用有無を選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>レシートタイプ</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={receiptType1List}
                name="receiptType"
                placeholder="レシートタイプを選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>領収書タイプ</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={receiptType2List}
                name="receiptType2"
                placeholder="領収書タイプを選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>決済商品</ControlLabel>
              <FormControl
                accepter={TagPicker}
                size="md"
                style={{ width: "100%" }}
                data={filteredPaymetItems}
                name="paymentItemId"
                placeholder="決済商品を選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>
                レシートロゴ画像（推奨サイズ 225 × 50px）
              </ControlLabel>
              <Uploader
                accept="image/png,image/jpeg"
                fileListVisible={false}
                autoUpload={false}
                listType="picture"
                onChange={files => {
                  const afile = files.pop();

                  previewFile(afile!.blobFile, async (value: any) => {
                    await setImageFileInfo({
                      name: afile?.name ? afile.name : "",
                      value,
                      size: imageFileInfo.size,
                    });
                    handleAddImageChange(value);
                  });
                }}
              >
                <button
                  style={{ width: "200px", height: "112px" }}
                  type="button"
                >
                  {imageFileInfo.value !== "" ? (
                    <img
                      src={imageFileInfo.value}
                      width="100%"
                      height="100%"
                      alt=""
                    />
                  ) : (
                    <Icon icon="camera" size="5x" />
                  )}
                </button>
              </Uploader>
              {imageFileInfo.size ? (
                ""
              ) : (
                <div style={{ color: "red" }}>
                  <Icon
                    icon="remind"
                    style={{
                      color: "#ffb300",
                      fontSize: 24,
                    }}
                  />
                  アップロードされた画像は推奨サイズではありません
                </div>
              )}
              <ControlLabel>ファイル名</ControlLabel>
              <FormControl
                name="img"
                value={imageFileInfo.name}
                readOnly
                plaintext
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="remark" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledPrimaryButton onClick={handleCheckAndSubmitAddForm}>
              OK
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelClick}>
              Cancel
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showEdit} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            決済設定編集
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={addModel}
            formValue={editFormValue}
            ref={(ref: FormInstance) => setEditForm(ref)}
            onChange={handleEditFormChange}
          >
            <FormGroup>
              <ControlLabel>決済設定ID</ControlLabel>
              <FormControl readOnly name="id" plaintext />
            </FormGroup>
            <FormGroup>
              <ControlLabel>端数処理</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={roundingTypeList}
                name="roundingType"
                placeholder="端数処理を選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>決済システム使用有無</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={[
                  {
                    label: "使用する",
                    value: "true",
                  },
                  {
                    label: "停止する",
                    value: "false",
                  },
                ]}
                name="paymentFlg"
                placeholder="決済システムの使用有無を選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>レシートタイプ</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={receiptType1List}
                name="receiptType"
                placeholder="レシートタイプを選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>領収書タイプ</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={receiptType2List}
                name="receiptType2"
                placeholder="領収書タイプを選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>決済商品</ControlLabel>
              <FormControl
                accepter={TagPicker}
                size="md"
                style={{ width: "100%" }}
                data={filteredPaymetItems}
                name="paymentItemId"
                placeholder="決済商品を選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>
                レシートロゴ画像（推奨サイズ 225 × 50px）
              </ControlLabel>
              <Uploader
                accept="image/png,image/jpeg"
                fileListVisible={false}
                autoUpload={false}
                listType="picture"
                onChange={files => {
                  const afile = files.pop();

                  previewFile(afile!.blobFile, async (value: any) => {
                    await setImageFileInfo({
                      name: afile?.name ? afile.name : "",
                      value,
                      size: imageFileInfo.size,
                    });
                    handleEditImageChange(value);
                  });
                }}
              >
                <button
                  style={{ width: "200px", height: "112px" }}
                  type="button"
                >
                  {imageFileInfo.value !== "" ? (
                    <img
                      src={imageFileInfo.value}
                      width="100%"
                      height="100%"
                      alt=""
                    />
                  ) : (
                    <Icon icon="camera" size="5x" />
                  )}
                </button>
              </Uploader>
              {imageFileInfo.size ? (
                ""
              ) : (
                <div style={{ color: "red" }}>
                  <Icon
                    icon="remind"
                    style={{
                      color: "#ffb300",
                      fontSize: 24,
                    }}
                  />
                  アップロードされた画像は推奨サイズではありません
                </div>
              )}
              <ControlLabel>ファイル名</ControlLabel>
              <FormControl
                name="img"
                value={imageFileInfo.name}
                readOnly
                plaintext
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="remark" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledPrimaryButton onClick={handleCheckAndSubmitEditForm}>
              OK
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelClick}>
              Cancel
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showDelete} overflow onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            決済設定削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List bordered={false}>
            {checkedKeys
              .map(key => {
                return data?.paymentSettings
                  .filter(ps => ps.id === key)
                  .map(value => value.id)[0];
              })
              .map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{
                    paddingLeft: "10px",
                    color: "#d84421",
                  }}
                >
                  {item}
                </List.Item>
              ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: "#d84421",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記の決済設定を削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          <StyledDeleteButton isChecked onClick={handleSubmitDelete}>
            OK
          </StyledDeleteButton>
          <StyledCancelButton onClick={onCancelClick}>
            Cancel
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>
    </>
  );
};

export default PaymentSettings;
