import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import addNotificationButtons, {
  AddNotificationButton,
} from "../../../api/Management/notificationButtonAPI/add";
import { expiredToken } from "../../auth";
import { AppThunk } from "../../../stores";
import { ApiState, startLoading, loadingFailed } from "../../../api/apiService";

const apiInitialState: ApiState<AddNotificationButton> = {
  isLoading: false,
  error: null,
  data: null,
};

const addNotificationButtonApiModule = createSlice({
  name: "addNotificationButton",
  initialState: apiInitialState,
  reducers: {
    getApiStart: startLoading,
    getApiSuccess(state, { payload }: PayloadAction<AddNotificationButton>) {
      state.isLoading = false;
      state.error = null;
      state.data = payload;
    },
    getApiFailure: loadingFailed,
  },
});

export const {
  getApiStart,
  getApiSuccess,
  getApiFailure,
} = addNotificationButtonApiModule.actions;

export default addNotificationButtonApiModule.reducer;

export const postNotificationButton = (
  token: string,
  id: string,
  val: string,
  viewData: string,
  buttonType: string,
  remark: string
): AppThunk => async dispatch => {
  try {
    dispatch(getApiStart());
    const data = await addNotificationButtons(
      token,
      id,
      val,
      viewData,
      buttonType,
      remark
    );
    dispatch(getApiSuccess(data));
  } catch (err) {
    switch (err.resultStatus) {
      case 126:
        dispatch(expiredToken(err.resultStatus));
        break;
      case 115:
        dispatch(getApiFailure(err.resultStatus));
        throw err;
      default:
        console.error(err);
        break;
    }
    dispatch(getApiFailure(err));
  }
};
