import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Memo = {
  id: string;
  memoInfo: {
    memoTitle?: string;
    memoText?: string;
    remark?: string;
  };
};

type MemoState = {
  memos: Memo[];
};

const initialState: MemoState = {
  memos: [],
};

const memosModule = createSlice({
  name: "memos",
  initialState,
  reducers: {
    setMemo(state: MemoState, { payload }: PayloadAction<MemoState>) {
      state.memos = payload.memos;
    },
    setMemoTitle(state: MemoState, { payload }: PayloadAction<Memo>) {
      state.memos.forEach(memo => {
        if (memo.id === payload.id) {
          memo.memoInfo.memoTitle = payload.memoInfo.memoTitle;
        }
      });
    },
    setMemoText(state: MemoState, { payload }: PayloadAction<Memo>) {
      state.memos.forEach(memo => {
        if (memo.id === payload.id) {
          memo.memoInfo.memoText = payload.memoInfo.memoText;
        }
      });
    },
  },
});

export const { setMemo, setMemoTitle, setMemoText } = memosModule.actions;

export default memosModule.reducer;
