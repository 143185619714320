import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    roomGroupId: string;
    name: string;
    roomList: string[];
    remark: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    roomGroupId: "",
    name: "",
    roomList: [],
    remark: "",
  },
};

const robotEditFormModule = createSlice({
  name: "robotEditForm",
  initialState,
  reducers: {
    setEditFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.roomGroupId = payload.formValue.roomGroupId;
      state.formValue.name = payload.formValue.name;
      state.formValue.roomList = payload.formValue.roomList;
      state.formValue.remark = payload.formValue.remark;
    },
    setEditFormInitialValue(state: FormValueState) {
      state.formValue = initialState.formValue;
    },
  },
});

export const {
  setEditFormValue,
  setEditFormInitialValue,
} = robotEditFormModule.actions;

export default robotEditFormModule.reducer;
