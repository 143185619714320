import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type SidebarState = {
  isExpand: boolean;
};

const initialState: SidebarState = {
  isExpand: false,
};

const sidebarModule = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleSidebar(
      state: SidebarState,
      { payload }: PayloadAction<SidebarState>
    ) {
      state.isExpand = payload.isExpand;
    },
  },
});

export const { toggleSidebar } = sidebarModule.actions;

export default sidebarModule.reducer;
