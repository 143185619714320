import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Worker = {
  userId: string | number | null;
  userName: string | number | null;
};

type Workers = {
  workers: Worker[];
};

const initialState: Workers = {
  workers: [],
};

const workersModule = createSlice({
  name: "workers",
  initialState,
  reducers: {
    setWorkers(state: Workers, { payload }: PayloadAction<Worker[]>) {
      state.workers = payload;
    },
  },
});

export const { setWorkers } = workersModule.actions;

export default workersModule.reducer;
