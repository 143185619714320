import React from "react";
import {
  Schema,
  Modal,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Uploader,
  Icon,
} from "rsuite";
import { FormInstance } from "rsuite/lib/Form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../reducers/rootReducer";
import {
  StyledPrimaryButton,
  StyledCancelButton,
  StyledModal,
} from "../../../container/templates/Management/Users";
import { setAddSpeechFormValue } from "../../../../modules/Management/Form/speechAddFormModule";

const addSpeechModel = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です")
    .pattern(
      /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
      "IDとして入力できるのは半角英数記号のみです。"
    ),
  file: Schema.Types.StringType().isRequired("必須項目です"),
  text: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
});

type Props = {
  show: boolean;
  onHide: () => void;
  setForm: any;
  submitSpeech: () => void;
  speechFileInfo: any;
  setSpeechFileInfo: any;
};

const AddSpeechModal = ({
  show,
  onHide,
  setForm,
  submitSpeech,
  speechFileInfo,
  setSpeechFileInfo,
}: Props) => {
  const dispatch = useDispatch();

  const { formValue: addSpeechFormValue } = useSelector(
    (state: RootState) => state.speechAddForm
  );

  const handleAddSpeechFormChange = (value: any) => {
    dispatch(
      setAddSpeechFormValue({
        formValue: {
          id: value.id,
          file: addSpeechFormValue.file,
          text: value.text,
          val: value.val,
          playTime: addSpeechFormValue.playTime,
        },
      })
    );
  };

  const handleAddSpeechChange = (file: string, playTime: number) => {
    dispatch(
      setAddSpeechFormValue({
        formValue: {
          id: addSpeechFormValue.id,
          file,
          text: addSpeechFormValue.text,
          val: addSpeechFormValue.val,
          playTime,
        },
      })
    );
  };

  const previewFile = (file: any, callback: any) => {
    const image = new Image();
    const reader = new FileReader();
    reader.onloadend = () => {
      image.src = reader.result?.toString()
        ? reader.result?.toString()
        : String(reader.result);
      callback(reader.result, image.naturalWidth, image.naturalHeight);
    };
    reader.readAsDataURL(file);
  };

  return (
    <StyledModal show={show} overflow={false} onHide={onHide}>
      <Modal.Header>
        <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
          スピーチ音声追加
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          fluid
          model={addSpeechModel}
          formValue={addSpeechFormValue}
          ref={(ref: FormInstance) => setForm(ref)}
          onChange={handleAddSpeechFormChange}
        >
          <FormGroup>
            <ControlLabel>スピーチID</ControlLabel>
            <FormControl name="id" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>表示テキスト</ControlLabel>
            <FormControl name="text" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>スピーチ音声アップロード</ControlLabel>
            <Uploader
              accept="audio/wav, audio/mp3"
              fileListVisible={false}
              autoUpload={false}
              listType="picture"
              onChange={files => {
                const afile = files.pop();
                if (afile !== undefined) {
                  previewFile(afile.blobFile, async (value: any) => {
                    const media = new Audio(value);

                    await setSpeechFileInfo({
                      name: afile?.name ? afile.name : "",
                      value,
                      file: afile.blobFile,
                    });
                    media.onloadedmetadata = () => {
                      handleAddSpeechChange(
                        afile.name ? afile.name : "",
                        media.duration
                      );
                    };
                  });
                }
              }}
            >
              <button style={{ width: "400px", height: "112px" }} type="button">
                {speechFileInfo.value !== "" ? (
                  <audio src={speechFileInfo.value} controls />
                ) : (
                  <Icon icon="file-audio-o" size="5x" />
                )}
              </button>
            </Uploader>
            <ControlLabel>ファイル名</ControlLabel>
            <FormControl
              name="img"
              value={speechFileInfo.name}
              readOnly
              plaintext
            />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <>
          <StyledPrimaryButton onClick={submitSpeech}>OK</StyledPrimaryButton>
          <StyledCancelButton onClick={onHide}>Cancel</StyledCancelButton>
        </>
      </Modal.Footer>
    </StyledModal>
  );
};

export default AddSpeechModal;
