import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    id: string;
    val: string;
    viewData: string;
    buttonType: string;
    remark: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    id: "",
    val: "",
    viewData: "",
    buttonType: "",
    remark: "",
  },
};

const notificationButtonAddFormModule = createSlice({
  name: "notificationButtonAddForm",
  initialState,
  reducers: {
    setAddFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue = payload.formValue;
    },
    setAddFormInitialValue(state: FormValueState) {
      state.formValue = initialState.formValue;
    },
  },
});

export const {
  setAddFormValue,
  setAddFormInitialValue,
} = notificationButtonAddFormModule.actions;

export default notificationButtonAddFormModule.reducer;
