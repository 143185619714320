import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    motionId: string;
    text: string;
    val: string;
    status: string;
    position: string;
    playTime: number;
    remark: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    motionId: "",
    text: "",
    val: "",
    status: "",
    position: "",
    playTime: 0,
    remark: "",
  },
};

const motionEditFormModule = createSlice({
  name: "motionEditForm",
  initialState,
  reducers: {
    setEditFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.motionId = payload.formValue.motionId;
      state.formValue.text = payload.formValue.text;
      state.formValue.val = payload.formValue.val;
      state.formValue.status = payload.formValue.status;
      state.formValue.position = payload.formValue.position;
      state.formValue.playTime = payload.formValue.playTime;
      state.formValue.remark = payload.formValue.remark;
    },
    setEditFormInitialValue(state: FormValueState) {
      state.formValue = initialState.formValue;
    },
  },
});

export const {
  setEditFormValue,
  setEditFormInitialValue,
} = motionEditFormModule.actions;

export default motionEditFormModule.reducer;
