import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    robotId: string;
    name: string;
    ip: string;
    robotNo: string;
    mac: string;
    switchbotMac: string;
    remark: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    robotId: "",
    name: "",
    ip: "",
    robotNo: "1",
    mac: "",
    switchbotMac: "",
    remark: "",
  },
};

const robotAddFormModule = createSlice({
  name: "robotAddForm",
  initialState,
  reducers: {
    setAddFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.robotId = payload.formValue.robotId;
      state.formValue.name = payload.formValue.name;
      state.formValue.ip = payload.formValue.ip;
      state.formValue.robotNo = payload.formValue.robotNo;
      state.formValue.mac = payload.formValue.mac;
      state.formValue.switchbotMac = payload.formValue.switchbotMac;
      state.formValue.remark = payload.formValue.remark;
    },
    setAddFormInitialValue(state: FormValueState) {
      state.formValue = initialState.formValue;
    },
  },
});

export const {
  setAddFormValue,
  setAddFormInitialValue,
} = robotAddFormModule.actions;

export default robotAddFormModule.reducer;
