/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormInstance } from "rsuite/lib/Form";
import {
  Form,
  Whisper,
  Popover,
  FormGroup,
  ControlLabel,
  FormControl,
  Schema,
  Alert,
  SelectPicker,
  List,
  TagPicker,
} from "rsuite";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import Title from "../../../../presentational/atoms/Text/Title";
import { RootState } from "../../../../../reducers/rootReducer";
import { fetchPcs } from "../../../../../modules/Management/Pcs/pcApiModule";
import { Margin } from "../../../../../utils/styled";
import PcsTable from "../../../../presentational/molecules/Table/PcsTable";
import Loading from "../../../../presentational/molecules/Loading";
import {
  StyledPrimaryButton,
  StyledEditButton,
  StyledDeleteButton,
  StyledCancelButton,
  StyledButtons,
} from "../Users";
import { setAddFormValue } from "../../../../../modules/Management/Form/Pc/pcAddFormModule";
import { setEditFormValue } from "../../../../../modules/Management/Form/Pc/pcEditFormModule";
import { postPc } from "../../../../../modules/Management/Pcs/addPcApiModule";
import { fetchActions } from "../../../../../modules/Management/Actions/actionsApiModule";
import { fetchRobots } from "../../../../../modules/Management/Robots/robotsApiModule";
import { fetchPaymentSettings } from "../../../../../modules/Management/PaymentSettings/paymentSettingsApiModule";
import { putPc } from "../../../../../modules/Management/Pcs/updatePcsApiModule";
import { delPc } from "../../../../../modules/Management/Pcs/deletePcApiModule";
import { setCheckedKeys } from "../../../../../modules/Management/checkModule";
import { theme } from "../../../../../theme";
import { fetchNotificationButtons } from "../../../../../modules/Management/NotificationButtons/notificationButtonApiModule";

const addModel = Schema.Model({
  pcId: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  name: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  actionId: Schema.Types.StringType().isRequired("必須項目です"),
  robot: Schema.Types.StringType().isRequired("必須項目です"),
});

export const csStartSoundData = [
  {
    label: "音無し",
    value: "0",
  },
  {
    label: "通知音１",
    value: "1",
  },
  {
    label: "通知音２",
    value: "2",
  },
  {
    label: "通知音３",
    value: "3",
  },
  {
    label: "通知音４",
    value: "4",
  },
  {
    label: "通知音５",
    value: "5",
  },
  {
    label: "通知音６",
    value: "6",
  },
  {
    label: "通知音７",
    value: "7",
  },
  {
    label: "通知音８",
    value: "8",
  },
  {
    label: "通知音９",
    value: "9",
  },
  {
    label: "通知音１０",
    value: "10",
  },
];

const Pcs = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [addForm, setAddForm] = useState<FormInstance>();

  const [showEdit, setShowEdit] = useState(false);
  const [editForm, setEditForm] = useState<FormInstance>();

  const [showDelete, setShowDelete] = useState(false);

  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.auth);
  const { data, isLoading } = useSelector((state: RootState) => state.pcs);

  const { data: actionsData } = useSelector(
    (state: RootState) => state.actions
  );
  const filteredActionsData = useMemo(() => {
    if (actionsData) {
      return actionsData.actions.map(action => {
        return {
          label: action.name,
          value: action.actionId,
        };
      });
    }
    return [
      {
        label: "",
        value: "",
      },
    ];
  }, [actionsData]);
  const { data: robotsData } = useSelector((state: RootState) => state.robots);
  const filteredRobotsData = useMemo(() => {
    if (robotsData) {
      return robotsData.robots.map(robot => {
        return {
          label: robot.name,
          value: robot.robotId,
        };
      });
    }
    return [
      {
        label: "",
        value: "",
      },
    ];
  }, [robotsData]);
  const { data: paymentSettingsData } = useSelector(
    (state: RootState) => state.paymentSettings
  );

  const { data: notificationButtonsData } = useSelector(
    (state: RootState) => state.notificationButtons
  );
  const filteredNotificationButtonsData = useMemo(() => {
    if (notificationButtonsData) {
      return notificationButtonsData.notificationButtons.map(
        notificationButton => {
          return {
            label: notificationButton.id,
            value: notificationButton.id,
          };
        }
      );
    }
    return [
      {
        label: "",
        value: "",
      },
    ];
  }, [notificationButtonsData]);

  const { checks } = useSelector((state: RootState) => state.checkCells);
  const checkedKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "pcs")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );

  useEffect(() => {
    if (token) {
      dispatch(fetchPcs(token));
      dispatch(fetchActions(token));
      dispatch(fetchRobots(token));
      dispatch(fetchPaymentSettings(token));
      dispatch(fetchNotificationButtons(token));
    }
  }, [dispatch, token]);

  const { formValue: addFormValue } = useSelector(
    (state: RootState) => state.pcAddForm
  );

  const { formValue: editFormValue } = useSelector(
    (state: RootState) => state.pcEditForm
  );

  const handleAddClick = () => {
    setShowAdd(true);
  };

  const handleAddFormChange = (value: any) => {
    dispatch(setAddFormValue({ formValue: value }));
  };

  const handleCheckAndSubmitAddForm = async () => {
    const checkResult = addForm!.check();
    if (checkResult && token) {
      try {
        setShowAdd(false);
        Alert.info("PC追加中...", 100000000);
        await dispatch(
          postPc(
            token,
            addFormValue.pcId,
            addFormValue.name,
            addFormValue.actionId,
            addFormValue.robot,
            addFormValue.ipAddress,
            addFormValue.address1,
            addFormValue.address2,
            addFormValue.telNumber,
            addFormValue.notificationButtons,
            addFormValue.csStartSoundNo,
            addFormValue.remark
          )
        );
        Alert.close();
        Alert.success("PCを追加しました", 5000);
        await dispatch(fetchPcs(token));
      } catch (err) {
        Alert.closeAll();
        Alert.error("入力したIDはすでに存在しています", 2000);
      }
    }
  };

  const handleEditClick = () => {
    if (data && actionsData && robotsData && paymentSettingsData) {
      const filteredPc = data.pcs.filter(pc => pc.pcId === checkedKeys[0])[0];
      dispatch(
        setEditFormValue({
          formValue: {
            pcId: filteredPc.pcId,
            name: filteredPc.name,
            actionId: filteredPc.actionId,
            robot: filteredPc.robotId,
            ipAddress: filteredPc.ipAddress,
            address1: filteredPc.address[0],
            address2: filteredPc.address[1],
            telNumber: filteredPc.telNumber,
            notificationButtons: filteredPc.notificationButtons,
            csStartSoundNo: filteredPc.csStartSoundNo
              ? filteredPc.csStartSoundNo.toString()
              : "0",
            remark: filteredPc.remark,
          },
        })
      );
    }
    setShowEdit(true);
  };

  const handleEditFormChange = (value: any) => {
    dispatch(setEditFormValue({ formValue: value }));
  };

  const handleCheckAndSubmitEditForm = async () => {
    const checkResult = editForm!.check();
    if (checkResult && token) {
      try {
        setShowEdit(false);
        Alert.info("PC更新中...", 100000000);
        await dispatch(
          putPc(
            token,
            editFormValue.pcId,
            editFormValue.name,
            editFormValue.actionId,
            editFormValue.robot,
            editFormValue.ipAddress,
            editFormValue.address1,
            editFormValue.address2,
            editFormValue.telNumber,
            editFormValue.notificationButtons,
            editFormValue.csStartSoundNo,
            editFormValue.remark
          )
        );
        Alert.close();
        Alert.success("PCを更新しました", 5000);
        await dispatch(fetchPcs(token));
      } catch (err) {
        Alert.closeAll();
        Alert.error("不明なエラーが発生しました", 2000);
      }
    }
  };

  const handleDeleteClick = () => {
    setShowDelete(true);
  };

  const handleSubmitDelete = async () => {
    if (token) {
      try {
        setShowDelete(false);
        Alert.info("PC削除中...", 100000000);
        await dispatch(delPc(token, checkedKeys[0]));
        Alert.close();
        Alert.success("PCを削除しました", 5000);
        dispatch(
          setCheckedKeys({
            id: "pcs",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        await dispatch(fetchPcs(token));
      } catch (err) {
        Alert.closeAll();
        Alert.error("不明なエラーが発生しました", 5000);
      }
    }
  };

  const onCancelClick = () => {
    setShowAdd(false);
    setShowEdit(false);
    setShowDelete(false);
  };
  const editSpeaker = () => {
    if (checkedKeys.length > 1) {
      return (
        <Popover title="編集について">
          <p>一度に編集できるPCは1つです</p>
          <p>チェックを外してください</p>
        </Popover>
      );
    }

    return (
      <Popover title="編集について">
        <p>編集したいPCにチェックをつけてください</p>
      </Popover>
    );
  };

  const deleteSpeaker = () => {
    return (
      <Popover title="削除について">
        <p>削除したいPCにチェックをつけてください</p>
      </Popover>
    );
  };
  return (
    <>
      <Title>PC管理</Title>
      <Margin top={20}>
        {!isLoading ? (
          data && data !== null && data.pcs && data.pcs.length !== 0 ? (
            <>
              <StyledButtons>
                <StyledPrimaryButton onClick={handleAddClick}>
                  追加
                </StyledPrimaryButton>
                {checkedKeys.length === 1 ? (
                  <>
                    <StyledEditButton isChecked onClick={handleEditClick}>
                      編集
                    </StyledEditButton>
                    <StyledDeleteButton isChecked onClick={handleDeleteClick}>
                      削除
                    </StyledDeleteButton>
                  </>
                ) : (
                  <>
                    <Whisper
                      placement="autoVerticalStart"
                      trigger="hover"
                      speaker={editSpeaker()}
                    >
                      <StyledEditButton isChecked={false}>
                        編集
                      </StyledEditButton>
                    </Whisper>
                    <Whisper
                      placement="autoVerticalStart"
                      trigger="hover"
                      speaker={deleteSpeaker()}
                    >
                      <StyledDeleteButton isChecked={false}>
                        削除
                      </StyledDeleteButton>
                    </Whisper>
                  </>
                )}
              </StyledButtons>
              <Margin top={5} />
              <PcsTable pcs={data.pcs} />
            </>
          ) : (
            <>
              <StyledButtons>
                <StyledPrimaryButton onClick={handleAddClick}>
                  追加
                </StyledPrimaryButton>
              </StyledButtons>
              <Margin top={5} />
              <div>PCはありません</div>
            </>
          )
        ) : (
          <Loading />
        )}
      </Margin>

      <Dialog
        open={showAdd}
        onClose={onCancelClick}
        fullWidth
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        scroll="body"
      >
        <DialogTitle id="form-dialog-title">PC追加</DialogTitle>
        <DialogContent>
          <Form
            fluid
            model={addModel}
            formValue={addFormValue}
            ref={(ref: FormInstance) => setAddForm(ref)}
            onChange={handleAddFormChange}
          >
            <FormGroup>
              <ControlLabel>PCID</ControlLabel>
              <FormControl name="pcId" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>PC名</ControlLabel>
              <FormControl name="name" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>アクションID</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={filteredActionsData}
                name="actionId"
                menuStyle={{ zIndex: 99999 }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>ロボットID</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={filteredRobotsData}
                name="robot"
                menuStyle={{ zIndex: 99999 }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>通知ボタン</ControlLabel>
              <FormControl
                accepter={TagPicker}
                style={{ width: 300 }}
                data={filteredNotificationButtonsData}
                name="notificationButtons"
                menuStyle={{ zIndex: 99999 }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>接客開始音</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={csStartSoundData}
                name="csStartSoundNo"
                menuStyle={{ zIndex: 99999 }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>IPアドレス</ControlLabel>
              <FormControl name="ipAddress" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>住所1</ControlLabel>
              <FormControl name="address1" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>住所2</ControlLabel>
              <FormControl name="address2" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>電話番号</ControlLabel>
              <FormControl name="telNumber" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="remark" />
            </FormGroup>
          </Form>
          <DialogActions>
            <StyledPrimaryButton onClick={handleCheckAndSubmitAddForm}>
              OK
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelClick}>
              Cancel
            </StyledCancelButton>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showEdit}
        onClose={onCancelClick}
        fullWidth
        maxWidth="lg"
        scroll="body"
      >
        <DialogTitle>PC編集</DialogTitle>
        <DialogContent>
          <Form
            fluid
            model={addModel}
            formValue={editFormValue}
            ref={(ref: FormInstance) => setEditForm(ref)}
            onChange={handleEditFormChange}
          >
            <FormGroup>
              <ControlLabel>PCID</ControlLabel>
              <FormControl readOnly name="pcId" plaintext />
            </FormGroup>
            <FormGroup>
              <ControlLabel>PC名</ControlLabel>
              <FormControl name="name" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>アクションID</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={filteredActionsData}
                name="actionId"
                menuStyle={{ zIndex: 99999 }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>ロボットID</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={filteredRobotsData}
                name="robot"
                menuStyle={{ zIndex: 99999 }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>通知ボタン</ControlLabel>
              <FormControl
                accepter={TagPicker}
                data={filteredNotificationButtonsData}
                name="notificationButtons"
                menuStyle={{ zIndex: 99999 }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>接客開始音</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={csStartSoundData}
                name="csStartSoundNo"
                menuStyle={{ zIndex: 99999 }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>IPアドレス</ControlLabel>
              <FormControl name="ipAddress" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>住所1</ControlLabel>
              <FormControl name="address1" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>住所2</ControlLabel>
              <FormControl name="address2" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>電話番号</ControlLabel>
              <FormControl name="telNumber" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="remark" />
            </FormGroup>
          </Form>
          <DialogActions>
            <StyledPrimaryButton onClick={handleCheckAndSubmitEditForm}>
              OK
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelClick}>
              Cancel
            </StyledCancelButton>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showDelete}
        onClose={onCancelClick}
        fullWidth
        maxWidth="lg"
        scroll="body"
      >
        <DialogTitle>PC削除</DialogTitle>
        <DialogContent>
          <List bordered={false}>
            {checkedKeys
              .map(key => {
                return data?.pcs
                  .filter(pc => pc.pcId === key)
                  .map(value => value.pcId)[0];
              })
              .map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{
                    paddingLeft: "10px",
                    color: theme.palette.error.main,
                  }}
                >
                  {item}
                </List.Item>
              ))}
          </List>
          <p
            style={{
              marginTop: "24px",
              color: theme.palette.error.main,
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記のPCを削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          <DialogActions>
            <StyledDeleteButton isChecked onClick={handleSubmitDelete}>
              OK
            </StyledDeleteButton>
            <StyledCancelButton onClick={onCancelClick}>
              Cancel
            </StyledCancelButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Pcs;
