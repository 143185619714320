import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { IconButton, Icon } from "rsuite";
import ChartsBox from "../ChartsBox";
import ChartHeader from "../../../atoms/Text/ChartHeader";
import SimplePieChart from "./SimplePieChart";
import { Margin } from "../../../../../utils/styled";
import { RootState } from "../../../../../reducers/rootReducer";
import { NoData } from "../LineCharts";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EndWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const PieCharts = ({
  onEditClick,
  onDeleteClick,
}: {
  onEditClick?: any;
  onDeleteClick?: any;
}) => {
  const { data } = useSelector((state: RootState) => state.graphApi);

  return (
    <>
      {data
        ? data.pie.map((graph, index) => {
            if (graph.graphData.length !== 0) {
              return (
                <ChartsBox key={index.toString()}>
                  <Margin top={20} left={20} right={20}>
                    <Wrapper>
                      <ChartHeader>{graph.graphName}</ChartHeader>
                      {onEditClick && onDeleteClick ? (
                        <EndWrapper>
                          <IconButton
                            icon={<Icon icon="edit" />}
                            onClick={() => {
                              onEditClick(graph);
                            }}
                            size="xs"
                            color="blue"
                            circle
                            style={{ marginRight: "5px" }}
                          />
                          <IconButton
                            icon={<Icon icon="close" />}
                            onClick={() => {
                              onDeleteClick(graph);
                            }}
                            size="xs"
                            color="red"
                            circle
                          />
                        </EndWrapper>
                      ) : (
                        ""
                      )}
                    </Wrapper>
                  </Margin>
                  <SimplePieChart data={graph.graphData} />
                </ChartsBox>
              );
            }
            return (
              <ChartsBox key={index.toString()}>
                <Margin top={20} left={20} right={20}>
                  <Wrapper>
                    <ChartHeader>{graph.graphName}</ChartHeader>
                    {onEditClick && onDeleteClick ? (
                      <EndWrapper>
                        <IconButton
                          icon={<Icon icon="edit" />}
                          onClick={() => {
                            onEditClick(graph);
                          }}
                          size="xs"
                          color="blue"
                          circle
                          style={{ marginRight: "5px" }}
                        />
                        <IconButton
                          icon={<Icon icon="close" />}
                          onClick={() => {
                            onDeleteClick(graph);
                          }}
                          size="xs"
                          color="red"
                          circle
                        />
                      </EndWrapper>
                    ) : (
                      ""
                    )}
                  </Wrapper>
                </Margin>
                <NoData>データがありません</NoData>
              </ChartsBox>
            );
          })
        : ""}
    </>
  );
};

export default PieCharts;
