import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import updateVideo, {
  UpdateVideo,
} from "../../../api/Management/videoApi/update";
import { expiredToken } from "../../auth";
import { AppThunk } from "../../../stores";
import { ApiState, startLoading, loadingFailed } from "../../../api/apiService";

const apiInitialState: ApiState<UpdateVideo> = {
  isLoading: false,
  error: null,
  data: null,
};

const updateVideoApiModule = createSlice({
  name: "updateVideo",
  initialState: apiInitialState,
  reducers: {
    getApiStart: startLoading,
    getApiSuccess(state, { payload }: PayloadAction<UpdateVideo>) {
      state.isLoading = false;
      state.error = null;
      state.data = payload;
    },
    getApiFailure: loadingFailed,
  },
});

export const {
  getApiStart,
  getApiSuccess,
  getApiFailure,
} = updateVideoApiModule.actions;

export default updateVideoApiModule.reducer;

export const putVideo = (
  token: string,
  id: string,
  file: any,
  text: string,
  val: string,
  playTime: number,
  fileSize: number | undefined
): AppThunk => async dispatch => {
  try {
    dispatch(getApiStart());
    const data = await updateVideo(
      token,
      id,
      file,
      text,
      val,
      playTime,
      fileSize
    );
    dispatch(getApiSuccess(data));
  } catch (err) {
    switch (err.resultStatus) {
      case 126:
        dispatch(expiredToken(err.resultStatus));
        break;
      case 115:
        dispatch(getApiFailure(err.resultStatus));
        throw err;
      case 114:
        dispatch(getApiFailure(err.resultStatus));
        throw err;
      default:
        console.error(err);
        break;
    }
    dispatch(getApiFailure(err));
  }
};
