import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    id?: string;
    name?: string;
    recordFlg?: number;
    remark?: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    id: "",
    name: "",
    recordFlg: 0,
    remark: "",
  },
};

const companyAddFormModule = createSlice({
  name: "companyForm",
  initialState,
  reducers: {
    setEditFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.id = payload.formValue.id;
      state.formValue.name = payload.formValue.name;
      state.formValue.recordFlg = payload.formValue.recordFlg;
      state.formValue.remark = payload.formValue.remark;
    },
  },
});

export const { setEditFormValue } = companyAddFormModule.actions;

export default companyAddFormModule.reducer;
