import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getActions, { Actions } from "../../../api/Management/actionsApi";
import { expiredToken } from "../../auth";
import { AppThunk } from "../../../stores";
import { ApiState, startLoading, loadingFailed } from "../../../api/apiService";

const apiInitialState: ApiState<Actions> = {
  isLoading: false,
  error: null,
  data: null,
};

const actionsApiModule = createSlice({
  name: "actions",
  initialState: apiInitialState,
  reducers: {
    getApiStart: startLoading,
    getApiSuccess(state, { payload }: PayloadAction<Actions>) {
      state.isLoading = false;
      state.error = null;
      state.data = payload;
    },
    getApiFailure: loadingFailed,
  },
});

export const {
  getApiStart,
  getApiSuccess,
  getApiFailure,
} = actionsApiModule.actions;

export default actionsApiModule.reducer;

export const fetchActions = (token: string): AppThunk => async dispatch => {
  try {
    dispatch(getApiStart());
    const data = await getActions(token);
    dispatch(getApiSuccess(data));
  } catch (err) {
    switch (err.resultStatus) {
      case 126:
        dispatch(expiredToken(err.resultStatus));
        break;
      case 115:
        dispatch(getApiFailure(err.resultStatus));
        throw err;
      default:
        console.error(err);
        break;
    }
    dispatch(getApiFailure(err));
  }
};
