import { Button } from "@material-ui/core";
import styled from "styled-components";

const MuiPrimaryButton = styled(Button)`
  background-color: ${props => props.theme.palette.primary.main};
  &:hover {
    background-color: ${props => props.theme.palette.primary.dark};
  }
  color: ${props => props.theme.palette.text.secondary};
`;

export default MuiPrimaryButton;
