import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    id: string;
    file: any;
    text: string;
    val: string;
    playTime: number;
  };
};

const initialState: FormValueState = {
  formValue: {
    id: "",
    file: null,
    text: "",
    val: "",
    playTime: 0,
  },
};

const speechAddFormModule = createSlice({
  name: "speechForm",
  initialState,
  reducers: {
    setAddSpeechFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.id = payload.formValue.id ? payload.formValue.id : "";
      state.formValue.file = payload.formValue.file
        ? payload.formValue.file
        : null;
      state.formValue.text = payload.formValue.text
        ? payload.formValue.text
        : "";
      state.formValue.val = payload.formValue.val ? payload.formValue.val : "";
      state.formValue.playTime = payload.formValue.playTime
        ? payload.formValue.playTime
        : 0;
    },
    setAddSpeechFormInitialValue(state: FormValueState) {
      state.formValue = initialState.formValue;
    },
  },
});

export const {
  setAddSpeechFormValue,
  setAddSpeechFormInitialValue,
} = speechAddFormModule.actions;

export default speechAddFormModule.reducer;
