import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    robotTypeId: string;
    name: string;
    motions: string[];
    remark: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    robotTypeId: "",
    name: "",
    motions: [],
    remark: "",
  },
};

const robotTypeEditFormModule = createSlice({
  name: "robotTypeEditForm",
  initialState,
  reducers: {
    setEditFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.robotTypeId = payload.formValue.robotTypeId;
      state.formValue.name = payload.formValue.name;
      state.formValue.motions = payload.formValue.motions;
      state.formValue.remark = payload.formValue.remark;
    },
    setEditFormInitialValue(state: FormValueState) {
      state.formValue = initialState.formValue;
    },
  },
});

export const {
  setEditFormValue,
  setEditFormInitialValue,
} = robotTypeEditFormModule.actions;

export default robotTypeEditFormModule.reducer;
