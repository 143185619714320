import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    id: string;
    name: string;
    amount: number | string;
    tax: number;
    remark: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    id: "",
    name: "",
    amount: 0,
    tax: 0,
    remark: "",
  },
};

const paymentItemAddFormModule = createSlice({
  name: "paymentForm",
  initialState,
  reducers: {
    setAddFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.id = payload.formValue.id;
      state.formValue.name = payload.formValue.name;
      state.formValue.amount = payload.formValue.amount;
      state.formValue.tax = payload.formValue.tax;
      state.formValue.remark = payload.formValue.remark;
    },
    setAddFormInitialValue(state: FormValueState) {
      state.formValue = initialState.formValue;
    },
  },
});

export const {
  setAddFormValue,
  setAddFormInitialValue,
} = paymentItemAddFormModule.actions;

export default paymentItemAddFormModule.reducer;
