import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    email: string;
    avatar: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    email: "",
    avatar: "",
  },
};

const profileFormModule = createSlice({
  name: "profileForm",
  initialState,
  reducers: {
    setProfileFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.email = payload.formValue.email;
      state.formValue.avatar = payload.formValue.avatar;
    },
  },
});

export const { setProfileFormValue } = profileFormModule.actions;

export default profileFormModule.reducer;
