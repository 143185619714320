import React, { useContext, useState } from "react";
import styled from "styled-components";
import clsx from "clsx";
import HomeIcon from "@material-ui/icons/Home";
import SettingsIcon from "@material-ui/icons/Settings";
import PaymentIcon from "@material-ui/icons/Payment";
import PersonalVideoIcon from "@material-ui/icons/PersonalVideo";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import InfoIcon from "@material-ui/icons/Info";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { RoutePath } from "../../../../Routes";
import { RootState } from "../../../../reducers/rootReducer";
import { drawerWidth } from "./NavHeader";
import { SidebarContext } from "../../templates/Layout";

const ToolbarIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: "0 8px";
  ${props => props.theme.mixins.toolbar};
`;

const StyledNestedListItem: any = styled(ListItem)`
  padding-left: ${props => `${props.theme.spacing(9)}px`};
  font-size: 10px;
`;

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const Sidebar = () => {
  const classes = useStyles();
  const { isOpen, setIsOpen } = useContext(SidebarContext);
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [isAnalysisOpen, setIsAnalysisOpen] = useState(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [isMonitorOpen, setIsMonitorOpen] = useState(false);
  const [isTimeleapOpen, setIsTimeleapOpen] = useState(false);

  const { userType, auth } = useSelector((state: RootState) => state.auth);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    setIsAdminOpen(false);
    setIsPaymentOpen(false);
    setIsMonitorOpen(false);
    setIsTimeleapOpen(false);
    setIsAnalysisOpen(false);
  };

  const handleAdminClick = () => {
    setIsAdminOpen(!isAdminOpen);
    setIsOpen(true);
  };

  const handlePaymentClick = () => {
    setIsPaymentOpen(!isPaymentOpen);
    setIsOpen(true);
  };

  const handleMonitorClick = () => {
    setIsMonitorOpen(!isMonitorOpen);
    setIsOpen(true);
  };

  const handleTimeleapClick = () => {
    setIsTimeleapOpen(!isTimeleapOpen);
    setIsOpen(true);
  };

  const handleAnalysisClick = () => {
    setIsAnalysisOpen(!isAnalysisOpen);
    setIsOpen(true);
  };

  return (
    <Drawer
      variant="permanent"
      open={isOpen}
      classes={{
        paper: clsx(classes.drawerPaper, !isOpen && classes.drawerPaperClose),
      }}
    >
      <ToolbarIcon>
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </ToolbarIcon>
      <Divider />
      <List>
        <ListItem button component={Link} to={RoutePath.top}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="トップページ" />
        </ListItem>
        {userType === 0 && (
          <>
            <ListItem button onClick={handleAdminClick}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="管理画面" />
              {isAdminOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={isAdminOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {auth === 2 ? (
                  <>
                    <StyledNestedListItem
                      button
                      component={Link}
                      to={RoutePath.management.users}
                    >
                      <ListItemText primary="遠隔スタッフ管理" />
                    </StyledNestedListItem>
                    <StyledNestedListItem
                      button
                      component={Link}
                      to={RoutePath.management.shops}
                    >
                      <ListItemText primary="店舗管理" />
                    </StyledNestedListItem>
                  </>
                ) : (
                  <>
                    <StyledNestedListItem
                      button
                      component={Link}
                      to={RoutePath.management.users}
                    >
                      <ListItemText primary="遠隔スタッフ管理" />
                    </StyledNestedListItem>
                    <StyledNestedListItem
                      button
                      component={Link}
                      to={RoutePath.management.rooms}
                    >
                      <ListItemText primary="ルーム管理" />
                    </StyledNestedListItem>
                    <StyledNestedListItem
                      button
                      component={Link}
                      to={RoutePath.management.shops}
                    >
                      <ListItemText primary="店舗管理" />
                    </StyledNestedListItem>
                    <StyledNestedListItem
                      button
                      component={Link}
                      to={RoutePath.management.media}
                    >
                      <ListItemText primary="メディアライブラリ" />
                    </StyledNestedListItem>
                    <StyledNestedListItem
                      button
                      component={Link}
                      to={RoutePath.management.analysisInfo}
                    >
                      <ListItemText primary="分析情報管理" />
                    </StyledNestedListItem>
                    <StyledNestedListItem
                      button
                      component={Link}
                      to={RoutePath.management.notifications}
                    >
                      <ListItemText primary="通知ボタン管理" />
                    </StyledNestedListItem>
                    <StyledNestedListItem button onClick={handlePaymentClick}>
                      <ListItemIcon>
                        <PaymentIcon />
                      </ListItemIcon>
                      <ListItemText primary="決済管理" />
                      {isPaymentOpen ? <ExpandLess /> : <ExpandMore />}
                    </StyledNestedListItem>
                    <Collapse in={isPaymentOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <StyledNestedListItem
                          button
                          component={Link}
                          to={RoutePath.management.paymentView}
                        >
                          <ListItemText primary="決済履歴" />
                        </StyledNestedListItem>
                        <StyledNestedListItem
                          button
                          component={Link}
                          to={RoutePath.management.paymentItems}
                        >
                          <ListItemText primary="商品一覧" />
                        </StyledNestedListItem>
                        <StyledNestedListItem
                          button
                          component={Link}
                          to={RoutePath.management.paymentSettings}
                        >
                          <ListItemText primary="決済設定" />
                        </StyledNestedListItem>
                      </List>
                    </Collapse>
                    <StyledNestedListItem button onClick={handleMonitorClick}>
                      <ListItemIcon>
                        <PersonalVideoIcon />
                      </ListItemIcon>
                      <ListItemText primary="モニター管理" />
                      {isMonitorOpen ? <ExpandLess /> : <ExpandMore />}
                    </StyledNestedListItem>
                    <Collapse in={isMonitorOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <StyledNestedListItem
                          button
                          component={Link}
                          to={RoutePath.management.camera}
                        >
                          <ListItemText primary="サポートカメラ管理" />
                        </StyledNestedListItem>
                        <StyledNestedListItem
                          button
                          component={Link}
                          to={RoutePath.management.monitor}
                        >
                          <ListItemText primary="モニターカメラ管理" />
                        </StyledNestedListItem>
                      </List>
                    </Collapse>
                    {(auth === 1 || auth === 0) && (
                      <>
                        <StyledNestedListItem
                          button
                          onClick={handleTimeleapClick}
                        >
                          <ListItemIcon>
                            <FlightTakeoffIcon />
                          </ListItemIcon>
                          <ListItemText primary="管理者専用" />
                          {isTimeleapOpen ? <ExpandLess /> : <ExpandMore />}
                        </StyledNestedListItem>
                        <Collapse
                          in={isTimeleapOpen}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            <StyledNestedListItem
                              button
                              component={Link}
                              to={RoutePath.management.timeleap.companies}
                            >
                              <ListItemText primary="カンパニー管理" />
                            </StyledNestedListItem>
                            <StyledNestedListItem
                              button
                              component={Link}
                              to={RoutePath.management.timeleap.robots}
                            >
                              <ListItemText primary="ロボット管理" />
                            </StyledNestedListItem>
                            <StyledNestedListItem
                              button
                              component={Link}
                              to={RoutePath.management.timeleap.roomGroups}
                            >
                              <ListItemText primary="ルームグループ管理" />
                            </StyledNestedListItem>
                            <StyledNestedListItem
                              button
                              component={Link}
                              to={RoutePath.management.timeleap.motions}
                            >
                              <ListItemText primary="モーション管理" />
                            </StyledNestedListItem>
                            <StyledNestedListItem
                              button
                              component={Link}
                              to={RoutePath.management.timeleap.robotTypes}
                            >
                              <ListItemText primary="ロボットタイプ管理" />
                            </StyledNestedListItem>
                            <StyledNestedListItem
                              button
                              component={Link}
                              to={RoutePath.management.timeleap.actions}
                            >
                              <ListItemText primary="アクション管理" />
                            </StyledNestedListItem>
                            <StyledNestedListItem
                              button
                              component={Link}
                              to={RoutePath.management.timeleap.pcs}
                            >
                              <ListItemText primary="PC情報管理" />
                            </StyledNestedListItem>
                          </List>
                        </Collapse>
                      </>
                    )}
                  </>
                )}
              </List>
            </Collapse>
          </>
        )}
        {auth !== 2 && (
          <>
            <ListItem button onClick={handleAnalysisClick}>
              <ListItemIcon>
                <TrendingUpIcon />
              </ListItemIcon>
              <ListItemText primary="分析画面" />
              {isAnalysisOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={isAnalysisOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {userType === 0 && auth !== 2 && (
                  <>
                    <StyledNestedListItem
                      button
                      component={Link}
                      to={RoutePath.analysis.dashboard}
                    >
                      <ListItemText primary="ダッシュボード" />
                    </StyledNestedListItem>
                    <StyledNestedListItem
                      button
                      component={Link}
                      to={RoutePath.analysis.shop}
                    >
                      <ListItemText primary="店舗毎分析" />
                    </StyledNestedListItem>
                    <StyledNestedListItem
                      button
                      component={Link}
                      to={RoutePath.analysis.workers}
                    >
                      <ListItemText primary="遠隔スタッフ分析" />
                    </StyledNestedListItem>
                  </>
                )}

                <StyledNestedListItem
                  button
                  component={Link}
                  to={RoutePath.analysis.customerService}
                >
                  <ListItemText primary="接客分析" />
                </StyledNestedListItem>
              </List>
            </Collapse>
          </>
        )}
        <ListItem
          button
          component="a"
          href="https://timeleap.co.jp/privacy-policy/"
        >
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="プライバシーポリシー" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
