/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Popover,
  Whisper,
  Modal,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Schema,
  Alert,
  List,
  TagPicker,
} from "rsuite";
import { FormInstance } from "rsuite/lib/Form";
import { FormControlLabel, Typography, Checkbox } from "@material-ui/core";
import {
  StyledPrimaryButton,
  StyledCancelButton,
  StyledEditButton,
  StyledDeleteButton,
  StyledModal,
  StyledButtons,
} from "./Users";
import { RootState } from "../../../../reducers/rootReducer";
import Title from "../../../presentational/atoms/Text/Title";
import { fetchRooms } from "../../../../modules/Management/Rooms/roomsApiModule";
import { setEditFormValue } from "../../../../modules/Management/Form/Room/roomEditFormModule";
import { putRoom } from "../../../../modules/Management/Rooms/updateRoomApiModule";
import { setCheckedKeys } from "../../../../modules/Management/checkModule";
import { delRoom } from "../../../../modules/Management/Rooms/deleteRoomApiModule";
import { delRooms } from "../../../../modules/Management/Rooms/deleteRoomsApiModule";
import RoomsTable from "../../../presentational/molecules/Table/RoomsTable";
import { Margin } from "../../../../utils/styled";
import Loading from "../../../presentational/molecules/Loading";
import { fetchPcs } from "../../../../modules/Management/Pcs/pcApiModule";
import { setAddFormValue } from "../../../../modules/Management/Form/Room/roomAddFormModule";
import { postRoom } from "../../../../modules/Management/Rooms/addRoomApiModule";

const addModel = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  name: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  pcIds: Schema.Types.ArrayType().isRequired("必須項目です"),
});

const editModel = Schema.Model({
  name: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  pcIds: Schema.Types.ArrayType().isRequired("必須項目です"),
});

const Rooms = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [addForm, setAddForm] = useState<FormInstance>();

  const [isLite, setIsLite] = useState(false);

  const [showEdit, setShowEdit] = useState(false);
  const [editForm, setEditForm] = useState<FormInstance>();

  const [showDelete, setShowDelete] = useState(false);

  const [roomTypeId, setRoomTypeId] = useState<string | null>(null);

  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.auth);

  const { data, isLoading, error: roomsError } = useSelector(
    (state: RootState) => state.rooms
  );
  const { data: pcsData, error: pcsError } = useSelector(
    (state: RootState) => state.pcs
  );

  const pcIds = useMemo(() => {
    return pcsData?.pcs.map(value => {
      return {
        label: value.name,
        value: value.pcId,
      };
    });
  }, [pcsData]);

  const { checks } = useSelector((state: RootState) => state.checkCells);
  const checkedKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "rooms")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );

  const { formValue: addFormValue } = useSelector(
    (state: RootState) => state.roomAddFormn
  );

  const { formValue: editFormValue } = useSelector(
    (state: RootState) => state.roomEditForm
  );

  useEffect(() => {
    const fetchData = () => {
      if (token) {
        dispatch(fetchRooms(token));
        dispatch(fetchPcs(token));
      }
    };
    fetchData();
  }, [dispatch, token]);

  const handleAddClick = () => {
    setShowAdd(true);
  };

  const handleAddFormChange = (value: any) => {
    dispatch(setAddFormValue({ formValue: value }));
  };

  const handleCheckAndSubmitAddForm = async () => {
    const checkResult = addForm!.check();
    if (checkResult && token) {
      try {
        await dispatch(
          postRoom(
            token,
            addFormValue.id,
            addFormValue.name,
            addFormValue.pcIds,
            addFormValue.deusGroupNo,
            isLite,
            addFormValue.memo
          )
        );
        dispatch(
          setAddFormValue({
            formValue: {
              id: "",
              name: "",
              pcIds: [],
              deusGroupNo: 0,
              liteFlg: 1,
              memo: "",
            },
          })
        );
        Alert.success("ルームを追加しました", 5000);
        setShowAdd(false);
        setIsLite(false);
        await dispatch(fetchRooms(token));
      } catch (err) {
        Alert.error("入力したIDはすでに登録されています", 2000);
      }
    }
  };

  const handleEditClick = useCallback(() => {
    const roomData = data?.rooms.filter(
      room => room.roomId === checkedKeys[0]
    )[0];

    if (roomData === undefined) return;

    dispatch(
      setEditFormValue({
        formValue: {
          id: checkedKeys[0],
          name: roomData?.name,
          pcIds: roomData?.pcId,
          liteFlg: roomData?.liteFlg,
          deusGroupNo: roomData?.deusGroupNo,
          memo: roomData?.remark,
        },
      })
    );
    setRoomTypeId(roomData.roomTypeId);
    setIsLite(roomData.liteFlg ?? false);
    setShowEdit(true);
  }, [checkedKeys, data, dispatch]);

  const handleEditFormChange = (value: any) => {
    dispatch(setEditFormValue({ formValue: value }));
  };

  const handleCheckAndSubmitEditForm = async () => {
    const checkResult = editForm!.check();
    if (checkResult && token) {
      await dispatch(
        putRoom(
          token,
          editFormValue.id ? editFormValue.id : "",
          editFormValue.name ? editFormValue.name : "",
          editFormValue.pcIds ? editFormValue.pcIds : [],
          editFormValue.memo ? editFormValue.memo : "",
          isLite,
          roomTypeId
        )
      );
      Alert.success("ルーム情報を更新しました", 5000);
      dispatch(
        setCheckedKeys({
          id: "rooms",
          checkInfo: { checked: false, checkedKeys: [], indeterminate: false },
        })
      );
      await dispatch(fetchRooms(token));
      setIsLite(false);
      setShowEdit(false);
    }
  };

  const handleDeleteClick = () => {
    setShowDelete(true);
  };

  const handleSubmitDelete = async () => {
    if (token) {
      try {
        if (checkedKeys.length === 1) {
          await dispatch(delRoom(token, checkedKeys[0]));
        } else {
          await dispatch(delRooms(token, checkedKeys));
        }
        dispatch(
          setCheckedKeys({
            id: "rooms",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        setShowDelete(false);
        Alert.success("削除しました", 5000);
        await dispatch(fetchRooms(token));
      } catch (err) {
        Alert.error("不明なエラーが発生しました", 2000);
      }
    }
  };

  const onCancelClick = () => {
    setIsLite(false);
    setShowAdd(false);
    setShowEdit(false);
    setShowDelete(false);
  };

  const editSpeaker = () => {
    if (checkedKeys.length > 1) {
      return (
        <Popover title="編集について">
          <p>一度に編集できるルームは1つです</p>
          <p>チェックを外してください</p>
        </Popover>
      );
    }

    return (
      <Popover title="編集について">
        <p>編集したいルームにチェックをつけてください</p>
      </Popover>
    );
  };

  const deleteSpeaker = () => {
    return (
      <Popover title="削除について">
        <p>削除したいルームにチェックをつけてください</p>
        <p>※複数可</p>
      </Popover>
    );
  };

  if (roomsError === 115 || pcsError === 115) {
    return (
      <>
        <Title>ルーム管理</Title>
        <Margin top={20}>
          <div>このページを閲覧する権限がありません</div>
        </Margin>
      </>
    );
  }

  return (
    <>
      <Typography variant="h6">ルーム管理</Typography>
      <Margin top={20}>
        {!isLoading ? (
          data && data !== null && pcIds && pcIds !== null ? (
            <>
              <StyledButtons>
                <StyledPrimaryButton
                  variant="contained"
                  color="primary"
                  onClick={handleAddClick}
                >
                  追加
                </StyledPrimaryButton>
                {checkedKeys.length > 0 && checkedKeys.length < 2 ? (
                  <StyledEditButton
                    variant="contained"
                    color="primary"
                    isChecked
                    onClick={handleEditClick}
                  >
                    編集
                  </StyledEditButton>
                ) : (
                  <Whisper
                    placement="autoVerticalStart"
                    trigger="hover"
                    speaker={editSpeaker()}
                  >
                    <StyledEditButton
                      variant="contained"
                      color="primary"
                      isChecked={false}
                    >
                      編集
                    </StyledEditButton>
                  </Whisper>
                )}
                {checkedKeys.length > 0 ? (
                  <StyledDeleteButton
                    variant="contained"
                    color="primary"
                    isChecked
                    onClick={handleDeleteClick}
                  >
                    削除
                  </StyledDeleteButton>
                ) : (
                  <Whisper
                    placement="autoVerticalStart"
                    trigger="hover"
                    speaker={deleteSpeaker()}
                  >
                    <StyledDeleteButton
                      variant="contained"
                      color="primary"
                      isChecked={false}
                    >
                      削除
                    </StyledDeleteButton>
                  </Whisper>
                )}
              </StyledButtons>
              <Margin top={10} />
              <RoomsTable rooms={data.rooms} pcs={pcIds} />
            </>
          ) : (
            <>
              <div>ルームはありません</div>
              <StyledPrimaryButton onClick={handleAddClick}>
                追加
              </StyledPrimaryButton>
            </>
          )
        ) : (
          <Loading />
        )}
      </Margin>

      <StyledModal show={showAdd} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            ルーム追加
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={addModel}
            formValue={addFormValue}
            ref={(ref: FormInstance) => setAddForm(ref)}
            onChange={handleAddFormChange}
          >
            <FormGroup>
              <ControlLabel>ルームID</ControlLabel>
              <FormControl name="id" placeholder="room1" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>ルーム名</ControlLabel>
              <FormControl name="name" placeholder="ルーム１" />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Checkbox
                    size="small"
                    onClick={() => {
                      setIsLite(!isLite);
                    }}
                    name="liteFlg"
                    value={isLite}
                    defaultChecked={isLite}
                  />
                }
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Typography style={{ fontSize: "14px" }}>
                    Lite用として設定する
                  </Typography>
                }
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>所属店舗</ControlLabel>
              <FormControl
                accepter={TagPicker}
                block
                data={pcIds || [{ label: "", value: "" }]}
                name="pcIds"
                placeholder="所属店舗を選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>deusグループNo</ControlLabel>
              <FormControl name="deusGroupNo" placeholder="1" type="number" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>メモ</ControlLabel>
              <FormControl name="memo" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <StyledPrimaryButton onClick={handleCheckAndSubmitAddForm}>
            登録
          </StyledPrimaryButton>
          <StyledCancelButton onClick={onCancelClick}>
            取り消し
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showEdit} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            ルーム編集
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={editModel}
            formValue={editFormValue}
            ref={(ref: FormInstance) => setEditForm(ref)}
            onChange={handleEditFormChange}
          >
            <FormGroup>
              <ControlLabel>ルームID</ControlLabel>
              <FormControl readOnly name="id" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>ルーム名</ControlLabel>
              <FormControl name="name" />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Checkbox
                    size="small"
                    onClick={() => {
                      setIsLite(!isLite);
                    }}
                    name="liteFlg"
                    value={isLite}
                    defaultChecked={isLite}
                  />
                }
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Typography style={{ fontSize: "14px" }}>
                    Lite用として設定する
                  </Typography>
                }
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>所属店舗</ControlLabel>
              <FormControl
                accepter={TagPicker}
                block
                data={pcIds || [{ label: "", value: "" }]}
                name="pcIds"
                placeholder="所属店舗を選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>メモ</ControlLabel>
              <FormControl name="memo" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <StyledPrimaryButton onClick={handleCheckAndSubmitEditForm}>
            登録
          </StyledPrimaryButton>
          <StyledCancelButton onClick={onCancelClick}>
            取り消し
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showDelete} overflow onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            ルーム削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List bordered={false}>
            {checkedKeys
              .map(key => {
                return data?.rooms
                  .filter(room => room.roomId === key)
                  .map(value => value.name)[0];
              })
              .map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{ paddingLeft: "10px", color: "#d84421" }}
                >
                  {item}
                </List.Item>
              ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: "#d84421",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記のルームを削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          <StyledDeleteButton isChecked onClick={handleSubmitDelete}>
            削除
          </StyledDeleteButton>
          <StyledCancelButton onClick={onCancelClick}>
            取り消し
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>
    </>
  );
};

export default Rooms;
