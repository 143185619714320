import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    id: string;
    imageName: string;
    img: any;
    remark: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    id: "",
    imageName: "",
    img: null,
    remark: "",
  },
};

const imageAddFormModule = createSlice({
  name: "imageForm",
  initialState,
  reducers: {
    setAddImageFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.id = payload.formValue.id ? payload.formValue.id : "";
      state.formValue.img = payload.formValue.img
        ? payload.formValue.img
        : null;
      state.formValue.imageName = payload.formValue.imageName;
      state.formValue.remark = payload.formValue.remark
        ? payload.formValue.remark
        : "";
    },
  },
});

export const { setAddImageFormValue } = imageAddFormModule.actions;

export default imageAddFormModule.reducer;
