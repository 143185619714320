import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import Title from "../../../presentational/atoms/Text/Title";
import SelectBox from "../../../presentational/molecules/SelectBox";
import { Margin } from "../../../../utils/styled";
import MultipleDatePicker from "../../../presentational/molecules/DatePicker";
import Table from "../../../presentational/molecules/Table/WorkersTable";
import { fetchWorkers } from "../../../../modules/Analysis/workersApiModule";
import { setWorkers } from "../../../../modules/Analysis/workers";
import { RootState } from "../../../../reducers/rootReducer";
import { primaryBlue } from "../../../../utils/color";

const Wrapper = styled.div`
  font-size: 12px;
  display: flex;
  flex: row;
  justify-content: space-between;
`;

const StartWrapper = styled.div`
  display: flex;
  flex: row;
  width: 100%;
  justify-content: flex-start;
`;

const EndWrapper = styled.div`
  display: flex;
  flex: row;
  width: 100%;
  justify-content: flex-end;
`;

const SelectWrapper = styled.div`
  width: 50%;
  font-size: 12px;
  margin-right: 10px;
`;

const CsvWrapper = styled.div`
  margin-left: 10px;
  text-decoration: none;
`;

const StyledCsv = styled(CSVLink)`
  display: inline-block;
  background-color: ${primaryBlue};
  color: #fff;
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1;
  text-decoration: none !important;
  letter-spacing: 0.05em;
  padding: 0.4em 1em;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  -webkit-tap-highlight-color: transparent;
  transition: 0.3s ease-out;
  &:hover {
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px 0px rgba(0, 0, 0, 0.2);
    color: #fff;
  }
`;

const Workers = () => {
  const dispatch = useDispatch();

  const { token } = useSelector((state: RootState) => state.auth);

  const { shops } = useSelector((state: RootState) => state.shop);
  const selectedShops = useMemo(
    () => shops.filter(value => value.id === "workers").map(shop => shop.shop),
    [shops]
  );

  const { picks } = useSelector((state: RootState) => state.datePick);
  const { startDate: wkStartDate, endDate: wkEndDate } = picks.filter(value => {
    return value.id === "workers";
  })[0];

  const { data } = useSelector((state: RootState) => state.workersApi);

  const csvData = useMemo(() => {
    return data?.map(worker => {
      return {
        name: worker.userName,
        totalServedCount: worker.totalServedCount,
        totalServedTime: worker.totalServedTime,
        logTime: worker.logTime,
        restTime: worker.restTime,
        speechCount: worker.speechCount,
        motionCount: worker.motionCount,
        imageCount: worker.imageCount,
        videoCount: worker.videoCount,
      };
    });
  }, [data]);

  useEffect(() => {
    const fetchData = () => {
      if (token) {
        const pcIds: string[] = selectedShops[0].map(item => {
          return item.value;
        });
        dispatch(fetchWorkers(token, wkStartDate, wkEndDate, pcIds));
      }
    };

    fetchData();
  }, [dispatch, selectedShops, token, wkEndDate, wkStartDate]);

  useEffect(() => {
    if (data) {
      const workers = data.map(worker => {
        return {
          userId: worker.userId,
          userName: worker.userName,
        };
      });
      dispatch(setWorkers(workers));
    }
  });

  return (
    <>
      <Title>遠隔スタッフ分析</Title>
      <Margin top={20} bottom={15}>
        <Wrapper>
          <StartWrapper>
            <SelectWrapper>
              <SelectBox id="workers" />
            </SelectWrapper>
          </StartWrapper>
          <EndWrapper>
            <MultipleDatePicker id="workers" placement="bottomEnd" />
            <CsvWrapper>
              {csvData ? (
                <StyledCsv data={csvData} filename="workers-data.csv">
                  CSV出力
                </StyledCsv>
              ) : null}
            </CsvWrapper>
          </EndWrapper>
        </Wrapper>
      </Margin>
      {data ? <Table data={data} /> : <div />}
    </>
  );
};

export default Workers;
