import React, { VFC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Schema,
  SelectPicker,
} from "rsuite";
import { FormInstance } from "rsuite/lib/Form";
import { setAddFormValue } from "../../../../modules/Management/Form/NotificationButton/notificationButtonAddFormModule";
import { RootState } from "../../../../reducers/rootReducer";
import {
  StyledCancelButton,
  StyledPrimaryButton,
} from "../../../container/templates/Management/AnalysisInfo";
import { StyledModal } from "../../../container/templates/Management/Users";

type Props = {
  show: boolean;
  onHide: () => void;
  setForm: any;
  submitNotificationButton: () => void;
};

const addModel = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  val: Schema.Types.StringType().isRequired("必須項目です"),
  viewData: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
});

export const AddNotificationButtonModal: VFC<Props> = props => {
  const { show, onHide, setForm, submitNotificationButton } = props;

  const dispatch = useDispatch();

  const { formValue: addFormValue } = useSelector(
    (state: RootState) => state.notificationButtonAddForm
  );

  const handleAddFormChange = (value: any) => {
    dispatch(setAddFormValue({ formValue: value }));
  };
  return (
    <StyledModal show={show} overflow={false} onHide={() => onHide()}>
      <Modal.Header>
        <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
          通知ボタン追加
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          fluid
          model={addModel}
          formValue={addFormValue}
          ref={(ref: FormInstance) => setForm(ref)}
          onChange={handleAddFormChange}
        >
          <FormGroup>
            <ControlLabel>通知ボタンID</ControlLabel>
            <FormControl name="id" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>通知ボタンの値</ControlLabel>
            <FormControl name="val" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>表示データ</ControlLabel>
            <FormControl name="viewData" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>ボタンタイプ</ControlLabel>
            <FormControl
              accepter={SelectPicker}
              searchable
              data={[
                {
                  label: "androidボタン",
                  value: 2,
                },
                {
                  label: "物理ボタン",
                  value: 3,
                },
                {
                  label: "フロントデザインボタン",
                  value: 4,
                },
              ]}
              name="buttonType"
              placeholder="ボタンタイプを選択してください"
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>備考</ControlLabel>
            <FormControl name="remark" />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <StyledPrimaryButton
          onClick={() => {
            submitNotificationButton();
          }}
        >
          OK
        </StyledPrimaryButton>
        <StyledCancelButton onClick={() => onHide()}>Cancel</StyledCancelButton>
      </Modal.Footer>
    </StyledModal>
  );
};
