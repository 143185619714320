import React from "react";
import styled, { css } from "styled-components";
import { Row, Col, Whisper } from "rsuite";
import { Image } from "../../../../api/Management/imageApi";
import { primaryBlue } from "../../../../utils/color";
import { StyledCheckbox } from "./VideoMedia";
import { descImageSpeaker } from "../Speaker";

export const ImgWrapper = styled.div<{ isChecked: boolean | undefined }>`
  ${({ isChecked }) => {
    return css`
      ${isChecked
        ? css`
            border: 3px solid ${primaryBlue};
            transition: all 0.2s;
          `
        : `
        border: 3px solid transparent;
        transition: all 0.2s;
        `}
      width: 100%;
      height: 200px;
      position: "relative";
      box-sizing: border-box;
      transition: all 0.2s;
      &:hover {
        border: 3px solid ${primaryBlue};
        transition: all 0.2s;
        cursor: pointer;
      }
    `;
  }}
`;

export const StyledImg = styled.img`
  width: 100%;
  height: 165px;
  padding: 5px;
  object-fit: contain;
`;

const ImageMedia = ({
  imagesData,
  checkedImageKeys,
  handleImageCheck,
}: {
  imagesData: Image[] | undefined | null;
  checkedImageKeys: string[] | undefined;
  handleImageCheck: (value: string, isChecked: boolean) => void;
}) => {
  return (
    <Row>
      {imagesData !== undefined &&
      imagesData !== null &&
      imagesData.length > 0 ? (
        imagesData.map((image, index) => {
          return (
            <Col
              md={6}
              sm={12}
              key={index.toString()}
              style={{
                boxSizing: "border-box",
                padding: "10px",
                border: "1px solid rgba(0, 0, 0, 0.4",
              }}
            >
              <Whisper
                placement="autoVerticalStart"
                speaker={descImageSpeaker(image)}
              >
                <ImgWrapper
                  isChecked={checkedImageKeys?.some(
                    (item: any) => item === image.imageId
                  )}
                  onClick={() => {
                    const isChecked = checkedImageKeys?.some(
                      (item: any) => item === image.imageId
                    );
                    handleImageCheck(image.imageId, !isChecked);
                  }}
                >
                  <div>
                    ID:
                    {image.imageId}
                  </div>
                  <StyledImg src={image.image} alt={image.imageId} />
                  <StyledCheckbox
                    value={image.imageId}
                    checked={checkedImageKeys?.some(
                      (item: any) => item === image.imageId
                    )}
                  />
                </ImgWrapper>
              </Whisper>
            </Col>
          );
        })
      ) : (
        <div>画像はありません</div>
      )}
    </Row>
  );
};

export default ImageMedia;
