import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    id?: string;
    name?: string;
    auth?: string | number;
    userType?: string | number;
    roomGroupId?: string;
    memo?: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    id: "",
    name: "",
    auth: "",
    userType: "",
    roomGroupId: "",
    memo: "",
  },
};

const userEditFormModule = createSlice({
  name: "userForm",
  initialState,
  reducers: {
    setEditFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.id = payload.formValue.id;
      state.formValue.name = payload.formValue.name
        ? payload.formValue.name
        : "";
      state.formValue.auth =
        payload.formValue.auth !== null ? payload.formValue.auth : "";
      state.formValue.userType =
        payload.formValue.userType !== null ? payload.formValue.userType : "";
      state.formValue.roomGroupId = payload.formValue.roomGroupId
        ? payload.formValue.roomGroupId
        : "";
      state.formValue.memo = payload.formValue.memo
        ? payload.formValue.memo
        : "";
    },
  },
});

export const { setEditFormValue } = userEditFormModule.actions;

export default userEditFormModule.reducer;
