import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { Row, Col, Modal, List, Alert } from "rsuite";
import { RootState } from "../../../../../reducers/rootReducer";
import { fetchCameras } from "../../../../../modules/Management/Monitor/cameraApiModule";
import Title from "../../../../presentational/atoms/Text/Title";
import { Margin } from "../../../../../utils/styled";
import {
  StyledDeleteButton,
  StyledPrimaryButton,
  StyledCancelButton,
} from "../Users";
import Loading from "../../../../presentational/molecules/Loading";
import { setCheckedKeys } from "../../../../../modules/Management/checkModule";
import { StyledCheckbox } from "../../../../presentational/molecules/Media/VideoMedia";
import { primaryBlue } from "../../../../../utils/color";
import { delCamera } from "../../../../../modules/Management/Monitor/deleteCameraApiModule";
import { delCameras } from "../../../../../modules/Management/Monitor/deleteCamerasApiModule";

export const ImgWrapper = styled.div<{ isChecked: boolean }>`
  ${({ isChecked }) => {
    return css`
      ${isChecked
        ? css`
            border: 3px solid ${primaryBlue};
            transition: all 0.2s;
          `
        : `
        border: 3px solid transparent;
        transition: all 0.2s;
        `}
      width: 100%;
      height: 230px;
      position: "relative";
      box-sizing: border-box;
      transition: all 0.2s;
      &:hover {
        border: 3px solid ${primaryBlue};
        transition: all 0.2s;
        cursor: pointer;
      }
    `;
  }}
`;

const StyledTabs = styled(Tabs)`
  background: #fff;
  border-radius: 5px 5px 0 0;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 165px;
  padding: 5px;
  object-fit: contain;
`;

const StyledFooter = styled.div`
  background: #fff;
  width: 100%;
  height: 100px;
  padding-top: 30px;
  border-radius: 0 0 5px 5px;
  text-align: center;
`;

const Camera = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);

  const [showDeleteImage, setShowDeleteImage] = useState(false);

  const { token } = useSelector((state: RootState) => state.auth);
  const { data, isLoading } = useSelector((state: RootState) => state.camera);
  const { checks } = useSelector((state: RootState) => state.checkCells);
  const checkedCameraKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "cameras")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );
  const dataKeys = useMemo(() => {
    if (data) {
      return Object.keys(data).filter(value => value !== "tokenExpiration");
    }
    return [];
  }, [data]);
  const camerasData = useMemo(() => {
    if (data) {
      const cameras: any = [];
      dataKeys.forEach(key => {
        const cam = data[key].map(camera => {
          return {
            pcId: key,
            camera: camera.camera,
            imgUrl: camera.imgUrl,
            scpId: camera.scpId,
            time: camera.updTimestamp,
          };
        });
        cameras.push(cam);
      });
      return cameras;
    }
    return [];
  }, [data, dataKeys]);
  useEffect(() => {
    if (token) {
      dispatch(fetchCameras(token));
    }
  }, [dispatch, token]);

  const handleSelect = (index: number) => {
    setSelected(index);
  };

  const handleDeleteClick = () => {
    setShowDeleteImage(true);
  };

  const onCancelClick = () => {
    setShowDeleteImage(false);
  };

  const handleImageCheck = (value: any, isChecked: boolean) => {
    const nextCheckedKeys = isChecked
      ? [...checkedCameraKeys, value]
      : checkedCameraKeys.filter(item => item !== value);
    dispatch(
      setCheckedKeys({
        id: "cameras",
        checkInfo: {
          checked: false,
          checkedKeys: nextCheckedKeys,
          indeterminate: false,
        },
      })
    );
  };

  const handleSubmitDeleteImage = async () => {
    if (token) {
      try {
        setShowDeleteImage(false);
        Alert.info("サポートカメラ画像を削除中...", 100000000);
        if (checkedCameraKeys.length === 1) {
          await dispatch(delCamera(token, checkedCameraKeys[0]));
        } else {
          await dispatch(delCameras(token, checkedCameraKeys));
        }
        dispatch(
          setCheckedKeys({
            id: "cameras",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        Alert.close();
        Alert.success("削除しました", 5000);
        await dispatch(fetchCameras(token));
      } catch (err) {
        Alert.closeAll();
        Alert.error("不明なエラーが発生しました", 2000);
      }
    }
  };

  return (
    <>
      <Title>サポートカメラ管理</Title>
      <Margin top={20} />
      {!isLoading ? (
        <>
          <StyledTabs onSelect={handleSelect} selectedIndex={selected}>
            <TabList>
              {dataKeys.map((key, i) => (
                <Tab style={{ padding: "10px 20px" }} key={i.toString()}>
                  {key}
                </Tab>
              ))}
            </TabList>
            {dataKeys.map((key, idx) => {
              return (
                <TabPanel
                  style={{
                    overflowY: "scroll",
                    minHeight: "300px",
                    maxHeight: "500px",
                  }}
                  key={idx.toString()}
                >
                  <Row>
                    {camerasData ? (
                      camerasData.map((cameras: any) => {
                        const cm = cameras
                          .filter((value: any) => value.pcId === key)
                          .map((camera: any, index: number) => {
                            return (
                              <Col
                                md={6}
                                sm={12}
                                key={index.toString()}
                                style={{
                                  boxSizing: "border-box",
                                  padding: "10px",
                                  border: "1px solid rgba(0, 0, 0, 0.4",
                                }}
                              >
                                <ImgWrapper
                                  isChecked={checkedCameraKeys.some(
                                    (item: any) => item === camera.scpId
                                  )}
                                  onClick={() => {
                                    const isChecked = checkedCameraKeys.some(
                                      (item: any) => item === camera.scpId
                                    );
                                    handleImageCheck(camera.scpId, !isChecked);
                                  }}
                                >
                                  <p>{`カメラID: ${camera.camera} / 画像ID: ${camera.scpId}`}</p>
                                  <p>{camera.time}</p>
                                  <StyledImg
                                    src={camera.imgUrl}
                                    alt={camera.camera}
                                  />
                                  <StyledCheckbox
                                    value={camera.scpId}
                                    checked={checkedCameraKeys.some(
                                      (item: any) => item === camera.scpId
                                    )}
                                  />
                                </ImgWrapper>
                              </Col>
                            );
                          });
                        return cm;
                      })
                    ) : (
                      <div>なし</div>
                    )}
                  </Row>
                </TabPanel>
              );
            })}
          </StyledTabs>
          <StyledFooter>
            {checkedCameraKeys.length > 0 ? (
              <StyledDeleteButton isChecked onClick={handleDeleteClick}>
                削除
              </StyledDeleteButton>
            ) : (
              <StyledDeleteButton isChecked={false} onClick={handleDeleteClick}>
                削除
              </StyledDeleteButton>
            )}
            <p style={{ color: "red", fontSize: "12px", margin: "5px" }}>
              データの保存期間は三ヶ月です。三ヶ月経過したデータは完全に削除されます。
            </p>
          </StyledFooter>
        </>
      ) : (
        <Loading />
      )}

      <Modal show={showDeleteImage} overflow onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            画像削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List bordered={false}>
            {checkedCameraKeys
              .map(key => {
                return camerasData.map((cameras: any) =>
                  cameras
                    ?.filter((camera: any) => camera.scpId === key)
                    .map((value: any) => value.scpId)
                );
              })
              .map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{
                    paddingLeft: "10px",
                    color: "#d84421",
                  }}
                >
                  {item}
                </List.Item>
              ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: "#d84421",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記の画像を削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          <StyledPrimaryButton onClick={handleSubmitDeleteImage}>
            OK
          </StyledPrimaryButton>
          <StyledCancelButton onClick={onCancelClick}>
            Cancel
          </StyledCancelButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Camera;
