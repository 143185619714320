import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../stores";
import { ApiState, startLoading, loadingFailed } from "../../api/apiService";
import { ApiResponse } from "../../api/apiResponse";
import signUp from "../../api/singUpApi/add";

const apiInitialState: ApiState<ApiResponse> = {
  isLoading: false,
  error: null,
  data: null,
};

const signUpApiModule = createSlice({
  name: "signUp",
  initialState: apiInitialState,
  reducers: {
    getApiStart: startLoading,
    getApiSuccess(state, { payload }: PayloadAction<ApiResponse>) {
      state.isLoading = false;
      state.error = null;
      state.data = payload;
    },
    getApiFailure: loadingFailed,
  },
});

export const {
  getApiStart,
  getApiSuccess,
  getApiFailure,
} = signUpApiModule.actions;

export default signUpApiModule.reducer;

export const registerUser = (
  uuid: string,
  id: string,
  name: string,
  password: string
): AppThunk => async dispatch => {
  try {
    dispatch(getApiStart());
    const data = await signUp(uuid, id, name, password);
    dispatch(getApiSuccess(data));
  } catch (err) {
    switch (err.resultStatus) {
      case 105:
      case 110:
      case 111:
      case 115:
      case 134:
      case 140:
        dispatch(getApiFailure(err.resultStatus));
        throw err;
      default:
        dispatch(getApiFailure(err));
        console.error(err);
        throw err;
    }
  }
};
