import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FileInfo = {
  name: string;
  value: string;
  file: File | undefined;
};

const initialState: FileInfo = {
  name: "",
  value: "",
  file: undefined,
};

const fileInfoModule = createSlice({
  name: "fileInfo",
  initialState,
  reducers: {
    setSpeechFileInfo(state: FileInfo, { payload }: PayloadAction<FileInfo>) {
      state.name = payload.name;
      state.value = payload.value;
      state.file = payload.file;
    },
  },
});

export const { setSpeechFileInfo } = fileInfoModule.actions;

export default fileInfoModule.reducer;
