import React from "react";
import styled, { css } from "styled-components";
import { Row, Col, Whisper, Checkbox } from "rsuite";
import { Video } from "../../../../api/Management/videoApi";
import { descVideoSpeaker } from "../Speaker";
import { primaryBlue } from "../../../../utils/color";

export const ImgWrapper = styled.div<{ isChecked: boolean | undefined }>`
  ${({ isChecked }) => {
    return css`
      ${isChecked
        ? css`
            border: 3px solid ${primaryBlue};
            transition: all 0.2s;
          `
        : `
        border: 3px solid transparent;
        transition: all 0.2s;
        `}
      width: 100%;
      height: 200px;
      position: "relative";
      box-sizing: border-box;
      transition: all 0.2s;
      &:hover {
        border: 3px solid ${primaryBlue};
        transition: all 0.2s;
        cursor: pointer;
      }
    `;
  }}
`;

export const StyledVideo = styled.video`
  width: 100%;
  height: 170px;
  padding: 5px;
`;

export const StyledCheckbox = styled(Checkbox)`
  position: absolute;
  top: 15px;
  right: 15px;
  ${ImgWrapper}:hover & {
    display: block;
    transition: all 0.2s;
  }
`;

const VideoMedia = ({
  videosData,
  checkedVideoKeys,
  handleVideoCheck,
}: {
  videosData: Video[] | undefined | null;
  checkedVideoKeys: string[] | undefined;
  handleVideoCheck: (value: string, isChecked: boolean) => void;
}) => {
  return (
    <Row>
      {videosData !== undefined &&
      videosData !== null &&
      videosData.length > 0 ? (
        videosData.map((video, index) => {
          return (
            <Col
              md={6}
              sm={12}
              key={index.toString()}
              style={{
                boxSizing: "border-box",
                padding: "10px",
                border: "1px solid rgba(0, 0, 0, 0.4",
              }}
            >
              <Whisper
                placement="autoVerticalStart"
                speaker={descVideoSpeaker(video)}
              >
                <ImgWrapper
                  isChecked={checkedVideoKeys?.some(
                    (item: any) => item === video.videoId
                  )}
                  onClick={() => {
                    const isChecked = checkedVideoKeys?.some(
                      (item: any) => item === video.videoId
                    );
                    handleVideoCheck(video.videoId, !isChecked);
                  }}
                >
                  <div>
                    ID:
                    {video.videoId}
                  </div>
                  <StyledVideo src={video.url} autoPlay muted loop>
                    <source src={video.url} />
                  </StyledVideo>
                  <StyledCheckbox
                    value={video.videoId}
                    checked={checkedVideoKeys?.some(
                      (item: any) => item === video.videoId
                    )}
                  />
                </ImgWrapper>
              </Whisper>
            </Col>
          );
        })
      ) : (
        <div>動画はありません</div>
      )}
    </Row>
  );
};

export default VideoMedia;
