import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    id: string;
    name: string;
    robotType: string;
    infoButtons: string[];
    remark: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    id: "",
    name: "",
    robotType: "",
    infoButtons: [],
    remark: "",
  },
};

const actionAddFormModule = createSlice({
  name: "actionAddForm",
  initialState,
  reducers: {
    setAddFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.id = payload.formValue.id;
      state.formValue.name = payload.formValue.name;
      state.formValue.robotType = payload.formValue.robotType;
      state.formValue.infoButtons = payload.formValue.infoButtons;
      state.formValue.remark = payload.formValue.remark;
    },
  },
});

export const { setAddFormValue } = actionAddFormModule.actions;

export default actionAddFormModule.reducer;
