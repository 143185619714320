/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import styled from "styled-components";

export const DraggerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #999;
  cursor: col-resize;

  &::after {
    content: "";
    position: absolute;
    left: -2px;
    right: -2px;
    top: 0;
    bottom: 0;
  }

  &:hover {
    background: #333;
  }
`;

type Props = {
  x: number;
  y: number;
  onDrag: Function;
  handleReStart?: Function;
  children?: any;
};

const Dragger = (props: Props) => {
  const { x, y, onDrag, handleReStart, children } = props;
  const [screenX, setScreenX] = useState<number | null>(null);
  const [screenY, setScreenY] = useState<number | null>(null);
  const [ox, setOx] = useState<number | null>(null);
  const [oy, setOy] = useState<number | null>(null);

  const handleMouseMove = (e: any) => {
    if (screenX !== null && screenY !== null && ox !== null && oy !== null) {
      onDrag({ x: e.screenX - screenX + ox, y: e.screenY - screenY + oy });
    }
  };

  const handleMouseUp = (e: any) => {
    if (handleReStart) {
      if (screenX !== null && ox !== null) {
        handleReStart(e.screenX - screenX + ox);
      }
    }
    window.removeEventListener("mousemove", handleMouseMove);
    window.removeEventListener("mouseup", handleMouseUp);
  };

  const handleMouseDown = (e: any) => {
    setScreenX(e.screenX);
    setScreenY(e.screenY);
    setOx(x);
    setOy(y);
    window.addEventListener("mousemove", handleMouseMove, false);
    window.addEventListener("mouseup", handleMouseUp, false);
  };

  return (
    <DraggerWrapper
      onMouseDown={handleMouseDown}
      style={{ left: `${x}px`, top: `${y}px` }}
    >
      {children}
    </DraggerWrapper>
  );
};

export default Dragger;
