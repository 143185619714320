/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Modal,
  Schema,
  FormGroup,
  ControlLabel,
  FormControl,
  Uploader,
  Icon,
  Form,
} from "rsuite";
import { FormInstance } from "rsuite/lib/Form";
import { useDispatch, useSelector } from "react-redux";
import Resizer from "react-image-file-resizer";
import { Tooltip, IconButton } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import {
  StyledPrimaryButton,
  StyledCancelButton,
  StyledModal,
} from "../../../container/templates/Management/Users";
import { setAddImageFormValue } from "../../../../modules/Management/Form/imageAddFormModule";
import { RootState } from "../../../../reducers/rootReducer";
import { dataURIToBlob } from "../../../../utils/file";

const ToolTipTitle = styled.p`
  font-size: 17px;
  line-height: 1.5;
`;

const addImageModel = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です")
    .pattern(
      /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
      "IDとして入力できるのは半角英数記号のみです。"
    ),
  imageName: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
});

type Props = {
  show: boolean;
  onHide: () => void;
  setForm: any;
  submitImage: () => void;
};

const AddImageModal = ({ show, onHide, setForm, submitImage }: Props) => {
  const [imageFileInfo, setImageFileInfo] = useState({
    name: "",
    value: "",
    size: true,
  });
  const dispatch = useDispatch();

  const { formValue: addImageFormValue } = useSelector(
    (state: RootState) => state.imageAddForm
  );

  useEffect(() => {
    const image = new Image();
    image.src = imageFileInfo.value;
    if (imageFileInfo.value !== "") {
      image.onload = () => {
        setImageFileInfo({
          name: imageFileInfo.name,
          value: imageFileInfo.value,
          size: image.naturalWidth === 1727 && image.naturalHeight === 1080,
        });
      };
    }
  }, [imageFileInfo.name, imageFileInfo.value]);

  const handleAddImageFormChange = (value: any) => {
    dispatch(setAddImageFormValue({ formValue: { ...value } }));
  };

  const handleAddImageChange = (file: string) => {
    const newFile = dataURIToBlob(file);
    dispatch(
      setAddImageFormValue({
        formValue: {
          id: addImageFormValue.id,
          imageName: addImageFormValue.imageName,
          img: newFile,
          remark: addImageFormValue.remark,
        },
      })
    );
  };

  const resizeFile = (file: File, width: number, height: number) =>
    new Promise<string | File | Blob | ProgressEvent<FileReader>>(resolve => {
      Resizer.imageFileResizer(
        file,
        width,
        height,
        "PNG",
        100,
        0,
        uri => {
          resolve(uri);
        },
        "base64"
      );
    });

  const previewFile = (file: File | undefined, callback: any) => {
    if (file === undefined) return;
    const image = new Image();
    const reader = new FileReader();
    reader.onload = async () => {
      image.src =
        reader.result?.toString() ?? String(reader.result?.toString());
      image.onload = async () => {
        const selectedImageType =
          image.naturalWidth > image.naturalHeight ? "horizontal" : "vertical";
        if (selectedImageType === "horizontal") {
          const cnvsW = 1920;
          const cnvsH = (image.naturalHeight * cnvsW) / image.naturalWidth;
          const result = await resizeFile(file, cnvsW, cnvsH);
          callback(result);
        } else {
          const cnvsH = 1920;
          const cnvsW = (image.naturalWidth * cnvsH) / image.naturalHeight;
          const result = await resizeFile(file, cnvsW, cnvsH);
          callback(result);
        }
      };
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = () => {
    setImageFileInfo({ name: "", value: "", size: true });
    submitImage();
  };

  const onCancelClick = () => {
    onHide();
    setImageFileInfo({
      name: "",
      value: "",
      size: true,
    });
  };

  return (
    <StyledModal show={show} overflow={false} onHide={onCancelClick}>
      <Modal.Header>
        <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
          画像追加
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          fluid
          model={addImageModel}
          formValue={addImageFormValue}
          ref={(ref: FormInstance) => setForm(ref)}
          onChange={handleAddImageFormChange}
        >
          <FormGroup>
            <ControlLabel>画像ID</ControlLabel>
            <FormControl name="id" placeholder="image1" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>画像名</ControlLabel>
            <FormControl name="imageName" placeholder="画像名" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>
              画像アップロード
              <Tooltip
                placement="top"
                title={
                  <ToolTipTitle>
                    <div>
                      <p>{`<推奨サイズ>`}</p>
                      <p>縦横比16:9横置き：1920×1080</p>
                      <p>縦横比16:9縦置き：1080×1920</p>
                      <p>縦横比16:10横置き：1920×1200</p>
                      <p>縦横比16:10縦置き：1200×1920</p>
                    </div>
                  </ToolTipTitle>
                }
              >
                <IconButton>
                  <HelpIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </ControlLabel>
            <Uploader
              accept="image/png,image/jpeg"
              fileListVisible={false}
              autoUpload={false}
              listType="picture"
              onChange={files => {
                const afile = files.pop();

                previewFile(afile!.blobFile, async (value: any) => {
                  await setImageFileInfo({
                    name: afile?.name ? afile.name : "",
                    value,
                    size: imageFileInfo.size,
                  });
                  handleAddImageChange(value);
                });
              }}
            >
              <button
                style={{
                  width: "200px",
                  height: "112px",
                }}
                type="button"
              >
                {imageFileInfo.value !== "" ? (
                  <img
                    src={imageFileInfo.value}
                    width="100%"
                    height="100%"
                    alt=""
                  />
                ) : (
                  <Icon icon="camera" size="5x" />
                )}
              </button>
            </Uploader>
            <ControlLabel>ファイル名</ControlLabel>
            <FormControl
              name="img"
              value={imageFileInfo.name}
              readOnly
              plaintext
            />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <>
          <StyledPrimaryButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            登録
          </StyledPrimaryButton>
          <StyledCancelButton
            variant="contained"
            color="primary"
            onClick={onCancelClick}
          >
            取り消し
          </StyledCancelButton>
        </>
      </Modal.Footer>
    </StyledModal>
  );
};

export default AddImageModal;
