/* eslint-disable no-bitwise */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import React, { useState, useRef, useCallback } from "react";
import styled, { css } from "styled-components";
import { Icon } from "rsuite";
import WebAudio from "../../../../../utils/recorder/webaudio";
import {
  sliceAudioBuffer,
  serializeAudioBuffer,
} from "../../../../../utils/recorder/audio-helper";
import encodeWav from "../../../../../utils/encoder/wav";
import Player from "../../../../presentational/molecules/Recorder/Player";
import SelectBoxForRecord from "../../../../presentational/molecules/SelectBox/SelectBoxForRecord";
import { SpeechMicDeviceId } from "../../../../../modules/Management/Media/speechMicModule";

const AudioMeter = styled.div<{ index: number; visible: boolean }>`
  ${({ index, visible }) => {
    return css`
      width: 25px;
      height: 10px;
      background-color: ${index >= 17 && index <= 19
        ? "red"
        : index >= 13 && index < 17
        ? "yellow"
        : "blue"};
      margin-bottom: 2px;
      opacity: ${visible ? "1" : "0"};
    `;
  }}
`;

const Record = ({
  onSet,
  onSpeechInfo,
  handleAddSpeechChange,
}: {
  onSet: Function;
  onSpeechInfo: Function;
  handleAddSpeechChange: Function;
}) => {
  const mediaStream = useRef<MediaStream | null>(null);
  const [volume, setVolume] = useState(0);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const [chunks, setChunks] = useState<any[]>([]);
  const [, setAudioUrl] = useState<string>("");
  const [blobFile, setBlobFile] = useState<Blob | null>(null);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(Infinity);
  const [currentTime, setCurrentTime] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | null>(null);

  const getMediaStream = useCallback(async (mic?: SpeechMicDeviceId) => {
    try {
      const deviceId = mic ? mic.deviceId : "default";
      const stream = await window.navigator.mediaDevices.getUserMedia({
        audio: { deviceId },
        video: false,
      });
      mediaStream.current = stream;
    } catch (err) {
      console.error(err);
    }
  }, []);

  const getVolume = (localAnalyser: AnalyserNode) => {
    if (localAnalyser !== null) {
      const bit8 = new Uint8Array(localAnalyser.frequencyBinCount);
      localAnalyser.getByteFrequencyData(bit8);
      const localVolume =
        bit8.reduce((previous, current) => {
          return previous + current;
        }) / localAnalyser.frequencyBinCount;
      setVolume(localVolume);
      requestAnimationFrame(getVolume.bind(null, localAnalyser));
    }
    return 0;
  };

  const visualizer = async () => {
    if (!mediaStream.current) return false;
    const audioContext = new AudioContext();
    const localAnalyser = audioContext.createAnalyser();
    localAnalyser.fftSize = 128;
    const source = audioContext.createMediaStreamSource(mediaStream.current);
    source.connect(localAnalyser);

    requestAnimationFrame(getVolume.bind(null, localAnalyser));

    return true;
  };

  const checkVolume = async () => {
    if (!mediaStream.current) {
      await getMediaStream();
    }
    if (!visualizer()) {
      setTimeout(() => {
        checkVolume();
      }, 100);
    }
  };

  const RenderAudioMeter = useCallback(() => {
    const audioMeterList = [];
    for (let i = 0; i < 20; i += 1) {
      if (volume > 0) {
        audioMeterList.unshift(
          <AudioMeter key={i.toString()} index={i} visible={i * 5 < volume} />
        );
      } else {
        audioMeterList.unshift(
          <AudioMeter key={i.toString()} index={i} visible={i * 5 < volume} />
        );
      }
    }
    return <>{audioMeterList}</>;
  }, [volume]);

  const startRecording = async (mic: any) => {
    if (!mediaStream.current) {
      await getMediaStream(mic);
    }
    if (!visualizer()) {
      setTimeout(() => {
        checkVolume();
      }, 100);
    }
    if (mediaStream.current !== null) {
      const localChunks: any = [];
      const localMediaRecorder = new MediaRecorder(mediaStream.current);
      localMediaRecorder.addEventListener("dataavailable", (e: any) => {
        localChunks.push(e.data);
        setChunks(localChunks);
      });
      setMediaRecorder(localMediaRecorder);
      localMediaRecorder.start(1000);
      setProcessing(true);
      console.warn(localMediaRecorder.state);
      console.warn("recorder started");
    }
  };

  const stopRecording = async () => {
    if (mediaRecorder !== null) {
      mediaRecorder.addEventListener("stop", async (e: any) => {
        console.warn("recorder stopped");
        const blob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });
        setChunks([]);
        const audioURL = window.URL.createObjectURL(blob);
        setAudioUrl(audioURL);
        setBlobFile(blob);
        const localAudioBuffer = await WebAudio.decode(blob);
        setAudioBuffer(localAudioBuffer);
        setEndTime(localAudioBuffer.duration);
      });

      mediaRecorder.stop();
      setProcessing(false);
      console.warn(mediaRecorder.state);
    }
  };

  const previewFile = (file: any, callback: any) => {
    const image = new Image();
    const reader = new FileReader();
    reader.onloadend = () => {
      image.src = reader.result?.toString()
        ? reader.result?.toString()
        : String(reader.result);
      callback(reader.result, image.naturalWidth, image.naturalHeight);
    };
    reader.readAsDataURL(file);
  };

  const handleEncode = async (url: Blob) => {
    const localAudioBuffer = await WebAudio.decode(url);
    const { length, duration } = localAudioBuffer;

    const audioSliced: any = sliceAudioBuffer(
      localAudioBuffer,
      ~~((length * startTime) / duration),
      ~~((length * endTime) / duration)
    );

    setProcessing(true);

    const blob = encodeWav(serializeAudioBuffer(audioSliced));
    const audioURL = window.URL.createObjectURL(blob);
    setAudioUrl(audioURL);
    const lastIndex = audioURL.lastIndexOf("/");
    const fileName = `${audioURL.substr(lastIndex + 1)}.wav`;
    const localBlobFile = new File([blob], fileName, { type: "audio/wav" });
    previewFile(localBlobFile, async (value: any) => {
      const media = new Audio(value);
      await onSpeechInfo({
        name: fileName,
        value,
        file: localBlobFile,
      });
      media.onloadedmetadata = () => {
        handleAddSpeechChange(
          localBlobFile.name ? localBlobFile.name : "",
          media.duration
        );
      };
    });
    onSet(false);
    setProcessing(false);
  };

  const handleStartTimeChange = (time: number) => {
    setStartTime(time);
  };

  const handleEndTimeChange = (time: number) => {
    setEndTime(time);
  };

  const handleCurrentTimeChange = (time: number) => {
    setCurrentTime(time);
  };

  return (
    <>
      {audioBuffer !== null ? (
        <Player
          audioBuffer={audioBuffer}
          startTime={startTime}
          endTime={endTime}
          currentTime={currentTime}
          onStartTimeChange={handleStartTimeChange}
          onEndTimeChange={handleEndTimeChange}
          onCurrentTimeChange={handleCurrentTimeChange}
        />
      ) : (
        ""
      )}
      <Icon
        style={{
          cursor: "pointer",
          color: "green",
          border: "solid 1px green",
          borderRadius: "2px",
        }}
        icon="microphone"
        onClick={checkVolume}
      >
        ボリュームテスト
      </Icon>

      {processing ? (
        <Icon
          style={{
            cursor: "pointer",
            color: "red",
            border: "solid 1px red",
            borderRadius: "2px",
          }}
          icon="stop-circle"
          onClick={stopRecording}
        >
          録音停止
        </Icon>
      ) : (
        <Icon
          style={{
            cursor: "pointer",
            color: "red",
            border: "solid 1px red",
            borderRadius: "2px",
          }}
          icon="dot-circle-o"
          onClick={startRecording}
        >
          録音開始
        </Icon>
      )}
      <SelectBoxForRecord />
      {blobFile !== null ? (
        <Icon
          icon="file-download"
          style={{
            cursor: "pointer",
            color: "red",
            border: "solid 1px red",
            borderRadius: "2px",
          }}
          onClick={() => {
            handleEncode(blobFile);
          }}
        >
          録音したファイルをセットする
        </Icon>
      ) : (
        ""
      )}
      <RenderAudioMeter />
    </>
  );
};

export default Record;
