import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Schema,
  Form,
  SelectPicker,
  TagPicker,
  Alert,
} from "rsuite";
import { FormInstance } from "rsuite/lib/Form";
import Title from "../../../presentational/atoms/Text/Title";
import SubTitle from "../../../presentational/atoms/Text/SubTitle";
import { Margin } from "../../../../utils/styled";
import Card from "../../../presentational/molecules/Card";
import LineCharts from "../../../presentational/molecules/Charts/LineCharts";
import { RootState } from "../../../../reducers/rootReducer";
import { fetchCsCount } from "../../../../modules/Analysis/csCountApiModule";
import { fetchServedCount } from "../../../../modules/Analysis/servedCountApiModule";
import MultipleDatePicker from "../../../presentational/molecules/DatePicker";
import SelectBox from "../../../presentational/molecules/SelectBox";
import { fetchGraph } from "../../../../modules/Analysis/Graph/graphApiModule";
import PieCharts from "../../../presentational/molecules/Charts/PieCharts";
import {
  StyledPrimaryButton,
  StyledCancelButton,
  StyledDeleteButton,
  StyledModal,
} from "../Management/Users";
import { setAddFormValue } from "../../../../modules/Analysis/Form/graphAddFormModule";
import { fetchInfoButtons } from "../../../../modules/Management/InfoButtons/infoButtonApiModule";
import { fetchInfoButtonTypes } from "../../../../modules/Management/InfoButtons/getInfoButtonTypeApiModule";
import { postGraph } from "../../../../modules/Analysis/Graph/addGraphApiModule";
import { setEditFormValue } from "../../../../modules/Analysis/Form/graphEditFormModule";
import { Line, Pie } from "../../../../api/Analysis/graphApi";
import { putGraph } from "../../../../modules/Analysis/Graph/updateGraphApiModule";
import { delGraph } from "../../../../modules/Analysis/Graph/deleteGraphApiModule";

const Wrapper = styled.div`
  font-size: 14px;
  display: flex;
  flex: row;
  justify-content: space-between;
`;

const SelectWrapper = styled.div`
  width: 50%;
  font-size: 12px;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const LineChartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const addArrayModel = Schema.Model({
  name: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  type: Schema.Types.StringType().isRequired("必須項目です"),
  infoButtonIds: Schema.Types.ArrayType().isRequired("必須項目です"),
});

const addStringModel = Schema.Model({
  name: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  type: Schema.Types.StringType().isRequired("必須項目です"),
  infoButtonId: Schema.Types.StringType().isRequired("必須項目です"),
});

const Main = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [addForm, setAddForm] = useState<FormInstance>();

  const [showEdit, setShowEdit] = useState(false);
  const [editForm, setEditForm] = useState<FormInstance>();

  const [showDelete, setShowDelete] = useState(false);
  const [willDelete, setWillDelete] = useState<Line | Pie>();

  const dispatch = useDispatch();

  const { data: csCountData, isLoading: csCountLoading } = useSelector(
    (state: RootState) => state.csCountApi
  );

  const { token } = useSelector((state: RootState) => state.auth);
  const { shops } = useSelector((state: RootState) => state.shop);
  const selectedShops = useMemo(
    () =>
      shops.filter(value => value.id === "dashboard").map(shop => shop.shop),
    [shops]
  );

  const pcIds = useMemo(
    () =>
      selectedShops[0].map(item => {
        return item.value;
      }),
    [selectedShops]
  );

  const { picks } = useSelector((state: RootState) => state.datePick);
  const { startDate: csStartDate, endDate: csEndDate } = useMemo(
    () =>
      picks.filter(value => {
        return value.id === "csCount";
      })[0],
    [picks]
  );

  const { formValue: addFormValue } = useSelector(
    (state: RootState) => state.graphAddForm
  );

  const { formValue: editFormValue } = useSelector(
    (state: RootState) => state.graphEditForm
  );

  const { data: infoButtonData } = useSelector(
    (state: RootState) => state.infoButton
  );

  const infoButtonOptions = useMemo(() => {
    if (infoButtonData) {
      return infoButtonData.infoButtons.map(infoButton => {
        return {
          label: infoButton.text,
          value: infoButton.id,
          type: infoButton.infoButtonTypeId,
        };
      });
    }
    return [
      {
        label: "",
        value: "",
        type: "",
      },
    ];
  }, [infoButtonData]);

  const { data: infoButtonTypes } = useSelector(
    (state: RootState) => state.getInfoButtonTypes
  );

  const infoButtonTypeOptions = useMemo(() => {
    if (infoButtonTypes) {
      return infoButtonTypes.infoButtonTypes.map(infoButtonType => {
        return {
          label: infoButtonType.text,
          value: infoButtonType.id,
        };
      });
    }
    return [
      {
        label: "",
        value: "",
      },
    ];
  }, [infoButtonTypes]);

  useEffect(() => {
    if (token) {
      dispatch(fetchCsCount(token, csStartDate, csEndDate, pcIds));
    }
  }, [csEndDate, csStartDate, dispatch, pcIds, token]);

  useEffect(() => {
    if (token) {
      dispatch(fetchServedCount(token, csStartDate, csEndDate, pcIds));

      dispatch(fetchGraph(token, csStartDate, csEndDate, pcIds));
    }
  }, [csEndDate, csStartDate, dispatch, pcIds, selectedShops, token]);

  useEffect(() => {
    if (token) {
      dispatch(fetchInfoButtons(token));
      dispatch(fetchInfoButtonTypes(token));
    }
  }, [dispatch, token]);

  const handleAddClick = () => {
    setShowAdd(true);
  };

  const handleEditClick = (graph: Line | Pie) => {
    setShowEdit(true);
    if (infoButtonTypes) {
      if (graph.graphType === "line") {
        dispatch(
          setEditFormValue({
            formValue: {
              id: graph.graphId,
              name: graph.graphName,
              type: graph.graphType,
              infoButtonId: graph.infoButtonIds[0],
              infoButtonIds: [],
              infoType: "",
            },
          })
        );
      } else {
        const filteredIbo = infoButtonOptions.filter(
          ibo => ibo.value === graph.infoButtonIds[0]
        );
        const filteredIbt = infoButtonTypes.infoButtonTypes.filter(
          ibt => ibt.id === filteredIbo[0].type
        );
        dispatch(
          setEditFormValue({
            formValue: {
              id: graph.graphId,
              name: graph.graphName,
              type: graph.graphType,
              infoType: filteredIbt[0].id,
              infoButtonId: "",
              infoButtonIds: graph.infoButtonIds,
            },
          })
        );
      }
    }
  };

  const handleDeleteClick = (graph: Line | Pie) => {
    setWillDelete(graph);
    setShowDelete(true);
  };

  const handleAddFormChange = (value: any) => {
    dispatch(setAddFormValue({ formValue: value }));
  };

  const handleEditFormChange = (value: any) => {
    dispatch(setEditFormValue({ formValue: value }));
  };

  const onCancelClick = () => {
    setShowAdd(false);
    setShowEdit(false);
    setWillDelete(undefined);
    setShowDelete(false);
  };

  const handleAddTypeChange = (value: string) => {
    dispatch(
      setAddFormValue({
        formValue: {
          name: addFormValue.name,
          type: value,
          infoType: "",
          infoButtonId: "",
          infoButtonIds: [],
        },
      })
    );
  };
  const handleEditTypeChange = (value: string) => {
    dispatch(
      setEditFormValue({
        formValue: {
          id: editFormValue.id,
          name: editFormValue.name,
          type: value,
          infoType: "",
          infoButtonId: "",
          infoButtonIds: [],
        },
      })
    );
  };

  const handleEditInfoButtonTypeChange = (value: string) => {
    dispatch(
      setEditFormValue({
        formValue: {
          id: editFormValue.id,
          name: editFormValue.name,
          type: editFormValue.type,
          infoType: value,
          infoButtonId: "",
          infoButtonIds: [],
        },
      })
    );
  };

  const handleCheckAndSubmitAddForm = async () => {
    const checkResult = addForm!.check();
    if (checkResult && token) {
      try {
        const ibis =
          addFormValue.type === "line"
            ? [addFormValue.infoButtonId]
            : addFormValue.infoButtonIds;
        await dispatch(
          postGraph(token, addFormValue.name, addFormValue.type, ibis)
        );
        dispatch(
          setAddFormValue({
            formValue: {
              name: "",
              type: "",
              infoType: "",
              infoButtonId: "",
              infoButtonIds: [],
            },
          })
        );
        Alert.success("グラフを追加しました", 5000);
        setShowAdd(false);
        await dispatch(fetchGraph(token, csStartDate, csEndDate, pcIds));
      } catch (err) {
        Alert.error("不明なエラーが発生しました", 5000);
      }
    }
  };

  const handleCheckAndSucmitEditForm = async () => {
    const checkResult = editForm!.check();
    if (checkResult && token) {
      try {
        const ibis =
          editFormValue.type === "line"
            ? [editFormValue.infoButtonId]
            : editFormValue.infoButtonIds;
        await dispatch(
          putGraph(
            token,
            editFormValue.id,
            editFormValue.name,
            editFormValue.type,
            ibis
          )
        );
        dispatch(
          setEditFormValue({
            formValue: {
              id: "",
              name: "",
              type: "",
              infoType: "",
              infoButtonId: "",
              infoButtonIds: [],
            },
          })
        );
        Alert.success("グラフを更新しました", 5000);
        setShowEdit(false);
        await dispatch(fetchGraph(token, csStartDate, csEndDate, pcIds));
      } catch (err) {
        Alert.error("不明なエラーが発生しました", 5000);
      }
    }
  };

  const handleSubmitDelete = async () => {
    if (token && willDelete) {
      try {
        await dispatch(delGraph(token, willDelete.graphId));
        setWillDelete(undefined);
        setShowDelete(false);
        Alert.success("グラフを削除しました", 5000);
        await dispatch(fetchGraph(token, csStartDate, csEndDate, pcIds));
      } catch (err) {
        Alert.error("不明なエラーが発生しました", 5000);
      }
    }
  };

  return (
    <>
      <Title>RURA接客情報</Title>
      <Margin top={5} bottom={5}>
        <Wrapper>
          <MultipleDatePicker id="csCount" />
          <SelectWrapper>
            <SelectBox id="dashboard" />
          </SelectWrapper>
        </Wrapper>
      </Margin>
      <Margin top={20} bottom={10}>
        <SubTitle>店舗合計</SubTitle>
        <Margin top={10} />
        <CardWrapper>
          <Card
            header="接客した数"
            count={csCountData ? csCountData.actualTotalCounts : 0}
            isLoading={csCountLoading}
          />
          <Margin left={20} />
          <Card
            header="接客しなかった数"
            count={
              csCountData
                ? csCountData.totalCounts - csCountData.actualTotalCounts
                : 0
            }
            isLoading={csCountLoading}
          />
          <Margin left={20} />
          <Card
            header="接客画面に入った数"
            count={csCountData ? csCountData.totalCounts : 0}
            isLoading={csCountLoading}
          />
        </CardWrapper>
        <Margin top={20} />
        <CardWrapper>
          <Card
            header="接客した時間"
            count={csCountData ? csCountData.actualTotalHours : "00:00:00"}
            isLoading={csCountLoading}
          />
          <Margin left={20} />
          <Card
            header="接客しなかった時間"
            count={csCountData ? csCountData.totalNotServiceHours : "00:00:00"}
            isLoading={csCountLoading}
          />
          <Margin left={20} />
          <Card
            header="接客画面に入った時間"
            count={csCountData ? csCountData.totalHours : "00:00:00"}
            isLoading={csCountLoading}
          />
        </CardWrapper>
      </Margin>
      <Margin top={20} bottom={5}>
        <Wrapper>
          <SubTitle>選択された店舗グラフ</SubTitle>
          <StyledPrimaryButton
            onClick={handleAddClick}
            style={{ display: "block", height: "100%", width: "15%" }}
          >
            グラフを追加する
          </StyledPrimaryButton>
        </Wrapper>
      </Margin>

      <LineChartWrapper>
        <LineCharts
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
        />
        <PieCharts
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
        />
      </LineChartWrapper>

      <StyledModal show={showAdd} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            グラフ追加
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={
              addFormValue.type === "line" ? addStringModel : addArrayModel
            }
            formValue={addFormValue}
            ref={(ref: FormInstance) => setAddForm(ref)}
            onChange={handleAddFormChange}
          >
            <FormGroup>
              <ControlLabel>グラフ名</ControlLabel>
              <FormControl name="name" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>グラフタイプ</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                searchable={false}
                data={[
                  { label: "円グラフ", value: "pie" },
                  { label: "折れ線グラフ", value: "line" },
                ]}
                name="type"
                placeholder="グラフタイプを選択してください"
                onChange={handleAddTypeChange}
              />
            </FormGroup>
            {addFormValue.type === "line" ? (
              <FormGroup>
                <ControlLabel>情報ボタン</ControlLabel>
                <FormControl
                  accepter={SelectPicker}
                  block
                  data={infoButtonOptions || [{ label: "", value: "" }]}
                  name="infoButtonId"
                  placeholder="情報ボタンを選択してください"
                />
              </FormGroup>
            ) : (
              <>
                <FormGroup>
                  <ControlLabel>情報ボタンタイプ</ControlLabel>
                  <FormControl
                    accepter={SelectPicker}
                    data={infoButtonTypeOptions}
                    name="infoType"
                    placeholder="情報ボタンタイプを選択してください"
                    onChange={handleEditInfoButtonTypeChange}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>情報ボタン</ControlLabel>
                  <FormControl
                    accepter={TagPicker}
                    block
                    data={
                      infoButtonOptions.filter(
                        ib => ib.type === addFormValue.infoType
                      ) || [{ label: "", value: "" }]
                    }
                    name="infoButtonIds"
                    placeholder="情報ボタンを選択してください"
                  />
                </FormGroup>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <StyledPrimaryButton onClick={handleCheckAndSubmitAddForm}>
            OK
          </StyledPrimaryButton>
          <StyledCancelButton onClick={onCancelClick}>
            Cancel
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showEdit} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            グラフ編集
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={
              editFormValue.type === "line" ? addStringModel : addArrayModel
            }
            formValue={editFormValue}
            ref={(ref: FormInstance) => setEditForm(ref)}
            onChange={handleEditFormChange}
          >
            <FormGroup>
              <ControlLabel>グラフ名</ControlLabel>
              <FormControl name="name" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>グラフタイプ</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                searchable={false}
                data={[
                  { label: "円グラフ", value: "pie" },
                  { label: "折れ線グラフ", value: "line" },
                ]}
                name="type"
                placeholder="グラフタイプを選択してください"
                onChange={handleEditTypeChange}
              />
            </FormGroup>
            {editFormValue.type === "line" ? (
              <FormGroup>
                <ControlLabel>情報ボタン</ControlLabel>
                <FormControl
                  accepter={SelectPicker}
                  block
                  data={infoButtonOptions || [{ label: "", value: "" }]}
                  name="infoButtonId"
                  placeholder="情報ボタンを選択してください"
                />
              </FormGroup>
            ) : (
              <>
                <FormGroup>
                  <ControlLabel>情報ボタンタイプ</ControlLabel>
                  <FormControl
                    accepter={SelectPicker}
                    data={infoButtonTypeOptions}
                    name="infoType"
                    placeholder="情報ボタンタイプを選択してください"
                    onChange={handleEditInfoButtonTypeChange}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>情報ボタン</ControlLabel>
                  <FormControl
                    accepter={TagPicker}
                    block
                    data={
                      infoButtonOptions.filter(
                        ib => ib.type === editFormValue.infoType
                      ) || [{ label: "", value: "" }]
                    }
                    name="infoButtonIds"
                    placeholder="情報ボタンを選択してください"
                  />
                </FormGroup>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <StyledPrimaryButton onClick={handleCheckAndSucmitEditForm}>
            OK
          </StyledPrimaryButton>
          <StyledCancelButton onClick={onCancelClick}>
            Cancel
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showDelete} overflow onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            グラフ削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ paddingLeft: "10px", color: "#d84421" }}>
            {willDelete ? willDelete.graphName : ""}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: "#d84421",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記のグラフを削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          <StyledDeleteButton isChecked onClick={handleSubmitDelete}>
            OK
          </StyledDeleteButton>
          <StyledCancelButton onClick={onCancelClick}>
            Cancel
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>
    </>
  );
};

export default Main;
