import React, { Fragment } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import MeetingRoom from "@material-ui/icons/MeetingRoom";
import StorefrontIcon from "@material-ui/icons/Storefront";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import AssessmentIcon from "@material-ui/icons/Assessment";
import HistoryIcon from "@material-ui/icons/History";
import ListAltIcon from "@material-ui/icons/ListAlt";
import CreditCard from "@material-ui/icons/CreditCard";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import Mic from "@material-ui/icons/Mic";
import { Margin } from "../../../utils/styled";
import CardHeader from "../../presentational/atoms/Text/CardHeader";
import CardBody from "../../presentational/atoms/Text/CardBody";
import { textGray } from "../../../utils/color";
import { RoutePath } from "../../../Routes";
import { RootState } from "../../../reducers/rootReducer";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const CardWrapper = styled.div`
  width: 300px;
  height: 250px;
  max-width: 300px;
  max-height: 250px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  padding: 20px;
  transition: all 0.3s;
  &:hover {
    background-size: 300% 300%;
    transform: translateY(-5px);
    background-position: 99% 50%;
    border: 1px solid rgba(205, 200, 252, 0);
  }
`;

const StyledLink = styled(Link)`
  display: block;
  text-decoration: none !important;
  background: #fff;
  text-align: center;
  color: ${textGray};
  padding: 0.5em 1.25em;
  border-radius: 5px;
  margin-top: 25px;
  &:hover,
  &:active,
  &:visited,
  &:link {
    color: ${textGray};
  }
  &:hover {
    border-radius: 20px;
    transition: 0.25s;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.text.secondary};
  }
  transition: 0.4s;
`;

type PageType = {
  title: string;
  description: string;
  path: string;
  pathLabel: string;
  icon?: any;
  isMinimum: boolean;
};

const Top = () => {
  const { userType, auth } = useSelector((state: RootState) => state.auth);
  const ManagementPageList: PageType[] = [
    {
      title: "遠隔スタッフ管理",
      description:
        "遠隔スタッフ情報を管理する画面です。遠隔スタッフ情報の閲覧、追加、編集、削除が行うことができます。",
      path: RoutePath.management.users,
      pathLabel: "遠隔スタッフ管理",
      icon: <PeopleIcon fontSize="large" />,
      isMinimum: true,
    },
    {
      title: "ルーム管理",
      description:
        "ルーム情報を管理する画面です。ルームを追加したり、ルームに所属する店舗を変更することができます。",
      path: RoutePath.management.rooms,
      pathLabel: "ルーム管理",
      icon: <MeetingRoom fontSize="large" />,
      isMinimum: false,
    },
    {
      title: "店舗管理",
      description:
        "店舗を管理する画面です。店舗の名前、画像、動画、スピーチ、メモ等を編集することができます。",
      path: RoutePath.management.shops,
      pathLabel: "店舗管理",
      icon: <StorefrontIcon fontSize="large" />,
      isMinimum: true,
    },
    {
      title: "メディアライブラリ",
      description:
        "店舗で使用する画像や動画、スピーチ、スクリーンセーバーを統括的に管理する画面です。",
      path: RoutePath.management.media,
      pathLabel: "メディアライブラリ",
      icon: <PermMediaIcon fontSize="large" />,
      isMinimum: false,
    },
    {
      title: "通知ボタン管理",
      description:
        "店舗画面に表示される遠隔接客スタッフに通知するためのボタンに関する設定をする画面です。",
      path: RoutePath.management.notifications,
      pathLabel: "通知ボタン管理",
      icon: <NotificationsActiveIcon fontSize="large" />,
      isMinimum: false,
    },
    {
      title: "分析情報管理",
      description:
        "接客分析の際に使用する分析項目を管理する画面です。自由に分析項目をカスタマイズすることができます。",
      path: RoutePath.management.analysisInfo,
      pathLabel: "分析情報管理",
      icon: <AssessmentIcon fontSize="large" />,
      isMinimum: false,
    },
    {
      title: "決済履歴",
      description:
        "決済履歴を確認する画面です。決済の内容や実際の決済の画面、レシートを確認することができます。",
      path: RoutePath.management.paymentView,
      pathLabel: "決済履歴",
      icon: <HistoryIcon fontSize="large" />,
      isMinimum: false,
    },
    {
      title: "商品一覧",
      description:
        "取り扱っている商品を管理する画面です。取り扱う商品の登録や編集を行うことができます。",
      path: RoutePath.management.paymentItems,
      pathLabel: "商品一覧",
      icon: <ListAltIcon fontSize="large" />,
      isMinimum: false,
    },
    {
      title: "決済設定",
      description:
        "決済に関する設定を管理する画面です。決済に関する様々な設定を柔軟に行うことができます。",
      path: RoutePath.management.paymentSettings,
      pathLabel: "決済設定",
      icon: <CreditCard fontSize="large" />,
      isMinimum: false,
    },
    {
      title: "サポートカメラ管理",
      description:
        "店舗を撮影するサポートカメラを管理する画面です。撮影された画像の確認、削除を行うことができます。",
      path: RoutePath.management.camera,
      pathLabel: "サポートカメラ管理",
      icon: <PhotoCameraIcon fontSize="large" />,
      isMinimum: false,
    },
    {
      title: "モニターカメラ管理",
      description:
        "店舗を撮影するモニターカメラを管理する画面です。撮影された映像の確認、削除を行うことができます。",
      path: RoutePath.management.monitor,
      pathLabel: "モニターカメラ管理",
      icon: <PhotoCameraIcon fontSize="large" />,
      isMinimum: false,
    },
  ];
  const AnalysisPageList: PageType[] = [
    {
      title: "ダッシュボード",
      description:
        "全店舗の分析情報を概観することができる画面です。目的に沿ったグラフを自由に作成することができます。",
      path: RoutePath.analysis.dashboard,
      pathLabel: "ダッシュボード",
      icon: <DashboardIcon fontSize="large" />,
      isMinimum: true,
    },
    {
      title: "店舗毎分析",
      description:
        "店舗ごとに詳細を分析できる画面です。各店舗ごとの指標や遠隔スタッフの接客情報を閲覧することができます。",
      path: RoutePath.analysis.shop,
      pathLabel: "店舗毎分析",
      icon: <StorefrontIcon fontSize="large" />,
      isMinimum: false,
    },
    {
      title: "遠隔スタッフ分析",
      description:
        "全店舗の遠隔スタッフの接客情報を横断して閲覧することができます。気になる遠隔スタッフは個人単位で閲覧することができます。",
      path: RoutePath.analysis.workers,
      pathLabel: "遠隔スタッフ分析",
      icon: <SupervisorAccountIcon fontSize="large" />,
      isMinimum: true,
    },
    {
      title: "接客分析",
      description:
        "接客音声を分析することができます。絞り込み条件を使用することで目的の接客情報にすぐにたどり着くことができます。",
      path: RoutePath.analysis.customerService,
      pathLabel: "接客分析",
      icon: <Mic fontSize="large" />,
      isMinimum: true,
    },
  ];
  const PageListForWorker: PageType[] = [
    {
      title: "接客分析",
      description:
        "接客音声を分析することができます。絞り込み条件を使用することで目的の接客情報にすぐにたどり着くことができます。",
      path: RoutePath.analysis.customerService,
      pathLabel: "接客分析",
      icon: <Mic fontSize="large" />,
      isMinimum: false,
    },
  ];

  const isMinimum = process.env.REACT_APP_MINIMUM === "on";
  const PageListForAdminUser: PageType[] = [
    {
      title: "遠隔スタッフ管理",
      description:
        "遠隔スタッフ情報を管理する画面です。遠隔スタッフ情報の閲覧、追加、編集、削除が行うことができます。",
      path: RoutePath.management.users,
      pathLabel: "遠隔スタッフ管理",
      icon: <PeopleIcon fontSize="large" />,
      isMinimum: true,
    },
    {
      title: "店舗管理",
      description:
        "店舗を管理する画面です。店舗の名前、画像、動画、スピーチ、メモ等を編集することができます。",
      path: RoutePath.management.shops,
      pathLabel: "店舗管理",
      icon: <StorefrontIcon fontSize="large" />,
      isMinimum: true,
    },
  ];
  return (
    <>
      <Typography variant="h4" component="h2">
        RURA MANAGEMENT ポータルへようこそ！
      </Typography>
      <Margin top={20}>
        <Typography variant="h6" component="p">
          まずはこのポータルから目的に合ったページを探してみましょう。
        </Typography>
        <Margin top={25} />
        {userType === 1 && (
          <Margin top={10} right={10}>
            <CardWrapper>
              <Margin bottom={10}>
                <CardHeader>プロフィール設定</CardHeader>
              </Margin>
              <CardBody>プロフィールを編集することができます。</CardBody>
              <StyledLink to={RoutePath.management.profile}>
                プロフィール設定へ
              </StyledLink>
            </CardWrapper>
          </Margin>
        )}
        {userType === 0 ? (
          <>
            <Typography variant="h6">管理画面</Typography>
            <p style={{ fontSize: "18px", padding: "5px 0 0 5px" }}>
              管理画面では遠隔スタッフの管理をはじめ、RURAに関するあらゆる情報を管理することができます。
            </p>
            <Margin top={5} />
            <Wrapper>
              {auth === 2
                ? PageListForAdminUser.map((page, index) => {
                    if (isMinimum && !page.isMinimum)
                      return <Fragment key={index.toString()} />;
                    return (
                      <Margin top={10} right={10} key={index.toString()}>
                        <CardWrapper>
                          <Margin bottom={10}>
                            <CardHeader>
                              <span
                                style={{
                                  position: "relative",
                                  top: "10px",
                                  paddingRight: "5px",
                                }}
                              >
                                {page.icon}
                              </span>
                              {page.title}
                            </CardHeader>
                          </Margin>
                          <CardBody>{page.description}</CardBody>
                          <StyledLink to={page.path}>
                            {`${page.pathLabel}へ`}
                          </StyledLink>
                        </CardWrapper>
                      </Margin>
                    );
                  })
                : ManagementPageList.map((page, index) => {
                    if (isMinimum && !page.isMinimum)
                      return <Fragment key={index.toString()} />;
                    return (
                      <Margin top={10} right={10} key={index.toString()}>
                        <CardWrapper>
                          <Margin bottom={10}>
                            <CardHeader>
                              <span
                                style={{
                                  position: "relative",
                                  top: "10px",
                                  paddingRight: "5px",
                                }}
                              >
                                {page.icon}
                              </span>
                              {page.title}
                            </CardHeader>
                          </Margin>
                          <CardBody>{page.description}</CardBody>
                          <StyledLink to={page.path}>
                            {`${page.pathLabel}へ`}
                          </StyledLink>
                        </CardWrapper>
                      </Margin>
                    );
                  })}
            </Wrapper>
            <Margin top={20} />
          </>
        ) : (
          ""
        )}
        {auth !== null && auth <= 2 && (
          <>
            <Typography variant="h6">分析画面</Typography>
            <p style={{ fontSize: "18px", padding: "5px 0 0 5px" }}>
              分析画面ではカスタマイズした分析指標を元に様々な視点から接客情報を分析することができます。
            </p>
            <Margin top={5} />
            <Wrapper>
              {userType === 0
                ? AnalysisPageList.map((page, index) => {
                    if (isMinimum && !page.isMinimum)
                      return <Fragment key={index.toString()} />;
                    return (
                      <Margin top={10} right={10} key={index.toString()}>
                        <CardWrapper>
                          <Margin bottom={10}>
                            <CardHeader>
                              <span
                                style={{
                                  position: "relative",
                                  top: "10px",
                                  paddingRight: "5px",
                                }}
                              >
                                {page.icon}
                              </span>
                              {page.title}
                            </CardHeader>
                          </Margin>
                          <CardBody>{page.description}</CardBody>

                          <StyledLink to={page.path}>
                            {`${page.pathLabel}へ`}
                          </StyledLink>
                        </CardWrapper>
                      </Margin>
                    );
                  })
                : PageListForWorker.map((page, index) => {
                    if (isMinimum && !page.isMinimum)
                      return <Fragment key={index.toString()} />;
                    return (
                      <Margin top={10} right={10} key={index.toString()}>
                        <CardWrapper>
                          <Margin bottom={10}>
                            <CardHeader>
                              <span
                                style={{
                                  position: "relative",
                                  top: "10px",
                                  paddingRight: "5px",
                                }}
                              >
                                {page.icon}
                              </span>
                              {page.title}
                            </CardHeader>
                          </Margin>
                          <CardBody>{page.description}</CardBody>

                          <StyledLink to={page.path}>
                            {`${page.pathLabel}へ`}
                          </StyledLink>
                        </CardWrapper>
                      </Margin>
                    );
                  })}
            </Wrapper>
          </>
        )}
      </Margin>
    </>
  );
};

export default Top;
