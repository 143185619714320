import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  Whisper,
  Modal,
  List,
  Form,
  Schema,
  FormGroup,
  ControlLabel,
  FormControl,
  Uploader,
  Icon,
} from "rsuite";
import { FormInstance } from "rsuite/lib/Form";
import { Typography, MenuItem } from "@material-ui/core";
import Resizer from "react-image-file-resizer";
import Select from "@material-ui/core/Select";

import Title from "../../../../presentational/atoms/Text/Title";
import "react-tabs/style/react-tabs.css";
import { Margin } from "../../../../../utils/styled";
import { RootState } from "../../../../../reducers/rootReducer";
import { fetchImages } from "../../../../../modules/Management/Media/imageApiModule";
import Loading from "../../../../presentational/molecules/Loading";
import { setCheckedKeys } from "../../../../../modules/Management/checkModule";
import {
  StyledPrimaryButton,
  StyledDeleteButton,
  StyledCancelButton,
  StyledEditButton,
  StyledModal,
} from "../Users";
import { delImage } from "../../../../../modules/Management/Media/deleteImageApiModule";
import { delImages } from "../../../../../modules/Management/Media/deleteImagesApiModule";
import { setAddImageFormValue } from "../../../../../modules/Management/Form/imageAddFormModule";
import { postImage } from "../../../../../modules/Management/Media/addImageApiModule";
import { fetchVideos } from "../../../../../modules/Management/Media/videoApiModule";
import { delVideo } from "../../../../../modules/Management/Media/deleteVideoApiModule";
import { delVideos } from "../../../../../modules/Management/Media/deleteVideosApiModule";
import { setAddVideoFormValue } from "../../../../../modules/Management/Form/videoAddFormModule";
import { postVideo } from "../../../../../modules/Management/Media/addVideoApiModule";
import { fetchSpeeches } from "../../../../../modules/Management/Media/speechApiModule";
import { setAddSpeechFormValue } from "../../../../../modules/Management/Form/speechAddFormModule";
import SpeechesTable from "../../../../presentational/molecules/Table/SpeechesTable";
import { delSpeeches } from "../../../../../modules/Management/Media/deleteSpeechesApiModule";
import { delSpeech } from "../../../../../modules/Management/Media/deleteSpeechApiModule";
import { postSpeech } from "../../../../../modules/Management/Media/addSpeechApiModule";
import { deleteSpeaker } from "../../../../presentational/molecules/Speaker";
import VideoMedia, {
  StyledVideo,
} from "../../../../presentational/molecules/Media/VideoMedia";
import ImageMedia, {
  StyledImg,
} from "../../../../presentational/molecules/Media/ImageMedia";
import { putImage } from "../../../../../modules/Management/Media/updateImageApiModule";
import { putVideo } from "../../../../../modules/Management/Media/updateVideoApiModule";
import { putSpeech } from "../../../../../modules/Management/Media/updateSpeechApiModule";
import AddImageModal from "../../../../presentational/molecules/Modal/AddImageModal";
import Record from "./Record";
import { SidebarContext } from "../../Layout";
import useAlert from "../../../../../hooks/useAlert";
import { BaseLink } from "../../../../presentational/atoms/Link/BaseLink";
import { RoutePath } from "../../../../../Routes";
import preSignVideo from "../../../../../api/Management/videoApi/preSign";
import addS3 from "../../../../../api/Management/imageApi/addS3";
import preSignSpeech from "../../../../../api/Management/speechApi/preSign";

const StyledTabs = styled(Tabs)`
  background: #fff;
  border-radius: 5px 5px 0 0;
`;

export const StyledButtons = styled.div`
  width: 100%;
  padding-top: 5px;
  text-align: right;
`;

const addImageModel = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です")
    .pattern(
      /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
      "IDとして入力できるのは半角英数記号のみです。"
    ),
  img: Schema.Types.StringType().isRequired("必須項目です"),
});

const addVideoModel = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です")
    .pattern(
      /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
      "IDとして入力できるのは半角英数記号のみです。"
    ),
  file: Schema.Types.StringType().isRequired("必須項目です"),
});

const addSpeechMoel = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です")
    .pattern(
      /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
      "IDとして入力できるのは半角英数記号のみです。"
    ),
  // file: Schema.Types.StringType().isRequired("必須項目です"),
  text: Schema.Types.StringType().isRequired("必須項目です"),
});

const Media = () => {
  const [selectedImageType, setSelectedImageType] = useState("horizontal");
  const [selected, setSelected] = useState(0);
  const { isOpen } = useContext(SidebarContext);
  const { info, success, error, closeSnackbar } = useAlert();

  const [showImageAdd, setShowImageAdd] = useState(false);
  const [addImageForm, setImageAddForm] = useState<FormInstance>();
  const [imageFileInfo, setImageFileInfo] = useState({
    name: "",
    value: "",
    size: true,
  });
  const [showEditImage, setShowEditImage] = useState(false);
  const [showImageDelete, setShowImageDelete] = useState(false);

  const [showVideoAdd, setShowVideoAdd] = useState(false);
  const [addVideoForm, setAddVideoForm] = useState<FormInstance>();
  const [videoFileInfo, setVideoFileInfo] = useState<{
    name: string;
    value: string;
    file: File | undefined;
    fileSize: number | undefined;
  }>({
    name: "",
    value: "",
    file: undefined,
    fileSize: undefined,
  });
  const [showEditVideo, setShowEditVideo] = useState(false);
  const [showVideoDelete, setShowVideoDelete] = useState(false);

  const [showSpeechAdd, setShowSpeechAdd] = useState(false);
  const [addSpeechForm, setAddSpeechForm] = useState<FormInstance>();
  const [speechFileInfo, setSpeechFileInfo] = useState<{
    name: string;
    value: string;
    file: File | undefined;
  }>({
    name: "",
    value: "",
    file: undefined,
  });
  const [showEditSpeech, setShowEditSpeech] = useState(false);
  const [showSpeechRec, setShowSpeechRec] = useState(false);
  const [showSpeechDelete, setShowSpeechDelete] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);

  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.auth);

  const {
    data: imagesData,
    isLoading: imagesIsLoading,
    error: imagesError,
  } = useSelector((state: RootState) => state.images);
  const { isLoading: addImageLoading } = useSelector(
    (state: RootState) => state.addImage
  );

  const {
    data: videosData,
    isLoading: videosIsLoading,
    error: videosError,
  } = useSelector((state: RootState) => state.videos);
  const { isLoading: addVideoLoading } = useSelector(
    (state: RootState) => state.addVideo
  );

  const {
    data: speechesData,
    isLoading: speechesIsLoading,
    error: speechError,
  } = useSelector((state: RootState) => state.speech);
  const { isLoading: addSpeechIsLoading } = useSelector(
    (state: RootState) => state.addSpeech
  );

  const { checks } = useSelector((state: RootState) => state.checkCells);
  const checkedImageKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "images")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );
  const checkedVideoKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "videos")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );
  const checkedSpeechKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "speeches")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );

  const { formValue: addImageFormValue } = useSelector(
    (state: RootState) => state.imageAddForm
  );
  const { formValue: addVideoFormValue } = useSelector(
    (state: RootState) => state.videoAddForm
  );
  const { formValue: addSpeechFormValue } = useSelector(
    (state: RootState) => state.speechAddForm
  );

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          await dispatch(fetchImages(token));
          await dispatch(fetchVideos(token));
          await dispatch(fetchSpeeches(token));
        } catch (err) {
          console.error(err);
        }
      }
    };
    fetchData();
  }, [dispatch, token]);

  useEffect(() => {
    const image = new Image();
    image.src = imageFileInfo.value;
    if (imageFileInfo.value !== "") {
      image.onload = () => {
        setImageFileInfo({
          name: imageFileInfo.name,
          value: imageFileInfo.value,
          size: image.naturalWidth === 1727 && image.naturalHeight === 1080,
        });
      };
    }
  }, [imageFileInfo.name, imageFileInfo.value]);

  const resizeFile = (file: File, height: number, width: number) =>
    new Promise<string | File | Blob | ProgressEvent<FileReader>>(resolve => {
      Resizer.imageFileResizer(
        file,
        height,
        width,
        "PNG",
        100,
        0,
        uri => {
          resolve(uri);
        },
        "base64"
      );
    });

  const previewFile = (file: File | undefined, callback: any) => {
    if (file === undefined) return;
    setVideoLoading(true);
    const image = new Image();
    const reader = new FileReader();
    reader.onloadend = async () => {
      const cnvsH = 1080;
      const cnvsW = (image.naturalWidth * cnvsH) / image.naturalHeight;
      const result = await resizeFile(file, cnvsH, cnvsW);
      image.src = result.toString();
      callback(result.toString(), image.naturalWidth, image.naturalHeight);
    };
    reader.readAsDataURL(file);
    setVideoLoading(false);
  };

  const handleSelect = (index: number) => {
    setSelected(index);
  };

  const handleImageCheck = (value: any, isChecked: boolean) => {
    const nextCheckedKeys = isChecked
      ? [...checkedImageKeys, value]
      : checkedImageKeys.filter(item => item !== value);
    dispatch(
      setCheckedKeys({
        id: "images",
        checkInfo: {
          checked: false,
          checkedKeys: nextCheckedKeys,
          indeterminate: false,
        },
      })
    );
  };

  const handleVideoCheck = (value: any, isChecked: boolean) => {
    const nextCheckedKeys = isChecked
      ? [...checkedVideoKeys, value]
      : checkedVideoKeys.filter(item => item !== value);
    dispatch(
      setCheckedKeys({
        id: "videos",
        checkInfo: {
          checked: false,
          checkedKeys: nextCheckedKeys,
          indeterminate: false,
        },
      })
    );
  };

  const handleImageAddClick = () => {
    setShowImageAdd(true);
  };
  const handleImageEditClick = () => {
    const images = imagesData?.images.filter(
      image => image.imageId === checkedImageKeys[0]
    )[0];
    setImageFileInfo({
      name: images?.imageId ? images?.imageId : "",
      value: images?.image ? images?.image : "",
      size: true,
    });
    dispatch(
      setAddImageFormValue({
        formValue: {
          id: images?.imageId ? images?.imageId : "",
          imageName: images?.imageName ?? "",
          img: images?.image ? images?.image : "",
          remark: images?.remark ? images?.remark : "",
        },
      })
    );
    setShowEditImage(true);
  };
  const handleImageDeleteClick = () => {
    setShowImageDelete(true);
  };

  const handleVideoAddClick = () => {
    setShowVideoAdd(true);
  };
  const handleVideoEditClick = () => {
    const videos = videosData?.videos.filter(
      video => video.videoId === checkedVideoKeys[0]
    )[0];
    setVideoFileInfo({
      name: videos?.videoId ? videos?.videoId : "",
      value: videos?.url ? videos?.url : "",
      file: undefined,
      fileSize: undefined,
    });
    dispatch(
      setAddVideoFormValue({
        formValue: {
          id: videos?.videoId ? videos?.videoId : "",
          val: videos?.videoId ? videos?.videoId : "",
          text: videos?.text ? videos?.text : "",
          file: videos?.url ? videos?.url : undefined,
          playTime: videos?.time ? videos?.time : 0,
          fileSize: 0,
        },
      })
    );
    setShowEditVideo(true);
  };
  const handleVideoDeleteClick = () => {
    setShowVideoDelete(true);
  };

  const handleRec = () => {
    setShowSpeechRec(true);
  };

  const handleSpeechAddClick = () => {
    setShowSpeechAdd(true);
  };

  const handleSpeechEditClick = async () => {
    const speeches = speechesData?.speeches.find(
      speech => speech.speechId === checkedSpeechKeys[0]
    );
    if (speeches === undefined) return;
    fetch(speeches.url)
      .then(res => res.blob())
      .then(blob => new File([blob], speeches.speechId))
      .then(file => {
        previewFile(file, async (value: any) => {
          await setSpeechFileInfo({
            name: speeches?.speechId ? speeches?.speechId : "",
            value: speeches?.url ? speeches?.url : "",
            file,
          });
        });
      });
    dispatch(
      setAddSpeechFormValue({
        formValue: {
          id: speeches?.speechId ? speeches?.speechId : "",
          val: speeches?.val ? speeches?.val : "",
          text: speeches?.text ? speeches?.text : "",
          file: speeches?.url ? speeches.url : undefined,
          playTime: speeches?.time ? speeches?.time : 0,
        },
      })
    );
    setShowEditSpeech(true);
  };

  const handleSpeechDeleteClick = () => {
    setShowSpeechDelete(true);
  };

  const onCancelClick = () => {
    setShowImageAdd(false);
    setShowEditImage(false);
    setShowImageDelete(false);
    setShowVideoAdd(false);
    setShowEditVideo(false);
    setShowVideoDelete(false);
    setShowSpeechAdd(false);
    setShowEditSpeech(false);
    setShowSpeechDelete(false);
    dispatch(
      setAddImageFormValue({
        formValue: { id: "", imageName: "", img: "", remark: "" },
      })
    );
    dispatch(
      setAddVideoFormValue({
        formValue: {
          id: "",
          file: "",
          text: "",
          val: "",
          playTime: 0,
          fileSize: 0,
        },
      })
    );
    dispatch(
      setAddSpeechFormValue({
        formValue: {
          id: "",
          file: "",
          text: "",
          val: "",
          playTime: 0,
        },
      })
    );
    setImageFileInfo({
      name: "",
      value: "",
      size: true,
    });
    setImageFileInfo({ name: "", value: "", size: true });
    setVideoFileInfo({
      name: "",
      value: "",
      file: undefined,
      fileSize: undefined,
    });
    setSpeechFileInfo({ name: "", value: "", file: undefined });
  };

  const handleAddImageFormChange = (value: any) => {
    dispatch(setAddImageFormValue({ formValue: value }));
  };

  const handleAddVideoFormChange = (value: any) => {
    dispatch(
      setAddVideoFormValue({
        formValue: {
          id: value.id,
          file: addVideoFormValue.file,
          text: value.text,
          val: value.id,
          playTime: addVideoFormValue.playTime,
          fileSize: 0,
        },
      })
    );
  };

  const handleAddSpeechFormChange = (value: any) => {
    dispatch(
      setAddSpeechFormValue({
        formValue: {
          id: value.id,
          file: addSpeechFormValue.file,
          text: value.text,
          val: value.val,
          playTime: addSpeechFormValue.playTime,
        },
      })
    );
  };

  const handleAddImageChange = (file: any) => {
    dispatch(
      setAddImageFormValue({
        formValue: {
          id: addImageFormValue.id,
          imageName: addImageFormValue.imageName,
          img: file,
          remark: addImageFormValue.remark,
        },
      })
    );
  };

  const handleAddVideoChange = (file: string, playTime: number) => {
    dispatch(
      setAddVideoFormValue({
        formValue: {
          id: addVideoFormValue.id,
          file,
          text: addVideoFormValue.text,
          val: addVideoFormValue.id,
          playTime,
          fileSize: 0,
        },
      })
    );
  };

  const handleAddSpeechChange = (file: string, playTime: number) => {
    dispatch(
      setAddSpeechFormValue({
        formValue: {
          id: addSpeechFormValue.id,
          file,
          text: addSpeechFormValue.text,
          val: addSpeechFormValue.val,
          playTime,
        },
      })
    );
  };

  const handleCheckAndSubmitImageAddForm = async () => {
    const checkResult = addImageForm!.check();
    if (checkResult && token) {
      try {
        const imageAlertKey = info({ text: "画像を追加中...", persist: true });
        await dispatch(
          postImage(
            token,
            addImageFormValue.id,
            addImageFormValue.imageName,
            addImageFormValue.img,
            addImageFormValue.remark
          )
        );
        setShowImageAdd(false);
        dispatch(
          setAddImageFormValue({
            formValue: { id: "", imageName: "", img: "", remark: "" },
          })
        );
        closeSnackbar(imageAlertKey);
        success({ text: "画像を追加しました" });
        await dispatch(fetchImages(token));
      } catch (err) {
        console.error(err);
        closeSnackbar();
        switch (err.resultStatus) {
          case 111:
            error({
              text: "このIDはすでに登録されています。",
            });
            break;
          case 110:
            error({ text: "画像の追加に失敗しました。" });
            break;
          case 115:
            error({ text: "この操作を実行する権限がありません。" });
            break;
          default:
            error({ text: "エラーが発生しました。" });
            break;
        }
      }
    }
  };
  const handleCheckAndSubmitImageForUpdate = async () => {
    const checkResult = addImageForm!.check();
    if (checkResult && token) {
      try {
        setShowEditImage(false);
        info({ text: "画像を更新中..." });
        await dispatch(
          putImage(
            token,
            addImageFormValue.id,
            addImageFormValue.img,
            addImageFormValue.remark
          )
        );
        dispatch(
          setAddImageFormValue({
            formValue: { id: "", imageName: "", img: "", remark: "" },
          })
        );
        dispatch(
          setCheckedKeys({
            id: "images",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        await dispatch(fetchImages(token));
        closeSnackbar();
        success({ text: "画像を更新しました" });
      } catch (err) {
        console.error(err);
        closeSnackbar();
        switch (err.resultStatus) {
          case 111:
            error({
              text: "このIDはすでに登録されています。",
            });
            break;
          case 114:
            error({ text: "画像の更新に失敗しました。" });
            break;
          case 115:
            error({ text: "この操作を実行する権限がありません。" });
            break;
          default:
            error({ text: "エラーが発生しました。" });
            break;
        }
      }
    }
  };

  const handleSubmitDeleteImage = async () => {
    if (token) {
      try {
        setShowImageDelete(false);
        info({ text: "画像を削除中..." });
        if (checkedImageKeys.length === 1) {
          await dispatch(delImage(token, checkedImageKeys[0]));
        } else {
          await dispatch(delImages(token, checkedImageKeys));
        }
        dispatch(
          setCheckedKeys({
            id: "images",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        closeSnackbar();
        success({ text: "画像を削除しました" });
        await dispatch(fetchImages(token));
      } catch (err) {
        console.error(err);
        closeSnackbar();
        switch (err.resultStatus) {
          case 116:
            error({ text: "画像の削除に失敗しました。" });
            break;
          case 115:
            error({ text: "この操作を実行する権限がありません。" });
            break;
          default:
            error({ text: "エラーが発生しました。" });
            break;
        }
      }
    }
  };

  const handleCheckAndSubmitVideoAddForm = async () => {
    const checkResult = addVideoForm!.check();
    if (
      videoFileInfo.fileSize !== undefined &&
      videoFileInfo.fileSize >= 104857600
    ) {
      return;
    }
    if (checkResult && token && videoFileInfo.file !== undefined) {
      try {
        setShowVideoAdd(false);
        info({ text: "動画を追加中..." });
        const presigned = await preSignVideo(
          token,
          addVideoFormValue.id,
          "add"
        );
        await addS3(presigned.url, videoFileInfo.file);
        await dispatch(
          postVideo(
            token,
            addVideoFormValue.id,
            presigned.fileName,
            addVideoFormValue.text,
            addVideoFormValue.id,
            addVideoFormValue.playTime
          )
        );
        dispatch(
          setAddVideoFormValue({
            formValue: {
              id: "",
              file: "",
              text: "",
              val: "",
              playTime: 0,
              fileSize: 0,
            },
          })
        );
        closeSnackbar();
        success({ text: "動画を追加しました" });
        await dispatch(fetchVideos(token));
      } catch (err) {
        console.error(err);
        closeSnackbar();
        switch (err.resultStatus) {
          case 111:
            error({
              text: "このIDはすでに登録されています。",
            });
            break;
          case 110:
            error({ text: "動画の追加に失敗しました。" });
            break;
          case 115:
            error({ text: "この操作を実行する権限がありません。" });
            break;
          default:
            error({ text: "エラーが発生しました。" });
            break;
        }
      }
    }
  };

  const handleCheckAndSubmitVideoForUpdate = async () => {
    const checkResult = addVideoForm!.check();
    if (
      videoFileInfo.fileSize !== undefined &&
      videoFileInfo.fileSize >= 104857600
    ) {
      return;
    }
    if (checkResult && token) {
      try {
        setShowEditVideo(false);
        info({ text: "動画を更新中..." });
        await dispatch(
          putVideo(
            token,
            addVideoFormValue.id,
            videoFileInfo.file,
            addVideoFormValue.text,
            addVideoFormValue.id,
            addVideoFormValue.playTime,
            videoFileInfo.fileSize
          )
        );
        dispatch(
          setAddVideoFormValue({
            formValue: {
              id: "",
              file: "",
              text: "",
              val: "",
              playTime: 0,
              fileSize: 0,
            },
          })
        );
        dispatch(
          setCheckedKeys({
            id: "videos",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        closeSnackbar();
        success({ text: "動画を更新しました" });
        await dispatch(fetchVideos(token));
      } catch (err) {
        console.error(err);
        closeSnackbar();
        switch (err.resultStatus) {
          case 111:
            error({
              text: "このIDはすでに登録されています。",
            });
            break;
          case 114:
            error({ text: "動画の更新に失敗しました。" });
            break;
          case 115:
            error({ text: "この操作を実行する権限がありません。" });
            break;
          default:
            error({ text: "エラーが発生しました。" });
            break;
        }
      }
    }
  };

  const handleSubmitDeleteVideo = async () => {
    if (token) {
      try {
        setShowVideoDelete(false);
        info({ text: "動画を削除中..." });
        if (checkedVideoKeys.length === 1) {
          await dispatch(delVideo(token, checkedVideoKeys[0]));
        } else {
          await dispatch(delVideos(token, checkedVideoKeys));
        }
        dispatch(
          setCheckedKeys({
            id: "videos",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        closeSnackbar();
        success({ text: "動画を削除しました" });
        await dispatch(fetchVideos(token));
      } catch (err) {
        console.error(err);
        closeSnackbar();
        switch (err.resultStatus) {
          case 116:
            error({ text: "動画の削除に失敗しました。" });
            break;
          case 115:
            error({ text: "この操作を実行する権限がありません。" });
            break;
          default:
            error({ text: "エラーが発生しました。" });
            break;
        }
      }
    }
  };

  const handleCheckAndSubmitSpeechAddForm = async () => {
    const checkResult = addSpeechForm!.check();
    if (checkResult && token && speechFileInfo.file !== undefined) {
      try {
        setShowSpeechAdd(false);
        info({ text: "スピーチ音声を追加中..." });
        const presigned = await preSignSpeech(
          token,
          addSpeechFormValue.id,
          "add"
        );
        await addS3(presigned.url, speechFileInfo.file);
        await dispatch(
          postSpeech(
            token,
            addSpeechFormValue.id,
            presigned.fileName,
            addSpeechFormValue.text,
            addSpeechFormValue.val,
            addSpeechFormValue.playTime
          )
        );
        dispatch(
          setAddSpeechFormValue({
            formValue: {
              id: "",
              file: "",
              text: "",
              val: "",
              playTime: 0,
            },
          })
        );
        closeSnackbar();
        success({ text: "スピーチ音声の追加をしました" });
        await dispatch(fetchSpeeches(token));
      } catch (err) {
        console.error(err);
        closeSnackbar();
        switch (err.resultStatus) {
          case 111:
            error({ text: "このIDはすでに登録されています。" });
            break;
          case 110:
            error({ text: "スピーチ音声の追加に失敗しました。" });
            break;
          case 115:
            error({ text: "この操作を実行する権限がありません。" });
            break;
          default:
            error({ text: "エラーが発生しました。" });
            break;
        }
      }
    }
  };

  const handleCheckAndSubmitSpeechForUpdate = async () => {
    const checkResult = addSpeechForm!.check();
    if (checkResult && token) {
      try {
        setShowEditSpeech(false);
        info({ text: "スピーチ音声を更新中..." });
        await dispatch(
          putSpeech(
            token,
            addSpeechFormValue.id,
            speechFileInfo.file,
            addSpeechFormValue.text,
            "",
            addSpeechFormValue.playTime
          )
        );
        dispatch(
          setAddSpeechFormValue({
            formValue: {
              id: "",
              file: "",
              text: "",
              val: "",
              playTime: 0,
            },
          })
        );
        dispatch(
          setCheckedKeys({
            id: "speeches",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        closeSnackbar();
        success({ text: "スピーチ音声を更新しました" });
        await dispatch(fetchSpeeches(token));
      } catch (err) {
        console.error(err);
        closeSnackbar();
        switch (err.resultStatus) {
          case 111:
            error({ text: "このIDはすでに登録されています。" });
            break;
          case 114:
            error({ text: "スピーチ音声の更新に失敗しました。" });
            break;
          case 115:
            error({ text: "この操作を実行する権限がありません。" });
            break;
          default:
            error({ text: "エラーが発生しました。" });
            break;
        }
      }
    }
  };

  const handleSubmitDeleteSpeech = async () => {
    if (token) {
      try {
        setShowSpeechDelete(false);
        info({ text: "スピーチ音声を削除中..." });
        if (checkedSpeechKeys.length === 1) {
          await dispatch(delSpeech(token, checkedSpeechKeys[0]));
        } else {
          await dispatch(delSpeeches(token, checkedSpeechKeys));
        }
        dispatch(
          setCheckedKeys({
            id: "speeches",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        await closeSnackbar();
        success({ text: "スピーチ音声を削除しました" });
        await dispatch(fetchSpeeches(token));
      } catch (err) {
        console.error(err);
        closeSnackbar();
        switch (err.resultStatus) {
          case 116:
            error({ text: "スピーチ音声の削除に失敗しました。" });
            break;
          case 115:
            error({ text: "この操作を実行する権限がありません。" });
            break;
          default:
            error({ text: "エラーが発生しました。" });
            break;
        }
      }
    }
  };

  const renderButtons = () => {
    switch (selected) {
      case 0:
        return (
          <>
            <StyledPrimaryButton
              variant="contained"
              color="primary"
              onClick={handleImageAddClick}
            >
              追加
            </StyledPrimaryButton>
            {checkedImageKeys.length > 0 && checkedImageKeys.length < 2 ? (
              <StyledEditButton
                variant="contained"
                color="primary"
                isChecked
                onClick={handleImageEditClick}
              >
                編集
              </StyledEditButton>
            ) : (
              <StyledEditButton
                variant="contained"
                color="primary"
                isChecked={false}
              >
                編集
              </StyledEditButton>
            )}
            {checkedImageKeys.length > 0 ? (
              <StyledDeleteButton
                variant="contained"
                color="primary"
                isChecked
                onClick={handleImageDeleteClick}
              >
                削除
              </StyledDeleteButton>
            ) : (
              <Whisper
                placement="autoVerticalStart"
                trigger="hover"
                speaker={deleteSpeaker("画像")}
              >
                <StyledDeleteButton
                  variant="contained"
                  color="primary"
                  isChecked={false}
                >
                  削除
                </StyledDeleteButton>
              </Whisper>
            )}
          </>
        );
      case 1:
        return (
          <>
            <StyledPrimaryButton
              variant="contained"
              color="primary"
              onClick={handleVideoAddClick}
            >
              追加
            </StyledPrimaryButton>
            {checkedVideoKeys.length > 0 && checkedVideoKeys.length < 2 ? (
              <StyledEditButton
                variant="contained"
                color="primary"
                isChecked
                onClick={handleVideoEditClick}
              >
                編集
              </StyledEditButton>
            ) : (
              <StyledEditButton isChecked={false}>編集</StyledEditButton>
            )}
            {checkedVideoKeys.length > 0 ? (
              <StyledDeleteButton
                variant="contained"
                color="primary"
                isChecked
                onClick={handleVideoDeleteClick}
              >
                削除
              </StyledDeleteButton>
            ) : (
              <Whisper
                placement="autoVerticalStart"
                trigger="hover"
                speaker={deleteSpeaker("動画")}
              >
                <StyledDeleteButton
                  variant="contained"
                  color="primary"
                  isChecked={false}
                >
                  削除
                </StyledDeleteButton>
              </Whisper>
            )}
          </>
        );
      case 2:
        return (
          <>
            <StyledPrimaryButton
              variant="contained"
              color="primary"
              onClick={handleSpeechAddClick}
            >
              追加
            </StyledPrimaryButton>
            {checkedSpeechKeys.length > 0 && checkedSpeechKeys.length < 2 ? (
              <StyledEditButton
                variant="contained"
                color="primary"
                isChecked
                onClick={handleSpeechEditClick}
              >
                編集
              </StyledEditButton>
            ) : (
              <StyledEditButton
                variant="contained"
                color="primary"
                isChecked={false}
              >
                編集
              </StyledEditButton>
            )}
            {checkedSpeechKeys.length > 0 ? (
              <StyledDeleteButton
                variant="contained"
                color="primary"
                isChecked
                onClick={handleSpeechDeleteClick}
              >
                削除
              </StyledDeleteButton>
            ) : (
              <Whisper
                placement="autoVerticalStart"
                trigger="hover"
                speaker={deleteSpeaker("スピーチ")}
              >
                <StyledDeleteButton
                  variant="contained"
                  color="primary"
                  isChecked={false}
                >
                  削除
                </StyledDeleteButton>
              </Whisper>
            )}
          </>
        );
      default:
        return <div />;
    }
  };

  if (imagesError === 115 || speechError === 115 || videosError === 115) {
    return (
      <>
        <Title>メディアライブラリ</Title>
        <Margin top={20}>
          <div>このページを閲覧する権限がありません</div>
        </Margin>
      </>
    );
  }

  return (
    <>
      <Typography variant="h6">メディアライブラリ</Typography>
      <p>
        待機画面ほか、各種画面に利用する可能性のある画像や動画を追加できます。
      </p>
      <p style={{ color: "#ef4565" }}>
        ※メディアを変更した際には、店舗編集で変更内容を確認して、PCを再起動してください
      </p>
      <BaseLink to={RoutePath.management.shops}>店舗管理画面はこちら</BaseLink>
      <StyledButtons>{renderButtons()}</StyledButtons>
      <Margin top={20}>
        <StyledTabs onSelect={handleSelect} selectedIndex={selected}>
          <TabList style={{ position: "relative", top: "-1px" }}>
            <Tab style={{ padding: "10px 20px" }}>画像</Tab>
            <Tab style={{ padding: "10px 20px" }}>動画</Tab>
            <Tab style={{ padding: "10px 20px" }}>スピーチ</Tab>
          </TabList>

          <TabPanel
            style={{
              overflowY: "scroll",
              minHeight: "300px",
              maxHeight: "500px",
            }}
          >
            {!imagesIsLoading ? (
              <Margin top={10} left={10} right={10} bottom={10}>
                <ImageMedia
                  imagesData={imagesData?.images}
                  checkedImageKeys={checkedImageKeys}
                  handleImageCheck={handleImageCheck}
                />
              </Margin>
            ) : (
              <Loading />
            )}
          </TabPanel>
          <TabPanel
            style={{
              overflowY: "scroll",
              minHeight: "300px",
              maxHeight: "500px",
            }}
          >
            {!videosIsLoading ? (
              <Margin top={10} left={10} right={10} bottom={10}>
                <VideoMedia
                  videosData={videosData?.videos}
                  checkedVideoKeys={checkedVideoKeys}
                  handleVideoCheck={handleVideoCheck}
                />
              </Margin>
            ) : (
              <Loading />
            )}
          </TabPanel>
          <TabPanel
            style={{
              minHeight: "300px",
              maxHeight: "500px",
            }}
          >
            {!speechesIsLoading ? (
              <>
                {speechesData && speechesData !== null ? (
                  <SpeechesTable
                    speeches={speechesData.speeches}
                    id="speeches"
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              <Loading />
            )}
          </TabPanel>
        </StyledTabs>
      </Margin>

      <AddImageModal
        show={showImageAdd}
        onHide={onCancelClick}
        setForm={setImageAddForm}
        submitImage={handleCheckAndSubmitImageAddForm}
      />
      <StyledModal show={showEditImage} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            画像編集
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={addImageModel}
            formValue={addImageFormValue}
            ref={(ref: FormInstance) => setImageAddForm(ref)}
            onChange={handleAddImageFormChange}
          >
            <FormGroup>
              <ControlLabel>画像ID</ControlLabel>
              <FormControl readOnly name="id" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>画像の形</ControlLabel>
              <Select
                value={selectedImageType}
                onChange={event =>
                  setSelectedImageType(event.target.value as string)
                }
              >
                <MenuItem value="horizontal">横長</MenuItem>
                <MenuItem value="vertical">縦長</MenuItem>
              </Select>
            </FormGroup>
            <FormGroup>
              <ControlLabel>
                画像アップロード (推奨サイズ 1727 × 1080px)
              </ControlLabel>
              <Uploader
                accept="image/png,image/jpeg"
                fileListVisible={false}
                autoUpload={false}
                listType="picture"
                onChange={files => {
                  const afile = files.pop();

                  previewFile(afile?.blobFile, async (value: any) => {
                    await setImageFileInfo({
                      name: afile?.name ? afile.name : "",
                      value,
                      size: imageFileInfo.size,
                    });
                    handleAddImageChange(value);
                  });
                }}
              >
                <button
                  style={{ width: "200px", height: "112px" }}
                  type="button"
                >
                  {imageFileInfo.value !== "" ? (
                    <img
                      src={imageFileInfo.value}
                      width="100%"
                      height="100%"
                      alt=""
                    />
                  ) : (
                    <Icon icon="camera" size="5x" />
                  )}
                </button>
              </Uploader>
              {imageFileInfo.size ? (
                ""
              ) : (
                <div style={{ color: "red" }}>
                  <Icon
                    icon="remind"
                    style={{
                      color: "#ffb300",
                      fontSize: 24,
                    }}
                  />
                  アップロードされた画像は推奨サイズではありません
                </div>
              )}
              <ControlLabel>ファイル名</ControlLabel>
              <FormControl
                name="img"
                value={imageFileInfo.name}
                readOnly
                plaintext
              />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {addImageLoading ? (
            <>
              <StyledEditButton
                variant="contained"
                color="primary"
                isChecked={false}
              >
                登録
              </StyledEditButton>
              <StyledEditButton
                variant="contained"
                color="primary"
                isChecked={false}
              >
                Cancel
              </StyledEditButton>
            </>
          ) : (
            <>
              <StyledPrimaryButton
                variant="contained"
                color="primary"
                onClick={handleCheckAndSubmitImageForUpdate}
              >
                登録
              </StyledPrimaryButton>
              <StyledCancelButton
                variant="contained"
                color="primary"
                onClick={onCancelClick}
              >
                取り消し
              </StyledCancelButton>
            </>
          )}
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showImageDelete} overflow onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            画像削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List bordered={false}>
            {checkedImageKeys
              .map(key => {
                return imagesData?.images.filter(
                  image => image.imageId === key
                )[0];
              })
              .map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{
                    paddingLeft: "10px",
                    color: "#d84421",
                  }}
                >
                  <p>
                    画像ID:
                    {item?.imageId}
                  </p>
                  <StyledImg src={item?.image} />
                </List.Item>
              ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: "#d84421",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記の画像を削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          {addImageLoading ? (
            <>
              <StyledEditButton
                variant="contained"
                color="primary"
                isChecked={false}
              >
                削除
              </StyledEditButton>
              <StyledEditButton
                variant="contained"
                color="primary"
                isChecked={false}
              >
                取り消し
              </StyledEditButton>
            </>
          ) : (
            <>
              <StyledDeleteButton
                variant="contained"
                color="primary"
                isChecked
                onClick={handleSubmitDeleteImage}
              >
                削除
              </StyledDeleteButton>
              <StyledCancelButton
                variant="contained"
                color="primary"
                onClick={onCancelClick}
              >
                取り消し
              </StyledCancelButton>
            </>
          )}
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showVideoAdd} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            動画追加
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={addVideoModel}
            formValue={addVideoFormValue}
            ref={(ref: FormInstance) => setAddVideoForm(ref)}
            onChange={handleAddVideoFormChange}
          >
            <FormGroup>
              <ControlLabel>動画ID</ControlLabel>
              <FormControl name="id" placeholder="video1" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>表示テキスト</ControlLabel>
              <FormControl name="text" />
            </FormGroup>

            <FormGroup>
              <ControlLabel>動画アップロード（100MBまで）</ControlLabel>
              <Uploader
                accept="video/mp4"
                fileListVisible={false}
                autoUpload={false}
                listType="picture"
                onChange={files => {
                  const afile = files.pop();
                  if (afile !== undefined) {
                    previewFile(afile.blobFile, async (value: any) => {
                      const media = new Audio(value);
                      console.warn(afile.blobFile?.size);
                      await setVideoFileInfo({
                        name: afile?.name ? afile.name : "",
                        value,
                        file: afile.blobFile,
                        fileSize: afile.blobFile?.size,
                      });
                      media.onloadedmetadata = () => {
                        handleAddVideoChange(
                          afile.name ? afile.name : "",
                          media.duration
                        );
                      };
                    });
                  }
                }}
              >
                <button
                  style={{ width: "200px", height: "112px" }}
                  type="button"
                >
                  {videoFileInfo.value !== "" ? (
                    <video
                      src={videoFileInfo.value}
                      width="100%"
                      height="100%"
                    />
                  ) : (
                    <Icon icon="camera" size="5x" />
                  )}
                </button>
              </Uploader>
              {videoFileInfo.fileSize !== undefined &&
              videoFileInfo.fileSize >= 104857600 ? (
                <div style={{ color: "red" }}>
                  <Icon
                    icon="remind"
                    style={{
                      color: "#ffb300",
                      fontSize: 24,
                    }}
                  />
                  アップロードされた動画はサイズが大きすぎるためアップロードできません
                </div>
              ) : (
                ""
              )}
              <ControlLabel>ファイル名</ControlLabel>
              <FormControl
                name="file"
                value={videoFileInfo.name}
                readOnly
                plaintext
              />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {addVideoLoading || videoLoading ? (
            <>
              <StyledEditButton
                variant="contained"
                color="primary"
                isChecked={false}
              >
                登録
              </StyledEditButton>
              <StyledEditButton
                variant="contained"
                color="primary"
                isChecked={false}
              >
                取り消し
              </StyledEditButton>
            </>
          ) : (
            <>
              <StyledPrimaryButton
                variant="contained"
                color="primary"
                onClick={handleCheckAndSubmitVideoAddForm}
              >
                登録
              </StyledPrimaryButton>
              <StyledCancelButton
                variant="contained"
                color="primary"
                onClick={onCancelClick}
              >
                取り消し
              </StyledCancelButton>
            </>
          )}
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showEditVideo} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            動画編集
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={addVideoModel}
            formValue={addVideoFormValue}
            ref={(ref: FormInstance) => setAddVideoForm(ref)}
            onChange={handleAddVideoFormChange}
          >
            <FormGroup>
              <ControlLabel>動画ID</ControlLabel>
              <FormControl readOnly name="id" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>表示テキスト</ControlLabel>
              <FormControl name="text" />
            </FormGroup>

            <FormGroup>
              <ControlLabel>動画アップロード（100MBまで）</ControlLabel>
              <Uploader
                accept="video/mp4"
                fileListVisible={false}
                autoUpload={false}
                listType="picture"
                onChange={files => {
                  const afile = files.pop();
                  if (afile !== undefined) {
                    previewFile(afile.blobFile, async (value: any) => {
                      const media = new Audio(value);
                      await setVideoFileInfo({
                        name: afile?.name ? afile.name : "",
                        value,
                        file: afile.blobFile,
                        fileSize: afile.blobFile?.size,
                      });
                      media.onloadedmetadata = () => {
                        handleAddVideoChange(
                          afile.name ? afile.name : "",
                          media.duration
                        );
                      };
                    });
                  }
                }}
              >
                <button
                  style={{ width: "200px", height: "112px" }}
                  type="button"
                >
                  {videoFileInfo.value !== "" ? (
                    <video
                      src={videoFileInfo.value}
                      width="100%"
                      height="100%"
                    />
                  ) : (
                    <Icon icon="camera" size="5x" />
                  )}
                </button>
              </Uploader>
              {videoFileInfo.fileSize !== undefined &&
              videoFileInfo.fileSize >= 104857600 ? (
                <div style={{ color: "red" }}>
                  <Icon
                    icon="remind"
                    style={{
                      color: "#ffb300",
                      fontSize: 24,
                    }}
                  />
                  アップロードされた動画はサイズが大きすぎるためアップロードできません
                </div>
              ) : (
                ""
              )}
              <ControlLabel>ファイル名</ControlLabel>
              <FormControl
                name="file"
                value={videoFileInfo.name}
                readOnly
                plaintext
              />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {addVideoLoading || videoLoading ? (
            <>
              <StyledEditButton
                variant="contained"
                color="primary"
                isChecked={false}
              >
                登録
              </StyledEditButton>
              <StyledEditButton
                variant="contained"
                color="primary"
                isChecked={false}
              >
                取り消し
              </StyledEditButton>
            </>
          ) : (
            <>
              <StyledPrimaryButton
                variant="contained"
                color="primary"
                onClick={handleCheckAndSubmitVideoForUpdate}
              >
                登録
              </StyledPrimaryButton>
              <StyledCancelButton
                variant="contained"
                color="primary"
                onClick={onCancelClick}
              >
                取り消し
              </StyledCancelButton>
            </>
          )}
        </Modal.Footer>
      </StyledModal>

      <StyledModal
        show={showVideoDelete}
        overflow
        onHide={onCancelClick}
        isOpen={isOpen}
      >
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            動画削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List bordered={false}>
            {checkedVideoKeys
              .map(key => {
                return videosData?.videos.filter(
                  video => video.videoId === key
                )[0];
              })
              .map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{
                    paddingLeft: "10px",
                    color: "#d84421",
                  }}
                >
                  <p>
                    動画ID:
                    {item!.videoId}
                  </p>
                  <StyledVideo src={item!.url} autoPlay muted>
                    <source src={item!.url} />
                  </StyledVideo>
                </List.Item>
              ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: "#d84421",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記の動画を削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          {addVideoLoading ? (
            <>
              <StyledEditButton
                variant="contained"
                color="primary"
                isChecked={false}
              >
                削除
              </StyledEditButton>
              <StyledEditButton
                variant="contained"
                color="primary"
                isChecked={false}
              >
                取り消し
              </StyledEditButton>
            </>
          ) : (
            <>
              <StyledDeleteButton
                variant="contained"
                color="primary"
                isChecked
                onClick={handleSubmitDeleteVideo}
              >
                削除
              </StyledDeleteButton>
              <StyledCancelButton
                variant="contained"
                color="primary"
                onClick={onCancelClick}
              >
                取り消し
              </StyledCancelButton>
            </>
          )}
        </Modal.Footer>
      </StyledModal>

      <StyledModal
        show={showSpeechAdd}
        overflow={false}
        onHide={onCancelClick}
        isOpen={isOpen}
      >
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            スピーチ音声追加
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={addSpeechMoel}
            formValue={addSpeechFormValue}
            ref={(ref: FormInstance) => setAddSpeechForm(ref)}
            onChange={handleAddSpeechFormChange}
          >
            <FormGroup>
              <ControlLabel>スピーチID</ControlLabel>
              <FormControl name="id" placeholder="speech1" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>表示テキスト</ControlLabel>
              <FormControl name="text" placeholder="スピーチ1" />
            </FormGroup>

            <FormGroup>
              <ControlLabel>スピーチ音声アップロード</ControlLabel>

              <Uploader
                accept="audio/wav, audio/mp3"
                fileListVisible={false}
                autoUpload={false}
                listType="picture"
                onChange={files => {
                  const afile = files.pop();
                  if (afile !== undefined) {
                    previewFile(afile.blobFile, async (value: any) => {
                      const media = new Audio(value);

                      await setSpeechFileInfo({
                        name: afile?.name ? afile.name : "",
                        value,
                        file: afile.blobFile,
                      });
                      media.onloadedmetadata = () => {
                        handleAddSpeechChange(
                          afile.name ? afile.name : "",
                          media.duration
                        );
                      };
                    });
                  }
                }}
              >
                <button
                  style={{ width: "400px", height: "112px" }}
                  type="button"
                >
                  {speechFileInfo.value !== "" ? (
                    <audio src={speechFileInfo.value} controls />
                  ) : (
                    <Icon icon="file-audio-o" size="5x" />
                  )}
                </button>
              </Uploader>
              <ControlLabel>ファイル名</ControlLabel>
              <FormControl
                name="file"
                value={speechFileInfo.name}
                readOnly
                plaintext
              />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {addSpeechIsLoading ? (
            <>
              <StyledEditButton isChecked={false}>登録</StyledEditButton>
              <StyledEditButton isChecked={false}>取り消し</StyledEditButton>
            </>
          ) : (
            <>
              <StyledPrimaryButton
                variant="contained"
                color="primary"
                onClick={handleRec}
              >
                スピーチを録音する
              </StyledPrimaryButton>
              <StyledPrimaryButton
                variant="contained"
                color="primary"
                onClick={handleCheckAndSubmitSpeechAddForm}
              >
                登録
              </StyledPrimaryButton>
              <StyledCancelButton
                variant="contained"
                color="primary"
                onClick={onCancelClick}
              >
                取り消し
              </StyledCancelButton>
            </>
          )}
        </Modal.Footer>
      </StyledModal>

      <StyledModal
        show={showEditSpeech}
        overflow={false}
        onHide={onCancelClick}
        isOpen={isOpen}
      >
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            スピーチ音声編集
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={addSpeechMoel}
            formValue={addSpeechFormValue}
            ref={(ref: FormInstance) => setAddSpeechForm(ref)}
            onChange={handleAddSpeechFormChange}
          >
            <FormGroup>
              <ControlLabel>スピーチID</ControlLabel>
              <FormControl name="id" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>表示テキスト</ControlLabel>
              <FormControl name="text" />
            </FormGroup>

            <FormGroup>
              <ControlLabel>スピーチ音声アップロード</ControlLabel>
              <Uploader
                accept="audio/wav, audio/mp3"
                fileListVisible={false}
                autoUpload={false}
                listType="picture"
                onChange={files => {
                  const afile = files.pop();
                  if (afile !== undefined) {
                    previewFile(afile.blobFile, async (value: any) => {
                      const media = new Audio(value);

                      await setSpeechFileInfo({
                        name: afile?.name ? afile.name : "",
                        value,
                        file: afile.blobFile,
                      });
                      media.onloadedmetadata = () => {
                        handleAddSpeechChange(
                          afile.name ? afile.name : "",
                          media.duration
                        );
                      };
                    });
                  }
                }}
              >
                <button
                  style={{ width: "400px", height: "112px" }}
                  type="button"
                >
                  {speechFileInfo.value !== "" ? (
                    <audio src={speechFileInfo.value} controls />
                  ) : (
                    <Icon icon="file-audio-o" size="5x" />
                  )}
                </button>
              </Uploader>
              <ControlLabel>ファイル名</ControlLabel>
              <FormControl
                name="file"
                value={speechFileInfo.name}
                readOnly
                plaintext
              />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {addSpeechIsLoading ? (
            <>
              <StyledEditButton
                variant="contained"
                color="primary"
                isChecked={false}
              >
                登録
              </StyledEditButton>
              <StyledEditButton
                variant="contained"
                color="primary"
                isChecked={false}
              >
                取り消し
              </StyledEditButton>
            </>
          ) : (
            <>
              <StyledPrimaryButton
                variant="contained"
                color="primary"
                onClick={handleCheckAndSubmitSpeechForUpdate}
              >
                登録
              </StyledPrimaryButton>
              <StyledCancelButton
                variant="contained"
                color="primary"
                onClick={onCancelClick}
              >
                取り消し
              </StyledCancelButton>
            </>
          )}
        </Modal.Footer>
      </StyledModal>

      <StyledModal
        show={showSpeechRec}
        full
        onHide={() => {
          setShowSpeechRec(false);
        }}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          スピーチ音声録音
        </Modal.Header>
        <Modal.Body>
          <Record
            onSet={setShowSpeechRec}
            onSpeechInfo={setSpeechFileInfo}
            handleAddSpeechChange={handleAddSpeechChange}
          />
        </Modal.Body>
      </StyledModal>

      <StyledModal
        show={showSpeechDelete}
        overflow
        onHide={onCancelClick}
        isOpen={isOpen}
      >
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            スピーチ音声削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List bordered={false}>
            {checkedSpeechKeys
              .map(key => {
                return speechesData?.speeches.filter(
                  speech => speech.speechId === key
                )[0];
              })
              .map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{
                    paddingLeft: "10px",
                    color: "#d84421",
                  }}
                >
                  <p>
                    スピーチID:
                    {item ? item.speechId : ""}
                  </p>
                  <audio src={item ? item.url : ""} controls>
                    <source src={item ? item.url : ""} />
                  </audio>
                </List.Item>
              ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: "#d84421",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記のスピーチ音声を削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          {addVideoLoading ? (
            <>
              <StyledEditButton
                variant="contained"
                color="primary"
                isChecked={false}
              >
                削除
              </StyledEditButton>
              <StyledEditButton
                variant="contained"
                color="primary"
                isChecked={false}
              >
                取り消し
              </StyledEditButton>
            </>
          ) : (
            <>
              <StyledDeleteButton
                variant="contained"
                color="primary"
                isChecked
                onClick={handleSubmitDeleteSpeech}
              >
                削除
              </StyledDeleteButton>
              <StyledCancelButton
                variant="contained"
                color="primary"
                onClick={onCancelClick}
              >
                取り消し
              </StyledCancelButton>
            </>
          )}
        </Modal.Footer>
      </StyledModal>
    </>
  );
};

export default Media;
