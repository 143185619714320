import React, { createContext, Dispatch, useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../organisms/Nav/NavHeader";
import Sidebar from "../../organisms/Nav/Sidebar";
import MinimumSidebar from "../../organisms/Nav/MinimumSidebar";
import { me } from "../../../../modules/auth";
import { RootState } from "../../../../reducers/rootReducer";

const AppRoot = styled.div`
  display: flex;
`;

export const StyledContent = styled.main`
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
`;

const StyledAppBarSpacer = styled.div`
  ${props => props.theme.mixins.toolbar}
`;

const StyledContainer = styled(Container)`
  padding-top: ${props => props.theme.spacing(4)}px;
  padding-bottom: ${props => props.theme.spacing(4)}px;
  background-color: ${props => props.theme.palette.background.default};
`;
interface IContextProps {
  isOpen: boolean;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
}

export const SidebarContext = createContext({} as IContextProps);

const MainLayout = (props: any) => {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { token } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (token) dispatch(me(token));
  }, [dispatch, token]);

  return (
    <AppRoot>
      <SidebarContext.Provider value={{ isOpen, setIsOpen }}>
        <Header />
        {process.env.REACT_APP_MINIMUM === "on" ? (
          <MinimumSidebar />
        ) : (
          <Sidebar />
        )}
        <StyledContent>
          <StyledAppBarSpacer />
          <StyledContainer maxWidth={false}>{children}</StyledContainer>
        </StyledContent>
      </SidebarContext.Provider>
    </AppRoot>
  );
};

export default MainLayout;
