/* eslint-disable prefer-promise-reject-errors */
import { PayloadAction } from "@reduxjs/toolkit";
import fetcher from "./wrappedFetch";
import snakeToCamel from "../utils/snakeToCamel";

export interface ErrorDetail extends Error {
  resultStatus?: number;
  resultText?: string;
}

type Options = { method?: string; body?: string | FormData };

export interface ApiState<T> {
  isLoading: boolean;
  error: string | number | null;
  data: T | null;
}

export const apiInitialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const startLoading = <T>(state: ApiState<T>) => {
  state.isLoading = true;
};

export const loadingFailed = <T>(
  state: ApiState<T>,
  action: PayloadAction<string>
) => {
  state.isLoading = false;
  state.error = action.payload;
};

const apiService = {
  createHeaders(token?: string) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Call-System", "AnalysisScreen");

    if (token) {
      headers.append("Authentication-Token", token);
    }
    return headers;
  },
  createFormHeaders(token?: string) {
    const headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Call-System", "AnalysisScreen");

    if (token) {
      headers.append("Authentication-Token", token);
    }
    return headers;
  },

  async request<T = any>(api: {
    path: string;
    req: { method: string; headers?: Headers; body?: string | FormData };
  }): Promise<T> {
    const baseUrl = process.env.REACT_APP_API_SERVER_URL || "";
    const url = baseUrl + api.path;
    const response = await fetcher(url, api.req);
    return snakeToCamel(response);
  },
};

export default apiService;

export const api = (path: string, options: Options): Promise<Response> => {
  const headers = new Headers();
  headers.append("Call-System", "AnalysisScreen");
  if (typeof options.body === "string") {
    headers.append("Content-Type", "application/json");
  }

  const baseUrl = process.env.REACT_APP_API_SERVER_URL || "";
  const url = baseUrl + path;
  return fetch(url, {
    // credentials: "include",
    headers,
    ...options,
  });
};

export const request = <T>(
  method: string,
  path: string,
  body?: object | FormData
): Promise<T> => {
  return new Promise<T>((resolve, reject: (error: ErrorDetail) => void) => {
    const sendingOptions: Options = {};
    sendingOptions.method = method;
    if (body !== undefined) {
      sendingOptions.body =
        body instanceof FormData ? body : JSON.stringify(body);
    }

    api(path, sendingOptions)
      .then(response => {
        if (response.status >= 300 || response.status < 200) {
          reject({
            resultStatus: response.status,
            resultText: response.statusText,
            name: "error",
            message: response.statusText,
          } as ErrorDetail);
        }
        return response.json();
      })
      .then(responseData => resolve(snakeToCamel(responseData)))
      .catch(e => {
        reject(e);
      });
  });
};

export function get<T>(path: string): Promise<T> {
  return request<T>("GET", path, undefined);
}

export function post<T>(path: string, body?: object): Promise<T> {
  return request<T>("POST", path, body);
}

export function put<T>(path: string, body?: object): Promise<T> {
  return request<T>("PUT", path, body);
}

export function del<T>(path: string, body?: object): Promise<T> {
  return request<T>("DELETE", path, body);
}
