import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    mail: string;
    auth: number;
    userType: number;
    roomGroupId: string;
    memo?: string | null;
  };
};

const initialState: FormValueState = {
  formValue: {
    mail: "",
    auth: 0,
    userType: 0,
    roomGroupId: "",
    memo: "",
  },
};

const userAddFormModule = createSlice({
  name: "userForm",
  initialState,
  reducers: {
    setAddFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.mail = payload.formValue.mail;
      state.formValue.auth = payload.formValue.auth;
      state.formValue.userType = payload.formValue.userType;
      state.formValue.roomGroupId = payload.formValue.roomGroupId;
      state.formValue.memo = payload.formValue.memo
        ? payload.formValue.memo
        : "";
    },
  },
});

export const { setAddFormValue } = userAddFormModule.actions;

export default userAddFormModule.reducer;
