import styled from "styled-components";
import { black } from "../../../../utils/color";
import { headerFont } from "../../../../utils/fonts";

const Title = styled.h1`
  display: block;
  text-align: left;
  width: 100%;
  color: ${black};
  font-size: 16px;
  font-weight: bold;
  font-family: ${headerFont};
`;

export default Title;
