import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { uuidv4 } from "../../../utils/uuid";

export type FormValueState = {
  formValue: {
    id: string;
    title: string;
    text: string;
    remark: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    id: "",
    title: "",
    text: "",
    remark: "",
  },
};

const memoAddFormModule = createSlice({
  name: "memoForm",
  initialState,
  reducers: {
    setAddMemoFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.id = payload.formValue.id ? payload.formValue.id : "";
      state.formValue.title = payload.formValue.title
        ? payload.formValue.title
        : "";
      state.formValue.text = payload.formValue.text
        ? payload.formValue.text
        : "";
      state.formValue.remark = payload.formValue.remark
        ? payload.formValue.remark
        : "";
    },
    generateMemoId(state: FormValueState) {
      state.formValue.id = uuidv4();
    },
  },
});

export const {
  setAddMemoFormValue,
  generateMemoId,
} = memoAddFormModule.actions;

export default memoAddFormModule.reducer;
