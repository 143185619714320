/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Modal,
  Form,
  Schema,
  FormGroup,
  ControlLabel,
  FormControl,
  SelectPicker,
  Alert,
} from "rsuite";
import { FormInstance } from "rsuite/lib/Form";
import { Typography } from "@material-ui/core";
import { RootState } from "../../../../reducers/rootReducer";
import { fetchInfoButtons } from "../../../../modules/Management/InfoButtons/infoButtonApiModule";
import { Margin } from "../../../../utils/styled";
import { black } from "../../../../utils/color";
import { InfoButton } from "../../../../api/Management/infoButtonApi";
import Loading from "../../../presentational/molecules/Loading";
import PrimaryButton from "../../../presentational/atoms/Button/PrimaryButton";
import {
  setAddFormValue,
  setInitialAddFormValue,
} from "../../../../modules/Management/Form/InfoButton/infoButtonAddFormModule";
import CancelButton from "../../../presentational/atoms/Button/CancelButton";
import { fetchInfoButtonTypes } from "../../../../modules/Management/InfoButtons/getInfoButtonTypeApiModule";
import { postInfoButton } from "../../../../modules/Management/InfoButtons/addInfoButtonApiModule";
import { StyledDeleteButton, StyledModal } from "./Users";
import { delInfoButton } from "../../../../modules/Management/InfoButtons/deleteInfoButtonApiModule";
import { setEditFormValue } from "../../../../modules/Management/Form/InfoButton/infoButtonEditFormModule";
import { putInfoButton } from "../../../../modules/Management/InfoButtons/updateInfoButtonApiModule";
import { postInfoButtonType } from "../../../../modules/Management/InfoButtons/addInfoButtonTypeApiModule";

export const StyledPrimaryButton = styled(PrimaryButton)`
  display: inline-block;
  margin-right: 5px;
  &:hover {
    color: #fff;
    border-radius: 20px;
    transition: 0.25s;
  }
  transition: 0.25s;
`;

export const StyledCancelButton = styled(CancelButton)`
  &:hover {
    color: #fff;
    border-radius: 20px;
    transition: 0.25s;
  }
  transition: 0.25s;
`;

const StyledButtonTypeLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const displayTypeMap = [
  { displayTypeId: "ec32e79c-ddd5-4265-bff3-c7bbc4c9cb84", name: "接客中" },
  { displayTypeId: "e4c0dddc-89a3-40ad-b6b6-f4d65631203c", name: "接客後" },
];

const addModel = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  text: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  val: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  infoButtonTypeId: Schema.Types.StringType().isRequired("必須項目です"),
  displayTypeId: Schema.Types.StringType().isRequired("必須項目です"),
});

const addNewModel = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  text: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  val: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  infoButtonTypeId: Schema.Types.StringType().isRequired("必須項目です"),
  newInfoButtonTypeId: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  newInfoButtonTypeText: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
  newInfoType: Schema.Types.StringType().isRequired("必須項目です"),
  displayTypeId: Schema.Types.StringType().isRequired("必須項目です"),
});

const AnalysisInfo = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [addForm, setAddForm] = useState<FormInstance>();

  const [showEdit, setShowEdit] = useState(false);
  const [editForm, setEditForm] = useState<FormInstance>();

  const [showDelete, setShowDelete] = useState(false);
  const [willDeleteId, setWillDeleteId] = useState("");

  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.auth);

  const { data, isLoading } = useSelector(
    (state: RootState) => state.infoButton
  );

  const {
    data: infoButtonTypesData,
    isLoading: infoButtonTypesLoading,
  } = useSelector((state: RootState) => state.getInfoButtonTypes);

  const infoButtonTypeIds = useMemo(() => {
    if (infoButtonTypesData && infoButtonTypesData.infoButtonTypes) {
      return infoButtonTypesData.infoButtonTypes.map(
        infoButtonType => infoButtonType.id
      );
    }
    return [];
  }, [infoButtonTypesData]);

  const infoButtonTypeOptions = useMemo(() => {
    if (infoButtonTypesData && infoButtonTypesData.infoButtonTypes) {
      return infoButtonTypesData.infoButtonTypes.map(infoButtonType => {
        return {
          label: infoButtonType.text,
          value: infoButtonType.id,
        };
      });
    }
    return [];
  }, [infoButtonTypesData]);

  const filtered = useMemo(() => {
    if (infoButtonTypeIds && data) {
      return infoButtonTypeIds.map(id => {
        const filteredInfoButtons = data.infoButtons.filter(
          infoButton => infoButton.infoButtonTypeId === id
        );
        return filteredInfoButtons;
      });
    }
    return [];
  }, [data, infoButtonTypeIds]);

  const { formValue: addFormValue } = useSelector(
    (state: RootState) => state.infoButtonAddForm
  );

  const { formValue: editFormValue } = useSelector(
    (state: RootState) => state.infoButtonEditForm
  );

  useEffect(() => {
    if (token) {
      dispatch(fetchInfoButtonTypes(token));
      dispatch(fetchInfoButtons(token));
    }
  }, [dispatch, token]);

  const handleAddClick = () => {
    setShowAdd(true);
  };

  const handleAddFormChange = (value: any) => {
    dispatch(setAddFormValue({ formValue: value }));
  };

  const handleEditClick = (id: string) => {
    const infoButtonData = data?.infoButtons.filter(
      infoButton => infoButton.id === id
    )[0];
    dispatch(
      setEditFormValue({
        formValue: {
          id,
          text: infoButtonData?.text,
          val: infoButtonData?.val,
          infoButtonTypeId: infoButtonData?.infoButtonTypeId,
          displayTypeId: infoButtonData?.displayTypeId,
          remark: infoButtonData?.remark,
        },
      })
    );
    setShowEdit(true);
  };

  const handleEditFormChange = (value: any) => {
    dispatch(setEditFormValue({ formValue: value }));
  };

  const handleDeleteClick = (id: string) => {
    setWillDeleteId(id);
    setShowDelete(true);
  };

  const onCancelClick = () => {
    setShowAdd(false);
    setShowDelete(false);
    setShowEdit(false);
  };

  const handleCheckAndSubmitAddForm = async () => {
    const checkResult = addForm!.check();
    if (checkResult && token) {
      try {
        if (
          addFormValue.infoButtonTypeId ===
          "create_new_rura_analysis_info_option"
        ) {
          await dispatch(
            postInfoButtonType(
              token,
              addFormValue.newInfoButtonTypeId,
              addFormValue.newInfoButtonTypeText,
              addFormValue.newInfoType
            )
          );
          await dispatch(
            postInfoButton(
              token,
              addFormValue.id,
              addFormValue.text,
              addFormValue.val,
              "",
              addFormValue.newInfoButtonTypeId,
              addFormValue.displayTypeId,
              addFormValue.remark
            )
          );
        } else {
          await dispatch(
            postInfoButton(
              token,
              addFormValue.id,
              addFormValue.text,
              addFormValue.val,
              "",
              addFormValue.infoButtonTypeId,
              addFormValue.displayTypeId,
              addFormValue.remark
            )
          );
        }
        dispatch(setInitialAddFormValue());
        setShowAdd(false);
        Alert.success("分析情報を追加しました", 5000);
        await dispatch(fetchInfoButtons(token));
        await dispatch(fetchInfoButtonTypes(token));
      } catch (err) {
        Alert.error("入力したIDはすでに登録されています", 2000);
      }
    }
  };

  const handleCheckAndSubmitEditForm = async () => {
    const checkResult = editForm!.check();
    if (checkResult && token) {
      try {
        if (
          editFormValue.infoButtonTypeId ===
          "create_new_rura_analysis_info_option"
        ) {
          await dispatch(
            postInfoButtonType(
              token,
              editFormValue.newInfoButtonTypeId
                ? editFormValue.newInfoButtonTypeId
                : "",
              editFormValue.newInfoButtonTypeText
                ? editFormValue.newInfoButtonTypeText
                : "",
              editFormValue.newInfoType ? editFormValue.newInfoType : ""
            )
          );
          await dispatch(
            putInfoButton(
              token,
              editFormValue.id ? editFormValue.id : "",
              editFormValue.text ? editFormValue.text : "",
              editFormValue.val ? editFormValue.val : "",
              "",
              editFormValue.newInfoButtonTypeId
                ? editFormValue.newInfoButtonTypeId
                : "",
              editFormValue.displayTypeId ?? "",
              editFormValue.remark ? editFormValue.remark : ""
            )
          );
        } else {
          await dispatch(
            putInfoButton(
              token,
              editFormValue.id ? editFormValue.id : "",
              editFormValue.text ? editFormValue.text : "",
              editFormValue.val ? editFormValue.val : "",
              "",
              editFormValue.infoButtonTypeId
                ? editFormValue.infoButtonTypeId
                : "",
              editFormValue.displayTypeId ?? "",
              editFormValue.remark ? editFormValue.remark : ""
            )
          );
        }
        Alert.success("分析情報の更新をしました", 5000);
        setShowEdit(false);
        await dispatch(fetchInfoButtons(token));
        await dispatch(fetchInfoButtonTypes(token));
      } catch (err) {
        Alert.error("不明なエラーが発生しました", 2000);
      }
    }
  };

  const handleSubmitDelete = async () => {
    if (token && willDeleteId !== "") {
      try {
        await dispatch(delInfoButton(token, willDeleteId));
        setWillDeleteId("");
        setShowDelete(false);
        Alert.success("分析情報を削除しました", 5000);
        await dispatch(fetchInfoButtons(token));
        await dispatch(fetchInfoButtonTypes(token));
      } catch (err) {
        Alert.error("不明なエラーが発生しました", 2000);
      }
    }
  };
  return (
    <>
      <Typography variant="h6">分析情報管理</Typography>
      <Margin top={20} />
      <StyledPrimaryButton onClick={handleAddClick}>
        分析情報追加
      </StyledPrimaryButton>

      <Margin top={20} />
      {!isLoading && !infoButtonTypesLoading ? (
        filtered.length !== 0 ? (
          filtered.map((ib, index) => {
            if (ib.length === 0) {
              return "";
            }
            return (
              <Margin top={20} left={10} key={index.toString()}>
                <StyledButtonTypeLabel>
                  {ib[0].infoButtonTypeText}
                </StyledButtonTypeLabel>
                <Margin top={5}>
                  <Table data={ib} id="table" wordWrap autoHeight>
                    <Table.Column
                      minWidth={300}
                      flexGrow={1}
                      fixed
                      verticalAlign="middle"
                    >
                      <Table.HeaderCell
                        style={{ fontWeight: "bold", color: black }}
                      >
                        分析情報ID
                      </Table.HeaderCell>
                      <Table.Cell dataKey="id">
                        {(rowData: InfoButton) => {
                          return <div>{rowData.id}</div>;
                        }}
                      </Table.Cell>
                    </Table.Column>
                    <Table.Column
                      minWidth={300}
                      flexGrow={1}
                      verticalAlign="middle"
                    >
                      <Table.HeaderCell
                        style={{ fontWeight: "bold", color: black }}
                      >
                        分析情報名
                      </Table.HeaderCell>
                      <Table.Cell dataKey="text">
                        {(rowData: InfoButton) => {
                          return <div>{rowData.text}</div>;
                        }}
                      </Table.Cell>
                    </Table.Column>
                    <Table.Column
                      minWidth={300}
                      flexGrow={1}
                      verticalAlign="middle"
                    >
                      <Table.HeaderCell
                        style={{ fontWeight: "bold", color: black }}
                      >
                        分析タイミング
                      </Table.HeaderCell>
                      <Table.Cell dataKey="text">
                        {(rowData: InfoButton) => {
                          return (
                            <div>
                              {
                                displayTypeMap.find(
                                  type =>
                                    type.displayTypeId === rowData.displayTypeId
                                )?.name
                              }
                            </div>
                          );
                        }}
                      </Table.Cell>
                    </Table.Column>
                    <Table.Column
                      minWidth={300}
                      flexGrow={1}
                      verticalAlign="middle"
                    >
                      <Table.HeaderCell
                        style={{ fontWeight: "bold", color: black }}
                      >
                        備考
                      </Table.HeaderCell>
                      <Table.Cell dataKey="remark">
                        {(rowData: InfoButton) => {
                          return <div>{rowData.remark}</div>;
                        }}
                      </Table.Cell>
                    </Table.Column>

                    <Table.Column
                      minWidth={100}
                      flexGrow={1}
                      verticalAlign="middle"
                    >
                      <Table.HeaderCell
                        style={{ fontWeight: "bold", color: black }}
                      >
                        アクション
                      </Table.HeaderCell>
                      <Table.Cell dataKey="id">
                        {(rowData: InfoButton) => {
                          return (
                            <>
                              <StyledPrimaryButton
                                onClick={() => handleEditClick(rowData.id)}
                              >
                                編集
                              </StyledPrimaryButton>

                              <StyledDeleteButton
                                isChecked
                                onClick={() => handleDeleteClick(rowData.id)}
                              >
                                削除
                              </StyledDeleteButton>
                            </>
                          );
                        }}
                      </Table.Cell>
                    </Table.Column>
                  </Table>
                </Margin>
              </Margin>
            );
          })
        ) : (
          <div>データがありません</div>
        )
      ) : (
        <Loading />
      )}

      <StyledModal show={showAdd} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            分析情報追加
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={
              addFormValue.infoButtonTypeId !==
              "create_new_rura_analysis_info_option"
                ? addModel
                : addNewModel
            }
            formValue={addFormValue}
            ref={(ref: FormInstance) => setAddForm(ref)}
            onChange={handleAddFormChange}
          >
            <FormGroup>
              <ControlLabel>分析情報ID</ControlLabel>
              <FormControl name="id" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>分析情報名</ControlLabel>
              <FormControl name="text" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>分析情報値</ControlLabel>
              <FormControl name="val" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>分析タイプ</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                searchable
                data={[
                  {
                    label: "新規作成",
                    value: "create_new_rura_analysis_info_option",
                  },
                ].concat(infoButtonTypeOptions)}
                name="infoButtonTypeId"
                placeholder="分析タイプを選択してください"
              />
            </FormGroup>
            {addFormValue.infoButtonTypeId ===
            "create_new_rura_analysis_info_option" ? (
              <FormGroup>
                <ControlLabel>分析タイプID</ControlLabel>
                <FormControl name="newInfoButtonTypeId" />
                <ControlLabel>分析タイプ名</ControlLabel>
                <FormControl name="newInfoButtonTypeText" />
                <ControlLabel>分析情報タイプ</ControlLabel>
                <FormControl
                  accepter={SelectPicker}
                  searchable
                  data={[
                    {
                      label: "分析項目",
                      value: "contents",
                    },
                  ]}
                  name="newInfoType"
                  placeholder="分析情報タイプを選択してください"
                />
              </FormGroup>
            ) : (
              ""
            )}
            <FormGroup>
              <ControlLabel>分析タイミング</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                searchable={false}
                data={[
                  {
                    label: "接客中",
                    value: "ec32e79c-ddd5-4265-bff3-c7bbc4c9cb84",
                  },
                  {
                    label: "接客後",
                    value: "e4c0dddc-89a3-40ad-b6b6-f4d65631203c",
                  },
                ]}
                name="displayTypeId"
                placeholder="分析タイミングを選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="remark" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <StyledPrimaryButton onClick={handleCheckAndSubmitAddForm}>
            OK
          </StyledPrimaryButton>
          <StyledCancelButton onClick={onCancelClick}>
            Cancel
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showEdit} overflow={false} onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            分析情報編集
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={
              editFormValue.infoButtonTypeId !==
              "create_new_rura_analysis_info_option"
                ? addModel
                : addNewModel
            }
            formValue={editFormValue}
            ref={(ref: FormInstance) => setEditForm(ref)}
            onChange={handleEditFormChange}
          >
            <FormGroup>
              <ControlLabel>分析情報ID</ControlLabel>
              <FormControl readOnly name="id" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>分析情報名</ControlLabel>
              <FormControl name="text" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>分析情報値</ControlLabel>
              <FormControl name="val" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>分析タイプ</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                searchable
                data={infoButtonTypeOptions.concat([
                  {
                    label: "新規作成",
                    value: "create_new_rura_analysis_info_option",
                  },
                ])}
                name="infoButtonTypeId"
                placeholder="分析タイプを選択してください"
              />
            </FormGroup>
            {editFormValue.infoButtonTypeId ===
            "create_new_rura_analysis_info_option" ? (
              <FormGroup>
                <ControlLabel>分析タイプID</ControlLabel>
                <FormControl name="newInfoButtonTypeId" />
                <ControlLabel>分析タイプ名</ControlLabel>
                <FormControl name="newInfoButtonTypeText" />
                <ControlLabel>分析情報タイプ</ControlLabel>
                <FormControl
                  accepter={SelectPicker}
                  searchable
                  data={[
                    {
                      label: "分析項目",
                      value: "contents",
                    },
                  ]}
                  name="newInfoType"
                  placeholder="分析情報タイプを選択してください"
                />
              </FormGroup>
            ) : (
              ""
            )}
            <FormGroup>
              <ControlLabel>分析タイミング</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                searchable={false}
                data={[
                  {
                    label: "接客中",
                    value: "ec32e79c-ddd5-4265-bff3-c7bbc4c9cb84",
                  },
                  {
                    label: "接客後",
                    value: "e4c0dddc-89a3-40ad-b6b6-f4d65631203c",
                  },
                ]}
                name="displayTypeId"
                placeholder="分析タイミングを選択してください"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="remark" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <StyledPrimaryButton onClick={handleCheckAndSubmitEditForm}>
            OK
          </StyledPrimaryButton>
          <StyledCancelButton onClick={onCancelClick}>
            Cancel
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showDelete} overflow onHide={onCancelClick}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            分析情報削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              paddingLeft: "10px",
              color: "#d84421",
            }}
          >
            {willDeleteId}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: "#d84421",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記の分析情報を削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          <StyledDeleteButton isChecked onClick={handleSubmitDelete}>
            OK
          </StyledDeleteButton>
          <StyledCancelButton onClick={onCancelClick}>
            Cancel
          </StyledCancelButton>
        </Modal.Footer>
      </StyledModal>
    </>
  );
};

export default AnalysisInfo;
