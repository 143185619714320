import { Button } from "@material-ui/core";
import styled from "styled-components";

const MuiDangerButton = styled(Button)`
  background-color: ${props => props.theme.palette.error.main};
  &:hover {
    background-color: ${props => props.theme.palette.error.dark};
  }
  color: ${props => props.theme.palette.text.secondary};
`;

export default MuiDangerButton;
