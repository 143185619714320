import styled from "styled-components";
import { black } from "../../../../utils/color";
import { headerFont } from "../../../../utils/fonts";

const SubTitle = styled.h2`
  display: block;
  text-align: left;
  width: 100%;
  color: ${black};
  font-size: 14px;
  font-weight: bold;
  font-family: ${headerFont};
`;

export default SubTitle;
