import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    id: string;
    name: string;
    type: string;
    infoType: string;
    infoButtonId: string;
    infoButtonIds: string[];
  };
};

const initialState: FormValueState = {
  formValue: {
    id: "",
    name: "",
    type: "",
    infoType: "",
    infoButtonId: "",
    infoButtonIds: [],
  },
};

const graphEditFormModule = createSlice({
  name: "graphEditForm",
  initialState,
  reducers: {
    setEditFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.id = payload.formValue.id;
      state.formValue.name = payload.formValue.name;
      state.formValue.type = payload.formValue.type;
      state.formValue.infoType = payload.formValue.infoType;
      state.formValue.infoButtonId = payload.formValue.infoButtonId;
      state.formValue.infoButtonIds = payload.formValue.infoButtonIds;
    },
  },
});

export const { setEditFormValue } = graphEditFormModule.actions;

export default graphEditFormModule.reducer;
