import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { FormGroup, ControlLabel } from "rsuite";
import styled from "styled-components";
import { changeSpeechMic } from "../../../../modules/Management/Media/speechMicModule";

interface MediaInfoType {
  value: string;
  label: string;
}

const SelectLabel = styled(ControlLabel)`
  display: block;
  margin: 8px;
`;

const getMicList = async (devices: MediaDeviceInfo[]) => {
  const speechMicList: MediaInfoType[] = [];
  devices.forEach(device => {
    if (device.kind === "audioinput") {
      speechMicList.push({ value: device.deviceId, label: device.label });
    }
  });

  return speechMicList;
};

const SelectBoxForRecord = () => {
  const [speechMicList, setSpeechMicList] = useState([] as MediaInfoType[]);
  const dispatch = useDispatch();

  const getDevices = async () => {
    try {
      const devices = await window.navigator.mediaDevices
        .enumerateDevices()
        .then();
      const list = (await getMicList(devices)) as MediaInfoType[];
      setSpeechMicList(list);
    } catch (err) {
      console.error(err);
    }
  };

  const onChange = (selectedOptions: any) => {
    dispatch(changeSpeechMic({ selectedOptions }));
  };

  useEffect(() => {
    async function loadStream() {
      await getDevices();
    }
    loadStream();
  }, []);

  return (
    <FormGroup>
      <SelectLabel>マイク</SelectLabel>
      <Select
        defaultValue={speechMicList[0]}
        options={speechMicList}
        onChange={onChange}
      />
    </FormGroup>
  );
};

export default SelectBoxForRecord;
