import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormInstance } from "rsuite/lib/Form";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Schema,
  Alert,
} from "rsuite";
import { RootState } from "../../../reducers/rootReducer";
import { checkPreUserStatus } from "../../../modules/SignUp/checkSignUpApiModule";
import { setForgotPasswordFormValue } from "../../../modules/forgotPasswordFormModule";
import { StyledPrimaryButton, StyledCancelButton } from "./Management/Users";
import { Margin } from "../../../utils/styled";
import { RoutePath } from "../../../Routes";
import { postResetMail } from "../../../modules/Management/Profile/resetPasswordMailApiModule";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
`;

const FormWrapper = styled.div`
  display: inline-block;
  margin: 30px auto;
`;

const StyledImage = styled.img`
  width: 30%;
  height: 100px;
  object-fit: cover;
`;

const model = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
});

const ForgotPassword = () => {
  const [form, setForm] = useState<FormInstance>();
  const dispatch = useDispatch();
  const { uuid } = useParams<{ uuid: string }>();
  const history = useHistory();

  const { isLoading } = useSelector(
    (state: RootState) => state.resetPasswordMail
  );

  const { formValue } = useSelector(
    (state: RootState) => state.forgotPasswordForm
  );

  useEffect(() => {
    if (uuid) {
      dispatch(checkPreUserStatus(uuid));
    }
  }, [dispatch, uuid]);

  const handleFormChange = (value: any) => {
    dispatch(setForgotPasswordFormValue({ formValue: value }));
  };

  const handleSubmit = async () => {
    const checkResult = form!.check();
    if (checkResult) {
      try {
        Alert.info("メールを送信しています...", 100000000);
        await dispatch(postResetMail(formValue.id));
        Alert.close();
        Alert.success("パスワードリセットのメールを送信しました", 5000);
        await history.push(RoutePath.top);
      } catch (err) {
        Alert.close();
        switch (err.resultStatus) {
          case 131:
            Alert.error("IDが間違っていないか確かめてください", 5000);
            break;
          default:
            console.error(err);
        }
      }
    }
  };

  return (
    <>
      <Wrapper>
        <StyledImage src="/logo.png" alt="RURAロゴ" />
        <FormWrapper>
          <Form
            model={model}
            formValue={formValue}
            ref={(ref: FormInstance) => setForm(ref)}
            onChange={handleFormChange}
          >
            <FormGroup>
              <ControlLabel style={{ textAlign: "left" }}>
                遠隔スタッフID
              </ControlLabel>
              <FormControl name="id" />
            </FormGroup>
          </Form>
          <Margin top={20}>
            {!isLoading ? (
              <StyledPrimaryButton onClick={handleSubmit}>
                メールを送信する
              </StyledPrimaryButton>
            ) : (
              <StyledCancelButton>送信中</StyledCancelButton>
            )}
          </Margin>
        </FormWrapper>
      </Wrapper>
    </>
  );
};

export default ForgotPassword;
