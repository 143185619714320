/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled, { css } from "styled-components";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import {
  Col,
  ControlLabel,
  FlexboxGrid,
  Form,
  FormControl,
  FormGroup,
  List,
  Modal,
  Schema,
} from "rsuite";
import { FormInstance } from "rsuite/lib/Form";
import { useDispatch, useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { IconButton, Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import Title from "../../../../presentational/atoms/Text/Title";
import { Margin } from "../../../../../utils/styled";
import { RootState } from "../../../../../reducers/rootReducer";
import { setShopEditFormValue } from "../../../../../modules/Management/Form/shopEditFormModule";
import { fetchShop } from "../../../../../modules/Management/Pcs/shopApiModule";
import { setCheckedKeys } from "../../../../../modules/Management/checkModule";
import {
  StyledCancelButton,
  StyledDeleteButton,
  StyledPrimaryButton,
} from "../Users";
import AddImageModal from "../../../../presentational/molecules/Modal/AddImageModal";
import { postImage } from "../../../../../modules/Management/Media/addImageApiModule";
import { setAddImageFormValue } from "../../../../../modules/Management/Form/imageAddFormModule";
import { fetchImages } from "../../../../../modules/Management/Media/imageApiModule";
import { fetchVideos } from "../../../../../modules/Management/Media/videoApiModule";
import VideoMedia from "../../../../presentational/molecules/Media/VideoMedia";
import ImageMedia from "../../../../presentational/molecules/Media/ImageMedia";
import SpeechesTable from "../../../../presentational/molecules/Table/SpeechesTable";
import InfoButtonsTable from "../../../../presentational/molecules/Table/InfoButtonsTable";
import { fetchSpeeches } from "../../../../../modules/Management/Media/speechApiModule";
import {
  Memo,
  setMemo,
  setMemoText,
  setMemoTitle,
} from "../../../../../modules/Management/Pcs/memosModule";
import PrimaryButton from "../../../../presentational/atoms/Button/PrimaryButton";
import { gray, textGray } from "../../../../../utils/color";
import {
  generateMemoId,
  setAddMemoFormValue,
} from "../../../../../modules/Management/Form/memoAddFormModule";
import {
  setPreMemo,
  setPreMemoText,
  setPreMemoTitle,
} from "../../../../../modules/Management/Pcs/preMemosModule";
import { delMemos } from "../../../../../modules/Management/Pcs/deleteMemosApiModule";
import { postMemo } from "../../../../../modules/Management/Pcs/addMemoApiModule";
import { putAction } from "../../../../../modules/Management/Actions/updateActionApiModule";
import { RoutePath } from "../../../../../Routes";
import { putPc } from "../../../../../modules/Management/Pcs/updatePcApiModule";
import AddVideoModal from "../../../../presentational/molecules/Modal/AddVideoModal";
import { postVideo } from "../../../../../modules/Management/Media/addVideoApiModule";
import { setAddVideoFormInitialValue } from "../../../../../modules/Management/Form/videoAddFormModule";
import AddSpeechModal from "../../../../presentational/molecules/Modal/AddSpeechModal";
import { postSpeech } from "../../../../../modules/Management/Media/addSpeechApiModule";
import { setAddSpeechFormInitialValue } from "../../../../../modules/Management/Form/speechAddFormModule";
import CancelButton from "../../../../presentational/atoms/Button/CancelButton";
import { SidebarContext } from "../../Layout";
import { fetchInfoButtons } from "../../../../../modules/Management/InfoButtons/infoButtonApiModule";
import { changePcState } from "../../../../../modules/Management/Pcs/changeStateApiModule";
import HelpImg from "../../../../../img/screen_img.png";
import { putMemo } from "../../../../../modules/Management/Pcs/updateMemoApiModule";
import NotificationButtonsTable from "../../../../presentational/molecules/Table/NotificationButtonsTable";
import { fetchNotificationButtons } from "../../../../../modules/Management/NotificationButtons/notificationButtonApiModule";
import { fetchPc } from "../../../../../modules/Management/Pcs/getPcApiModule";
import { fetchAction } from "../../../../../modules/Management/Actions/getActionApiModule";
import { MediaEvent } from "../../../../../api/Management/actionsApi";
import useAlert from "../../../../../hooks/useAlert";
import Loading from "../../../../presentational/molecules/Loading";
import { delImage } from "../../../../../modules/Management/Media/deleteImageApiModule";
import { delImages } from "../../../../../modules/Management/Media/deleteImagesApiModule";
import preSignImage from "../../../../../api/Management/imageApi/preSign";
import addS3 from "../../../../../api/Management/imageApi/addS3";
import { delVideo } from "../../../../../modules/Management/Media/deleteVideoApiModule";
import { delVideos } from "../../../../../modules/Management/Media/deleteVideosApiModule";
import preSignVideo from "../../../../../api/Management/videoApi/preSign";
import { delSpeech } from "../../../../../modules/Management/Media/deleteSpeechApiModule";
import { delSpeeches } from "../../../../../modules/Management/Media/deleteSpeechesApiModule";
import preSignSpeech from "../../../../../api/Management/speechApi/preSign";
import { AddAnalysisInfoModal } from "../../../../presentational/molecules/Modal/AddAnalysisInfoModal";
import { setInitialAddFormValue } from "../../../../../modules/Management/Form/InfoButton/infoButtonAddFormModule";
import { setAddFormInitialValue } from "../../../../../modules/Management/Form/NotificationButton/notificationButtonAddFormModule";
import { postInfoButtonType } from "../../../../../modules/Management/InfoButtons/addInfoButtonTypeApiModule";
import { postInfoButton } from "../../../../../modules/Management/InfoButtons/addInfoButtonApiModule";
import { fetchInfoButtonTypes } from "../../../../../modules/Management/InfoButtons/getInfoButtonTypeApiModule";
import { delInfoButton } from "../../../../../modules/Management/InfoButtons/deleteInfoButtonApiModule";
import { delNotificationButton } from "../../../../../modules/Management/NotificationButtons/deleteNotificationButtonApiModule";
import { AddNotificationButtonModal } from "../../../../presentational/molecules/Modal/AddNotificationButtonModal";
import { postNotificationButton } from "../../../../../modules/Management/NotificationButtons/addNotificationButtonApiModule";
import getSoundEvent, {
  SoundEvent,
} from "../../../../../api/Management/soundEvnetApi/get";
import { SOUND_EVENT_TYPE_ID } from "../../../../../const/sound-event-types";
import SoundEventsTable, {
  SoundData,
} from "../../../../presentational/molecules/Table/SoundEventsTable";
import defaultNotificationSound from "../../../../../audios/default_sound.mp3";
import notificationSound01 from "../../../../../audios/notification_sound_01.mp3";
import notificationSound02 from "../../../../../audios/notification_sound_02.mp3";
import notificationSound03 from "../../../../../audios/notification_sound_03.mp3";
import updateSoundEvent from "../../../../../api/Management/soundEvnetApi/update";

const StyledAddButton = styled.a`
  display: block;
  color: ${textGray};
  font-size: 15px;
  cursor: pointer;
`;

const StyledBackButton = styled(CancelButton)`
  background: #d84421;
  &:hover {
    color: #fff;
    border-radius: 20px;
    transition: 0.25s;
  }
  transition: 0.25s;
`;

const ToolTipTitle = styled.p`
  font-size: 17px;
  line-height: 1.5;
`;

const drawerWidth = 300;
const StyledSelectModal = styled(Modal)<{ isOpen: boolean }>`
  ${({ isOpen }) =>
    isOpen &&
    `
    width: calc(100% - ${drawerWidth}px);
    margin-left: ${drawerWidth}px;
    `}
  margin-top: 100px;
  transition: ${props =>
    props.theme.transitions.create(["width", "margin"], {
      easing: props.theme.transitions.easing.sharp,
      duration: props.theme.transitions.duration.leavingScreen,
    })};
`;

export const StyledMemoDeleteButton = styled(PrimaryButton)<{
  isChecked: boolean;
}>`
  ${({ isChecked }) => {
    return css`
      ${isChecked
        ? css`
            background: #d84421;
            &:hover {
              color: #fff;
              border-radius: 20px;
              transition: 0.25s;
            }
          `
        : `background: ${gray};`}
      font-size: 10px;
      margin-left: 5px;
      margin-bottom: 5px;
      &:hover {
        color: #fff;
      }
      transition: 0.25s;
    `;
  }}
`;

const ReviewWrapper = styled.div`
  margin: 5px;
`;

const StyledImg = styled.img`
  width: 100%;
  margin-top: 5px;
`;

const StyledVideo = styled.video`
  width: 100%;
  margin-top: 5px;
`;

const addMemoModel = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です")
    .pattern(
      /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
      "IDとして入力できるのは半角英数記号のみです。"
    ),
  title: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です"),
});

const editModel = Schema.Model({
  id: Schema.Types.StringType()
    .maxLength(50, "50文字以内で入力してください")
    .isRequired("必須項目です")
    .pattern(
      /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
      "IDとして入力できるのは半角英数記号のみです。"
    ),
  name: Schema.Types.StringType().isRequired("必須項目です"),
});

const detectionAudioFiles: SoundData[] = [
  {
    name: "デフォルト通知音",
    audioNo: 0,
    url: defaultNotificationSound,
  },
  {
    name: "通知音 1",
    audioNo: 1,
    url: notificationSound01,
  },
  {
    name: "通知音 2",
    audioNo: 2,
    url: notificationSound02,
  },
  {
    name: "通知音 3",
    audioNo: 3,
    url: notificationSound03,
  },
];

const Shop = () => {
  const { isOpen } = useContext(SidebarContext);
  const [showImage, setShowImage] = useState(false);
  const [showWillDeleteImage, setShowWillDeleteImage] = useState(false);
  const [showDeleteImage, setShowDeleteImage] = useState(false);
  const [showAddImage, setShowAddImage] = useState(false);
  const [addImageForm, setImageAddForm] = useState<FormInstance>();
  const { info, success, error, closeSnackbar } = useAlert();

  const [showVideo, setShowVideo] = useState(false);
  const [showAddVideo, setShowAddVideo] = useState(false);
  const [showWillDeleteVideo, setShowWillDeleteVideo] = useState(false);
  const [showDeleteVideo, setShowDeleteVideo] = useState(false);
  const [videoFileInfo, setVideoFileInfo] = useState<{
    name: string;
    value: string;
    file: File | undefined;
    fileSize: number | undefined;
  }>({
    name: "",
    value: "",
    file: undefined,
    fileSize: undefined,
  });
  const [addVideoForm, setVideoAddForm] = useState<FormInstance>();

  const [showSpeech, setShowSpeech] = useState(false);
  const [showNotificationSound, setShowNotificationSound] = useState(false);
  const [showWillDeleteSpeech, setShowWillDeleteSpeech] = useState(false);
  const [showDeleteSpeech, setShowDeleteSpeech] = useState(false);
  const [speechFileInfo, setSpeechFileInfo] = useState<{
    name: string;
    value: string;
    file: File | undefined;
  }>({
    name: "",
    value: "",
    file: undefined,
  });
  const [addSpeechForm, setSpeechAddForm] = useState<FormInstance>();
  const [showAddSpeech, setShowAddSpeech] = useState(false);

  const [showNotificationButton, setShowNotificationButton] = useState(false);
  const [addNotificationButtonForm, setAddNotificationButtonForm] = useState<
    FormInstance
  >();
  const [
    showWillDeleteNotificationButton,
    setShowWillDeleteNotificationButton,
  ] = useState(false);
  const [
    showDeleteNotificationButton,
    setShowDeleteNotificationButton,
  ] = useState(false);
  const [showAddNotificationButton, setShowAddNotificationButton] = useState(
    false
  );

  const [showInfoButton, setShowInfoButton] = useState(false);
  const [addInfoButtonForm, setAddInfoButtonForm] = useState<FormInstance>();
  const [showAddInfoButton, setShowAddInfoButton] = useState(false);
  const [showWillDeleteInfoButton, setShowWillDeleteInfoButton] = useState(
    false
  );
  const [showDeleteInfoButton, setShowDeleteInfoButton] = useState(false);

  const [showAddMemo, setShowAddMemo] = useState(false);
  const [addMemoForm, setAddMemoForm] = useState<FormInstance>();
  const [willUpdateMemos, setWillUpdateMemos] = useState<string[]>([]);
  const [willDeleteMemos, setWillDeleteMemos] = useState<Memo[]>([]);

  const [showWaitMedia, setShowWaitMedia] = useState(false);
  const [checkedWaitMediaKey, setCheckedWaitMediaKey] = useState<string>();
  const [willWaitMedia, setWillWaitMedia] = useState<{
    waitMediaId?: string;
    waitMedia?: string;
    remark?: string;
    type?: string;
  }>();

  const [showRestMedia, setShowRestMedia] = useState(false);
  const [checkedRestMediaKey, setCheckedRestMediaKey] = useState<string>();
  const [willRestMedia, setWillRestMedia] = useState<{
    restMediaId?: string;
    restMedia?: string;
    remark?: string;
    type?: string;
  }>();

  const [showBackMedia, setShowBackMedia] = useState(false);
  const [checkedBackMediaKey, setCheckedBackMediaKey] = useState<string>();
  const [willBackMedia, setWillBackMedia] = useState<{
    backMediaId?: string;
    backMedia?: string;
    remark?: string;
    type?: string;
  }>();

  const [showScreen, setShowScreen] = useState(false);
  const [checkedScreenKey, setCheckedScreenKey] = useState<string>();
  const [willScreen, setWillScreen] = useState<{
    screenId?: string;
    screen?: string;
    remark?: string;
    type?: string;
  }>();

  const [showBlur, setShowBlur] = useState(false);
  const [checkedBlurKey, setCheckedBlurKey] = useState<string>();
  const [willBlur, setWillBlur] = useState<{
    blurId?: string;
    blur?: string;
    remark?: string;
    type?: string;
  }>();

  const [showDetection, setShowDetection] = useState(false);
  const [checkedDetectionKey, setCheckedDetectionKey] = useState<string>();
  const [willDetection, setWillDetection] = useState<{
    detectionId?: string;
    detection?: string;
    remark?: string;
    type?: string;
  }>();

  const [showReception, setShowReception] = useState(false);
  const [checkedReceptionKey, setCheckedReceptionKey] = useState<string>();
  const [willReception, setWillReception] = useState<{
    receptionId?: string;
    reception?: string;
    remark?: string;
    type?: string;
  }>();

  const [showConfirm, setShowConfirm] = useState(false);
  const [showSaveConfirm, setShowSaveConfirm] = useState(false);
  const [detectionSoundEventData, setDetectionSoundEventData] = useState<
    SoundEvent
  >();
  const [
    selectedDetectionSoundEventItem,
    setSelectedDetectionSoundEventItem,
  ] = useState<SoundData>();

  const history = useHistory();
  const { shopId } = useParams<{ shopId: string }>();
  const [editForm, setEditForm] = useState<FormInstance>();
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.auth);

  const { data: pcData, isLoading: isPcDataLoading } = useSelector(
    (state: RootState) => state.pc
  );
  const { data: actionData, isLoading: isActionDataLoading } = useSelector(
    (state: RootState) => state.action
  );
  const { data, isLoading } = useSelector((state: RootState) => state.shopApi);
  const { data: imagesData, isLoading: isImagesDataLoading } = useSelector(
    (state: RootState) => state.images
  );
  const { data: videosData, isLoading: isVideosDataLoading } = useSelector(
    (state: RootState) => state.videos
  );
  const { data: speechesData, isLoading: isSpeechesDataLoading } = useSelector(
    (state: RootState) => state.speech
  );

  const {
    data: notificationButtonData,
    isLoading: isNotificationButtonDataLoading,
  } = useSelector((state: RootState) => state.notificationButtons);
  const {
    data: infoButtonsData,
    isLoading: isInfoButtonsDataLoading,
  } = useSelector((state: RootState) => state.infoButton);

  const { checks } = useSelector((state: RootState) => state.checkCells);
  const checkedImageKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "shopImages")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );
  const checkedWillDeleteImageKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "shopWillDeleteImages")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );
  const checkedVideoKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "shopVideos")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );
  const checkedWillDeleteVideoKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "shopWillDeleteVideos")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );
  const checkedSpeechKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "shopSpeeches")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );
  const checkedWillDeleteSpeechKeys = useMemo(
    () =>
      checks.find(value => value.id === "shopWillDeleteSpeeches")?.checkInfo
        .checkedKeys,
    [checks]
  );
  const checkedNotificationButtonKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "shopNotificationButtons")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );

  const checkedWillDeleteNotificationButtonKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "shopWillDeleteNotificationButtons")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );
  const checkedInfoButtonKeys = useMemo(
    () =>
      checks
        .filter(value => value.id === "shopInfoButtons")
        .map(check => check.checkInfo.checkedKeys)[0],
    [checks]
  );

  const checkedWillDeleteInfoButtonKeys = useMemo(
    () =>
      checks.find(value => value.id === "shopWillDeleteInfoButtons")?.checkInfo
        .checkedKeys,
    [checks]
  );

  const waitImage = useMemo(() => {
    if (imagesData === null) return undefined;
    const mediaId = actionData?.mediaEvents.find(
      mediaEvent =>
        mediaEvent.eventType === "WAIT" && mediaEvent.mediaType === "image"
    )?.mediaId;
    return imagesData.images.find(image => image.imageId === mediaId);
  }, [actionData, imagesData]);

  const waitVideo = useMemo(() => {
    if (videosData === null) return undefined;
    const mediaId = actionData?.mediaEvents.find(
      mediaEvent =>
        mediaEvent.eventType === "WAIT" && mediaEvent.mediaType === "video"
    )?.mediaId;
    return videosData.videos.find(video => video.videoId === mediaId);
  }, [actionData, videosData]);

  const restImage = useMemo(() => {
    if (imagesData === null) return undefined;
    const mediaId = actionData?.mediaEvents.find(
      mediaEvent =>
        mediaEvent.eventType === "REST" && mediaEvent.mediaType === "image"
    )?.mediaId;
    return imagesData?.images.find(image => image.imageId === mediaId);
  }, [actionData, imagesData]);

  const restVideo = useMemo(() => {
    if (videosData === null) return undefined;
    const mediaId = actionData?.mediaEvents.find(
      mediaEvent =>
        mediaEvent.eventType === "REST" && mediaEvent.mediaType === "video"
    )?.mediaId;
    return videosData.videos.find(video => video.videoId === mediaId);
  }, [actionData, videosData]);

  const backImage = useMemo(() => {
    if (imagesData === null) return undefined;
    const mediaId = actionData?.mediaEvents.find(
      mediaEvent =>
        mediaEvent.eventType === "BACK" && mediaEvent.mediaType === "image"
    )?.mediaId;
    return imagesData?.images.find(image => image.imageId === mediaId);
  }, [actionData, imagesData]);

  const backVideo = useMemo(() => {
    if (videosData === null) return undefined;
    const mediaId = actionData?.mediaEvents.find(
      mediaEvent =>
        mediaEvent.eventType === "BACK" && mediaEvent.mediaType === "video"
    )?.mediaId;
    return videosData.videos.find(video => video.videoId === mediaId);
  }, [actionData, videosData]);

  const screenImage = useMemo(() => {
    if (imagesData === null) return undefined;
    const mediaId = actionData?.mediaEvents.find(
      mediaEvent =>
        mediaEvent.eventType === "SCREEN" && mediaEvent.mediaType === "image"
    )?.mediaId;
    return imagesData.images.find(image => image.imageId === mediaId);
  }, [actionData, imagesData]);

  const screenVideo = useMemo(() => {
    if (videosData === null) return undefined;
    const mediaId = actionData?.mediaEvents.find(
      mediaEvent =>
        mediaEvent.eventType === "SCREEN" && mediaEvent.mediaType === "video"
    )?.mediaId;
    return videosData.videos.find(video => video.videoId === mediaId);
  }, [actionData, videosData]);

  const blurImage = useMemo(() => {
    if (imagesData === null) return undefined;
    const mediaId = actionData?.mediaEvents.find(
      mediaEvent =>
        mediaEvent.eventType === "BLUR" && mediaEvent.mediaType === "image"
    )?.mediaId;
    return imagesData.images.find(image => image.imageId === mediaId);
  }, [actionData, imagesData]);

  const detectionImage = useMemo(() => {
    if (imagesData === null) return undefined;
    const mediaId = actionData?.mediaEvents.find(
      mediaEvent =>
        mediaEvent.eventType === "DETECTION" && mediaEvent.mediaType === "image"
    )?.mediaId;
    return imagesData.images.find(image => image.imageId === mediaId);
  }, [actionData, imagesData]);

  const receptionImage = useMemo(() => {
    if (imagesData === null) return undefined;
    const mediaId = actionData?.mediaEvents.find(
      mediaEvent =>
        mediaEvent.eventType === "RECEPTION" && mediaEvent.mediaType === "image"
    )?.mediaId;
    return imagesData.images.find(image => image.imageId === mediaId);
  }, [actionData, imagesData]);

  const { memos } = useSelector((state: RootState) => state.memos);
  const onDragEndMemos = useCallback(
    (result: DropResult) => {
      if (result.destination === undefined) return;

      const items = [...memos];
      const [sourceIndex, destinationIndex] = [
        result.source.index,
        result.destination.index,
      ];
      [items[sourceIndex], items[destinationIndex]] = [
        items[destinationIndex],
        items[sourceIndex],
      ];

      dispatch(setMemo({ memos: items }));
    },
    [dispatch, memos]
  );

  const { preMemos } = useSelector((state: RootState) => state.preMemos);

  const { formValue } = useSelector((state: RootState) => state.shopEditForm);

  const { formValue: addImageFormValue } = useSelector(
    (state: RootState) => state.imageAddForm
  );

  const { formValue: addVideoFormValue } = useSelector(
    (state: RootState) => state.videoAddForm
  );

  const { formValue: addSpeechFormValue } = useSelector(
    (state: RootState) => state.speechAddForm
  );

  const { formValue: addNotificationButtonFormValue } = useSelector(
    (state: RootState) => state.notificationButtonAddForm
  );

  const { formValue: addInfoButtonFormValue } = useSelector(
    (state: RootState) => state.infoButtonAddForm
  );

  const { formValue: addMemoFormValue } = useSelector(
    (state: RootState) => state.memoAddForm
  );

  useEffect(() => {
    if (token && shopId) {
      dispatch(fetchPc(token, shopId));
    }
  }, [dispatch, shopId, token]);

  useEffect(() => {
    if (token && pcData?.actionId) {
      dispatch(fetchAction(token, pcData.actionId));
    }
  }, [dispatch, pcData, token]);

  useEffect(() => {
    if (token && shopId) {
      dispatch(fetchShop(token, shopId));
      dispatch(fetchImages(token));
      dispatch(fetchVideos(token));
      dispatch(fetchSpeeches(token));
      dispatch(fetchNotificationButtons(token));
      dispatch(fetchInfoButtons(token));
      dispatch(fetchInfoButtonTypes(token));
    }
  }, [dispatch, shopId, token]);

  // Pc に紐ずく soundEvent の取得
  useEffect(() => {
    (async () => {
      if (token == null) return;
      const resultData = await getSoundEvent(token, shopId);
      const detection = resultData.soundEvents.find(
        d => d.soundEventTypeId === SOUND_EVENT_TYPE_ID.DETECTION
      );
      setDetectionSoundEventData(detection);
    })();
  }, [shopId, token]);

  useEffect(() => {
    if (data) {
      dispatch(
        setShopEditFormValue({
          formValue: {
            id: data.pcId,
            name: data.name,
            images: data.images.map(image => image.imageId),
            videos: data.videos.map(video => video.videoId),
            speeches: data.speeches.map(speech => speech.speechId),
            notificationButtons: data.notificationButtons,
            infoButtons: data.infoButtons.map(
              infoButton => infoButton.infoButtonId
            ),
            remark: data.remark,
          },
        })
      );
      const memoInfo = data.memos.map(memo => {
        return {
          id: memo.memoId,
          memoInfo: {
            memoTitle: memo.memoTitle,
            memoText: memo.memoText,
          },
        };
      });
      dispatch(setMemo({ memos: memoInfo }));
    }
  }, [data, dispatch]);

  useEffect(() => {
    const nextCheckedImageKeys = data?.images.map(image => image.imageId);
    if (nextCheckedImageKeys) {
      dispatch(
        setCheckedKeys({
          id: "shopImages",
          checkInfo: {
            checked: false,
            checkedKeys: nextCheckedImageKeys,
            indeterminate: false,
          },
        })
      );
    }
    const nextCheckedVideoKeys = data?.videos.map(video => video.videoId);
    if (nextCheckedVideoKeys) {
      dispatch(
        setCheckedKeys({
          id: "shopVideos",
          checkInfo: {
            checked: false,
            checkedKeys: nextCheckedVideoKeys,
            indeterminate: false,
          },
        })
      );
    }
    const nextCheckedSpeechKeys = data?.speeches.map(speech => speech.speechId);
    if (nextCheckedSpeechKeys) {
      dispatch(
        setCheckedKeys({
          id: "shopSpeeches",
          checkInfo: {
            checked: false,
            checkedKeys: nextCheckedSpeechKeys,
            indeterminate: false,
          },
        })
      );
    }

    const nextCheckedNotificationButtonKeys = data?.notificationButtons;
    if (nextCheckedNotificationButtonKeys) {
      dispatch(
        setCheckedKeys({
          id: "shopNotificationButtons",
          checkInfo: {
            checked: false,
            checkedKeys: nextCheckedNotificationButtonKeys,
            indeterminate: false,
          },
        })
      );
    }

    const nextCheckedInfoButtonKeys = data?.infoButtons.map(
      infoButton => infoButton.infoButtonId
    );
    if (nextCheckedInfoButtonKeys) {
      dispatch(
        setCheckedKeys({
          id: "shopInfoButtons",
          checkInfo: {
            checked: false,
            checkedKeys: nextCheckedInfoButtonKeys,
            indeterminate: false,
          },
        })
      );
    }
    if (waitImage !== undefined) {
      setCheckedWaitMediaKey(waitImage.imageId);
    }

    if (waitVideo !== undefined) {
      setCheckedWaitMediaKey(waitVideo.videoId);
    }

    if (restImage !== undefined) {
      setCheckedRestMediaKey(restImage.imageId);
    }

    if (restVideo !== undefined) {
      setCheckedRestMediaKey(restVideo.videoId);
    }

    if (backImage !== undefined) {
      setCheckedBackMediaKey(backImage.imageId);
    }

    if (backVideo !== undefined) {
      setCheckedBackMediaKey(backVideo.videoId);
    }

    if (screenImage !== undefined) {
      setCheckedScreenKey(screenImage.imageId);
    }

    if (screenVideo !== undefined) {
      setCheckedScreenKey(screenVideo.videoId);
    }

    if (blurImage !== undefined) {
      setCheckedBlurKey(blurImage.imageId);
    }

    if (receptionImage !== undefined) {
      setCheckedReceptionKey(receptionImage.imageId);
    }
  }, [
    backImage,
    backVideo,
    blurImage,
    receptionImage,
    data,
    dispatch,
    restImage,
    restVideo,
    screenImage,
    screenVideo,
    waitImage,
    waitVideo,
  ]);

  useEffect(() => {
    dispatch(
      setShopEditFormValue({
        formValue: {
          id: formValue.id,
          name: formValue.name,
          images: checkedImageKeys,
          videos: checkedVideoKeys,
          speeches: checkedSpeechKeys,
          notificationButtons: checkedNotificationButtonKeys,
          infoButtons: checkedInfoButtonKeys,
          remark: formValue.remark,
        },
      })
    );
  }, [
    checkedImageKeys,
    checkedInfoButtonKeys,
    checkedNotificationButtonKeys,
    checkedSpeechKeys,
    checkedVideoKeys,
    dispatch,
    formValue.id,
    formValue.name,
    formValue.remark,
  ]);

  useEffect(() => {
    dispatch(setShopEditFormValue({ formValue: {} }));
    dispatch(setMemo({ memos: [] }));
  }, [dispatch]);

  const handleShopFormChange = (value: any) => {
    dispatch(
      setShopEditFormValue({
        formValue: value,
      })
    );
  };

  const handleGoBack = () => {
    setShowConfirm(true);
  };
  const handleSave = () => {
    setShowSaveConfirm(true);
  };

  const onClickAddImage = () => {
    setShowImage(true);
  };

  const onClickAddVideo = () => {
    setShowVideo(true);
  };

  const onClickAddSpeech = () => {
    setShowSpeech(true);
  };

  const onClickAdNotificationSound = () => {
    setShowNotificationSound(true);
  };

  const onClickAddNotificationButton = () => {
    setShowNotificationButton(true);
  };

  const onClickAddInfoButton = () => {
    setShowInfoButton(true);
  };

  const onClickAddWaitImage = () => {
    setShowWaitMedia(true);
  };

  const onClickAddRestImage = () => {
    setShowRestMedia(true);
  };

  const onClickAddBackImage = () => {
    setShowBackMedia(true);
  };

  const onClickAddScreenImage = () => {
    setShowScreen(true);
  };

  const onClickAddBlurImage = () => {
    setShowBlur(true);
  };

  const onClickAddDetectionImage = () => {
    setShowDetection(true);
  };

  const onClickAddReceptionImage = () => {
    setShowReception(true);
  };

  const onClickAddMemo = () => {
    setShowAddMemo(true);
    dispatch(generateMemoId());
  };

  const handleImageCheck = (value: any, isChecked: boolean) => {
    const nextCheckedKeys = isChecked
      ? [...checkedImageKeys, value]
      : checkedImageKeys.filter(item => item !== value);
    dispatch(
      setCheckedKeys({
        id: "shopImages",
        checkInfo: {
          checked: false,
          checkedKeys: nextCheckedKeys,
          indeterminate: false,
        },
      })
    );
  };
  const handleWillDeleteImageCheck = (value: any, isChecked: boolean) => {
    const nextCheckedKeys = isChecked
      ? [...checkedWillDeleteImageKeys, value]
      : checkedWillDeleteImageKeys.filter(item => item !== value);
    dispatch(
      setCheckedKeys({
        id: "shopWillDeleteImages",
        checkInfo: {
          checked: false,
          checkedKeys: nextCheckedKeys,
          indeterminate: false,
        },
      })
    );
  };

  const handleWillDeleteVideoCheck = (value: string, isChecked: boolean) => {
    const nextCheckedKeys = isChecked
      ? [...checkedWillDeleteVideoKeys, value]
      : checkedWillDeleteVideoKeys.filter(item => item !== value);
    dispatch(
      setCheckedKeys({
        id: "shopWillDeleteVideos",
        checkInfo: {
          checked: false,
          checkedKeys: nextCheckedKeys,
          indeterminate: false,
        },
      })
    );
  };

  const handleVideoCheck = (value: any, isChecked: boolean) => {
    const nextCheckedKeys = isChecked
      ? [...checkedVideoKeys, value]
      : checkedVideoKeys.filter(item => item !== value);
    dispatch(
      setCheckedKeys({
        id: "shopVideos",
        checkInfo: {
          checked: false,
          checkedKeys: nextCheckedKeys,
          indeterminate: false,
        },
      })
    );
  };

  const handleMemoTitle = (id: string, value: string) => {
    dispatch(
      setMemoTitle({
        id,
        memoInfo: {
          memoTitle: value,
        },
      })
    );

    if (!willUpdateMemos.includes(id)) {
      const nextMemos = _.cloneDeep(willUpdateMemos);
      nextMemos.push(id);
      setWillUpdateMemos(nextMemos);
    }
  };

  const handleMemoText = (id: string, value: string) => {
    dispatch(
      setMemoText({
        id,
        memoInfo: {
          memoText: value,
        },
      })
    );
    if (!willUpdateMemos.includes(id)) {
      const nextMemos = _.cloneDeep(willUpdateMemos);
      nextMemos.push(id);
      setWillUpdateMemos(nextMemos);
    }
  };

  const handlePreMemoTitle = (id: string, value: string) => {
    dispatch(
      setPreMemoTitle({
        id,
        preMemoInfo: {
          preMemoTitle: value,
        },
      })
    );
  };

  const handlePreMemoText = (id: string, value: string) => {
    dispatch(
      setPreMemoText({
        id,
        preMemoInfo: {
          preMemoText: value,
        },
      })
    );
  };

  const deleteMemo = (id: string) => {
    const copyMemo = _.cloneDeep(memos);
    const filterdMemo = copyMemo.filter(memo => memo.id !== id);
    const willDeleteMemo = copyMemo.filter(memo => memo.id === id)[0];
    dispatch(setMemo({ memos: filterdMemo }));
    const willDeleteMemoIds = willDeleteMemos.map(memo => memo.id);
    if (!willDeleteMemoIds.includes(id)) {
      const nextMemos = _.cloneDeep(willDeleteMemos);
      nextMemos.push(willDeleteMemo);
      setWillDeleteMemos(nextMemos);
    }
  };

  const restoreMemo = (willRestoreMemo: Memo) => {
    const copyWillDeleteMemo = _.cloneDeep(willDeleteMemos);
    const filteredMemo = copyWillDeleteMemo.filter(
      memo => memo.id !== willRestoreMemo.id
    );
    setWillDeleteMemos(filteredMemo);
    const restoreWillDeleteMemo = copyWillDeleteMemo.filter(
      memo => memo.id === willRestoreMemo.id
    )[0];
    const copyMemo = _.cloneDeep(memos);
    copyMemo.push(restoreWillDeleteMemo);
    dispatch(setMemo({ memos: copyMemo }));
  };

  const deletePreMemo = (id: string) => {
    const copyPreMemo = _.cloneDeep(preMemos);
    const filterdMemo = copyPreMemo.filter(memo => memo.id !== id);
    dispatch(setPreMemo({ preMemos: filterdMemo }));
  };

  const handleWaitMediaCheck = (value: string, isChecked: boolean) => {
    const nextCheckedKeys = isChecked ? value : undefined;
    setCheckedWaitMediaKey(nextCheckedKeys);
  };
  const handleRestMediaCheck = (value: string, isChecked: boolean) => {
    const nextCheckedKeys = isChecked ? value : undefined;
    setCheckedRestMediaKey(nextCheckedKeys);
  };
  const handleBackMediaCheck = (value: string, isChecked: boolean) => {
    const nextCheckedKeys = isChecked ? value : undefined;
    setCheckedBackMediaKey(nextCheckedKeys);
  };
  const handleScreenCheck = (value: string, isChecked: boolean) => {
    const nextCheckedKeys = isChecked ? value : undefined;
    setCheckedScreenKey(nextCheckedKeys);
  };

  const handleBlurCheck = (value: string, isChecked: boolean) => {
    const nextCheckedKeys = isChecked ? value : undefined;
    setCheckedBlurKey(nextCheckedKeys);
  };

  const handleDetectionCheck = (value: string, isChecked: boolean) => {
    const nextCheckedKeys = isChecked ? value : undefined;
    setCheckedDetectionKey(nextCheckedKeys);
  };

  const handleReceptionCheck = (value: string, isChecked: boolean) => {
    const nextCheckedKeys = isChecked ? value : undefined;
    setCheckedReceptionKey(nextCheckedKeys);
  };

  const setWaitMedia = () => {
    setShowWaitMedia(false);
    const nextImage = imagesData?.images.filter(
      image => image.imageId === checkedWaitMediaKey
    );
    if (nextImage && nextImage.length > 0) {
      const media = {
        waitMediaId: nextImage[0].imageId,
        waitMedia: nextImage[0].image,
        remark: nextImage[0].remark,
        type: "image",
      };
      setWillWaitMedia(media);
      return;
    }

    const nextVideo = videosData?.videos.filter(
      video => video.videoId === checkedWaitMediaKey
    );
    if (nextVideo && nextVideo.length > 0) {
      const media = {
        waitMediaId: nextVideo[0].videoId,
        waitMedia: nextVideo[0].url,
        remark: nextVideo[0].remark,
        type: "video",
      };
      setWillWaitMedia(media);
      return;
    }
    setWillWaitMedia({ waitMediaId: "" });
  };

  const setRestImage = () => {
    setShowRestMedia(false);
    const nextImage = imagesData?.images.find(
      image => image.imageId === checkedRestMediaKey
    );
    if (nextImage !== undefined) {
      const media = {
        restMediaId: nextImage.imageId,
        restMedia: nextImage.image,
        remark: nextImage.remark,
        type: "image",
      };
      setWillRestMedia(media);
      return;
    }

    const nextVideo = videosData?.videos.find(
      video => video.videoId === checkedRestMediaKey
    );
    if (nextVideo !== undefined) {
      const media = {
        restMediaId: nextVideo.videoId,
        restMedia: nextVideo.url,
        remark: nextVideo.remark,
        type: "video",
      };
      setWillRestMedia(media);
      return;
    }

    setWillRestMedia({ restMediaId: "" });
  };

  const setBackMedia = () => {
    setShowBackMedia(false);
    const nextImage = imagesData?.images.find(
      image => image.imageId === checkedBackMediaKey
    );
    const nextVideo = videosData?.videos.find(
      video => video.videoId === checkedBackMediaKey
    );
    if (nextImage !== undefined) {
      const media = {
        backMediaId: nextImage.imageId,
        backMedia: nextImage.image,
        remark: nextImage.remark,
        type: "image",
      };
      setWillBackMedia(media);
      return;
    }

    if (nextVideo !== undefined) {
      const media = {
        backMediaId: nextVideo.videoId,
        backMedia: nextVideo.url,
        remark: nextVideo.remark,
        type: "video",
      };
      setWillBackMedia(media);
      return;
    }
    setWillBackMedia({
      backMediaId: "",
    });
  };

  const setScreen = async () => {
    setShowScreen(false);
    const nextScreenImage = imagesData?.images.find(
      image => image.imageId === checkedScreenKey
    );
    if (nextScreenImage !== undefined) {
      const screen = {
        screenId: nextScreenImage.imageId,
        screen: nextScreenImage.image,
        remark: nextScreenImage.remark,
        type: "image",
      };
      setWillScreen(screen);
      return;
    }
    const nextScreenVideo = videosData?.videos.find(
      video => video.videoId === checkedScreenKey
    );
    if (nextScreenVideo !== undefined) {
      const screen = {
        screenId: nextScreenVideo.videoId,
        screen: nextScreenVideo.url,
        remark: nextScreenVideo.remark,
        type: "video",
      };
      setWillScreen(screen);
      return;
    }

    setWillScreen({ screenId: "" });
  };

  const setBlurMedia = async () => {
    setShowBlur(false);
    const nextBlurImage = imagesData?.images.find(
      image => image.imageId === checkedBlurKey
    );
    if (nextBlurImage !== undefined) {
      const blur = {
        blurId: nextBlurImage.imageId,
        blur: nextBlurImage.image,
        remark: nextBlurImage.remark,
        type: "image",
      };
      setWillBlur(blur);
      return;
    }
    setWillBlur({ blurId: "" });
  };

  const setDetectionMedia = async () => {
    setShowDetection(false);
    const nextDetectionImage = imagesData?.images.find(
      image => image.imageId === checkedDetectionKey
    );
    if (nextDetectionImage !== undefined) {
      const detection = {
        detectionId: nextDetectionImage.imageId,
        detection: nextDetectionImage.image,
        remark: nextDetectionImage.remark,
        type: "image",
      };
      setWillDetection(detection);
      return;
    }
    setWillDetection({ detectionId: "" });
  };

  const setReceptionMedia = async () => {
    setShowReception(false);
    const nextReceptionImage = imagesData?.images.find(
      image => image.imageId === checkedReceptionKey
    );
    if (nextReceptionImage !== undefined) {
      const reception = {
        receptionId: nextReceptionImage.imageId,
        reception: nextReceptionImage.image,
        remark: nextReceptionImage.remark,
        type: "image",
      };
      setWillReception(reception);
      return;
    }
    setWillReception({ receptionId: "" });
  };

  const handleAddMemoFormChange = (value: any) => {
    dispatch(setAddMemoFormValue({ formValue: value }));
  };
  const handleCheckAndSubmitMemoAddForm = async () => {
    const checkResult = addMemoForm!.check();
    if (checkResult && token && shopId) {
      try {
        const copyPreMemo = _.cloneDeep(preMemos);
        const copyMemo = _.cloneDeep(memos);
        copyPreMemo.forEach(memo => {
          if (memo.id === addMemoFormValue.id) {
            throw new Error("入力されたIDはすでに存在します");
          }
        });
        copyMemo.forEach(memo => {
          if (memo.id === addMemoFormValue.id) {
            throw new Error("入力されたIDはすでに存在します");
          }
        });
        setShowAddMemo(false);
        copyPreMemo.push({
          id: addMemoFormValue.id,
          preMemoInfo: {
            preMemoTitle: addMemoFormValue.title,
            preMemoText: addMemoFormValue.text,
            remark: addMemoFormValue.remark,
          },
        });
        await dispatch(setPreMemo({ preMemos: copyPreMemo }));
        await dispatch(
          setAddMemoFormValue({
            formValue: {
              id: "",
              title: "",
              text: "",
              remark: "",
            },
          })
        );
      } catch (err) {
        error({ text: err.message });
      }
    }
  };

  const onCancelClick = () => {
    setShowImage(false);
    setShowVideo(false);
    setShowSpeech(false);
    setShowAddMemo(false);
    setShowConfirm(false);
    setShowScreen(false);
    setShowInfoButton(false);
    setShowSaveConfirm(false);
    setShowNotificationButton(false);
    setShowAddNotificationButton(false);
    setShowNotificationSound(false);
  };

  const onCancelClickDelete = () => {
    setShowDeleteImage(false);
    setShowDeleteVideo(false);
    setShowDeleteSpeech(false);
    setShowDeleteInfoButton(false);
    setShowDeleteNotificationButton(false);
  };
  const onModalCancel = () => {
    setShowAddImage(false);
    setShowAddVideo(false);
    setShowAddSpeech(false);
    setShowAddInfoButton(false);
    setShowAddNotificationButton(false);
  };

  const onCancelWaitModal = () => {
    if (willWaitMedia !== undefined) {
      setCheckedWaitMediaKey(willWaitMedia.waitMediaId);
    } else if (waitImage !== undefined) {
      setCheckedWaitMediaKey(waitImage.imageId);
    } else if (waitVideo !== undefined) {
      setCheckedWaitMediaKey(waitVideo.videoId);
    }
    setShowWaitMedia(false);
  };

  const onCancelRestModal = () => {
    if (willRestMedia !== undefined) {
      setCheckedRestMediaKey(willRestMedia.restMediaId);
    } else if (restImage !== undefined) {
      setCheckedRestMediaKey(restImage.imageId);
    } else if (restVideo !== undefined) {
      setCheckedRestMediaKey(restVideo.videoId);
    }
    setShowRestMedia(false);
  };

  const onCancelBackModal = () => {
    if (willBackMedia !== undefined) {
      setCheckedBackMediaKey(willBackMedia.backMediaId);
    } else if (backImage !== undefined) {
      setCheckedBackMediaKey(backImage.imageId);
    } else if (backVideo !== undefined) {
      setCheckedBackMediaKey(backVideo.videoId);
    }
    setShowBackMedia(false);
  };

  const onCancelScreenModal = () => {
    if (willScreen !== undefined) {
      setCheckedScreenKey(willScreen.screenId);
    } else if (screenImage !== undefined) {
      setCheckedScreenKey(screenImage.imageId);
    } else if (screenVideo !== undefined) {
      setCheckedScreenKey(screenVideo.videoId);
    }
    setShowScreen(false);
  };

  const onCancelBlurModal = () => {
    if (willBlur !== undefined) {
      setCheckedBlurKey(willBlur.blurId);
    } else if (blurImage !== undefined) {
      setCheckedBlurKey(blurImage.imageId);
    }
    setShowBlur(false);
  };

  const onCancelDetectionModal = () => {
    if (willDetection !== undefined) {
      setCheckedDetectionKey(willDetection.detectionId);
    } else if (detectionImage !== undefined) {
      setCheckedBlurKey(detectionImage.imageId);
    }
    setShowDetection(false);
  };

  const onCancelReceptionModal = () => {
    if (willReception !== undefined) {
      setCheckedReceptionKey(willReception.receptionId);
    } else if (receptionImage !== undefined) {
      setCheckedReceptionKey(receptionImage.imageId);
    }

    setShowReception(false);
  };

  const handleCheckAndSubmitImageAddForm = async () => {
    const checkResult = addImageForm!.check();
    if (checkResult && token && shopId) {
      try {
        setShowAddImage(false);
        info({ text: "画像を追加中..." });
        const presigned = await preSignImage(
          token,
          addImageFormValue.id,
          "add"
        );
        await addS3(presigned.url, addImageFormValue.img);
        await dispatch(
          postImage(
            token,
            addImageFormValue.id,
            addImageFormValue.imageName,
            presigned.fileName,
            addImageFormValue.remark
          )
        );
        dispatch(
          setAddImageFormValue({
            formValue: { id: "", imageName: "", img: "", remark: "" },
          })
        );
        closeSnackbar();
        success({ text: "画像を追加しました" });
        dispatch(fetchImages(token));
      } catch (err) {
        if (err.status > 200) {
          error({ text: "エラーが発生しました。管理者にお知らせください" });
          return;
        }
        error({ text: "入力したIDはすでに登録されています" });
      }
    }
  };

  const handleSubmitDeleteImage = async () => {
    if (token) {
      try {
        setShowDeleteImage(false);
        setShowWillDeleteImage(false);
        info({ text: "画像を削除中..." });
        if (checkedWillDeleteImageKeys.length === 1) {
          await dispatch(delImage(token, checkedWillDeleteImageKeys[0]));
        } else {
          await dispatch(delImages(token, checkedWillDeleteImageKeys));
        }
        dispatch(
          setCheckedKeys({
            id: "shopWillDeleteImages",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        closeSnackbar();
        success({ text: "画像を削除しました" });
        await dispatch(fetchImages(token));
      } catch (err) {
        console.error(err);
        closeSnackbar();
        switch (err.resultStatus) {
          case 116:
            error({ text: "画像の削除に失敗しました。" });
            break;
          case 115:
            error({ text: "この操作を実行する権限がありません。" });
            break;
          default:
            error({ text: "エラーが発生しました。" });
            break;
        }
        setShowWillDeleteImage(true);
      }
    }
  };

  const handleCheckAndSubmitVideoAddForm = async () => {
    const checkResult = addVideoForm!.check();
    if (checkResult && token && shopId && videoFileInfo.file !== undefined) {
      try {
        info({ text: "動画を追加中" });
        const presigned = await preSignVideo(
          token,
          addVideoFormValue.id,
          "add"
        );
        await addS3(presigned.url, videoFileInfo.file);
        await dispatch(
          postVideo(
            token,
            addVideoFormValue.id,
            presigned.fileName,
            addVideoFormValue.text,
            addVideoFormValue.id,
            addVideoFormValue.playTime
          )
        );
        setShowAddVideo(false);
        dispatch(setAddVideoFormInitialValue());
        closeSnackbar();
        success({ text: "動画の追加をしました" });
        dispatch(fetchVideos(token));
      } catch (err) {
        error({ text: "入力したIDはすでに登録されています" });
      }
    }
  };

  const handleSubmitDeleteVideo = async () => {
    if (token) {
      try {
        setShowDeleteVideo(false);
        setShowWillDeleteVideo(false);
        info({ text: "動画を削除中..." });
        if (checkedWillDeleteVideoKeys.length === 1) {
          await dispatch(delVideo(token, checkedWillDeleteVideoKeys[0]));
        } else {
          await dispatch(delVideos(token, checkedWillDeleteVideoKeys));
        }
        dispatch(
          setCheckedKeys({
            id: "shopWillDeleteVideos",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        closeSnackbar();
        success({ text: "動画を削除しました" });
        await dispatch(fetchVideos(token));
      } catch (err) {
        console.error(err);
        closeSnackbar();
        switch (err.resultStatus) {
          case 116:
            error({ text: "動画の削除に失敗しました。" });
            break;
          case 115:
            error({ text: "この操作を実行する権限がありません。" });
            break;
          default:
            error({ text: "エラーが発生しました。" });
            break;
        }
        setShowWillDeleteVideo(true);
      }
    }
  };

  const handleCheckAndSubmitSpeechAddForm = async () => {
    const checkResult = addSpeechForm!.check();
    if (checkResult && token && speechFileInfo.file !== undefined) {
      try {
        setShowAddSpeech(false);
        info({ text: "スピーチ音声を追加中..." });
        const presigned = await preSignSpeech(
          token,
          addSpeechFormValue.id,
          "add"
        );
        await addS3(presigned.url, speechFileInfo.file);
        await dispatch(
          postSpeech(
            token,
            addSpeechFormValue.id,
            presigned.fileName,
            addSpeechFormValue.text,
            addSpeechFormValue.val,
            addSpeechFormValue.playTime
          )
        );
        dispatch(setAddSpeechFormInitialValue());
        closeSnackbar();
        success({ text: "スピーチ音声の追加をしました" });
        await dispatch(fetchSpeeches(token));
      } catch (err) {
        closeSnackbar();
        error({ text: "入力したIDはすでに登録されています" });
      }
    }
  };

  const handleSubmitDeleteSpeech = async () => {
    if (token && checkedWillDeleteSpeechKeys !== undefined) {
      try {
        setShowDeleteSpeech(false);
        setShowWillDeleteSpeech(false);
        info({ text: "スピーチを削除中..." });
        if (checkedWillDeleteSpeechKeys?.length === 1) {
          await dispatch(delSpeech(token, checkedWillDeleteSpeechKeys[0]));
        } else {
          await dispatch(delSpeeches(token, checkedWillDeleteSpeechKeys));
        }
        dispatch(
          setCheckedKeys({
            id: "shopWillDeleteSpeeches",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        closeSnackbar();
        success({ text: "スピーチを削除しました" });
        await dispatch(fetchSpeeches(token));
      } catch (err) {
        console.error(err);
        closeSnackbar();
        switch (err.resultStatus) {
          case 116:
            error({ text: "スピーチの削除に失敗しました。" });
            break;
          case 115:
            error({ text: "この操作を実行する権限がありません。" });
            break;
          default:
            error({ text: "エラーが発生しました。" });
            break;
        }
        setShowWillDeleteSpeech(true);
      }
    }
  };

  const handleCheckAndSubmitNotificationButtonAddForm = async () => {
    const checkResult = addNotificationButtonForm!.check();
    if (checkResult && token) {
      try {
        await dispatch(
          postNotificationButton(
            token,
            addNotificationButtonFormValue.id,
            addNotificationButtonFormValue.val,
            addNotificationButtonFormValue.viewData,
            addNotificationButtonFormValue.buttonType,
            addNotificationButtonFormValue.remark
          )
        );
        await dispatch(setAddFormInitialValue());
        setShowAddNotificationButton(false);
        success({ text: "通知ボタンを追加しました" });
        await dispatch(fetchNotificationButtons(token));
      } catch (err) {
        error({ text: "入力したIDはすでに登録されています" });
      }
    }
  };

  const handleSubmitDeleteNotificationButton = async () => {
    if (token && checkedWillDeleteNotificationButtonKeys !== undefined) {
      try {
        info({ text: "通知ボタンを削除中..." });
        checkedWillDeleteNotificationButtonKeys.forEach(async item => {
          await dispatch(delNotificationButton(token, item));
        });
        dispatch(
          setCheckedKeys({
            id: "shopWillDeleteNotificationButtons",
            checkInfo: {
              checked: false,
              checkedKeys: [],
              indeterminate: false,
            },
          })
        );
        closeSnackbar();
        success({ text: "通知ボタンを削除しました" });
        setShowDeleteNotificationButton(false);
        setShowWillDeleteNotificationButton(false);
        setShowNotificationButton(false);
        await dispatch(fetchNotificationButtons(token));
      } catch (err) {
        console.error(err);
        closeSnackbar();
        switch (err.resultStatus) {
          case 116:
            error({ text: "通知ボタンの削除に失敗しました。" });
            break;
          case 115:
            error({ text: "この操作を実行する権限がありません。" });
            break;
          default:
            error({ text: "エラーが発生しました。" });
            break;
        }
        setShowWillDeleteNotificationButton(true);
      }
    }
  };

  const handleCheckAndSubmitInfoButtonAddForm = async () => {
    const checkResult = addInfoButtonForm!.check();
    if (checkResult && token) {
      try {
        if (
          addInfoButtonFormValue.infoButtonTypeId ===
          "create_new_rura_analysis_info_option"
        ) {
          await dispatch(
            postInfoButtonType(
              token,
              addInfoButtonFormValue.newInfoButtonTypeId,
              addInfoButtonFormValue.newInfoButtonTypeText,
              addInfoButtonFormValue.newInfoType
            )
          );
          await dispatch(
            postInfoButton(
              token,
              addInfoButtonFormValue.id,
              addInfoButtonFormValue.text,
              addInfoButtonFormValue.val,
              "",
              addInfoButtonFormValue.newInfoButtonTypeId,
              addInfoButtonFormValue.displayTypeId,
              addInfoButtonFormValue.remark
            )
          );
        } else {
          await dispatch(
            postInfoButton(
              token,
              addInfoButtonFormValue.id,
              addInfoButtonFormValue.text,
              addInfoButtonFormValue.val,
              "",
              addInfoButtonFormValue.infoButtonTypeId,
              addInfoButtonFormValue.displayTypeId,
              addInfoButtonFormValue.remark
            )
          );
        }
        dispatch(setInitialAddFormValue);
        setShowAddInfoButton(false);
        success({ text: "分析情報を追加しました" });
        await dispatch(fetchInfoButtons(token));
        await dispatch(fetchInfoButtonTypes(token));
      } catch (err) {
        error({ text: "入力したIDはすでに登録されています" });
      }
    }
  };

  const handleSubmitDeleteInfoButtons = async () => {
    if (token) {
      try {
        if (checkedWillDeleteInfoButtonKeys?.length === 1) {
          await dispatch(
            delInfoButton(token, checkedWillDeleteInfoButtonKeys[0])
          );
        } else {
          info({ text: "分析情報は1つずつしか削除できません" });
          return;
        }
        setShowDeleteInfoButton(false);
        success({ text: "分析情報を削除しました" });
        await dispatch(fetchInfoButtons(token));
        await dispatch(fetchInfoButtonTypes(token));
      } catch (err) {
        error({ text: "不明なエラーが発生しました" });
      }
    }
  };

  // 今後 SoundEvent が増えてきたら 引数に sound_type_id を受け取って switch で分岐する
  const handleClickChangeSoundEvent = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    soundData: SoundData
  ) => {
    setSelectedDetectionSoundEventItem(soundData);
  };

  const handleAllSubmit = async () => {
    const checkResult = editForm!.check();
    if (checkResult && token && shopId && actionData?.id) {
      try {
        const addInfoKey = info({ text: "保存中..." });
        await dispatch(
          putPc(
            token,
            shopId,
            formValue.name ? formValue.name : "",
            formValue.notificationButtons ?? []
          )
        );
        const mediaEvents: MediaEvent[] = [
          {
            eventType: "WAIT",
            mediaId:
              willWaitMedia?.waitMediaId ??
              waitImage?.imageId ??
              waitVideo?.videoId ??
              "",
            mediaType:
              willWaitMedia?.type !== undefined
                ? willWaitMedia.type
                : waitImage !== undefined
                ? "image"
                : waitVideo !== undefined
                ? "video"
                : "image",
          },
          {
            eventType: "REST",
            mediaId:
              willRestMedia?.restMediaId ??
              restImage?.imageId ??
              restVideo?.videoId ??
              "",
            mediaType:
              willRestMedia?.type !== undefined
                ? willRestMedia.type
                : restImage !== undefined
                ? "image"
                : restVideo !== undefined
                ? "video"
                : "image",
          },
          {
            eventType: "SCREEN",
            mediaId:
              willScreen?.screenId ??
              screenImage?.imageId ??
              screenVideo?.videoId ??
              "",
            mediaType:
              willScreen?.type !== undefined
                ? willScreen.type
                : screenImage !== undefined
                ? "image"
                : screenVideo !== undefined
                ? "video"
                : "image",
          },
          {
            eventType: "BACK",
            mediaId:
              willBackMedia?.backMediaId ??
              backImage?.imageId ??
              backVideo?.videoId ??
              "",
            mediaType:
              willBackMedia?.type !== undefined
                ? willBackMedia.type
                : backImage !== undefined
                ? "image"
                : backVideo !== undefined
                ? "video"
                : "image",
          },
          {
            eventType: "BLUR",
            mediaId: willBlur?.blurId ?? blurImage?.imageId ?? "",
            mediaType:
              willBlur?.type ?? blurImage !== undefined ? "image" : "image",
          },
          {
            eventType: "RECEPTION",
            mediaId:
              willReception?.receptionId ?? receptionImage?.imageId ?? "",
            mediaType:
              willReception?.type ?? receptionImage !== undefined
                ? "image"
                : "image",
          },
          {
            eventType: "DETECTION",
            mediaId:
              willDetection?.detectionId ?? detectionImage?.imageId ?? "",
            mediaType:
              willDetection?.type ?? detectionImage !== undefined
                ? "image"
                : "image",
          },
        ];
        await dispatch(
          putAction(
            token,
            actionData?.id,
            actionData?.name,
            formValue.images ? formValue.images : [],
            formValue.speeches ? formValue.speeches : [],
            formValue.videos ? formValue.videos : [],
            actionData.robotType.id,
            formValue.infoButtons ? formValue.infoButtons : [],
            mediaEvents,
            formValue.remark ? formValue.remark : ""
          )
        );
        if (preMemos.length !== 0) {
          preMemos.forEach(async memo => {
            await dispatch(
              postMemo(
                token,
                shopId,
                memo.id,
                memo.preMemoInfo.preMemoTitle
                  ? memo.preMemoInfo.preMemoTitle
                  : "",
                memo.preMemoInfo.preMemoText
                  ? memo.preMemoInfo.preMemoText
                  : "",
                memo.preMemoInfo.remark ? memo.preMemoInfo.remark : ""
              )
            );
          });
        }
        if (memos.length !== 0) {
          memos.forEach(async memo => {
            await dispatch(
              putMemo(
                token,
                shopId,
                memo.id,
                memo.memoInfo.memoTitle || "",
                memo.memoInfo.memoText || "",
                memo.memoInfo.remark || ""
              )
            );
          });
        }
        if (willDeleteMemos.length !== 0) {
          await dispatch(delMemos(token, willDeleteMemos));
        }

        await dispatch(changePcState(token, shopId));

        if (
          detectionSoundEventData !== undefined &&
          selectedDetectionSoundEventItem !== undefined
        ) {
          if (
            detectionSoundEventData.audioNo !==
            selectedDetectionSoundEventItem.audioNo
          ) {
            await updateSoundEvent(
              token,
              shopId,
              detectionSoundEventData?.soundEventTypeId,
              selectedDetectionSoundEventItem.audioNo
            );
          }
        }

        setWillDeleteMemos([]);
        setWillUpdateMemos([]);
        dispatch(setPreMemo({ preMemos: [] }));
        dispatch(
          setShopEditFormValue({
            formValue: {
              id: "",
              name: "",
              images: [],
              videos: [],
              speeches: [],
              remark: "",
            },
          })
        );
        setWillWaitMedia({ waitMediaId: "" });
        setWillRestMedia({ restMediaId: "" });
        setWillBackMedia({ backMediaId: "" });
        setWillScreen({ screenId: "" });
        setWillBlur({ blurId: "" });

        closeSnackbar(addInfoKey);
        dispatch(fetchAction(token, actionData?.id));
        success({ text: "保存しました" });
        setTimeout(() => {
          window.location.reload();
        }, 100);
        history.push(RoutePath.management.shops);
      } catch (err) {
        console.error(err);
        error({ text: "保存に失敗しました" });
      }
    }
  };

  const allReset = () => {
    setWillDeleteMemos([]);
    setWillUpdateMemos([]);
    dispatch(setPreMemo({ preMemos: [] }));
    dispatch(
      setShopEditFormValue({
        formValue: {
          id: "",
          name: "",
          images: [],
          videos: [],
          speeches: [],
          remark: "",
        },
      })
    );
    setWillWaitMedia({ waitMediaId: "" });
    setWillRestMedia({ restMediaId: "" });
    setWillBackMedia({ backMediaId: "" });
    setWillScreen({ screenId: "" });
    setWillBlur({ blurId: "" });
    setCheckedWaitMediaKey(undefined);
    setCheckedRestMediaKey(undefined);
    setCheckedBackMediaKey(undefined);
    setCheckedBlurKey(undefined);
    setCheckedReceptionKey(undefined);
    setCheckedScreenKey(undefined);
  };

  return (
    <>
      <Title>店舗編集</Title>
      {isLoading ||
      isActionDataLoading ||
      isImagesDataLoading ||
      isVideosDataLoading ||
      isInfoButtonsDataLoading ||
      isNotificationButtonDataLoading ||
      isPcDataLoading ||
      isSpeechesDataLoading ? (
        <Loading />
      ) : (
        <>
          <Margin top={20}>
            <Form
              model={editModel}
              formValue={formValue}
              ref={(ref: FormInstance) => setEditForm(ref)}
              onChange={handleShopFormChange}
            >
              <FormGroup>
                <ControlLabel>店舗ID</ControlLabel>
                <FormControl
                  plaintext
                  name="id"
                  style={{ background: "transparent" }}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>店舗名</ControlLabel>
                <FormControl name="name" />
              </FormGroup>
              <FlexboxGrid justify="start">
                <FlexboxGrid.Item colspan={8}>
                  <FormGroup>
                    <ControlLabel>
                      画像
                      <Tooltip
                        placement="top"
                        title={
                          <ToolTipTitle>
                            待機画面ほか、各種画面に利用する可能性のある画像はこちらで先に登録の必要があります。
                          </ToolTipTitle>
                        }
                      >
                        <IconButton>
                          <HelpIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </ControlLabel>
                    <StyledPrimaryButton onClick={onClickAddImage}>
                      選択する
                    </StyledPrimaryButton>
                    <span>{`：${checkedImageKeys.length}枚選択済み`}</span>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>動画</ControlLabel>
                    <StyledPrimaryButton onClick={onClickAddVideo}>
                      選択する
                    </StyledPrimaryButton>
                    <span>{`：${checkedVideoKeys.length}個選択済み`}</span>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>スピーチ</ControlLabel>
                    <StyledPrimaryButton onClick={onClickAddSpeech}>
                      選択する
                    </StyledPrimaryButton>
                    <span>{`：${checkedSpeechKeys.length}個選択済み`}</span>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>通知ボタン</ControlLabel>
                    <StyledPrimaryButton onClick={onClickAddNotificationButton}>
                      選択する
                    </StyledPrimaryButton>
                    <span>{`：${checkedNotificationButtonKeys.length}個選択済み`}</span>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>分析情報</ControlLabel>
                    <StyledPrimaryButton onClick={onClickAddInfoButton}>
                      選択する
                    </StyledPrimaryButton>
                    <span>{`：${checkedInfoButtonKeys.length}個選択済み`}</span>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>通知音</ControlLabel>
                    <StyledPrimaryButton onClick={onClickAdNotificationSound}>
                      選択する
                    </StyledPrimaryButton>
                  </FormGroup>
                  <FormGroup style={{ verticalAlign: "top" }}>
                    <ControlLabel>店舗メモ</ControlLabel>
                    {willDeleteMemos.map((memo, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                          key={index.toString()}
                        >
                          <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                            {memo.id}
                          </div>
                          <StyledMemoDeleteButton
                            isChecked={false}
                            onClick={() => {
                              restoreMemo(memo);
                            }}
                          >
                            復元する
                          </StyledMemoDeleteButton>
                        </div>
                      );
                    })}
                    <DragDropContext onDragEnd={onDragEndMemos}>
                      <Droppable droppableId="memos">
                        {({
                          droppableProps,
                          innerRef: droppableRef,
                          placeholder,
                        }) => (
                          <div
                            {...droppableProps}
                            ref={droppableRef}
                            className="memos"
                          >
                            {memos.map((memo, index) => {
                              return (
                                <Draggable
                                  key={memo.id}
                                  draggableId={memo.id}
                                  index={index}
                                >
                                  {({
                                    draggableProps,
                                    dragHandleProps,
                                    innerRef: draggableRef,
                                  }) => (
                                    <div
                                      ref={draggableRef}
                                      {...draggableProps}
                                      {...dragHandleProps}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-start",
                                        }}
                                      >
                                        <div
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {memo.id}
                                        </div>
                                        <StyledMemoDeleteButton
                                          isChecked
                                          onClick={() => {
                                            deleteMemo(memo.id);
                                          }}
                                        >
                                          削除する
                                        </StyledMemoDeleteButton>
                                      </div>
                                      <FormControl
                                        style={{ verticalAlign: "top" }}
                                        placeholder={memo.memoInfo.memoTitle}
                                        value={memo.memoInfo.memoTitle}
                                        onChange={value =>
                                          handleMemoTitle(memo.id, value)
                                        }
                                      />
                                      <FormControl
                                        style={{ verticalAlign: "top" }}
                                        componentClass="textarea"
                                        placeholder={memo.memoInfo.memoText}
                                        value={memo.memoInfo.memoText}
                                        onChange={value =>
                                          handleMemoText(memo.id, value)
                                        }
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {preMemos.map((memo, index) => {
                      return (
                        <Fragment key={index.toString()}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <div
                              style={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              {memo.id}
                            </div>
                            <StyledMemoDeleteButton
                              isChecked
                              onClick={() => {
                                deletePreMemo(memo.id);
                              }}
                            >
                              削除する
                            </StyledMemoDeleteButton>
                          </div>
                          <FormControl
                            style={{ verticalAlign: "top" }}
                            placeholder={memo.preMemoInfo.preMemoTitle}
                            value={memo.preMemoInfo.preMemoTitle}
                            onChange={value =>
                              handlePreMemoTitle(memo.id, value)
                            }
                          />
                          <FormControl
                            style={{ verticalAlign: "top" }}
                            componentClass="textarea"
                            placeholder={memo.preMemoInfo.preMemoText}
                            value={memo.preMemoInfo.preMemoText}
                            onChange={value =>
                              handlePreMemoText(memo.id, value)
                            }
                          />
                        </Fragment>
                      );
                    })}
                    <StyledAddButton onClick={onClickAddMemo}>
                      メモを追加する
                    </StyledAddButton>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>備考</ControlLabel>
                    <FormControl name="remark" />
                  </FormGroup>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} md={12} xs={24}>
                  <FlexboxGrid justify="end">
                    <FlexboxGrid.Item
                      componentClass={Col}
                      colspan={24}
                      md={12}
                      xs={24}
                    >
                      <FormGroup>
                        <ControlLabel>
                          待機メディア
                          <Tooltip
                            placement="top"
                            title={
                              <ToolTipTitle>
                                <img
                                  src={HelpImg}
                                  alt="ヘルプ画像"
                                  width="100%"
                                />
                                すぐに対応可能な接客担当者が待機画面上に一人以上いる場合に表示するメディアを設定できます。
                              </ToolTipTitle>
                            }
                          >
                            <IconButton>
                              <HelpIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ControlLabel>
                        {willWaitMedia?.waitMediaId === "" &&
                        checkedWaitMediaKey === undefined ? (
                          <div>待機メディアが設定されていません</div>
                        ) : willWaitMedia !== undefined ? (
                          willWaitMedia.waitMedia?.includes(".mp4") ? (
                            <StyledVideo
                              src={willWaitMedia.waitMedia}
                              autoPlay
                              muted
                              loop
                            />
                          ) : (
                            <StyledImg
                              src={willWaitMedia.waitMedia}
                              alt="待機メディア"
                            />
                          )
                        ) : waitImage !== undefined ? (
                          <StyledImg src={waitImage.image} alt="待機メディア" />
                        ) : waitVideo !== undefined ? (
                          <StyledVideo
                            src={waitVideo.url}
                            autoPlay
                            muted
                            loop
                          />
                        ) : (
                          <ReviewWrapper>
                            <div>待機メディアが設定されていません</div>
                          </ReviewWrapper>
                        )}
                        <StyledPrimaryButton onClick={onClickAddWaitImage}>
                          選択する
                        </StyledPrimaryButton>
                      </FormGroup>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item
                      componentClass={Col}
                      colspan={24}
                      md={12}
                      xs={24}
                    >
                      <FormGroup>
                        <ControlLabel>
                          休憩メディア
                          <Tooltip
                            placement="top"
                            title={
                              <ToolTipTitle>
                                <img
                                  src={HelpImg}
                                  alt="ヘルプ画像"
                                  width="100%"
                                />
                                すぐに対応可能な接客担当者が不在（全員が休憩中、もしくは他店舗で接客を行っている状態）の場合に表示するメディアを設定できます。
                              </ToolTipTitle>
                            }
                          >
                            <IconButton>
                              <HelpIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ControlLabel>
                        {willRestMedia?.restMediaId === "" &&
                        checkedRestMediaKey === undefined ? (
                          <div>休憩メディアが設定されていません</div>
                        ) : willRestMedia !== undefined ? (
                          willRestMedia.restMedia?.includes(".mp4") ? (
                            <StyledVideo
                              src={willRestMedia.restMedia}
                              autoPlay
                              muted
                              loop
                            />
                          ) : (
                            <StyledImg
                              src={willRestMedia.restMedia}
                              alt="休憩メディア"
                            />
                          )
                        ) : restImage !== undefined ? (
                          <StyledImg src={restImage.image} alt="休憩メディア" />
                        ) : restVideo !== undefined ? (
                          <StyledVideo
                            src={restVideo.url}
                            autoPlay
                            muted
                            loop
                          />
                        ) : (
                          <ReviewWrapper>
                            <div>休憩メディアが設定されていません</div>
                          </ReviewWrapper>
                        )}
                        <StyledPrimaryButton onClick={onClickAddRestImage}>
                          選択する
                        </StyledPrimaryButton>
                      </FormGroup>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item
                      componentClass={Col}
                      colspan={24}
                      md={12}
                      xs={24}
                    >
                      <FormGroup>
                        <ControlLabel>
                          背景メディア
                          <Tooltip
                            placement="top"
                            title={
                              <ToolTipTitle>
                                <img
                                  src={HelpImg}
                                  alt="ヘルプ画像"
                                  width="100%"
                                />
                                お客様に見える画面の背景に表示するメディアを設定できます。
                              </ToolTipTitle>
                            }
                          >
                            <IconButton>
                              <HelpIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ControlLabel>
                        {willBackMedia?.backMediaId === "" &&
                        checkedBackMediaKey === undefined ? (
                          <div>背景メディアが設定されていません</div>
                        ) : willBackMedia !== undefined ? (
                          willBackMedia.backMedia?.includes(".mp4") ? (
                            <StyledVideo
                              src={willBackMedia.backMedia}
                              autoPlay
                              muted
                              loop
                            />
                          ) : (
                            <StyledImg
                              src={willBackMedia.backMedia}
                              alt="背景メディア"
                            />
                          )
                        ) : backImage !== undefined ? (
                          <StyledImg src={backImage.image} alt="背景メディア" />
                        ) : backVideo !== undefined ? (
                          <StyledVideo
                            src={backVideo.url}
                            autoPlay
                            muted
                            loop
                          />
                        ) : (
                          <ReviewWrapper>
                            <div>背景メディアが設定されていません</div>
                          </ReviewWrapper>
                        )}
                        <StyledPrimaryButton onClick={onClickAddBackImage}>
                          選択する
                        </StyledPrimaryButton>
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>
                          接客開始時デフォルト選択の画像
                          <Tooltip
                            placement="top"
                            title={
                              <ToolTipTitle>
                                接客開始時にデフォルトで表示する画像を設定できます。
                              </ToolTipTitle>
                            }
                          >
                            <IconButton>
                              <HelpIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ControlLabel>
                        {willReception?.receptionId === "" &&
                        checkedReceptionKey === undefined ? (
                          <div>
                            接客開始時のデフォルト画像が設定されていません
                          </div>
                        ) : willReception !== undefined ? (
                          <StyledImg
                            src={willReception.reception}
                            alt="接客開始時のデフォルト画像"
                          />
                        ) : receptionImage !== undefined ? (
                          <StyledImg
                            src={receptionImage.image}
                            alt="接客開始時のデフォルト画像"
                          />
                        ) : (
                          <ReviewWrapper>
                            <div>
                              接客開始時のデフォルト画像が設定されていません
                            </div>
                          </ReviewWrapper>
                        )}
                        <StyledPrimaryButton onClick={onClickAddReceptionImage}>
                          選択する
                        </StyledPrimaryButton>
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>
                          呼び出しボタンを押した際の画像
                          <Tooltip
                            placement="top"
                            title={
                              <ToolTipTitle>
                                呼び出しボタンが押された時の画像を設定できます。
                              </ToolTipTitle>
                            }
                          >
                            <IconButton>
                              <HelpIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ControlLabel>
                        {willDetection?.detectionId === "" &&
                        checkedDetectionKey === undefined ? (
                          <div>
                            呼び出しボタンが押された時の画像が設定されていません
                          </div>
                        ) : willDetection !== undefined ? (
                          <StyledImg
                            src={willDetection.detection}
                            alt="呼び出しボタンが押された時の画像"
                          />
                        ) : detectionImage !== undefined ? (
                          <StyledImg
                            src={detectionImage.image}
                            alt="呼び出しボタンが押された時の画像"
                          />
                        ) : (
                          <ReviewWrapper>
                            <div>
                              呼び出しボタンが押された時の画像が設定されていません
                            </div>
                          </ReviewWrapper>
                        )}
                        <StyledPrimaryButton onClick={onClickAddDetectionImage}>
                          選択する
                        </StyledPrimaryButton>
                      </FormGroup>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item
                      componentClass={Col}
                      colspan={24}
                      md={12}
                      xs={24}
                    >
                      <FormGroup>
                        <ControlLabel>
                          スクリーンセーバー
                          <Tooltip
                            placement="top"
                            title={
                              <ToolTipTitle>
                                待機画面からスクリーンセーバーを選択した際に、全画面表示するメディアを設定できます。
                              </ToolTipTitle>
                            }
                          >
                            <IconButton>
                              <HelpIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ControlLabel>
                        {willScreen?.screenId === "" &&
                        checkedScreenKey === undefined ? (
                          <div>スクリーンセーバーが設定されていません</div>
                        ) : willScreen !== undefined ? (
                          willScreen.screen?.includes(".mp4") ? (
                            <StyledVideo
                              src={willScreen.screen}
                              autoPlay
                              muted
                              loop
                            />
                          ) : (
                            <StyledImg
                              src={willScreen.screen}
                              alt="スクリーンセーバー"
                            />
                          )
                        ) : screenVideo !== undefined ? (
                          <StyledVideo
                            src={screenVideo.url}
                            autoPlay
                            muted
                            loop
                          />
                        ) : screenImage !== undefined ? (
                          <StyledImg
                            src={screenImage.image}
                            alt="スクリーンセーバー"
                          />
                        ) : (
                          <ReviewWrapper>
                            <div>スクリーンセーバーが設定されていません</div>
                          </ReviewWrapper>
                        )}
                        <StyledPrimaryButton onClick={onClickAddScreenImage}>
                          選択する
                        </StyledPrimaryButton>
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>
                          接客開始時の画像
                          <Tooltip
                            placement="top"
                            title={
                              <ToolTipTitle>
                                接客開始時に表示する画像を設定できます。
                              </ToolTipTitle>
                            }
                          >
                            <IconButton>
                              <HelpIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ControlLabel>
                        {willBlur?.blurId === "" &&
                        checkedBlurKey === undefined ? (
                          <div>接客開始時の画像が設定されていません</div>
                        ) : willBlur !== undefined ? (
                          <StyledImg
                            src={willBlur.blur}
                            alt="接客開始時の画像"
                          />
                        ) : blurImage !== undefined ? (
                          <StyledImg
                            src={blurImage.image}
                            alt="接客開始時の画像"
                          />
                        ) : (
                          <ReviewWrapper>
                            <div>接客開始時の画像が設定されていません</div>
                          </ReviewWrapper>
                        )}
                        <StyledPrimaryButton onClick={onClickAddBlurImage}>
                          選択する
                        </StyledPrimaryButton>
                      </FormGroup>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </Form>
          </Margin>
          <Margin top={20}>
            <StyledCancelButton onClick={handleGoBack}>戻る</StyledCancelButton>
            <StyledPrimaryButton
              style={{ marginLeft: "10px" }}
              onClick={handleSave}
            >
              保存する
            </StyledPrimaryButton>
          </Margin>
        </>
      )}
      <StyledSelectModal
        full
        show={showImage}
        overFlow={false}
        onHide={onCancelClick}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          画像選択
        </Modal.Header>
        <Modal.Body>
          {imagesData ? (
            <ImageMedia
              imagesData={imagesData.images}
              checkedImageKeys={checkedImageKeys}
              handleImageCheck={handleImageCheck}
            />
          ) : (
            <div>画像はありません</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledDeleteButton
              isChecked
              onClick={() => {
                setShowWillDeleteImage(true);
              }}
            >
              画像を選択して削除する
            </StyledDeleteButton>
            <StyledPrimaryButton
              onClick={() => {
                setShowAddImage(true);
              }}
            >
              新しい画像を追加する
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelClick}>
              設定する
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledSelectModal>
      <StyledSelectModal
        full
        show={showWillDeleteImage}
        overFlow={false}
        onHide={() => setShowWillDeleteImage(false)}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          画像削除
        </Modal.Header>
        <Modal.Body>
          {imagesData ? (
            <ImageMedia
              imagesData={imagesData.images}
              checkedImageKeys={checkedWillDeleteImageKeys}
              handleImageCheck={handleWillDeleteImageCheck}
            />
          ) : (
            <div>画像はありません</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledDeleteButton
              isChecked
              onClick={() => {
                setShowDeleteImage(true);
              }}
            >
              削除
            </StyledDeleteButton>
            <StyledCancelButton
              variant="contained"
              color="primary"
              onClick={() => setShowWillDeleteImage(false)}
            >
              取り消し
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledSelectModal>
      <StyledSelectModal
        show={showDeleteImage}
        overflow
        onHide={onCancelClickDelete}
        isOpen={isOpen}
      >
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            画像削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List bordered={false}>
            {checkedWillDeleteImageKeys
              .map(key => {
                return imagesData?.images.filter(
                  image => image.imageId === key
                )[0];
              })
              .map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{
                    paddingLeft: "10px",
                    color: "#d84421",
                  }}
                >
                  <p>
                    画像ID:
                    {item?.imageId}
                  </p>
                  <StyledImg src={item?.image} />
                </List.Item>
              ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: "#d84421",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記の画像を削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          <StyledDeleteButton isChecked onClick={handleSubmitDeleteImage}>
            削除
          </StyledDeleteButton>
          <StyledCancelButton
            variant="contained"
            color="primary"
            onClick={onCancelClickDelete}
          >
            取り消し
          </StyledCancelButton>
        </Modal.Footer>
      </StyledSelectModal>
      <AddImageModal
        show={showAddImage}
        onHide={onModalCancel}
        setForm={setImageAddForm}
        submitImage={handleCheckAndSubmitImageAddForm}
      />

      <StyledSelectModal
        show={showVideo}
        full
        overFlow={false}
        onHide={onCancelClick}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          動画選択
        </Modal.Header>
        <Modal.Body>
          <VideoMedia
            videosData={videosData?.videos}
            checkedVideoKeys={checkedVideoKeys}
            handleVideoCheck={handleVideoCheck}
          />
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledDeleteButton
              isChecked
              onClick={() => {
                setShowWillDeleteVideo(true);
              }}
            >
              動画を選択して削除する
            </StyledDeleteButton>
            <StyledPrimaryButton
              onClick={() => {
                setShowAddVideo(true);
              }}
            >
              新しい動画を追加する
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelClick}>
              設定する
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledSelectModal>
      <StyledSelectModal
        full
        show={showWillDeleteVideo}
        overFlow={false}
        onHide={() => setShowWillDeleteVideo(false)}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          動画削除
        </Modal.Header>
        <Modal.Body>
          {videosData ? (
            <VideoMedia
              videosData={videosData.videos}
              checkedVideoKeys={checkedWillDeleteVideoKeys}
              handleVideoCheck={handleWillDeleteVideoCheck}
            />
          ) : (
            <div>動画はありません</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledDeleteButton
              isChecked
              onClick={() => {
                setShowDeleteVideo(true);
              }}
            >
              削除
            </StyledDeleteButton>
            <StyledCancelButton
              variant="contained"
              color="primary"
              onClick={() => setShowWillDeleteVideo(false)}
            >
              取り消し
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledSelectModal>
      <StyledSelectModal
        show={showDeleteVideo}
        overflow
        onHide={onCancelClickDelete}
        isOpen={isOpen}
      >
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            動画削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List bordered={false}>
            {checkedWillDeleteVideoKeys
              .map(key => {
                return videosData?.videos.filter(
                  video => video.videoId === key
                )[0];
              })
              .map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{
                    paddingLeft: "10px",
                    color: "#d84421",
                  }}
                >
                  <p>
                    動画ID:
                    {item?.videoId}
                  </p>
                  <StyledVideo src={item?.videoId} />
                </List.Item>
              ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: "#d84421",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記の動画を削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          <StyledDeleteButton isChecked onClick={handleSubmitDeleteVideo}>
            削除
          </StyledDeleteButton>
          <StyledCancelButton
            variant="contained"
            color="primary"
            onClick={onCancelClickDelete}
          >
            取り消し
          </StyledCancelButton>
        </Modal.Footer>
      </StyledSelectModal>
      <AddVideoModal
        show={showAddVideo}
        onHide={onModalCancel}
        setForm={setVideoAddForm}
        submitVideo={handleCheckAndSubmitVideoAddForm}
        videoFileInfo={videoFileInfo}
        setVideoFileInfo={setVideoFileInfo}
      />
      <StyledSelectModal
        show={showSpeech}
        full
        overFlow={false}
        onHide={onCancelClick}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          スピーチ選択
        </Modal.Header>
        <Modal.Body>
          {speechesData && speechesData !== null ? (
            <SpeechesTable speeches={speechesData.speeches} id="shopSpeeches" />
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledDeleteButton
              isChecked
              onClick={() => {
                setShowWillDeleteSpeech(true);
              }}
            >
              スピーチを選択して削除する
            </StyledDeleteButton>
            <StyledPrimaryButton
              onClick={() => {
                setShowAddSpeech(true);
              }}
            >
              新しいスピーチを追加する
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelClick}>
              設定する
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledSelectModal>
      <StyledSelectModal
        full
        show={showWillDeleteSpeech}
        overFlow={false}
        onHide={() => setShowWillDeleteSpeech(false)}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          スピーチ削除
        </Modal.Header>
        <Modal.Body>
          {speechesData ? (
            <SpeechesTable
              id="shopWillDeleteSpeeches"
              speeches={speechesData.speeches}
            />
          ) : (
            <div>スピーチはありません</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledDeleteButton
              isChecked
              onClick={() => {
                setShowDeleteSpeech(true);
              }}
            >
              削除
            </StyledDeleteButton>
            <StyledCancelButton
              variant="contained"
              color="primary"
              onClick={() => setShowWillDeleteSpeech(false)}
            >
              取り消し
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledSelectModal>
      <StyledSelectModal
        show={showDeleteSpeech}
        overflow
        onHide={onCancelClickDelete}
        isOpen={isOpen}
      >
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            スピーチ削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List bordered={false}>
            {checkedWillDeleteSpeechKeys
              ?.map(key => {
                return speechesData?.speeches.find(
                  speech => speech.speechId === key
                );
              })
              ?.map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{
                    paddingLeft: "10px",
                    color: "#d84421",
                  }}
                >
                  <p>
                    スピーチID:
                    {item?.speechId}
                  </p>
                  <audio src={item ? item.url : ""} controls>
                    <source src={item ? item.url : ""} />
                  </audio>
                </List.Item>
              ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: "#d84421",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記のスピーチを削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          <StyledDeleteButton isChecked onClick={handleSubmitDeleteSpeech}>
            削除
          </StyledDeleteButton>
          <StyledCancelButton
            variant="contained"
            color="primary"
            onClick={onCancelClickDelete}
          >
            取り消し
          </StyledCancelButton>
        </Modal.Footer>
      </StyledSelectModal>
      <AddSpeechModal
        show={showAddSpeech}
        onHide={onModalCancel}
        setForm={setSpeechAddForm}
        submitSpeech={handleCheckAndSubmitSpeechAddForm}
        speechFileInfo={speechFileInfo}
        setSpeechFileInfo={setSpeechFileInfo}
      />

      <StyledSelectModal
        show={showNotificationSound}
        full
        overFlow={false}
        onHide={onCancelClick}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          通知音選択
        </Modal.Header>
        <Modal.Body>
          {detectionSoundEventData !== undefined && (
            <SoundEventsTable
              soundEvent={detectionSoundEventData}
              soundData={detectionAudioFiles}
              checkedItem={selectedDetectionSoundEventItem}
              handleClickItem={handleClickChangeSoundEvent}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledCancelButton onClick={onCancelClick}>
              設定する
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledSelectModal>

      <StyledSelectModal
        show={showNotificationButton}
        full
        overFlow={false}
        onHide={onCancelClick}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          通知ボタン選択
        </Modal.Header>
        <Modal.Body>
          {notificationButtonData !== undefined &&
          notificationButtonData !== null ? (
            <NotificationButtonsTable
              notificationButtons={notificationButtonData.notificationButtons}
              id="shopNotificationButtons"
            />
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <StyledDeleteButton
            isChecked
            onClick={() => {
              setShowWillDeleteNotificationButton(true);
            }}
          >
            通知ボタンを選択して削除する
          </StyledDeleteButton>
          <StyledPrimaryButton
            onClick={() => {
              setShowAddNotificationButton(true);
            }}
          >
            新しい通知ボタンを追加する
          </StyledPrimaryButton>
          <StyledCancelButton onClick={onCancelClick}>
            設定する
          </StyledCancelButton>
        </Modal.Footer>
      </StyledSelectModal>

      <AddNotificationButtonModal
        show={showAddNotificationButton}
        onHide={onModalCancel}
        setForm={setAddNotificationButtonForm}
        submitNotificationButton={handleCheckAndSubmitNotificationButtonAddForm}
      />

      <StyledSelectModal
        full
        show={showWillDeleteNotificationButton}
        overFlow={false}
        onHide={() => setShowWillDeleteNotificationButton(false)}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          通知ボタン削除
        </Modal.Header>
        <Modal.Body>
          {notificationButtonData ? (
            <NotificationButtonsTable
              notificationButtons={notificationButtonData.notificationButtons}
              id="shopWillDeleteNotificationButtons"
            />
          ) : (
            <div>通知ボタンはありません</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledDeleteButton
              isChecked
              onClick={() => {
                setShowDeleteNotificationButton(true);
              }}
            >
              削除
            </StyledDeleteButton>
            <StyledCancelButton
              variant="contained"
              color="primary"
              onClick={() => setShowWillDeleteNotificationButton(false)}
            >
              取り消し
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledSelectModal>
      <StyledSelectModal
        show={showDeleteNotificationButton}
        overflow
        onHide={onCancelClickDelete}
        isOpen={isOpen}
      >
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            通知ボタン削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List bordered={false}>
            {checkedWillDeleteNotificationButtonKeys
              .map(key => {
                return notificationButtonData?.notificationButtons.filter(
                  notificationButton => notificationButton.id === key
                )[0];
              })
              .map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{
                    paddingLeft: "10px",
                    color: "#d84421",
                  }}
                >
                  <p>
                    通知ボタンID:
                    {item?.id}
                  </p>
                  <p>
                    表示データ:
                    {item?.viewData}
                  </p>
                </List.Item>
              ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: "#d84421",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記の通知ボタンを削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          <StyledDeleteButton
            isChecked
            onClick={handleSubmitDeleteNotificationButton}
          >
            削除
          </StyledDeleteButton>
          <StyledCancelButton
            variant="contained"
            color="primary"
            onClick={onCancelClickDelete}
          >
            取り消し
          </StyledCancelButton>
        </Modal.Footer>
      </StyledSelectModal>

      <StyledSelectModal
        show={showInfoButton}
        full
        overFlow={false}
        onHide={onCancelClick}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          分析情報選択
        </Modal.Header>
        <Modal.Body>
          {infoButtonsData && infoButtonsData !== null ? (
            <InfoButtonsTable
              id="shopInfoButtons"
              infoButtons={infoButtonsData.infoButtons}
            />
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledDeleteButton
              isChecked
              onClick={() => {
                setShowWillDeleteInfoButton(true);
              }}
            >
              分析情報を選択して削除する
            </StyledDeleteButton>
            <StyledPrimaryButton
              onClick={() => {
                setShowAddInfoButton(true);
              }}
            >
              新しい分析情報を追加する
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelClick}>
              設定する
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledSelectModal>
      <AddAnalysisInfoModal
        show={showAddInfoButton}
        onHide={onModalCancel}
        setForm={setAddInfoButtonForm}
        submitAnalysisInfo={handleCheckAndSubmitInfoButtonAddForm}
      />
      <StyledSelectModal
        full
        show={showWillDeleteInfoButton}
        overFlow={false}
        onHide={() => setShowWillDeleteInfoButton(false)}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          分析情報削除
        </Modal.Header>
        <Modal.Body>
          {infoButtonsData ? (
            <InfoButtonsTable
              id="shopWillDeleteInfoButtons"
              infoButtons={infoButtonsData.infoButtons}
            />
          ) : (
            <div>分析情報はありません</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledDeleteButton
              isChecked
              onClick={() => {
                setShowDeleteInfoButton(true);
              }}
            >
              削除
            </StyledDeleteButton>
            <StyledCancelButton
              variant="contained"
              color="primary"
              onClick={() => setShowWillDeleteInfoButton(false)}
            >
              取り消し
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledSelectModal>
      <StyledSelectModal
        show={showDeleteInfoButton}
        overflow
        onHide={onCancelClick}
      >
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", fontWeight: "bold" }}>
            分析情報削除
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List bordered={false}>
            {checkedWillDeleteInfoButtonKeys
              ?.map(key => {
                return infoButtonsData?.infoButtons.find(
                  infoButton => infoButton.id === key
                );
              })
              ?.map((item, index) => (
                <List.Item
                  key={index.toString()}
                  index={index}
                  style={{
                    paddingLeft: "10px",
                    color: "#d84421",
                  }}
                >
                  <p>
                    分析情報ID:
                    {item?.id}
                  </p>
                </List.Item>
              ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              marginTop: "24px",
              color: "#d84421",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            上記の分析情報を削除してもよろしいですか？
          </p>
          <p style={{ marginBottom: "24px", textAlign: "center" }}>
            一度削除すると、復元することはできません。
          </p>
          <StyledDeleteButton isChecked onClick={handleSubmitDeleteInfoButtons}>
            OK
          </StyledDeleteButton>
          <StyledCancelButton onClick={onCancelClickDelete}>
            Cancel
          </StyledCancelButton>
        </Modal.Footer>
      </StyledSelectModal>

      <StyledSelectModal
        show={showAddMemo}
        overFlow={false}
        onHide={onCancelClick}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          メモ追加
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            model={addMemoModel}
            formValue={addMemoFormValue}
            ref={(ref: FormInstance) => setAddMemoForm(ref)}
            onChange={handleAddMemoFormChange}
          >
            <FormGroup>
              <ControlLabel>メモタイトル</ControlLabel>
              <FormControl name="title" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>メモテキスト</ControlLabel>
              <FormControl name="text" componentClass="textarea" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>備考</ControlLabel>
              <FormControl name="remark" />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <StyledPrimaryButton onClick={handleCheckAndSubmitMemoAddForm}>
            追加する
          </StyledPrimaryButton>
          <StyledCancelButton onClick={onCancelClick}>
            キャンセル
          </StyledCancelButton>
        </Modal.Footer>
      </StyledSelectModal>
      <StyledSelectModal
        show={showConfirm}
        onHide={onCancelClick}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          ご確認ください
        </Modal.Header>
        <Modal.Body>これまでの変更は破棄されます。よろしいですか？</Modal.Body>
        <Modal.Footer>
          <StyledBackButton
            onClick={async () => {
              allReset();
              setTimeout(() => {
                window.location.reload();
              }, 100);
              history.goBack();
            }}
          >
            破棄して戻る
          </StyledBackButton>
          <StyledCancelButton onClick={onCancelClick}>
            編集を続ける
          </StyledCancelButton>
        </Modal.Footer>
      </StyledSelectModal>
      <StyledSelectModal
        show={showSaveConfirm}
        onHide={onCancelClick}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          ご確認ください
        </Modal.Header>
        <Modal.Body>
          10分ほど接客ができなくななる可能性がありますがよろしいですか？
        </Modal.Body>
        <Modal.Footer>
          <StyledPrimaryButton onClick={handleAllSubmit}>
            保存する
          </StyledPrimaryButton>
          <StyledCancelButton onClick={onCancelClick}>
            取り消し
          </StyledCancelButton>
        </Modal.Footer>
      </StyledSelectModal>

      <StyledSelectModal
        full
        show={showWaitMedia}
        overFlow={false}
        onHide={onCancelWaitModal}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          待機メディア選択
        </Modal.Header>
        <Modal.Body>
          <Tabs>
            <TabList>
              <Tab style={{ padding: "10px 20px" }}>画像</Tab>
              <Tab style={{ padding: "10px 20px" }}>動画</Tab>
            </TabList>
            <TabPanel>
              <Margin top={10} left={10} right={10} bottom={10}>
                <ImageMedia
                  imagesData={imagesData?.images
                    .filter(image => checkedImageKeys.includes(image.imageId))
                    .filter(image => image.imageId !== checkedRestMediaKey)
                    .filter(image => image.imageId !== checkedBackMediaKey)
                    .filter(image => image.imageId !== checkedScreenKey)
                    .filter(image => image.imageId !== checkedBlurKey)
                    .filter(image => image.imageId !== checkedReceptionKey)
                    .filter(image => image.imageId !== checkedDetectionKey)}
                  checkedImageKeys={
                    checkedWaitMediaKey !== undefined
                      ? [checkedWaitMediaKey]
                      : undefined
                  }
                  handleImageCheck={handleWaitMediaCheck}
                />
              </Margin>
            </TabPanel>
            <TabPanel>
              <Margin top={10} left={10} right={10} bottom={10}>
                <VideoMedia
                  videosData={videosData?.videos
                    .filter(video => checkedVideoKeys.includes(video.videoId))
                    .filter(video => video.videoId !== checkedRestMediaKey)
                    .filter(video => video.videoId !== checkedBackMediaKey)
                    .filter(video => video.videoId !== checkedScreenKey)}
                  checkedVideoKeys={
                    checkedWaitMediaKey !== undefined
                      ? [checkedWaitMediaKey]
                      : undefined
                  }
                  handleVideoCheck={handleWaitMediaCheck}
                />
              </Margin>
            </TabPanel>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledPrimaryButton onClick={setWaitMedia}>
              設定する
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelWaitModal}>
              閉じる
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledSelectModal>

      <StyledSelectModal
        full
        show={showRestMedia}
        overFlow={false}
        onHide={onCancelRestModal}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          休憩メディア選択
        </Modal.Header>
        <Modal.Body>
          <Tabs>
            <TabList>
              <Tab style={{ padding: "10px 20px" }}>画像</Tab>
              <Tab style={{ padding: "10px 20px" }}>動画</Tab>
            </TabList>

            <TabPanel>
              <Margin top={10} left={10} right={10} bottom={10}>
                <ImageMedia
                  imagesData={imagesData?.images
                    .filter(image => checkedImageKeys.includes(image.imageId))
                    .filter(image => image.imageId !== checkedWaitMediaKey)
                    .filter(image => image.imageId !== checkedBackMediaKey)
                    .filter(image => image.imageId !== checkedScreenKey)
                    .filter(image => image.imageId !== checkedBlurKey)
                    .filter(image => image.imageId !== checkedReceptionKey)
                    .filter(image => image.imageId !== checkedDetectionKey)}
                  checkedImageKeys={
                    checkedRestMediaKey !== undefined
                      ? [checkedRestMediaKey]
                      : undefined
                  }
                  handleImageCheck={handleRestMediaCheck}
                />
              </Margin>
            </TabPanel>
            <TabPanel>
              <Margin top={10} left={10} right={10} bottom={10}>
                <VideoMedia
                  videosData={videosData?.videos
                    .filter(video => checkedVideoKeys.includes(video.videoId))
                    .filter(video => video.videoId !== checkedWaitMediaKey)
                    .filter(video => video.videoId !== checkedBackMediaKey)
                    .filter(video => video.videoId !== checkedScreenKey)}
                  checkedVideoKeys={
                    checkedRestMediaKey !== undefined
                      ? [checkedRestMediaKey]
                      : undefined
                  }
                  handleVideoCheck={handleRestMediaCheck}
                />
              </Margin>
            </TabPanel>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledPrimaryButton onClick={setRestImage}>
              設定する
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelRestModal}>
              閉じる
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledSelectModal>

      <StyledSelectModal
        full
        show={showBackMedia}
        overFlow={false}
        onHide={onCancelBackModal}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          背景メディア選択
        </Modal.Header>
        <Modal.Body>
          <Tabs>
            <TabList>
              <Tab style={{ padding: "10px 20px" }}>画像</Tab>
              <Tab style={{ padding: "10px 20px" }}>動画</Tab>
            </TabList>

            <TabPanel>
              <Margin top={10} left={10} right={10} bottom={10}>
                <ImageMedia
                  imagesData={imagesData?.images
                    .filter(image => checkedImageKeys.includes(image.imageId))
                    .filter(image => image.imageId !== checkedWaitMediaKey)
                    .filter(image => image.imageId !== checkedRestMediaKey)
                    .filter(image => image.imageId !== checkedScreenKey)
                    .filter(image => image.imageId !== checkedBlurKey)
                    .filter(image => image.imageId !== checkedReceptionKey)
                    .filter(image => image.imageId !== checkedDetectionKey)}
                  checkedImageKeys={
                    checkedBackMediaKey !== undefined
                      ? [checkedBackMediaKey]
                      : undefined
                  }
                  handleImageCheck={handleBackMediaCheck}
                />
              </Margin>
            </TabPanel>
            <TabPanel>
              <Margin top={10} left={10} right={10} bottom={10}>
                <VideoMedia
                  videosData={videosData?.videos
                    .filter(video => checkedVideoKeys.includes(video.videoId))
                    .filter(video => video.videoId !== checkedRestMediaKey)
                    .filter(video => video.videoId !== checkedWaitMediaKey)
                    .filter(video => video.videoId !== checkedScreenKey)}
                  checkedVideoKeys={
                    checkedBackMediaKey !== undefined
                      ? [checkedBackMediaKey]
                      : undefined
                  }
                  handleVideoCheck={handleBackMediaCheck}
                />
              </Margin>
            </TabPanel>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledPrimaryButton onClick={setBackMedia}>
              設定する
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelBackModal}>
              閉じる
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledSelectModal>

      <StyledSelectModal
        full
        show={showScreen}
        overFlow={false}
        onHide={onCancelScreenModal}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          スクリーンセーバー選択
        </Modal.Header>
        <Modal.Body>
          <Tabs>
            <TabList>
              <Tab style={{ padding: "10px 20px" }}>画像</Tab>
              <Tab style={{ padding: "10px 20px" }}>動画</Tab>
            </TabList>
            <TabPanel>
              <Margin top={10} left={10} right={10} bottom={10}>
                <ImageMedia
                  imagesData={imagesData?.images
                    .filter(image => checkedImageKeys.includes(image.imageId))
                    .filter(image => image.imageId !== checkedWaitMediaKey)
                    .filter(image => image.imageId !== checkedBackMediaKey)
                    .filter(image => image.imageId !== checkedRestMediaKey)
                    .filter(image => image.imageId !== checkedBlurKey)
                    .filter(image => image.imageId !== checkedReceptionKey)
                    .filter(image => image.imageId !== checkedDetectionKey)}
                  checkedImageKeys={
                    checkedScreenKey !== undefined
                      ? [checkedScreenKey]
                      : undefined
                  }
                  handleImageCheck={handleScreenCheck}
                />
              </Margin>
            </TabPanel>
            <TabPanel>
              <Margin top={10} left={10} right={10} bottom={10}>
                <VideoMedia
                  videosData={videosData?.videos
                    .filter(video => checkedVideoKeys.includes(video.videoId))
                    .filter(video => video.videoId !== checkedWaitMediaKey)
                    .filter(video => video.videoId !== checkedBackMediaKey)
                    .filter(video => video.videoId !== checkedRestMediaKey)
                    .filter(video => video.videoId !== checkedBlurKey)}
                  checkedVideoKeys={
                    checkedScreenKey !== undefined
                      ? [checkedScreenKey]
                      : undefined
                  }
                  handleVideoCheck={handleScreenCheck}
                />
              </Margin>
            </TabPanel>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledPrimaryButton onClick={setScreen}>
              設定する
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelScreenModal}>
              閉じる
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledSelectModal>

      <StyledSelectModal
        full
        show={showBlur}
        overFlow={false}
        onHide={onCancelBlurModal}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          接客時の画像選択
        </Modal.Header>
        <Modal.Body>
          <Margin top={10} left={10} right={10} bottom={10}>
            <ImageMedia
              imagesData={imagesData?.images
                .filter(image => checkedImageKeys.includes(image.imageId))
                .filter(image => image.imageId !== checkedWaitMediaKey)
                .filter(image => image.imageId !== checkedRestMediaKey)
                .filter(image => image.imageId !== checkedScreenKey)
                .filter(image => image.imageId !== checkedBackMediaKey)
                .filter(image => image.imageId !== checkedReceptionKey)
                .filter(image => image.imageId !== checkedDetectionKey)}
              checkedImageKeys={
                checkedBlurKey !== undefined ? [checkedBlurKey] : undefined
              }
              handleImageCheck={handleBlurCheck}
            />
          </Margin>
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledPrimaryButton onClick={setBlurMedia}>
              設定する
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelBlurModal}>
              閉じる
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledSelectModal>

      <StyledSelectModal
        full
        show={showDetection}
        overFlow={false}
        onHide={onCancelDetectionModal}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          呼び出しボタンが押された時の画像選択
        </Modal.Header>
        <Modal.Body>
          <Margin top={10} left={10} right={10} bottom={10}>
            <ImageMedia
              imagesData={imagesData?.images
                .filter(image => checkedImageKeys.includes(image.imageId))
                .filter(image => image.imageId !== checkedWaitMediaKey)
                .filter(image => image.imageId !== checkedRestMediaKey)
                .filter(image => image.imageId !== checkedScreenKey)
                .filter(image => image.imageId !== checkedBackMediaKey)
                .filter(image => image.imageId !== checkedReceptionKey)
                .filter(image => image.imageId !== checkedBlurKey)}
              checkedImageKeys={
                checkedDetectionKey !== undefined
                  ? [checkedDetectionKey]
                  : undefined
              }
              handleImageCheck={handleDetectionCheck}
            />
          </Margin>
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledPrimaryButton onClick={setDetectionMedia}>
              設定する
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelDetectionModal}>
              閉じる
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledSelectModal>

      <StyledSelectModal
        full
        show={showReception}
        overFlow={false}
        onHide={onCancelReceptionModal}
        isOpen={isOpen}
      >
        <Modal.Header style={{ textAlign: "center", fontWeight: "bold" }}>
          接客開始時のデフォルト画像選択
        </Modal.Header>
        <Modal.Body>
          <Margin top={10} left={10} right={10} bottom={10}>
            <ImageMedia
              imagesData={imagesData?.images
                .filter(image => checkedImageKeys.includes(image.imageId))
                .filter(image => image.imageId !== checkedWaitMediaKey)
                .filter(image => image.imageId !== checkedRestMediaKey)
                .filter(image => image.imageId !== checkedScreenKey)
                .filter(image => image.imageId !== checkedBackMediaKey)
                .filter(image => image.imageId !== checkedBlurKey)
                .filter(image => image.imageId !== checkedDetectionKey)}
              checkedImageKeys={
                checkedReceptionKey !== undefined
                  ? [checkedReceptionKey]
                  : undefined
              }
              handleImageCheck={handleReceptionCheck}
            />
          </Margin>
        </Modal.Body>
        <Modal.Footer>
          <>
            <StyledPrimaryButton onClick={setReceptionMedia}>
              設定する
            </StyledPrimaryButton>
            <StyledCancelButton onClick={onCancelReceptionModal}>
              閉じる
            </StyledCancelButton>
          </>
        </Modal.Footer>
      </StyledSelectModal>
    </>
  );
};

export default Shop;
