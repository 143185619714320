const UUID_V4_PATTERN = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");

/**
 * UUID v4 文字列を生成します。
 */
export const uuidv4 = () => {
  return [...UUID_V4_PATTERN]
    .map(v => {
      switch (v) {
        case "x":
          return Math.floor(Math.random() * 16).toString(16);
        case "y":
          return (Math.floor(Math.random() * 4) + 8).toString(16);
        default:
          return v;
      }
    })
    .join("");
};
