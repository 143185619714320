import apiService from "../apiService";
import { ApiResponse } from "../apiResponse";

export interface Pc {
  pcId: string | null;
  pcName: string | null;
}

export interface Result {
  pcList: Pc[];
  company: string | null;
  userId: string | null;
  userName: string | null;
  token: string | null;
  auth: number | string | null;
  userType: number | string | null;
  tokenExpiration: string | null;
  error?: any;
}

export interface loginResult extends ApiResponse {
  data: Result;
}

const loginApi = async (id?: string, password?: string): Promise<Result> => {
  const api = {
    path: "/rura/login/analysis",
    req: {
      method: "POST",
      headers: apiService.createHeaders(),
      body: JSON.stringify({ id, pass: password }),
    },
  };

  const response = await apiService.request<loginResult>(api);
  if (response.resultStatus !== 0) {
    throw response;
  }

  const {
    company,
    token,
    auth,
    userType,
    userId,
    userName,
    pcList,
    tokenExpiration,
  } = response.data;

  return {
    userId,
    userName,
    token,
    auth,
    userType,
    company,
    pcList,
    tokenExpiration,
  };
};

export default loginApi;
