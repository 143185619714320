export const primaryBlue = "#21a8d8";
export const gray = "#c8ced3";
export const darkGray = "#66646A";
export const hgray = "#dadad0";
export const textGray = "#73818f";
export const black = "#2f3539";
export const lightBlue = "#e7eef4";
export const white = "#ffffff";
export const red = "#ef4565";
export const chartsColor = [
  "#6ac1b7",
  "#ee817b",
  "#b2e2ec",
  "#ffbb28",
  "#ff8042",
  "#eb8686",
  "#8cd790",
  "#f99f48",
  "#ffc042",
  "#f7d76b",
  "#f7b46b",
  "#79bd9a",
  "#fc913a",
  "#0088fe",
  "#00c49f",
];
