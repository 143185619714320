import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type PreCondition = {
  id: string;
  condition: [
    {
      value: string | boolean;
      label: string;
    }
  ];
};

type PreConditionState = {
  conditions: PreCondition[];
};

const initialState: PreConditionState = {
  conditions: [
    {
      id: "pcs",
      condition: [
        {
          value: "",
          label: "",
        },
      ],
    },
    {
      id: "users",
      condition: [
        {
          value: "",
          label: "",
        },
      ],
    },
    {
      id: "contents",
      condition: [
        {
          value: "",
          label: "",
        },
      ],
    },
    {
      id: "emotions",
      condition: [
        {
          value: "",
          label: "",
        },
      ],
    },
    {
      id: "actions",
      condition: [
        {
          value: "",
          label: "",
        },
      ],
    },
    {
      id: "audioOnly",
      condition: [
        {
          value: false,
          label: "",
        },
      ],
    },
    {
      id: "textOnly",
      condition: [
        {
          value: false,
          label: "",
        },
      ],
    },
    {
      id: "workerAudioOnly",
      condition: [
        {
          value: false,
          label: "",
        },
      ],
    },
    {
      id: "workerTextOnly",
      condition: [
        {
          value: false,
          label: "",
        },
      ],
    },
  ],
};

const preConditionModule = createSlice({
  name: "preCondition",
  initialState,
  reducers: {
    setPreConditions(
      state: PreConditionState,
      { payload }: PayloadAction<PreCondition[]>
    ) {
      state.conditions.forEach(condition => {
        payload.forEach(selectedConditions => {
          if (condition.id === selectedConditions.id) {
            condition.condition = selectedConditions.condition;
          }
        });
      });
    },
    setInitialPreConditions(state: PreConditionState) {
      state.conditions = initialState.conditions;
    },
  },
});

export const {
  setPreConditions,
  setInitialPreConditions,
} = preConditionModule.actions;

export default preConditionModule.reducer;
