import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    id: string;
    name: string;
    pcIds: string[];
    deusGroupNo: number;
    liteFlg: number;
    memo: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    id: "",
    name: "",
    pcIds: [],
    deusGroupNo: 1,
    liteFlg: 1,
    memo: "",
  },
};

const roomAddFormModule = createSlice({
  name: "roomAddForm",
  initialState,
  reducers: {
    setAddFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.id = payload.formValue.id;
      state.formValue.name = payload.formValue.name;
      state.formValue.pcIds = payload.formValue.pcIds;
      state.formValue.deusGroupNo = payload.formValue.deusGroupNo;
      state.formValue.liteFlg = payload.formValue.liteFlg;
      state.formValue.memo = payload.formValue.memo;
    },
  },
});

export const { setAddFormValue } = roomAddFormModule.actions;

export default roomAddFormModule.reducer;
