import styled from "styled-components";
import { headerFont } from "../../../../utils/fonts";
import { textGray } from "../../../../utils/color";

const ChartHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-family: ${headerFont};
  color: ${textGray};
`;

export default ChartHeader;
