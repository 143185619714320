import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory, Redirect, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { gray, textGray } from "../../../utils/color";
import MuiPrimaryButton from "../../presentational/atoms/Button/MuiPrimaryButton";
import { login as loginApi } from "../../../modules/auth";
import { RootState } from "../../../reducers/rootReducer";
import { RoutePath } from "../../../Routes";
import { Margin } from "../../../utils/styled";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  margin-top: ${props => props.theme.spacing(24)}px;
`;

const LoginForm = styled.form`
  padding: 22px;
  border-radius: 5px;
  border: 1px solid ${gray};
`;

const LabelText = styled.p`
  text-align: left;
  font-size: 16px;
  color: #999;
`;

const Input = styled.input`
  outline: none;
  display: block;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  width: 250px;
  font-size: 20px;
`;

const StyledForgotLink = styled(Link)`
  display: block;
  font-size: 9px;
  color: ${textGray};
`;

const Login = () => {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const { token, error } = useSelector((state: RootState) => state.auth);
  const ErrorMessage = () => {
    switch (error) {
      case 126:
        return (
          <>
            <p style={{ color: "red", fontSize: "6px" }}>
              トークンの有効期限が切れました。
              <br />
              再ログインしてください
            </p>
          </>
        );
      case 105:
        return (
          <>
            <p style={{ color: "red", fontSize: "6px" }}>
              遠隔スタッフIDまたはパスワードが間違っています。
            </p>
          </>
        );
      case 102:
        return (
          <>
            <p style={{ color: "red", fontSize: "6px" }}>
              遠隔スタッフIDまたはパスワードが間違っています。
            </p>
          </>
        );
      default:
        return <></>;
    }
  };

  const submit = async () => {
    try {
      await dispatch(loginApi(id, password));
      history.push(RoutePath.top);
    } catch (e) {
      setPassword("");
      console.error(e);
      throw e;
    }
  };

  const onEnter = (e: any) => {
    if (e.keyCode === 13) submit();
  };

  useEffect(() => {
    const inputRef = document.getElementById("user");
    if (inputRef !== null) {
      inputRef.focus();
    }
  }, []);

  return token ? (
    <Redirect to={RoutePath.top} />
  ) : (
    <Wrapper>
      <LoginForm>
        <LabelText>遠隔スタッフID</LabelText>
        <Input
          type="text"
          id="user"
          placeholder="UserID"
          value={id}
          onChange={e => setId(e.target.value)}
          onKeyDown={onEnter}
        />
        <LabelText>パスワード</LabelText>
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          onKeyDown={onEnter}
        />
        <MuiPrimaryButton variant="contained" color="primary" onClick={submit}>
          ログイン
        </MuiPrimaryButton>
        <ErrorMessage />
        <Margin top={5}>
          <StyledForgotLink to={RoutePath.forgot}>
            パスワードを忘れた方はこちら
          </StyledForgotLink>
        </Margin>
      </LoginForm>
    </Wrapper>
  );
};

export default Login;
