import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import Waver from "./Waver";
import Dragger from "./Dragger";
import WebAudio from "../../../../utils/recorder/webaudio";

const PlayerWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  height: 160px;
  display: block;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
`;

const Clipper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const DragCurrent = styled(Dragger)`
  background: #0cf;
`;

const containerWidth = 1400;
const containerHeight = 160;

function getClipRect(start: number, end: number) {
  return `rect(0, ${end}px, ${containerHeight}px, ${start}px)`;
}

const color1 = "#0cf";
const color2 = "#0cf";
const gray1 = "#ddd";
const gray2 = "#e3e3e3";

type Props = {
  audioBuffer: AudioBuffer;
  startTime: number;
  endTime: number;
  currentTime: number;
  onStartTimeChange: Function;
  onEndTimeChange: Function;
  onCurrentTimeChange: Function;
};

const Player = (props: Props) => {
  const {
    audioBuffer,
    currentTime,
    startTime,
    endTime,
    onStartTimeChange,
    onEndTimeChange,
    onCurrentTimeChange,
  } = props;
  const [audio, setAudio] = useState<WebAudio | null>(null);
  const [, setLocalCurrentTime] = useState<number>(0);
  const prevAudioBuffer = useRef<AudioBuffer>();
  const [processing, setProcessing] = useState(false);

  const getWidthDurationRatio = useCallback(() => {
    return containerWidth / audioBuffer.duration;
  }, [audioBuffer.duration]);

  const onAudioProcess = useCallback(
    (crrnt: number) => {
      setProcessing(true);
      if (currentTime < endTime && crrnt >= endTime) {
        onCurrentTimeChange(startTime || 0);
      } else {
        setLocalCurrentTime(crrnt);
        onCurrentTimeChange(crrnt);
      }
    },
    [currentTime, endTime, onCurrentTimeChange, startTime]
  );

  const onAudioProcessEnd = useCallback(() => {
    onCurrentTimeChange(startTime || 0);
    setProcessing(false);
  }, [onCurrentTimeChange, startTime]);

  const initWebAudio = useCallback(() => {
    prevAudioBuffer.current = audioBuffer;
    const localAudio = new WebAudio(audioBuffer);
    localAudio.on("process", onAudioProcess);
    localAudio.on("end", onAudioProcessEnd);
    localAudio.play(currentTime);

    setAudio(localAudio);
  }, [audioBuffer, currentTime, onAudioProcess, onAudioProcessEnd]);

  useEffect(() => {
    if (prevAudioBuffer.current !== audioBuffer) {
      initWebAudio();
    }
  }, [audioBuffer, initWebAudio]);

  useEffect(() => {
    if (audio !== null && currentTime >= endTime) {
      audio.pause();
    }
  }, [audio, currentTime, endTime]);

  const pos2Time = useCallback(
    (pos: any) => {
      return pos / getWidthDurationRatio();
    },
    [getWidthDurationRatio]
  );

  const keepInRange = (x: number) => {
    if (x < 0) {
      return 0;
    }

    if (x > containerWidth) {
      return containerWidth;
    }

    return x;
  };

  const dragEnd = (pos: any) => {
    onEndTimeChange(pos2Time(keepInRange(pos.x)));
  };

  const dragCurrent = (pos: any) => {
    onCurrentTimeChange(pos2Time(keepInRange(pos.x)));
  };

  const dragStart = (pos: any) => {
    onStartTimeChange(pos2Time(keepInRange(pos.x)));
  };

  const reStart1 = (time: number) => {
    if (audio !== null) {
      if (!processing) {
        audio.play(pos2Time(keepInRange(time)));
      } else {
        audio.pause();
        audio.play(pos2Time(keepInRange(time)));
      }
    }
  };

  const reStart2 = () => {
    if (audio !== null) {
      if (!processing) {
        audio.play(startTime);
      } else {
        audio.pause();
        audio.play(startTime);
      }
    }
  };

  const time2Pos = (time: number) => {
    return time * getWidthDurationRatio();
  };
  const start = time2Pos(startTime);
  const end = time2Pos(endTime);
  const current = time2Pos(currentTime);

  return (
    <PlayerWrapper>
      <Clipper>
        <Waver
          audioBuffer={audioBuffer}
          width={containerWidth}
          height={containerHeight}
          color1={gray1}
          color2={gray2}
        />
      </Clipper>
      <Clipper style={{ clip: getClipRect(start, end) }}>
        <Waver
          audioBuffer={audioBuffer}
          width={containerWidth}
          height={containerHeight}
          color1={color1}
          color2={color2}
        />
      </Clipper>
      <Dragger x={start} y={0} onDrag={dragStart} handleReStart={reStart1} />
      {processing ? <DragCurrent x={current} y={0} onDrag={dragCurrent} /> : ""}
      <Dragger x={end} y={0} onDrag={dragEnd} handleReStart={reStart2} />
    </PlayerWrapper>
  );
};

export default Player;
