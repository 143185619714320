import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    id?: string;
    text?: string;
    infoButtonTypeId?: string;
    infoType?: string;
    val?: string;
    remark?: string;
    displayTypeId?: string;
    newInfoButtonTypeId?: string;
    newInfoButtonTypeText?: string;
    newInfoType?: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    id: "",
    text: "",
    infoButtonTypeId: "",
    infoType: "",
    val: "",
    remark: "",
    displayTypeId: "",
    newInfoButtonTypeId: "",
    newInfoButtonTypeText: "",
    newInfoType: "",
  },
};

const infoButtonEditFormModule = createSlice({
  name: "infoButtonEditForm",
  initialState,
  reducers: {
    setEditFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.id = payload.formValue.id ? payload.formValue.id : "";
      state.formValue.text = payload.formValue.text
        ? payload.formValue.text
        : "";
      state.formValue.infoButtonTypeId = payload.formValue.infoButtonTypeId
        ? payload.formValue.infoButtonTypeId
        : "";
      state.formValue.val = payload.formValue.val ? payload.formValue.val : "";
      state.formValue.infoType = payload.formValue.infoType
        ? payload.formValue.infoType
        : "";
      state.formValue.remark = payload.formValue.remark
        ? payload.formValue.remark
        : "";
      state.formValue.displayTypeId = payload.formValue.displayTypeId
        ? payload.formValue.displayTypeId
        : "";
      state.formValue.newInfoButtonTypeId = payload.formValue
        .newInfoButtonTypeId
        ? payload.formValue.newInfoButtonTypeId
        : "";
      state.formValue.newInfoButtonTypeText = payload.formValue
        .newInfoButtonTypeText
        ? payload.formValue.newInfoButtonTypeText
        : "";
      state.formValue.newInfoType = payload.formValue.newInfoType
        ? payload.formValue.newInfoType
        : "";
    },
    setInitialEditFormValue(state: FormValueState) {
      state.formValue = initialState.formValue;
    },
  },
});

export const {
  setEditFormValue,
  setInitialEditFormValue,
} = infoButtonEditFormModule.actions;

export default infoButtonEditFormModule.reducer;
