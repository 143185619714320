import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MediaEvent } from "../../../../api/Management/actionsApi";

export type FormValueState = {
  formValue: {
    id: string;
    name: string;
    robotType: string;
    infoButtons: string[];
    mediaEvents: MediaEvent[];
    remark: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    id: "",
    name: "",
    robotType: "",
    infoButtons: [],
    mediaEvents: [],
    remark: "",
  },
};

const actionEditFormModule = createSlice({
  name: "actionEditForm",
  initialState,
  reducers: {
    setEditFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.id = payload.formValue.id;
      state.formValue.name = payload.formValue.name;
      state.formValue.robotType = payload.formValue.robotType;
      state.formValue.infoButtons = payload.formValue.infoButtons;
      state.formValue.mediaEvents = payload.formValue.mediaEvents;
      state.formValue.remark = payload.formValue.remark;
    },
    setEditFormInitialValue(state: FormValueState) {
      state.formValue = initialState.formValue;
    },
  },
});

export const {
  setEditFormValue,
  setEditFormInitialValue,
} = actionEditFormModule.actions;

export default actionEditFormModule.reducer;
