import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  Form,
  Schema,
  FormGroup,
  ControlLabel,
  FormControl,
  Alert,
} from "rsuite";
import { FormInstance } from "rsuite/lib/Form";
import { RootState } from "../../../../../reducers/rootReducer";
import { Margin } from "../../../../../utils/styled";
import { setResetPasswordFormValue } from "../../../../../modules/Management/Form/Profile/resetPasswordFormModule";
import { StyledPrimaryButton, StyledCancelButton } from "../Users";
import { checkPassword } from "../../../../../modules/Management/Users/confirmPasswordApiModule";
import Loading from "../../../../presentational/molecules/Loading";
import { resetPassword } from "../../../../../modules/Management/Users/changePasswordApiModule";
import { RoutePath } from "../../../../../Routes";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
`;

const FormWrapper = styled.div`
  display: inline-block;
  margin: 30px auto;
`;

const StyledImage = styled.img`
  width: 30%;
  height: 100px;
  object-fit: cover;
`;

const model = Schema.Model({
  password: Schema.Types.StringType()
    .addRule(value => {
      const pattern = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,20}$/;
      const result = value.match(pattern);
      if (result === null) {
        return false;
      }
      return true;
    }, "パスワードは8字以上20字以内、大文字、小文字、数字が少なくとも1つ含まれている必要があります。")
    .isRequired("必須項目です"),
});

const ResetPassword = () => {
  const [form, setForm] = useState<FormInstance>();
  const dispatch = useDispatch();
  const { uuid } = useParams<{ uuid: string }>();
  const history = useHistory();
  const location = useLocation();

  const { isLoading, error: confirmPasswordError } = useSelector(
    (state: RootState) => state.confirmPassword
  );
  const { isLoading: changeLoading } = useSelector(
    (state: RootState) => state.changePassword
  );

  const { formValue } = useSelector(
    (state: RootState) => state.resetPasswordForm
  );

  useEffect(() => {
    if (uuid) {
      dispatch(checkPassword(uuid));
    }
  }, [dispatch, uuid]);

  useEffect(() => {
    if (confirmPasswordError !== 131) return;
    history.push(RoutePath.expiredPassword);
  }, [confirmPasswordError, history]);

  const handleFormChange = (value: any) => {
    dispatch(setResetPasswordFormValue({ formValue: value }));
  };

  const handleSubmit = async () => {
    const checkResult = form!.check();
    if (uuid && checkResult) {
      try {
        await dispatch(resetPassword(uuid, formValue.password));
        if (location.search) {
          const params = new URLSearchParams(location.search);
          const redirectUrl = params.get("redirect_url");
          if (redirectUrl) {
            window.location.replace(redirectUrl);
          }
        }
        await history.push(RoutePath.management.profile);
        await Alert.success("パスワードのリセットが完了しました", 5000);
      } catch (err) {
        Alert.error("不明なエラーが発生しました", 5000);
        console.error(err);
      }
    }
  };

  return (
    <>
      {!isLoading ? (
        <>
          <Wrapper>
            <StyledImage src="/logo.png" alt="RURAロゴ" />
            <FormWrapper>
              <Form
                model={model}
                formValue={formValue}
                ref={(ref: FormInstance) => setForm(ref)}
                onChange={handleFormChange}
              >
                <FormGroup>
                  <ControlLabel style={{ textAlign: "left" }}>
                    パスワード
                  </ControlLabel>
                  <FormControl name="password" type="password" />
                </FormGroup>
              </Form>
              <Margin top={20}>
                {!changeLoading ? (
                  <StyledPrimaryButton onClick={handleSubmit}>
                    変更する
                  </StyledPrimaryButton>
                ) : (
                  <StyledCancelButton>変更中</StyledCancelButton>
                )}
              </Margin>
            </FormWrapper>
          </Wrapper>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ResetPassword;
