import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import updateRemark, {
  UpdateRenark,
} from "../../../api/Management/shopApi/updateRemark";
import { expiredToken, setTokenExpiration } from "../../auth";
import { AppThunk } from "../../../stores";
import { startLoading, loadingFailed, ApiState } from "../../../api/apiService";

const apiInitialState: ApiState<UpdateRenark> = {
  isLoading: false,
  error: null,
  data: null,
};

const updateRemarkApiModule = createSlice({
  name: "updateRemark",
  initialState: apiInitialState,
  reducers: {
    getApiStart: startLoading,
    getApiSuccess(state, { payload }: PayloadAction<UpdateRenark>) {
      state.isLoading = false;
      state.error = null;
      state.data = payload;
    },
    getApiFailure: loadingFailed,
  },
});

export const {
  getApiStart,
  getApiSuccess,
  getApiFailure,
} = updateRemarkApiModule.actions;

export default updateRemarkApiModule.reducer;

export const putRemark = (
  token: string,
  id: string,
  waitId: string,
  waitReset: string,
  waitMediaType: string,
  restId: string,
  restReset: string,
  restMediaType: string,
  backMediaId: string,
  backReset: string,
  backMediaType: string
): AppThunk => async dispatch => {
  try {
    dispatch(getApiStart());
    const data = await updateRemark(
      token,
      id,
      waitId,
      waitReset,
      waitMediaType,
      restId,
      restReset,
      restMediaType,
      backMediaId,
      backReset,
      backMediaType
    );
    dispatch(setTokenExpiration(data.tokenExpiration));
    dispatch(getApiSuccess(data));
  } catch (err) {
    switch (err.resultStatus) {
      case 126:
        dispatch(expiredToken(err.resultStatus));
        break;
      case 115:
        dispatch(getApiFailure(err.resultStatus));
        throw err;
      default:
        console.error(err);
        break;
    }
    dispatch(getApiFailure(err));
  }
};
