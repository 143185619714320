import styled from "styled-components";
import { white } from "../../../../utils/color";

const ChartsBox = styled.div`
  flex: auto;
  max-width: 700px;
  min-width: 500px;
  height: 500px;
  background: ${white};
  border-radius: 6px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  margin: 20px;
`;

export default ChartsBox;
