import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    password: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    password: "",
  },
};

const resetPasswordFormModule = createSlice({
  name: "resetPasswordForm",
  initialState,
  reducers: {
    setResetPasswordFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.password = payload.formValue.password;
    },
  },
});

export const { setResetPasswordFormValue } = resetPasswordFormModule.actions;

export default resetPasswordFormModule.reducer;
