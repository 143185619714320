import styled from "styled-components";

const BaseButton = styled.a`
  display: inline-block;
  padding: 0.5em 1.25em;
  text-decoration: none !important;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
`;

export default BaseButton;
