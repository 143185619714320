import React from "react";
import styled from "styled-components";
import CardHeader from "../../atoms/Text/CardHeader";
import { white } from "../../../../utils/color";
import { Margin } from "../../../../utils/styled";

interface IProps {
  header: string;
  count: number | string | null;
  isLoading: boolean;
}

const CardBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 33%;
  max-width: 300px;
  background: ${white};
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
`;

const TextBox = styled.div`
  width: 100%;
  padding: 16px 18px;
  text-align: left;
  vertical-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-right: 20px;
`;

const Card = (props: IProps) => {
  const { header, count, isLoading } = props;

  return (
    <CardBox>
      <TextBox>
        <CardHeader>{header}</CardHeader>
        <Margin top={9} bottom={9}>
          {isLoading ? (
            "Loading..."
          ) : (
            <div>
              {count}
              <span style={{ fontSize: "12px", paddingLeft: "10px" }}>
                {header === "接客した数" ||
                header === "接客しなかった数" ||
                header === "今月の接客総数" ||
                header === "接客画面に入った数"
                  ? "回"
                  : ""}
              </span>
            </div>
          )}
        </Margin>
      </TextBox>
    </CardBox>
  );
};

export default Card;
