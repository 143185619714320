import React, { useMemo } from "react";
import styled from "styled-components";
import _ from "lodash";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
} from "recharts";
import { chartsColor } from "../../../../../utils/color";

const LabelText = styled.text`
  font-weight: bold;
`;

const SimplePieChart = (props: { data: { name: string; value: number }[] }) => {
  const { data } = props;

  const sortedData = useMemo(() => {
    const clonedData = _.cloneDeep(data);
    return clonedData.sort((a: any, b: any) => b.value - a.value);
  }, [data]);
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = useMemo(
    () => ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      index,
    }: any) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return percent !== 0 ? (
        <LabelText
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </LabelText>
      ) : (
        <LabelText />
      );
    },
    [RADIAN]
  );

  return (
    <ResponsiveContainer width="100%" height="90%">
      <PieChart>
        <Pie
          data={sortedData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={100}
          fill="#8884d8"
          isAnimationActive={false}
          startAngle={450}
          endAngle={90}
        >
          {data.map((entry, index) => (
            <Cell
              key={index.toString()}
              fill={chartsColor[index % chartsColor.length]}
            />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default SimplePieChart;
