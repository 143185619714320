import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk, persistor } from "../stores";
import loginApi, { Result } from "../api/loginApi";
import { meApi } from "../api/loginApi/me";
import { RootState } from "../reducers/rootReducer";

const initialState: Result = {
  pcList: [],
  company: null,
  userId: null,
  userName: null,
  token: null,
  auth: null,
  userType: null,
  tokenExpiration: null,
  error: null,
};

interface apiError {
  resultStatus: string;
}

const authModule = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, { payload }: PayloadAction<Result>) {
      state.pcList = payload.pcList;
      state.company = payload.company;
      state.userId = payload.userId;
      state.userName = payload.userName;
      state.auth = payload.auth;
      state.userType = payload.userType;
      state.token = payload.token;
      state.tokenExpiration = payload.tokenExpiration;
      state.error = null;
    },
    setError(state, { payload }: PayloadAction<apiError>) {
      state.error = payload.resultStatus;
    },
    setTX(state, { payload }: PayloadAction<string>) {
      state.tokenExpiration = payload;
    },
  },
});

export const { setUser, setError, setTX } = authModule.actions;

export const isAuthSelector = (state: RootState) => {
  if (state.auth.token !== null && state.auth.tokenExpiration !== null) {
    return true;
  }

  return false;
};

export default authModule.reducer;

export const login = (
  id: string,
  password: string
): AppThunk => async dispatch => {
  const user: Result = await loginApi(id, password).catch(e => {
    dispatch(setError(e));
    throw e;
  });
  dispatch(setUser(user));
};

export const me = (token: string): AppThunk => async dispatch => {
  const user: Result = await meApi(token).catch(e => {
    dispatch(setError(e));
    throw e;
  });
  dispatch(setUser(user));
};

export const logout = (): AppThunk => async dispatch => {
  try {
    await dispatch(setUser(initialState));
    persistor.purge();
  } catch (err) {
    console.error(err);
  }
};

export const expiredToken = (code: any): AppThunk => async dispatch => {
  try {
    await dispatch(setUser(initialState));
    persistor.purge();
    await dispatch(setError({ resultStatus: code }));
  } catch (err) {
    console.error(err);
  }
};

export const setTokenExpiration = (tx: string): AppThunk => async dispatch => {
  try {
    await dispatch(setTX(tx));
  } catch (err) {
    console.error(err);
  }
};
