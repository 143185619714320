import { createMuiTheme } from "@material-ui/core";
import { lightBlue } from "./utils/color";

export const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "メイリオ",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      main: "#3DA9FC",
      light: "#54D5FF",
      dark: "#326FDF",
    },
    error: {
      main: "#ef4565",
      light: "#F45B94",
      dark: "#D43E3A",
    },
    text: {
      primary: "#5f6c7b",
      secondary: "#FFFFFE",
    },
    background: {
      default: "#FFFFFE",
    },
  },
  overrides: {
    MuiTableRow: {
      root: {
        "&$selected, &$selected:hover": {
          backgroundColor: lightBlue,
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#FFFFFE",
        "&$active": {
          color: "#FFFFFE",
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: "#5f6c7b",
        "&$checked": {
          color: "#5f6c7b",
        },
      },
    },
  },
});
