import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Check = {
  id: string;
  checkInfo: {
    checked: boolean;
    checkedKeys: string[];
    indeterminate: boolean;
  };
};

type CheckState = {
  checks: Check[];
};

const initialState: CheckState = {
  checks: [
    {
      id: "users",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "rooms",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "images",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "videos",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "speeches",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "screens",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "shopImages",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "shopWillDeleteImages",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "shopVideos",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "shopWillDeleteVideos",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "shopSpeeches",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "shopWillDeleteSpeeches",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "shopInfoButtons",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "shopWillDeleteInfoButtons",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "cameras",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "monitors",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "companies",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "payments",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "paymentItems",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "paymentSettings",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "robots",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "roomGroups",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "motions",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "robotTypes",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "actions",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "actionImages",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "actionVideos",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "actionSpeeches",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "pcs",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "notificationButtons",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "shopNotificationButtons",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
    {
      id: "shopWillDeleteNotificationButtons",
      checkInfo: {
        checked: false,
        checkedKeys: [],
        indeterminate: false,
      },
    },
  ],
};

const checkModule = createSlice({
  name: "checkCells",
  initialState,
  reducers: {
    setChecked(state: CheckState, { payload }: PayloadAction<Check>) {
      state.checks.forEach(check => {
        if (check.id === payload.id) {
          check.checkInfo.checked = payload.checkInfo.checked;
        }
      });
    },
    setIndeterminate(state: CheckState, { payload }: PayloadAction<Check>) {
      state.checks.forEach(check => {
        if (check.id === payload.id) {
          check.checkInfo.indeterminate = payload.checkInfo.indeterminate;
        }
      });
    },
    setCheckedKeys(state: CheckState, { payload }: PayloadAction<Check>) {
      state.checks.forEach(check => {
        if (check.id === payload.id) {
          check.checkInfo.checkedKeys = payload.checkInfo.checkedKeys;
        }
      });
    },
  },
});

export const {
  setChecked,
  setCheckedKeys,
  setIndeterminate,
} = checkModule.actions;

export default checkModule.reducer;
