import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormValueState = {
  formValue: {
    pcId: string;
    name: string;
    actionId: string;
    robot: string;
    ipAddress: string;
    address1: string;
    address2: string;
    telNumber: string;
    notificationButtons: string[];
    csStartSoundNo: string;
    remark: string;
  };
};

const initialState: FormValueState = {
  formValue: {
    pcId: "",
    name: "",
    actionId: "",
    robot: "",
    ipAddress: "",
    address1: "",
    address2: "",
    telNumber: "",
    notificationButtons: [""],
    csStartSoundNo: "0",
    remark: "",
  },
};

const pcEditFormModule = createSlice({
  name: "pcEditForm",
  initialState,
  reducers: {
    setEditFormValue(
      state: FormValueState,
      { payload }: PayloadAction<FormValueState>
    ) {
      state.formValue.pcId = payload.formValue.pcId;
      state.formValue.name = payload.formValue.name;
      state.formValue.actionId = payload.formValue.actionId;
      state.formValue.robot = payload.formValue.robot;
      state.formValue.ipAddress = payload.formValue.ipAddress;
      state.formValue.address1 = payload.formValue.address1;
      state.formValue.address2 = payload.formValue.address2;
      state.formValue.telNumber = payload.formValue.telNumber;
      state.formValue.notificationButtons =
        payload.formValue.notificationButtons;
      state.formValue.csStartSoundNo = payload.formValue.csStartSoundNo;
      state.formValue.remark = payload.formValue.remark;
    },
    setEditFormInitialValue(state: FormValueState) {
      state.formValue = initialState.formValue;
    },
  },
});

export const {
  setEditFormValue,
  setEditFormInitialValue,
} = pcEditFormModule.actions;

export default pcEditFormModule.reducer;
