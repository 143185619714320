import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import getCsCount, { CsCount } from "../../api/Analysis/csCountApi";
import { AppThunk } from "../../stores";
import { expiredToken } from "../auth";
import { ApiState, startLoading, loadingFailed } from "../../api/apiService";

const apiInitialState: ApiState<CsCount> = {
  isLoading: false,
  error: null,
  data: {
    actualTotalCounts: 0,
    actualTotalHours: "00:00:00",
    totalCounts: 0,
    totalHours: "00:00:00",
    totalNotServiceHours: "00:00:00",
  },
};

const csCountApiModule = createSlice({
  name: "csCountApi",
  initialState: apiInitialState,
  reducers: {
    getApiStart: startLoading,
    getApiSuccess(state, { payload }: PayloadAction<CsCount>) {
      state.isLoading = false;
      state.error = null;
      state.data = payload;
    },
    getApiFailure: loadingFailed,
  },
});

export const {
  getApiStart,
  getApiSuccess,
  getApiFailure,
} = csCountApiModule.actions;

export default csCountApiModule.reducer;

export const fetchCsCount = (
  token: string,
  startTime?: string,
  finishTime?: string,
  storeList?: (string | null)[]
): AppThunk => async dispatch => {
  try {
    dispatch(getApiStart());
    const data = await getCsCount(token, startTime, finishTime, storeList);
    dispatch(getApiSuccess(data));
  } catch (err) {
    switch (err.resultStatus) {
      case 126:
        dispatch(expiredToken(err.resultStatus));
        break;
      case 115:
        dispatch(getApiFailure(err.resultStatus));
        throw err;
      default:
        console.error(err);
        break;
    }
    dispatch(getApiFailure(err));
  }
};
