/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { white, gray, textGray } from "../../../../utils/color";
import { Worker } from "../../../../api/Analysis/workersApi";
import { RootState } from "../../../../reducers/rootReducer";
import { RoutePath } from "../../../../Routes";

declare module "react-table" {
  export interface TableOptions<D extends object> extends UseSortByOptions<D> {}

  export interface TableInstance<D extends object = {}>
    extends UseSortByInstanceProps<D> {}

  export interface TableState<D extends object = {}>
    extends UseSortByState<D> {}

  export interface ColumnInstance<D extends object = {}>
    extends UseSortByColumnProps<D> {}
}

const TableWrapper = styled.div`
  overflow: scroll;
  max-height: 40vh;
  background-color: ${white};
  border-radius: 6px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
`;

const Tables = styled.table`
  width: 100%;
`;

const Thead = styled.thead`
  font-weight: bold;
`;

const Tbody = styled.tbody``;

const Tr = styled.tr``;

const Th = styled.th`
  &:first-child {
    z-index: 3;
    border-right: solid 1px ${gray};
    left: 0;
  }
  white-space: nowrap;
  border-bottom: 1px solid ${gray};
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 20px;
  background-color: #eee;
`;

const TbTh = styled(Th)`
  &:first-child {
    z-index: 1;
    border-right: solid 1px ${gray};
    left: 0;
  }
  z-index: 1;
  background-color: ${white};
`;

const Td = styled.td`
  &:nth-of-type(1) {
    border-left: solid 1px ${gray};
  }
  padding: 20px;
  text-align: center;
`;

const StyledSpan = styled.span``;

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  height: 100%;
  color: ${textGray};
  padding: 20px;
`;

type Props = {
  data: Worker[];
};

const Table = ({ data }: Props) => {
  const datas = useMemo(() => data, [data]);
  const columns = useMemo(
    () => [
      {
        Header: "名前",
        accessor: "userName",
      },
      {
        Header: "接客数",
        accessor: "totalServedCount",
      },
      {
        Header: "接客時間",
        accessor: "totalServedTime",
      },
      {
        Header: "シフトイン時間",
        accessor: "logTime",
      },
      {
        Header: "休憩時間",
        accessor: "restTime",
      },
      {
        Header: "スピーチ使用数",
        accessor: "speechCount",
      },
      {
        Header: "モーション使用数",
        accessor: "motionCount",
      },
      {
        Header: "画像切替使用数",
        accessor: "imageCount",
      },
      {
        Header: "動画使用数",
        accessor: "videoCount",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data: datas,
    },
    useSortBy
  );

  const { workers } = useSelector((state: RootState) => state.workers);

  return (
    <>
      <TableWrapper>
        <Tables {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup, hidx: number) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, cidx: number) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <StyledSpan>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon size="sm" icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon size="sm" icon={faSortUp} />
                        )
                      ) : (
                        "-"
                      )}
                    </StyledSpan>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row, ri) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    if (index === 0) {
                      const user = workers
                        ? workers.filter(
                            worker => worker.userName === cell.value
                          )[0]
                        : "";

                      return (
                        <TbTh key={index.toString()}>
                          <StyledLink
                            to={`${RoutePath.analysis.workers}/${
                              user ? user.userId : ""
                            }`}
                          >
                            {cell.render("Cell")}
                          </StyledLink>
                        </TbTh>
                      );
                    }
                    return (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Tables>
      </TableWrapper>
    </>
  );
};

export default Table;
